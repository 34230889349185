import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from 'react-router-dom';
const SkeletonBreadcrumb = () => {
    return (
        <div className="row mainbreadcrumb-responsive">
            <div className="col-md-6 my-4">
                <div className="d-flex px-3">
                    <Skeleton className="py-1 px-4" /> /{" "}
                    <Skeleton className="py-1 px-4" /> /{" "}
                </div>
            </div>
            <div className="col-md-6 mt-4">
                <div className="d-flex justify-content-end">
                    <Skeleton className="py-1 px-5" style={{ width: "240px" }} />
                </div>
            </div>
        </div>
    );
};
class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Details: [],
            Logintime: "",
            Email: "",
            loader: true,
        };
    }
    componentDidMount() {
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        var lastlogin = localStorage.getItem("lastlogin");
        var LastDateTime = JSON.parse(lastlogin);
        this.setState({
            Details: details[0],
            Logintime: LastDateTime,
            Email: details[0].fld_email,
            loader: false,
        });
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                {this.state.loader == true ? (
                    <>
                        <SkeletonBreadcrumb />
                    </>
                ) : (
                    <div className="row mainbreadcrumb-responsive">
                        <div className="col-md-6">
                            <div>
                                <ul className="breadcrumbs">
                                    <li style={{ fontWeight: "900" }}>
                                        <Link
                                            to="/dashboard"
                                        >
                                            {
                                                JSON.parse(localStorage.getItem("ClientDetails"))[0]
                                                    .fld_companyname
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={this.props.urlPath}>{this.props.breadcrumbItem}</a>
                                    </li>
                                    <li>
                                        <a href={this.props.urlPath1 ? this.props.urlPath1 : ""}>
                                            {this.props.breadcrumbItem1
                                                ? this.props.breadcrumbItem1
                                                : ""}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="AccountExpiry">
                                <p className="last-login-font">
                                    Last Login - {this.state.Logintime}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default Breadcrumbs;
