export const setname=name =>{
    return{
        type:"SET_NAME",
        payload:name
    }
}
export const setmobile=mobile =>{
    return{
        type:"SET_MOBILE",
        payload:mobile
    }
}
export const setemail=email =>{
  return{
      type:"SET_EMAIL",
      payload:email
  }
}
export const setdob=dob =>{
  return{
      type:"SET_DOB",
      payload:dob
  }
}
export const setanniversary=anniversarydate =>{
  return{
      type:"SET_ANNIVERSARY_DATE",
      payload:anniversarydate
  }
}
export const setdesignation=designation =>{
  return{
      type:"SET_DESIGNATION",
      payload:designation
  }
}
export const setdepartment=department =>{
  return{
      type:"SET_DEPARTMENT",
      payload:department
  }
}
export const setusertype=usertype =>{
  return{
      type:"SET_USER_TYPE",
      payload:usertype
  }
}
export const setaddress=address =>{
  return{
      type:"SET_ADDRESS",
      payload:address
  }
}
export const setcountry=country =>{
  return{
      type:"SET_COUNTRY",
      payload:country
  }
}
export const setstate=state =>{
  return{
      type:"SET_STATE",
      payload:state
  }
}
export const setcity=city =>{
  return{
      type:"SET_CITY",
      payload:city
  }
}
export const setpincode=pincode =>{
  return{
      type:"SET_PINCODE",
      payload:pincode
  }
}
export const setaadharcard=aadharcard =>{
  return{
      type:"SET_AADHAR_CARD",
      payload:aadharcard
  }
}
export const setpancard=pancard =>{
  return{
      type:"SET_PAN_CARD",
      payload:pancard
  }
}
export const setdrivinglicenses=drivinglicenses =>{
  return{
      type:"SET_DRIVING_LICENSES",
      payload:drivinglicenses
  }
}
export const setbankname=bankname =>{
  return{
      type:"SET_BankName",
      payload:bankname
  }
}
export const setaccountno=accountno =>{
    return{
        type:"SET_Accountno",
        payload:accountno
    }
  }
  export const setbranch=branch =>{
    return{
        type:"SET_Branch",
        payload:branch
    }
  }
  export const setIfsccode=Ifsccode =>{
    return{
        type:"SET_Ifsccode",
        payload:Ifsccode
    }
  }
  export const setSalary=Salary =>{
    return{
        type:"SET_Salary",
        payload:Salary
    }
  }
export const setuserpassword=password =>{
  return{
      type:"SET_PASSWORD",
      payload:password
  }
}
export const setuserconfirmpassword=confirmpassword =>{
  return{
      type:"SET_CONFIRM_PASSWORD",
      payload:confirmpassword
  }
}
export const setclearuser=() =>{
  return{
      type:"CLEAR_User",
  }
}