import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import warehouseicon from "../../assets/images/dashboardimages/warehouse.png";
import Warehouselisticon from "../../assets/images/inventory.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
class WarehouseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      WarehouseData: [],
      Id: "",
      searchInput: "",
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("");
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetWarehouseByState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          if (obj.data.length != 0) {
            this.setState({
              WarehouseData: obj.data,
            });
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Warehouse Name",
          field: "warehouse_name",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Contact Person",
          field: "person_name",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Contact Number",
          field: "contact_number",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Email",
          field: "email",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Country",
          field: "country",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Pincode",
          field: "pincode",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 200,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.WarehouseData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_name
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_contact_person
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_contact_email
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_status
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          warehouse_name: data.fld_name,
          person_name: data.fld_contact_person,
          contact_number: data.fld_contact_number,
          email: data.fld_contact_email,
          country: data.countryname,
          pincode: data.fld_pincode,
          status: data.fld_status,
          action: (
            <i
              className="fas fa-edit btn"
              style={{ fontSize: "15px", marginTop: "-11px" }}
              onClick={() => {
                localStorage.setItem("warehouseId", JSON.stringify(data));
                window.location.href = "/add-warehouse";
              }}
            ></i>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/warehouse-master"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <div
                          className="Bechofy-bg-soft-pink"
                          style={{ height: "60px" }}
                        >
                          <div className="row my-1">
                            <div className="col-2">
                              <div className="form-group">
                                <h4
                                  className="FilterCard"
                                  style={{ marginTop: "15px" }}
                                >
                                  Warehouse List
                                </h4>
                              </div>
                            </div>
                            <div className="col-10">
                              <button
                                onClick={() => {
                                  window.location.href = "/add-warehouse";
                                }}
                                className="btn align-items-center btn Bechofy-btn AddNewBtn"
                              >
                                <span className="">
                                  <img
                                    src={warehouseicon}
                                    alt="block user"
                                    className="btnicons"
                                    style={{ marginRight: "5px" }}
                                  ></img>{" "}
                                </span>
                                Add New Warehouse{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <Card className="overflow-hidden">
                          <CardBody className="pt-0">
                            {this.state.WarehouseData.length != 0 ? (
                              <Row>
                                <MDBCol
                                  md="12"
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        <span className="">
                                          <img
                                            src={Searchicon}
                                            alt="block user"
                                            className="btnicons"
                                          ></img>
                                        </span>
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Type the search phrase you wish to search within the grid"
                                      aria-label="Search"
                                      aria-describedby="basic-addon1"
                                      onChange={(e) => this.seachBarHandler(e)}
                                      value={this.state.searchInput}
                                    />
                                  </div>
                                </MDBCol>
                                <Col md="12">
                                  <MDBDataTable
                                    hover
                                    // scrollY
                                    striped
                                    bordered
                                    data={data}
                                    seachTop={false}
                                    entriesOptions={[10, 25, 50, 100]}
                                  >
                                    <MDBTableHead columns={data.columns} />
                                    <MDBTableBody rows={data.rows} />
                                  </MDBDataTable>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col sm="12" className="text-center mt-4">
                                  <img
                                    src={Warehouselisticon}
                                    alt="block user"
                                    className="customerhistory-icons ms-3 mt-1"
                                    style={{ marginRight: "5px" }}
                                  />
                                </Col>
                                <Col sm="12" className="text-center my-3">
                                  <h6>Warehouse list no found</h6>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default WarehouseList;
