import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
//i18n
import { withNamespaces } from 'react-i18next';
import { BsFilterRight } from "react-icons/bs";
import "../../../pages/Authentication/login.css";
class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            Name: '',
            Details: [],
            Userimage: ''
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    componentDidMount() {
        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        // //
        this.setState({
            Details: details[0],
            Name: details[0].fld_name,
            Userimage: details[0].fld_photo
        })
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <BsFilterRight className="product-icon-font" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" onClick={() => {
                            localStorage.setItem("authUser", JSON.stringify({
                                username: this.state.details.fld_name
                            }));
                        }} href="/profile">
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                            {this.props.t('My Profile')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="change_password">
                            <i className="fa fa-key font-size-16 align-middle mr-1" aria-hidden="true"></i> {this.props.t('Change Password')}
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <a onClick={() => {
                            localStorage.removeItem('LoginDetail')
                            localStorage.removeItem('access')
                            if (localStorage.getItem("RememberMe") !== "true" || localStorage.getItem('RememberMe') === "null") {
                                localStorage.removeItem('ClientDetails')
                                localStorage.removeItem('RememberMe')
                            }
                            localStorage.removeItem('lastlogin')
                            localStorage.removeItem('LoginDetail')
                            localStorage.removeItem('SubMenuRights');
                            window.location.href = '/'
                        }} className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{this.props.t('Logout')}</span>
                        </a>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}
export default withRouter(withNamespaces()(ProfileMenu));
