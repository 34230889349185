import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactChipInput from "react-chip-input";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class cutomerList extends Component {
  state = {
    data: [],
    searchInput: "",
    AttributeData: [],
    ViewClick: "true",
    open: false,
    Status: "Active",
    Name: "",
    openedit: false,
    Id: "",
    Status: "Active",
    Name: "",
    chips: [],
    Attributedrop: [],
    Attribute: "",
    Checbox: false,
    count: ""
  };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.Dots("Please wait...");
    GetApiCall.getRequest("GetAttributeMasterDrop").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          Attributedrop: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    GetApiCall.getRequest("GetAttributeValue").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          AttributeData: obj.data,
          count: obj.data.length
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  SaveAttribute() {
    if (this.state.Attribute != "") {
      if (this.state.chips != "") {
        this.onPost();
      } else {
        Notiflix.Notify.Failure("Please enter attribute value");
      }
    } else {
      Notiflix.Notify.Failure("Please enter attribute name");
    }
  }
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        mappingId: this.state.Attribute,
        attributeValue: this.state.chips.join(","),
        status: this.state.Status,
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
      },
      "AddAttributeValue"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(" Attribute value successfully added.");
          window.location.reload();
        } else {
          Notiflix.Notify.Failure(obj);
        }
      })
    );
  };
  addChip = (value) => {
    const chips = this.state.chips.slice();
    chips.push(value);
    this.setState({ chips });
  };
  removeChip = (index) => {
    const chips = this.state.chips.slice();
    chips.splice(index, 1);
    this.setState({ chips });
  };
  onChangeAttribute(text) {
    this.setState(
      {
        Attribute: text.target.value,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {
            id: this.state.Attribute,
          },
          "GetAttributeValue_Byid"
        ).then((results2) =>
          results2.json().then((obj2) => {
            if (results2.status == 200 || results2.status == 201) {
              let arr = [];
              if (obj2.data) {
                obj2.data.map((item) => {
                  arr.push(item.fld_attributesvalue);
                });
              }
              this.setState({
                chips: arr,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    const data = {
      columns: [
        {
          label: "Attribute Name",
          field: "job",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Attribute Value",
          field: "value",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 150,
        },
      ],
      rows:
        this.state.Checbox == false
          ? this.state.AttributeData.map((data, item) => {
            return {
              serial: item + 1,
              job: data.fld_attributename,
              value: data.fld_attributesvalue,
              status: data.fld_status,
            };
          })
          : this.state.AttributeData.filter((data) => {
            if (data.fld_status == "Active") {
              return data;
            }
          }).map((data, item) => {
            return {
              serial: item + 1,
              job: data.fld_attributename,
              value: data.fld_attributesvalue,
              status: data.fld_status,
            };
          }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Modal
              open={this.state.open}
              onClose={() => {
                this.setState({ open: false });
              }}
              center
            >
              <div className="modal-content modelcontent2">
                <div
                  className="modal-header Bechofy-bg-soft-pink"
                  style={{ background: "#777f80" }}
                >
                  <h4 className="modal-title text-white">
                    Add / Update Attribute Value
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="validationCustom01">
                        Attribute Name<span className="mandatory">*</span>
                      </label>
                      <select
                        value={this.state.Attribute}
                        onChange={this.onChangeAttribute.bind(this)}
                        className="custom-select my-1 mr-sm-2"
                      >
                        <option value="">Select Attribute </option>
                        {this.state.Attributedrop.map((attri) => (
                          <option key={attri.value} value={attri.value}>
                            {attri.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col col-12">
                    <div className="form-group">
                      <label htmlFor="CategoryName"> Attribute Values</label>
                      <ReactChipInput
                        classes="class1 class2"
                        chips={this.state.chips}
                        onSubmit={(value) => this.addChip(value)}
                        onRemove={(index) => this.removeChip(index)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="validationCustom01">
                        Status<span className="mandatory">*</span>
                      </label>
                      <br />
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          checked={this.state.Status == "Active" ? true : false}
                          onChange={() => {
                            this.setState({
                              Status: "Active",
                            });
                          }}
                        />{" "}
                        Active
                      </label>
                      <label
                        className="radio-inline"
                        style={{ marginLeft: "10px" }}
                      >
                        <input
                          type="radio"
                          name="optradio"
                          checked={
                            this.state.Status == "Inactive" ? true : false
                          }
                          onChange={() => {
                            this.setState({
                              Status: "Inactive",
                            });
                          }}
                        />{" "}
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn align-items-center Bechofy-btn"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={() => {
                      this.setState({
                        open: false,
                        Status: "Active",
                      });
                      window.location.reload();
                    }}
                  >
                    Close
                  </button>
                  <button
                    style={{ float: "right" }}
                    className="btn align-items-center Bechofy-btn "
                    onClick={this.SaveAttribute.bind(this)}
                  >
                    Save New Attribute Value
                  </button>
                  <span></span>
                </div>
              </div>
            </Modal>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <div className="Bechofy-bg-soft-pink">
                          <Row>
                            <Col xs="6">
                              <div className="Bechofy-text p-3">
                                <h5 className="Bechofy-text">
                                  {/* Attribute Value List [{this.state.count}] */}
                                  {this.state.count == 0 ? 'Attribute Value List' : <>Attribute Value List [{this.state.count}]</>}
                                </h5>
                              </div>
                            </Col>
                            <Col xs="6">
                              <div className="Bechofy-text p-3">
                                <a
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                    });
                                  }}
                                  style={{
                                    float: "right",
                                    marginTop: "-5px",
                                    color: "#777f80",
                                  }}
                                  className="align-items-center Bechofy-btn AddNewBtn category-Add-btn"
                                >
                                  <i
                                    className="fa fa-plus mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add / Update Attribute Value{" "}
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-0">
                          <Row></Row>
                        </CardBody>
                        <CardBody className="pt-0">
                          <Row>
                            <Col xs="12">
                              <MDBCol
                                md="12"
                                style={{ marginBottom: "10px" }}
                                className="d-flex justify-content-between px-0"
                              >
                                <span></span>
                                <span>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="activecheckattributevalue"
                                      onChange={() => {
                                        if (this.state.Checbox == false) {
                                          this.setState({
                                            Checbox: true,
                                          });
                                          const removeFav =
                                            this.state.AttributeData;
                                          removeFav.filter((data) => {
                                            if (data.fld_status === "Active") {
                                              return data;
                                            }
                                          });
                                        } else if (this.state.Checbox == true) {
                                          this.setState({
                                            Checbox: false,
                                          });
                                          const removeFav =
                                            this.state.AttributeData;
                                          removeFav.filter((data) => {
                                            if (
                                              data.fld_status === "Active" ||
                                              data.fld_status === "Inactive"
                                            ) {
                                              return data;
                                            }
                                          });
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{
                                        fontSize: "15px",
                                        color: "#777f80",
                                      }}
                                    >
                                      Show Only Active
                                    </label>
                                  </div>
                                </span>
                              </MDBCol>
                              <CardBody className="pt-0 px-0 mx-0">
                                <Row>
                                  <Col
                                    xs="12"
                                    className="latestreview-mdbtable"
                                  >
                                    <MDBDataTable
                                      hover
                                      // scrollY
                                      striped
                                      bordered
                                      data={data}
                                      seachTop={false}
                                      entriesOptions={[10, 25, 50, 100]}
                                    >
                                      <MDBTableHead
                                        className="centered"
                                        columns={data.columns}
                                      />
                                      {this.state.AttributeData.length != 0 ? (
                                        <MDBTableBody rows={data.rows} />
                                      ) : (
                                        <p>No Data Found</p>
                                      )}
                                    </MDBDataTable>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default cutomerList;
