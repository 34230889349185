
var urlString = "https://api.bechofy.in/";
// var urlString = "http://localhost:8052/";
var schema;
if (localStorage.getItem("ClientDetails") != null) {
	schema = JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema;
}
// var urlString = "http://localhost:8051/API/";
var PostApiCall = {
	postRequest(userData, url) {
		return fetch(urlString + url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
				"schema": schema
			},
			body: JSON.stringify(userData),
		})
			.then((response) => {
				if (response.status == 401) {
					window.location.href = "/"
					if (response.statusText === "Unauthorized") {
						window.location.href = "/"
						localStorage.removeItem("access")
						return response;
					}
					return response;

				} else {
					return response;
				}
			})
			.catch((error) => {
				return error;
			});
	},
};
export default PostApiCall;
