
import React from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { BiRupee } from "react-icons/bi";
import { Link, useLocation } from 'react-router-dom';
import "./ProductCard.css";
import PostApiCall from '../../Api';
import Notiflix from "notiflix";

function ProductCard(props) {
    const location = useLocation();
    const product = props.product;
    const VariantId = props.VariantId;
    function getExtension(filename) {
        return filename?.split('.').pop()
    }
    return (
        <div className="mx-2 h-100">
            <Col className="bg-light h-100">
                <div className="">
                    {getExtension(product.VariantImage?.split("#")[0].toLowerCase()) ===
                        "mp4" ? (
                        <Link to={`/product-detail/${props.product.fld_variantid}`}>
                            <video
                                src={product.VariantImage.split("#")[0]}
                                autoPlay
                                loop
                                autoFocus
                                autoCorrect="true"
                                muted
                                className="img-fluid w-100"
                            />
                        </Link>
                    ) : (
                        <a href={`/product-detail/${props.product.fld_variantid}`}>
                            <Image
                                src={product.VariantImage?.split("#")[0]}
                                className="img-fulid w-100 product-image "
                            />
                        </a>
                    )}
                </div>
                <div className="p-3">
                    {props.showItemName === 'true' ?
                        <><h5 className="mb-lg-2 mb-3 fw-bold">
                            <Link
                                className="text-dark text-decoration-none"
                                to={`/product-detail/${props.product.fld_variantid}`}
                            >
                                {product.fld_variantname}
                            </Link>
                        </h5><p className="mb-0 text-dark category-name">
                                {product.fld_categoryname} / {product.fld_subcategoryname}
                            </p></> : ""}

                    {props.showItemPrice === 'true' ?
                        <h5 className="my-lg-2 my-3 d-flex justify-content-between">
                            <p className="mb-0">
                                <span>
                                    <BiRupee />
                                    {product.fld_website_discount_price}
                                </span>{" "}
                                {product.fld_website_discount_price !== product.fld_mrp ? (
                                    <span className="mrp-price">
                                        <BiRupee />
                                        {product.fld_mrp}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </p>
                            {product.fld_website_discount_price !== product.fld_mrp ? (
                                <span>{product.fld_discount_percent}% OFF</span>
                            ) : (
                                ""
                            )}
                        </h5> : ""
                    }
                    {props.showAddToCartBtn === 'true' ?
                        <div className="mt-lg-3">
                            <Row className="justify-content-end">
                                <Col lg={12}>
                                    {
                                        product.available_stock == "InStock" ?
                                            <Button className="w-100 btn-theme">Add to Cart</Button>
                                            : <Button className="btn w-100 btn-theme" disabled>Out of Stock </Button>
                                    }
                                </Col>
                            </Row>
                        </div> : ""
                    }
                    {props.showCheckbox === "true" ?
                        <Form>
                            <Form.Check
                                type="switch"
                                // checked={checkbox === "true" ? true : false}
                                id={"custom-switch-featured" + product.fld_variantid}
                                value={product.fld_variantid}
                                onChange={(e) => {
                                    let variantid = e.target.value
                                    Notiflix.Loading.Dots("...")
                                    PostApiCall.postRequest({
                                        id: props.sectionId,
                                        sequence: props.sequence,
                                        sectionname: props.sectionname,
                                        status: props.sectionstatus,
                                        headline1: props.heading1,
                                        headline2: props.description1,
                                        headline3: props.description2,
                                        mediatype: props.mediaType,
                                        totalitemtobedisplayed: props.itemToBeDisplayed,
                                        showitemname: props.showItemName,
                                        showshortdescription: props.showItemDescription,
                                        showprice: props.showItemPrice,
                                        showaddtocart: props.showAddToCartBtn,
                                    }, "AddWebsiteHomeSection"
                                    ).then((results) => {
                                        results.json()?.then((obj) => {
                                            if (results.status == 200 || results.status == 201) {
                                                PostApiCall.postRequest(
                                                    {
                                                        sectionid: obj.data[0].fldid,
                                                        status: "True",
                                                        variantid: variantid,
                                                    },
                                                    "UpdateWebsiteHomePageItems"
                                                ).then((results) =>
                                                    results.json().then((obj) => {
                                                        if (results.status == 200 || results.status == 201) {
                                                            Notiflix.Notify.Success("Status Updated Successfully");
                                                            props.setResponse(!props.response);
                                                            Notiflix.Loading.Remove();
                                                        } else {
                                                            Notiflix.Notify.Failure(obj.data);
                                                            Notiflix.Loading.Remove();
                                                        }
                                                    })
                                                );
                                            }
                                        });
                                    });

                                }}
                            />
                        </Form>
                        : ""}
                </div>

            </Col>
        </div>
    );
}
export default ProductCard;