import { MDBCol, MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class StaticPageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,
      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      PageData: [],
    };
  }
  // onCloseModal = () => {
  //     this.setState({ open: false });
  //   };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.Dots("");
    PostApiCall.postRequest({ whereclause: "" }, "GetStaticPagesList").then((resultdes) =>
      resultdes.json().then((obj) => {

        this.setState({
          PageData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Name",
          field: "pagename",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Heading",
          field: "pageheading",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Content",
          field: "pagecontent",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.PageData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (`${data.fld_page_name ? data.fld_page_name.toLowerCase() : ""}`.includes(
            this.state.searchInput.toLowerCase()
          ) ||
            `${data.fld_page_heading ? data.fld_page_heading.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_content ? data.fld_content.toLowerCase() : ""}`.includes(
              this.state.searchInput.toLowerCase()
            ))
          //   ||
          //   `${data.fld_pricepercent?data.fld_pricepercent.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          pagename: data.fld_page_name,
          pageheading: data.fld_page_heading,
          pagecontent: data.fld_content,
          action: (
            <td className="text-center actionuser">
              <Link
                to={`/static-pages/update`}
                onClick={() => {
                  localStorage.setItem(
                    "PageDetails",
                    JSON.stringify({ ...data })
                  );
                }}
              >
                <i
                  className="fas fa-edit btn"
                  style={{ fontSize: "15px", marginTop: "-11px" }}></i>
              </Link>
              <span className="btn" style={{ marginTop: "-9px" }}>
                <i
                  className="fa fa-trash py-auto "
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    confirmAlert({
                      title: "Confirm to Delete",
                      message: "Are you sure you want to delete page.",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            Notiflix.Loading.Dots("");
                            PostApiCall.postRequest(
                              {
                                offer_id: data.fld_offerid,
                                type:
                                  this.state.CategoryName != "" &&
                                    this.state.SubCategory == ""
                                    ? "Category"
                                    : this.state.SubCategory != "" &&
                                      this.state.ItemName == ""
                                      ? "SubCategory"
                                      : this.state.ItemName != ""
                                        ? "Variant"
                                        : "Vertical",
                                typeid:
                                  this.state.CategoryName != "" &&
                                    this.state.SubCategory == ""
                                    ? this.state.CategoryName
                                    : this.state.SubCategory != "" &&
                                      this.state.ItemName == ""
                                      ? this.state.SubCategory
                                      : this.state.ItemName != ""
                                        ? this.state.ItemName
                                        : this.state.VerticalName,
                                name: this.state.pagename,
                                caption: this.state.pageheading,
                                pricepercent: this.state.OfferPrice,
                                maximumdiscountprice:
                                  this.state.MaximumDiscountPrice,
                                minapplyamnt:
                                  this.state.MinimumAmountApplicable,
                                maxapplyamnt:
                                  this.state.MaximumAmountApplicable,
                                minimumproduct: this.state.MinimumProducts,
                                freegift: this.state.FreeGifts,
                                firstorder: this.state.FirstOrder,
                                allorder: this.state.AllOrders,
                                onetime: this.state.OneTime,
                                freeshipping: this.state.FreeShipping,
                                refferaldiscount: this.state.RefferalDiscount,
                                description: this.state.OfferDescription,
                                code: this.state.OfferCode,
                                startdate: this.state.StartDate,
                                enddate: this.state.EndDate,
                                termscondition: this.state.Terms,
                                showonwebsite: "Delete",
                                userid: JSON.parse(
                                  localStorage.getItem("LoginDetail")
                                )[0].fld_userid,
                              },
                              "AddOffer"
                            ).then((results) =>
                              // const objs = JSON.parse(result._bodyText)
                              results.json().then((obj) => {
                                if (
                                  results.status == 200 ||
                                  results.status == 201
                                ) {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.Success(
                                    "Page successfully deleted."
                                  );
                                  window.location.reload();
                                } else {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.Failure(
                                    "Something went wrong, try again later."
                                  );
                                }
                              })
                            );
                          },
                        },
                        {
                          label: "No",
                          // onClick: () => alert('Click No')
                        },
                      ],
                    });
                  }}
                ></i>
              </span>
            </td>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ background: "#777f80" }}
                  >
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text text-white">
                            Static Pages
                          </h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <a
                            onClick={() => {
                              window.location.href = "/static-pages/create";
                            }}
                            style={{
                              float: "right",
                              marginTop: "-5px",
                              color: "#777f80",
                              backgroundColor: "#fff",
                            }}
                            className="btn align-items-center btn Bechofy-btn "
                          >
                            Add New Page{" "}
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <CardBody className="pt-0">
                          <Row></Row>
                        </CardBody>
                        <CardBody className="pt-0">
                          <Row>
                            <MDBCol md="12" style={{ marginBottom: "10px" }}>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search by Page Name"
                                aria-label="Search"
                                onChange={(e) => this.seachBarHandler(e)}
                                value={this.state.searchInput}
                              />
                            </MDBCol>
                            <Col md="12">
                              {this.state.PageData.length !== 0 ? (
                                <MDBDataTableV5
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[10, 25, 50, 100]}
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTableV5>
                              ) : (
                                <p>No Data Found</p>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      {/* <WelcomeComp /> */}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default StaticPageList;
