import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../../Api";
import GtLogo from "../../../assets/images/bechofylogo.png";
import moment from "moment";
class categoryManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			PriceData: [],
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			SP: 0,
			CP: 0,
			MRP: 0,
			WDP: 0,
			PriceState: 0,
			StateData: [],
			GST: 0,
			BasePrice: 0,
			ShippingChargeGST: 0,
			BaseShippingCharge: 0,
			MinQty: 0,
			MaxQty: 0,
			USDPrice: 0
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		var VariantID = JSON.parse(localStorage.getItem("variantid"));
		var StateID = JSON.parse(localStorage.getItem("stateId"))
		PostApiCall.postRequest(
			{
				stateid: StateID,
				variantid: VariantID
			},
			"downloadcsv"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						PriceData: obj.data,
					});
					Notiflix.Loading.Remove();
				}
			}))

		// get variant price
		PostApiCall.postRequest(
			{
				variantid: this.props.sVariantId,
				stateid: localStorage.getItem("ClientDetails")[0].fld_pricing_type === 'Statewise' ? this.state.PriceState : 0,
			},
			"StateWisePrice"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						VariantData: obj.data,
						ViewVariantList: true,
						ViewProductList: false
					});
					if (obj.data.length != 0) {
						this.setState({
							CostPrice: obj.data[0].CostPrice,
							MRP: obj.data[0].MRP,
							SellingPrice: obj.data[0].SellingPrice,
							Websitediscountprice: obj.data[0].WebsiteDiscountPrice,
							BasePrice: obj.data[0].BasePrice,
							ShippingCharge: obj.data[0].ShippingAmount,
							ShippingChargeGST: obj.data[0].ShippingGst,
							GST: obj.data[0].ItemGST,
							USDPrice: obj.data[0].USDPrice,
							BaseShippingCharge: obj.data[0].ShippingBaseAmount,
							MinQty: obj.data[0].MinimumQty,
							MaxQty: obj.data[0].MaximumQty,
						})
					}
				}
			}
			)
		)
		PostApiCall.postRequest(
			{
				whereClause: `where fld_variantid=${this.props.sVariantId}`,
				recordCount: '*'
			},
			"GetVariantData"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						VariantData: obj.data,
						ViewVariantList: true,
						ViewProductList: false
					});
					if (obj.data.length != 0) {
						this.setState({
							VariantID: obj.data[0].fld_variantid,
							VariantName: obj.data[0].fld_variantname,
							SKU: obj.data[0].fld_sku,
							Barcode: obj.data[0].fld_barcode,
							Status: obj.data[0].fld_status == 'TRUE' ? 'Yes' : 'No',
							AttributeMappingId: obj.data[0].fld_attributemappingid,
							ItemStatus: obj.data[0].fld_status,
							GSTRate: obj.data[0].fld_Gstrate,
						})
						// populate attribute
						PostApiCall.postRequest(
							{
								id: obj.data[0].fld_categoryid,
								sfor: 'NA'
							},
							"GetCategoryAttributeValue"
						).then((results2) =>
							results2.json().then((obj2) => {

								if (results2.status == 200 || results2.status == 201) {
									this.setState({
										AttributData: obj2.data,
									});
									Notiflix.Loading.Remove();
								}
							})
						);
						var item = []
						item.push({
							label: obj.data[0].fld_itemname,
							value: obj.data[0].fld_productid
						})
						new Promise((resolve, reject) => {
							setTimeout(resolve, 1000);
						}).then(() => {
							this.setState({
								ShortDescription: obj.data[0].fld_short_description,
								LongDescription: obj.data[0].fld_long_description,
							});
						});
						if (obj.data) {
							obj.data.map((data, i) => {
								this.setState({
									VariantDataForimage: data
								})
							})
						}
					}
				}
				Notiflix.Loading.Remove();
			}
			))
	}
	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};
	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		var VariantID = JSON.parse(localStorage.getItem("variantid"));
		var StateID = JSON.parse(localStorage.getItem("stateId"))
		PostApiCall.postRequest(
			{
				variantpriceid: null,
				variantid: VariantID,
				mrp: this.state.MRP,
				costPrice: this.state.CP,
				sellingPrice: this.state.SP,
				websiteDiscountPrice: this.state.SP,
				stateid: StateID,
				updatedby: details[0].fld_userid,
			},
			"Add_Price"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					Notiflix.Loading.Remove();
					localStorage.removeItem("variantid");
					localStorage.removeItem("stateId");
					Notiflix.Notify.Success("Price successfully added.");
					window.location.href = "/price-management";
				}
				else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj1.data);
				}
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">
								<div>
									<ul className="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/price-management">Price Management</a></li>
										<li>Add New Price</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
							</div>
						</div>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden pagebackground">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Add New Price
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<Row style={{ marginTop: '15px' }}>
										<Col xl="4" >
											<Card className="" style={{ height: '230px' }}>
												<div className="Bechofy-bg-soft-pink" style={{ height: '80px' }}
												>
													<Row>
														{this.state.PriceData.map((data, i) => {
															return (
																<Col xs="7">
																	<div className="Bechofy-text p-3">
																		<h5 className="Bechofy-text">{data.ProductName}</h5>
																		<p className="mb-4"><b>SKU</b> {data.SKU}</p>
																	</div>
																</Col>
															)
														})}
													</Row>
												</div>
												<CardBody className="pt-4">
													<Row>
														<Col xs="6">
															<p>MRP</p>
															<p>Cost Price</p>
															<p> Selling Price</p>
														</Col>
														{this.state.PriceData.map((data, i) => (
															<Col xs="6">
																<p>{data.MRP}</p>
																<p>{data.CostPrice}</p>
																<p>₹ {data.SellingPrice}</p>
															</Col>
														))}
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col md="8">
											<Card className="mini-stats-wid" style={{ height: '400px' }}>
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Add Price
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0 px-0">
													{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_pricing_type === 'Statewise' ?
														<Row>
															<Col xl="12">
																<div className="row mt-1 mb-3">
																	<div className="col-12">
																		<select
																			className="form-control gridinput"
																			value={this.state.PriceState}
																			onChange={(text) => {
																				this.setState(
																					{
																						PriceState: text.target.value,
																					},
																				);
																			}}
																		>
																			<option value="">Select State</option>
																			{this.state.StateData.map((data) => (
																				<option key={data.value} value={data.value}>
																					{data.label}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
															</Col>
														</Row> : ""}
													<Row>
														<Col xs="12">
															<div className="form my-3">
																<div className="row  ">
																	<div className="col col-12 px-4">
																		<div className="row">
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						MRP
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MRP}
																						onChange={(text) => {
																							this.setState({
																								MRP: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Cost Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.CostPrice}
																						onChange={(text) => {
																							this.setState({
																								CostPrice: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			{/* INR Selling price */}
																			{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'Both' ||
																				JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'INR' ?
																				<><div className="col-3">
																					<div className="form-group">
																						<label htmlFor="CategoryName">
																							INR Selling Price(Without GST)
																							<span className="mandatory">*</span>
																						</label>
																						<input
																							type="text"
																							id="CategoryName"
																							disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? true : false}
																							className="form-control my-1 mr-sm-2"
																							value={this.state.BasePrice}
																							onChange={(text) => {
																								var newval = (text.target.value * parseFloat(this.state.GSTRate) / 100)
																								var gstval = newval.toFixed(2);
																								var gst = Math.round(gstval)
																								gst = gst.toFixed(2);
																								var totalaftergst = (parseFloat(text.target.value)) + parseFloat(gstval)
																								this.setState({
																									Websitediscountprice: totalaftergst,
																									GST: gst,
																									BasePrice: text.target.value
																								});
																							}} />
																					</div>
																				</div><div className="col-3">
																						<div className="form-group">
																							<label htmlFor="CategoryName">
																								INR GST
																								<span className="mandatory">*</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								disabled="true"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.GST}
																								onChange={(text) => {
																									this.setState({
																										GST: text.target.value,
																									});
																								}} />
																						</div>
																					</div><div className="col-3">
																						<div className="form-group">
																							<label htmlFor="CategoryName">
																								INR Selling Price With GST
																								<span className="mandatory">*</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? false : true}
																								className="form-control my-1 mr-sm-2"
																								value={this.state.Websitediscountprice}
																								onChange={(text) => {
																									var newval = (text.target.value * parseFloat(this.state.GSTRate)) / (1 + parseFloat(this.state.GSTRate) / 100) / 100
																									var gstval = newval.toFixed(2);
																									var gst = Math.round(gstval * 100) / 100
																									var gstby2 = newval / 2;
																									gstby2 = gstby2.toFixed(2);
																									var baseprice = (parseFloat(text.target.value)) - parseFloat(gstval)
																									this.setState({
																										Websitediscountprice: text.target.value,
																										GST: gst,
																										BasePrice: baseprice
																									});
																								}} />
																						</div>
																					</div></>
																				: ""}
																			{/* INR Selling price */}
																			{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'Both' ||
																				JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'USD' ?
																				<div className="col-3">
																					<div className="form-group">
																						<label htmlFor="CategoryName">
																							USD Selling Price
																							<span className="mandatory">*</span>
																						</label>
																						<input
																							type="text"
																							id="CategoryName"
																							className="form-control my-1 mr-sm-2"
																							value={this.state.USDPrice}
																							onChange={(text) => {
																								this.setState({
																									USDPrice:
																										text.target.value,
																								});
																							}}
																						/>
																					</div>
																				</div>
																				: ""}
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Shipping Charge(Without GST)
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? true : false}
																						value={this.state.BaseShippingCharge}
																						onChange={(text) => {
																							var newval = (text.target.value * parseFloat(this.state.GSTRate) / 100)
																							var gstval = newval.toFixed(2);
																							var gst = Math.round(gstval)
																							gst = gst.toFixed(2);
																							var totalaftergst = (parseFloat(text.target.value)) + parseFloat(gstval)
																							this.setState({
																								ShippingCharge: totalaftergst,
																								ShippingChargeGST: gst,
																								BaseShippingCharge: text.target.value
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Shipping Charge GST
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						disabled="true"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.ShippingChargeGST}
																						onChange={(text) => {
																							this.setState({
																								ShippingChargeGST: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Shipping Charge
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? false : true}
																						value={this.state.ShippingCharge}
																						onChange={(text) => {
																							var newval = (text.target.value * parseFloat(this.state.GSTRate)) / (1 + parseFloat(this.state.GSTRate) / 100) / 100
																							var gstval = newval.toFixed(2);
																							var gst = Math.round(gstval * 100) / 100
																							var gstby2 = newval / 2;
																							gstby2 = gstby2.toFixed(2);
																							var baseprice = (parseFloat(text.target.value)) - gstval
																							this.setState({
																								ShippingCharge: text.target.value,
																								ShippingChargeGST: gst,
																								BaseShippingCharge: baseprice
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Mininum Quantity
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="MinQty"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MinQty}
																						onChange={(text) => {
																							this.setState({
																								MinQty: text.target.value
																							});
																						}} />
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Maximum Quantity
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="MinQty"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MaxQty}
																						onChange={(text) => {
																							this.setState({
																								MaxQty: text.target.value
																							});
																						}} />
																				</div>
																			</div>
																			<div className="col-12">
																				<button
																					style={{
																						float: "right",
																						marginTop: "5px",
																					}}
																					className="btn align-items-center Bechofy-btn "
																					onClick={() => {
																						var login = localStorage.getItem("LoginDetail");
																						var details = JSON.parse(login);
																						PostApiCall.postRequest(
																							{
																								variantpriceid: this.state.VariantID,
																								variantid: this.state.VariantID,
																								mrp: this.state.MRP,
																								costPrice: this.state.CostPrice,
																								sellingPrice: this.state.Websitediscountprice,
																								websiteDiscountPrice: this.state.Websitediscountprice,
																								baseprice: this.state.BasePrice,
																								baseshippingcharge: this.state.BaseShippingCharge,
																								gst: this.state.GST,
																								shippinggst: this.state.ShippingChargeGST,
																								shippingcharge: this.state.ShippingCharge,
																								usdprice: this.state.USDPrice,
																								createdOn: moment().format("YYYY-MM-DD"),
																								updatedOn: moment().format("YYYY-MM-DD"),
																								updatedBy: details[0].fld_userid,
																								stateid: this.state.PriceState,
																								MinQty: this.state.MinQty,
																								MaxQty: this.state.MaxQty,
																							},
																							"AddVariantPrice"
																						).then((resultcategory2) =>
																							resultcategory2.json().then((obj2) => {
																								if (resultcategory2.status == 200 || resultcategory2.status == 201) {
																									Notiflix.Notify.Success(
																										"Price Updated Successfully."
																									);
																									Notiflix.Loading.Remove();
																								} else {
																									Notiflix.Loading.Remove();
																									Notiflix.Notify.Failure(obj2.data);
																								}
																							})
																						);
																					}}
																				>
																					Save
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</Card>
								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={() => {
																if (this.state.MRP != 0) {
																	if (this.state.CP != 0) {
																		if (this.state.SP != 0) {
																			this.onPost()
																		}
																		else {
																			Notiflix.Notify.Failure('Please enter Selling Price')
																		}
																	}
																	else {
																		Notiflix.Notify.Failure('Please enter Cost Price')
																	}
																}
																else {
																	Notiflix.Notify.Failure('Please enter MRP')
																}
															}}
														>
															Add Price
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default categoryManagement;
