import {
  MDBCol
} from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import Drawer from "react-modern-drawer";
import Slider from "react-slick";
import {
  Card,
  CardBody, Col, Container,
  Row
} from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import addicon from "../../assets/images/dashboardimages/product.png";
import Refreshicon from "../../assets/images/dashboardimages/refresh.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import varianticon from "../../assets/images/dashboardimages/variant.png";
import variantlist from "../../assets/images/dashboardimages/variantlist.png";
import listemptyicon from "../../assets/images/list-items.png";
import offersicon from "../../assets/images/offers.png";
import pricelisticon from "../../assets/images/price-list.png";
import stocklisticon from "../../assets/images/stock.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import AddVariant from "../../pages/ProductMangement/AddVariant";
import "../Authentication/login.css";
import AddStockItem from "../ProductMangement/StockManagement/AddStockitem";
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,
      view: false,
      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      ProductData: [],
      VariantData: [],
      Category: null,
      SubCategory: null,
      Product: "",
      ProductName: "",
      Brand: null,
      Vertical: null,
      SearchField: null,
      ViewProductList: true,
      ViewVariantList: false,
      suggestions: [],
      BrandData: [],
      VerticalData: [],
      CategoryData: [],
      SubCatgeoryData: [],
      count: "",
      VariantVisible: false,
      StockVisible: false,
      AttributeVisible: false,
      PriceVisible: false,
      OfferVisible: false,
      DrawerFor: "",
      IsOpenVariant: false,
      IsOpenStock: false,
      IsOpenNewVariant: false,
      EditFor: "",
      VariantIdEdit: 0,
      AttributeValuesEdit: "",
      ShowAddNewVaraintButton: false,
      VariantStatus: false,
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    localStorage.removeItem("VariantImageID");
    localStorage.removeItem("VariantIdForImage");
    localStorage.removeItem("itemDetails");
    localStorage.removeItem("VariantDetails");
    GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          BrandData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticalData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    // ========GetVariantList=========
  }

  repeatLastSearch = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_vertical_id=${localStorage.getItem("lastsearchitems").split(",")[1]}`,
      },
      "Get_categorydropdown"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (
          results1.status == 200 ||
          results1.status == 201
        ) {
          this.setState({
            CategoryData: obj1.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        categoryid: localStorage.getItem("lastsearchitems").split(",")[2],
      },
      "Get_subcategoryDropdown"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          this.setState({
            SubCatgeoryData: obj1.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        whereClause: ` where fld_subcategoryid =  ${localStorage.getItem("lastsearchitems").split(",")[3]}`,
        recordCount: " fld_productid,fld_itemname,fld_status ",
      },
      "GetProductMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ProductData: obj.data,
            count: obj.data.length,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
    this.setState({
      Brand: localStorage.getItem("lastsearchitems").split(",")[0],
      Vertical: localStorage.getItem("lastsearchitems").split(",")[1],
      Category: localStorage.getItem("lastsearchitems").split(",")[2],
      SubCategory: localStorage.getItem("lastsearchitems").split(",")[3],
      Product: localStorage.getItem("lastsearchitems").split(",")[4]
    })
    this.getVariantData();
  }
  getVariantData = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_productid=${localStorage.getItem("lastsearchitems").split(",")[4]}`,
        recordCount: "*",
      },
      "GetVariantData"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            VariantData: obj.data,
            ViewVariantList: true,
            ViewProductList: false,
            ShowAddNewVaraintButton: true
          });
          Notiflix.Loading.Remove();
        }
      })
    )
  }
  toggleDrawer = (sfor, variantid, attributevales) => {
    console.log("attributevales " + attributevales)
    if (this.state.IsOpenVariant === true) {
      this.getVariantData();
    }
    this.setState({
      EditFor: sfor,
      IsOpenVariant: !this.state.IsOpenVariant,
      VariantIdEdit: variantid,
      AttributeValuesEdit: attributevales
    });

  };
  toggleDrawerNewVariant = () => {
    if (this.state.IsOpenNewVariant === true) {
      this.getVariantData();
    }
    this.setState({
      EditFor: "variant",
      IsOpenNewVariant: !this.state.IsOpenNewVariant,
    });
  };
  toggleStockDrawer = (variantid) => {
    if (this.state.IsOpenStock === true) {
      this.getVariantData();
    }
    this.setState({
      IsOpenStock: !this.state.IsOpenStock,
      VariantIdEdit: variantid
    });
  };
  onChangeCategory(text) {
    this.setState(
      {
        Category: text.target.value,
      },
      () => {
        PostApiCall.postRequest(
          {
            categoryid: this.state.Category,
          },
          "Get_subcategoryDropdown"
        ).then((results1) =>
          results1.json().then((obj1) => {
            if (results1.status == 200 || results1.status == 201) {
              this.setState({
                SubCatgeoryData: obj1.data,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  onChangeSubCategory(text) {
    this.setState(
      {
        SubCategory: text.target.value,
      },
      () => {
        // ======Get Item List==========
        PostApiCall.postRequest(
          {
            whereClause: ` where fld_subcategoryid =  ${this.state.SubCategory}`,
            recordCount: " fld_productid,fld_itemname,fld_status ",
          },
          "GetProductMaster"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              this.setState({
                ProductData: obj.data,
                count: obj.data.length,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
        Notiflix.Loading.Remove();
      }
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    const { text, suggestions } = this.state;
    const itemdata = {
      columns: [
        {
          label: "Item Name",
          field: "itemname",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Classification",
          field: "classification",
          sort: "disabled",
          width: 150,
        },
        {
          label: "GST (%)",
          field: "gst",
          sort: "disabled",
          width: 150,
        },
        {
          label: "",
          field: "actions",
          sort: "disabled",
          width: 100,
        },
      ], // end for variant
      rows: this.state.ProductData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.fld_itemname
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_verticlename ? data.fld_verticlename.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_subcategoryname
              ? data.fld_subcategoryname.toLowerCase()
              : ""
              }`.includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          itemname: (
            <div>
              <span className="ClassificationtextLeft">
                <b>Item Name</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_itemname}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>HSN</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_hsncode}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Variant</b>
              </span>
              <span className="ClassificationtextRight">
                <a
                  onClick={() => {
                    PostApiCall.postRequest(
                      {
                        whereClause: `where fld_productid=${data.fld_productid}`,
                        recordCount: "*",
                      },
                      "GetVariantData"
                    ).then((results) =>
                      results.json().then((obj) => {
                        if (results.status == 200 || results.status == 201) {

                          this.setState({
                            VariantData: obj.data,
                            ViewVariantList: true,
                            ViewProductList: false,
                          });
                          Notiflix.Loading.Remove();
                        }
                      })
                    );
                  }}
                  style={{ color: "green" }}
                >
                  ({data.VariantCount} Variant)
                </a>
              </span>
            </div>
          ),
          classification: (
            <div className="tablealign">
              <span className="ClassificationtextLeft">
                <b>Vertical</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_verticlename}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Category</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_categoryname}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Sub Category</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_subcategoryname}
              </span>
            </div>
          ),
          gst: data.fld_Gstrate,
          actions: (
            <span>
              <img
                src={Editicon}
                alt="Edit user"
                style={{ marginLeft: "2px" }}
                className="btnicons"
                onClick={() => {
                  localStorage.setItem(
                    "itemDetails",
                    JSON.stringify(data.fld_productid)
                  );
                  window.location.href = "/add-item";
                }}
              ></img>
            </span>
          ),
        };
      }),
    };
    // =========VariantList=============
    const variantdata = {
      columns: [
        {
          label: "Variant Image",
          field: "itemimage",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Variant Name",
          field: "itemname",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Attributes",
          field: "attributes",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Price",
          field: "price",
          sort: "disabled",
          width: 150,
        },
        {
          label: "GST (%)",
          field: "gst",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 150,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ], // end for variant
      rows: this.state.VariantData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.fld_itemname
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_verticlename ? data.fld_verticlename.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_subcategoryname
              ? data.fld_subcategoryname.toLowerCase()
              : ""
              }`.includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          itemimage: (
            <img
              src={data.VariantImage}
              alt="Variantimage"
              className="variant-list-image"
            ></img>
          ),
          itemname: (
            <div className="tablealign">
              <span className="ClassificationtextLeft">
                {data.fld_verticlename}/{data.fld_categoryname}/
                {data.fld_subcategoryname}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Variant</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_variantname}
              </span>{" "}
              <br />
              <span className="ClassificationtextLeft">
                <b>Brand </b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_brand_name}
              </span>
            </div>
          ),
          attributes: data.attribute_values.split(",").map((attr, i) => {
            return (
              <div className="tablealign">
                <span className="ClassificationtextLeft">
                  <b>{attr.split("#")[0]}</b>
                </span>
                <span className="ClassificationtextRight">
                  {attr.split("#")[1]}
                </span>
                <br />
              </div>
            );
          }),
          price: (
            <div className="tablealign">
              <span className="ClassificationtextLeft">
                <b>MRP</b>
              </span>
              <span className="ClassificationtextRight">
                {"₹" + parseFloat(data.fld_mrp).toFixed(2)}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Cost Price</b>
              </span>
              <span className="ClassificationtextRight">
                {"₹" + parseFloat(data.fld_cost_price).toFixed(2)}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Selling Price(Without GST)</b>
              </span>
              <span className="ClassificationtextRight">
                {"₹" + parseFloat(data.fld_selling_price).toFixed(2)}
              </span>
            </div>
          ),
          gst: data.fld_Gstrate,
          status: data.fld_status,
          action: (
            <span>
              <img
                src={Editicon}
                alt="View user"
                className="btnicons"
                onClick={() => {
                  localStorage.setItem(
                    "VariantDetails",
                    JSON.stringify(data.fld_variantid)
                  );
                  window.location.href = "/add-new-variant";
                }}
              ></img>
            </span>
          ),
        };
      }),
    };
    const variantImages = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 800,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <Drawer
          open={this.state.IsOpenVariant}
          onClose={this.toggleDrawer.bind(this, 'variant', 0, "")}
          direction="right"
          lockBackgroundScroll='no'
          width={450}
          className="sidebar-verticalmaster drawer item-management px-2 py-3 page-itm-side-draw hidebread"
        >
          {this.state.VariantIdEdit > 0 ?
            <AddVariant className='hidebread' sfor={this.state.EditFor} sVariantId={this.state.VariantIdEdit} sAttributeValues={this.state.AttributeValuesEdit} sProductId={this.state.Product} /> : ""
          }
        </Drawer>
        <Drawer
          open={this.state.IsOpenNewVariant}
          onClose={this.toggleDrawerNewVariant.bind(this)}
          direction="right"
          lockBackgroundScroll='no'
          width={450}
          className="sidebar-verticalmaster drawer item-management px-2 py-3 page-itm-side-draw hidebread"
        >
          {this.state.Product > 0 && this.state.EditFor !== "" ?
            <AddVariant className='hidebread' sfor={this.state.EditFor} sVariantId="0" sProductId={this.state.Product} /> : ""
          }
        </Drawer>
        <Drawer
          open={this.state.IsOpenStock}
          onClose={this.toggleStockDrawer.bind(this, 'stock', 0, "")}
          direction="right"
          lockBackgroundScroll='no'
          width={450}
          className="sidebar-verticalmaster drawer item-management px-2 py-3 page-itm-side-draw"
        >
          {this.state.VariantIdEdit > 0 ?
            <AddStockItem sVariantId={this.state.VariantIdEdit} /> : ""
          }
        </Drawer>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/customer-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-6">
                        <div className="form-group">
                          <h4
                            className="px-4 text-white text-start"
                            style={{ marginTop: "10px" }}
                          >
                            Filter Data By
                          </h4>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="position-relative">
                          <button
                            onClick={() => {
                              localStorage.removeItem("itemDetails");
                              window.location.href = "/add-item";
                            }}
                            className="btn align-items-center btn Bechofy-btn AddNewBtn"
                          >
                            <span className="">
                              <img
                                src={addicon}
                                alt="block user"
                                className="btnicons"
                                style={{ marginRight: "5px" }}
                              ></img>{" "}
                            </span>
                            Add New Item{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card style={{ padding: " 0 20px" }}>
                    <div className="row my-1 ">
                      <div className="col-xl-9 mt-3 mt-lg-0 col-12">
                        <div className="row">
                          <div className="col-lg-2 col-md-6">
                            <div className="form-group mt-lg-3">
                              <select
                                className="form-control"
                                placeholder="Select Brand"
                                value={this.state.Brand}
                                onChange={(text) => {
                                  this.setState({
                                    Brand: text.target.value,
                                  });
                                }}
                              >
                                <option>Select Brand</option>
                                {this.state.BrandData.map((brand) => (
                                  <option key={brand.value} value={brand.value}>
                                    {brand.label} - {brand.fld_status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6">
                            <div className="form-group mt-lg-3">
                              <select
                                className="form-control"
                                placeholder="Select Vertical"
                                value={this.state.Vertical}
                                onChange={(text) => {
                                  this.setState(
                                    {
                                      Vertical: text.target.value,
                                    },
                                    () => {
                                      PostApiCall.postRequest(
                                        {
                                          whereClause: `where fld_vertical_id=${this.state.Vertical}`,
                                        },
                                        "Get_categorydropdown"
                                      ).then((results1) =>
                                        results1.json().then((obj1) => {
                                          if (
                                            results1.status == 200 ||
                                            results1.status == 201
                                          ) {
                                            this.setState({
                                              CategoryData: obj1.data,
                                            });
                                            Notiflix.Loading.Remove();
                                          }
                                        })
                                      );
                                    }
                                  );
                                }}
                              >
                                <option value="">Select Vertical</option>
                                {this.state.VerticalData.map((Verticle) => (
                                  <option
                                    key={Verticle.value}
                                    value={Verticle.value}
                                  >
                                    {Verticle.label} - {Verticle.fld_status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6">
                            <div className="form-group mt-lg-3">
                              <select
                                className="form-control"
                                placeholder="Select Vertical"
                                value={this.state.Category}
                                onChange={this.onChangeCategory.bind(this)}
                              >
                                <option value="">Select Category</option>
                                {this.state.CategoryData.map((Category) => (
                                  <option
                                    key={Category.value}
                                    value={Category.value}
                                  >
                                    {Category.label} - {Category.fld_status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-group mt-lg-3">
                              <select
                                className="form-control"
                                placeholder="Select Vertical"
                                value={this.state.SubCategory}
                                onChange={this.onChangeSubCategory.bind(this)}
                              >
                                <option>Select Sub Category</option>
                                {this.state.SubCatgeoryData.map((subcat) => (
                                  <option key={subcat.value} value={subcat.value}>
                                    {subcat.label} - {subcat.fld_status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-group mt-lg-3">
                              <select
                                className="form-control"
                                placeholder="Select Vertical"
                                value={this.state.Product}
                                onChange={(text) => {
                                  this.setState({
                                    Product: text.target.value
                                  });
                                }}
                              >
                                <option>Select Product</option>
                                {this.state.ProductData.map((product) => (
                                  <option key={product.fld_itemname} value={product.fld_productid}>
                                    {product.fld_itemname} - {product.fld_status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 mb-md-3 mt-lg-0">
                        <button
                          onClick={() => {
                            PostApiCall.postRequest(
                              {
                                whereClause: `where fld_productid=${this.state.Product}`,
                                recordCount: "*",
                              },
                              "GetVariantData"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (results.status == 200 || results.status == 201) {

                                  localStorage.setItem("lastsearchitems", this.state.Brand + "," + this.state.Vertical + "," + this.state.Category + "," + this.state.SubCategory + "," + this.state.Product)
                                  if (obj.data.length > 0) {
                                    localStorage.setItem("lastsearchitemsvalue", " Brand - " + obj.data[0].fld_brand_name + " Vertical - " + obj.data[0].fld_verticlename + " Category - " + obj.data[0].fld_categoryname + " SubCategory - " + obj.data[0].fld_subcategoryname + " Item - " + obj.data[0].fld_itemname)
                                  }
                                  this.setState({
                                    VariantData: obj.data,
                                    ViewVariantList: true,
                                    ViewProductList: false,
                                    ShowAddNewVaraintButton: true
                                  });
                                  Notiflix.Loading.Remove();
                                }
                              })
                            );
                          }}
                          className="btn align-items-center btn Bechofy-btn FilterCardBtn3 mt-lg-3"
                        >
                          <span className="">
                            <img
                              src={variantlist}
                              alt="block user"
                              className="btnicons"
                              style={{ marginRight: "5px" }}
                            ></img>{" "}
                          </span>
                          Get Variants
                        </button>

                        <button
                          disabled={this.state.Product == "" ? true : false}
                          onClick={() => {
                            localStorage.setItem("itemDetails", JSON.stringify(this.state.Product));
                            window.location.href = `/update-item`;
                          }}
                          className="btn align-items-center btn Bechofy-btn FilterCardBtn3 mt-lg-3"
                        >
                          Update Item
                        </button>
                        <button
                          className="btn align-items-center btn refresh-item-responsive-btn Bechofy-btn FilterCardBtn4 mt-lg-3"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <span className="">
                            <img
                              src={Refreshicon}
                              alt="block user"
                              className="btnicons"
                              style={{ marginRight: "5px" }}
                            ></img>{" "}
                          </span>
                        </button>

                      </div>
                    </div>
                    <div className="row my-1">
                      <h6>Last Search:<a onClick={() => {
                        this.repeatLastSearch();
                      }}>{localStorage.getItem("lastsearchitemsvalue")}</a>
                      </h6>
                    </div>
                  </Card>
                  <Card>
                    <div
                      className="Bechofy-bg-soft-pink"
                      style={{ height: "60px" }}
                    >
                      <div className="row my-1">
                        <div className="col-6">
                          <div
                            className="form-group"
                            style={{
                              display:
                                this.state.ViewProductList == true
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <h4
                              className="FilterCard px-3 mx-4"
                              style={{ marginTop: "10px" }}
                            >
                              {this.state.count == 0 ? 'Variant List' : <>Variant List [{this.state.count}]</>}
                            </h4>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              display:
                                this.state.ViewVariantList == true
                                  ? ""
                                  : "none",
                            }}
                          >
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              Variant List
                            </h4>
                          </div>
                        </div>
                        {this.state.ShowAddNewVaraintButton === true ?
                          <div className="col-6">
                            <div className="position-relative">
                              <button
                                onClick={
                                  this.toggleDrawerNewVariant.bind(this)
                                }
                                className="btn align-items-center btn Bechofy-btn AddNewBtn"
                              >
                                <span className="">
                                  <img
                                    src={varianticon}
                                    alt="block user"
                                    className="btnicons"
                                    style={{ marginRight: "5px" }}
                                  ></img>{" "}
                                </span>
                                Add New Variant{" "}
                              </button>
                            </div>
                          </div> : ""
                        }
                      </div>
                    </div>
                    {this.state.VariantData.length != 0 ? (
                      <CardBody className="pt-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div>
                              <ul className="breadcrumbs pt-lg-3 mb-lg-0">
                                <li>
                                  <a href="https://store.bechofy.in/">
                                    {this.state.VariantData.length > 0 ? this.state.VariantData[0].fld_brand_name : ""}
                                  </a>
                                </li>
                                <li> {this.state.VariantData.length > 0 ? this.state.VariantData[0].fld_verticlename : ""}</li>
                                <li> {this.state.VariantData.length > 0 ? this.state.VariantData[0].fld_categoryname : ""}</li>
                                <li> {this.state.VariantData.length > 0 ? this.state.VariantData[0].fld_subcategoryname : ""}</li>
                                <li> {this.state.VariantData.length > 0 ? this.state.VariantData[0].fld_itemname : ""}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div>
                          <MDBCol
                            md="12"
                            style={{ marginBottom: "15px", marginTop: "15px" }}
                          >
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="">
                                    <img
                                      src={Searchicon}
                                      alt="block user"
                                      className="btnicons"
                                    ></img>
                                  </span>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type the search phrase you wish to search within the grid"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => this.seachBarHandler(e)}
                                value={this.state.searchInput}
                              />
                            </div>
                          </MDBCol>
                        </div>
                        {/* *********Items****** */}
                        <Row className="mx-1">
                          {this.state.VariantData.map((variant) => (
                            <Col lg={6} className="mb-4">
                              <div className="card pt-3 pl-3 pr-3 attribute-card item-varient-select-card">
                                <div className="row g-0 mb-3">
                                  <div className="col-md-4">
                                    <a href="#" className="add-edit-product-images">
                                      <img
                                        src={Editicon}
                                        onClick={this.toggleDrawer.bind(this, 'image', variant.fld_variantid, null)}
                                        className="btnicons"
                                        alt=""
                                      />
                                    </a>
                                    <Slider
                                      {...variantImages}
                                      className="product-image-slider"
                                    >
                                      {variant.VariantImage.split("#") > 0 ? variant.VariantImage.split("#") > 0?.map((img, i) => {
                                        <div className="best-products">
                                          <div className="img-container" width="50%">
                                            {img.split(/[#?]/)[0].split('.').pop().trim() !== 'mp4' ?
                                              <img
                                                src={img}
                                                alt="product image"
                                                className="img-fluid rounded-start"
                                              ></img> :
                                              <video src={img} autoPlay loop autoFocus autoCorrect='true' muted className="img-fluid rounded-start" />
                                            }
                                          </div>
                                        </div>
                                      }) : <div className="best-products">
                                        <div className="img-container" width="50%">
                                          {variant.VariantImage.split(/[#?]/)[0].split('.').pop().trim() !== 'mp4' ?
                                            <img
                                              src={variant.VariantImage}
                                              alt="product image"
                                              className="img-fluid rounded-start"
                                            ></img> :
                                            <video src={variant.VariantImage} autoPlay loop autoFocus autoCorrect='true' muted className="img-fluid rounded-start" />
                                          }
                                        </div>
                                      </div>}
                                    </Slider>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="card-body p-0">
                                      <div className="d-flex mb-3 justify-content-between align-items-center">
                                        <h5 className="card-title mb-0">
                                          {variant.fld_variantname}
                                        </h5>
                                        <Form>
                                          <Form.Check
                                            type="switch"
                                            checked={variant.fld_status === "Active" ? true : false}
                                            id="custom-switch"
                                            onClick={(e) => {
                                              PostApiCall.postRequest(
                                                {
                                                  status: e.target.checked === true ? "Active" : "InActive",
                                                  variantid: variant.fld_variantid,
                                                },
                                                "UpdateVariantStatus"
                                              ).then((results) =>
                                                results.json().then((obj) => {
                                                  if (results.status == 200 || results.status == 201) {
                                                    Notiflix.Notify.Success("Status Updated Successfully");
                                                    this.repeatLastSearch();
                                                    Notiflix.Loading.Remove();
                                                  } else {
                                                    Notiflix.Notify.Failure(obj.data);
                                                    Notiflix.Loading.Remove();
                                                  }
                                                })
                                              );
                                            }}
                                            label="Active"
                                          />
                                        </Form>
                                      </div>
                                      <p className="card-text mb-2" dangerouslySetInnerHTML={{ __html: variant.fld_short_description }}>
                                      </p>
                                      {localStorage.getItem("ClientDetails")[0].fld_pricing_type !== 'Statewise' ?
                                        <p className="card-text mb-2 font-weight-bold">
                                          Rs. {variant.fld_website_discount_price}
                                        </p>
                                        : ""}
                                      <Row>
                                        {variant.attribute_values.split(",").map((attr, i) => {
                                          return (
                                            <><Col lg={6}>
                                              <div>
                                                <p className="mb-0"><b>{attr.split("#")[0]}</b>: {attr.split("#")[1]}</p>
                                              </div>
                                            </Col></>
                                          );
                                        })}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex border-top pt-3 justify-content-end">
                                  <a
                                    onClick={this.toggleDrawer.bind(this, 'variant', variant.fld_variantid, null)}
                                    className="btn align-items-center FilterCardBtn3 border"
                                    href="#"
                                    title="Edit Variant"
                                  >
                                    <img
                                      src={varianticon}
                                      alt="Variant List"
                                      className="btnicons"
                                    ></img>
                                  </a>
                                  <a
                                    onClick={this.toggleStockDrawer.bind(this, variant.fld_variantid)}
                                    className="btn align-items-center FilterCardBtn3 border"
                                    href="#"
                                    title="Add Stock"
                                  >
                                    <img
                                      src={stocklisticon}
                                      alt="Stock List"
                                      className="btnicons"
                                    ></img>
                                  </a>
                                  <a
                                    onClick={this.toggleDrawer.bind(this, 'attribute', variant.fld_variantid, variant.attribute_values)}
                                    className="btn align-items-center FilterCardBtn3 border"
                                    href="#"
                                    title="Add Attribute"
                                  >
                                    <img
                                      src={varianticon}
                                      alt="Add Price"
                                      className="btnicons"
                                    ></img>
                                  </a>
                                  <a
                                    onClick={this.toggleDrawer.bind(this, 'price', variant.fld_variantid, null)}
                                    className="btn align-items-center FilterCardBtn3 border"
                                    href="#"
                                    title="Add Price"
                                  >
                                    <img
                                      src={pricelisticon}
                                      alt="Add Price"
                                      className="btnicons"
                                    ></img>
                                  </a>
                                  <a
                                    onClick={this.toggleDrawer.bind(this)}
                                    className="btn align-items-center FilterCardBtn3 border mr-0"
                                    href="#"
                                    title="Add Offers"
                                  >
                                    <img
                                      src={offersicon}
                                      alt="Add Price"
                                      className="btnicons"
                                    ></img>
                                  </a>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    ) : (
                      <Row className="mt-0 pt-4">
                        <Col sm="12" className="text-center">
                          <img
                            src={listemptyicon}
                            alt="block user"
                            className="customerhistory-icons ms-3 mt-1"
                            style={{ marginRight: "5px" }}
                          />
                        </Col>
                        <Col sm="12" className="text-center my-3">
                          <h6>No item list found</h6>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ProductList;
