import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle, Col, Container, Media, Row
} from "reactstrap";
import PostApiCall from "../../Api";
import Addressicon from "../../assets/images/address-book.png";
import backicon from '../../assets/images/backicon.png';
import Blockicon from "../../assets/images/dashboardimages/blocked.png";
import user1 from "../../assets/images/helpcenter.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
import CustomerCardHistory from "./CustomerCardHistory";
import CustomerFrequentlyBought from "./CustomerFrequentlyBought";
import CustomerOrderList from "./CustomerOrderList";
import OrderGraph from "./OrderSummary";
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl:
        "http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg",
      // ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
      Status: "Active",
      ImageData: [],
      UnitofMeasurement: "",
      Price: 0,
      Points: 0,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      RawMaterialName: "",
      GenderData: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      Gender: "",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Name: "",
      Mobile: "",
      Email: "",
      DOB: "",
      AnniversaryDate: "",
      Designation: "",
      Department: "",
      UserType: "",
      Address: "",
      Country: "",
      State: "",
      City: "",
      Pincode: "",
      AadharCard: "",
      PANCard: "",
      DrivingLicenses: "",
      BankName: "",
      Branch: "",
      AccountNumber: "",
      IFSCCode: "",
      Salary: "",
      Password: "",
      ConfirmPassword: "",
      UserData: [
        { value: "Admin", label: "Admin" },
        { value: "Super Admin", label: "Super Admin" },
        { value: "Users", label: "Users" },
      ],
      NumRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      DepartmentData: [],
      AadharData: [],
      DesignationData: [],
      BankData: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Details: [],
      Logintime: "",
      CustomerData: [],
      AddressData: [],
      OrderData: [],
      Landmark: "",
      Status: "",
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    //   this.props.setclearbrand()
    var customer = localStorage.getItem("customerDetails");
    var CustomerDetails = JSON.parse(customer);
    var lastlogin = localStorage.getItem("lastlogin");
    var LastDateTime = JSON.parse(lastlogin);
    this.setState({
      Logintime: LastDateTime,
    });
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customerid=${CustomerDetails}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            CustomerData: obj.data,
            Name: obj.data[0].fld_name,
            Email: obj.data[0].fld_email_address,
            Mobile: obj.data[0].fld_phone_number,
            Gender: obj.data[0].fld_gender,
            DOB: obj.data[0].fld_dob,
            Address: obj.data[0].fld_address_1,
            Country: obj.data[0].CountryName,
            State: obj.data[0].StateName,
            City: obj.data[0].CityName,
            Pincode: obj.data[0].fld_pincode,
            Landmark: obj.data[0].fld_landmark,
            Status: obj.data[0].fld_status,
          });
          // Notiflix.Loading.Remove();
        }
      })
    );
    // ===============Address Data=================
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AddressData: obj.data,
          });
          // Notiflix.Loading.Remove();
        }
      })
    );
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: ` where fld_customer_id=${CustomerDetails} order by fld_order_date desc`,
        FromClause: " top 1 * "
      },
      "GetCustomerDashBoardDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            OrderData: obj.data,
          });
          // Notiflix.Loading.Remove();
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/customer-profile"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden pagebackground">
                  <div className="Bechofy-bg-soft-pink view-customer-bg">
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0 pb-0 text-white">
                            Customer Profile
                          </h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <Link to={`/customer-management`}>
                          <button
                            // onClick={() => {
                            //   window.location.href = "/customer-management";
                            // }}
                            className="btn align-items-center Bechofy-btn AddNewBtn"
                            style={{
                              marginTop: '9px'
                            }}
                          >
                            <span className="">
                              <img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
                            Back to Customer List{" "}
                          </button>
                        </Link>
                        <div className="Bechofy-text mt-2">
                          {this.state.Status !== "Suspended" ? (
                            <button
                              className="align-items-center Bechofy-btn vertical-sequentialize-btn"
                              onClick={() => {
                                // Notiflix.Loading.Dots();
                                var customer =
                                  localStorage.getItem("customerDetails");
                                var CustomerDetails = JSON.parse(customer);
                                let details = JSON.parse(
                                  localStorage.getItem("LoginDetail")
                                );
                                PostApiCall.postRequest(
                                  {
                                    customerid: CustomerDetails,
                                    name: this.state.Name,
                                    email: this.state.Email,
                                    mobile: this.state.Mobile,
                                    gender: this.state.Gender,
                                    dateofbirth: this.state.DOB,
                                    presentaddress: this.state.Address,
                                    presentcountry: this.state.Country,
                                    presentstate: this.state.State,
                                    presentcity: this.state.City,
                                    presentpincode: this.state.Pincode,
                                    password: null,
                                    status: "Suspended",
                                    updatedon: moment().format("DD/MM/YYYY"),
                                    updatedby: details[0].fld_userid,
                                    landmark: null,
                                  },
                                  "UpdateCustomer"
                                ).then((resultcategory) =>
                                  resultcategory.json().then((obj) => {
                                    if (
                                      obj &&
                                      obj.data[0] &&
                                      obj.data[0].Result ==
                                      "Successfully UPDATED"
                                    ) {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Success(
                                        "Customer Successfully Suspended"
                                      );
                                      window.location.reload();
                                    } else {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Failure(
                                        "Something went wrong, please try again later"
                                      );
                                    }
                                  })
                                );
                              }}
                            >
                              <span className="">
                                <img
                                  src={Blockicon}
                                  alt="block user"
                                  className="btnicons"
                                ></img>
                              </span>{" "}
                              Suspend User{" "}
                            </button>
                          ) : (
                            <button
                              className="align-items-center Bechofy-btn vertical-sequentialize-btn"
                              onClick={() => {
                                // Notiflix.Loading.Dots();
                                var customer =
                                  localStorage.getItem("customerDetails");
                                var CustomerDetails = JSON.parse(customer);
                                let details = JSON.parse(
                                  localStorage.getItem("LoginDetail")
                                );
                                PostApiCall.postRequest(
                                  {
                                    customerid: CustomerDetails,
                                    name: this.state.Name,
                                    email: this.state.Email,
                                    mobile: this.state.Mobile,
                                    gender: this.state.Gender,
                                    dateofbirth: this.state.DOB,
                                    presentaddress: this.state.Address,
                                    presentcountry: this.state.Country,
                                    presentstate: this.state.State,
                                    presentcity: this.state.City,
                                    presentpincode: this.state.Pincode,
                                    password: null,
                                    status: "Active",
                                    updatedon: moment().format("DD/MM/YYYY"),
                                    updatedby: details[0].fld_userid,
                                    landmark: null,
                                  },
                                  "UpdateCustomer"
                                ).then((resultcategory) =>
                                  resultcategory.json().then((obj) => {
                                    if (
                                      obj &&
                                      obj.data[0] &&
                                      obj.data[0].Result ==
                                      "Successfully UPDATED"
                                    ) {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Success(
                                        "Customer activated succesfully"
                                      );
                                      window.location.reload();
                                    } else {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Failure(
                                        "Something went wrong, please try again later"
                                      );
                                    }
                                  })
                                );
                              }}
                            >
                              Activate User{" "}
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row style={{ marginTop: "15px" }}>
                    <Col xl="4">
                      <Card className="overflow-hidden viewcustomer-top-card-size">
                        <div
                          className="Bechofy-bg-soft-pink"
                          style={{ height: "85px" }}
                        >
                          {this.state.CustomerData.map((data, i) => {
                            if (i == 0) {
                              return (
                                <Row>
                                  <Col xs="7">
                                    <div className="Bechofy-text p-3">
                                      <h5
                                        className="Bechofy-text"
                                        style={{ display: "flex" }}
                                      >
                                        {data.fld_name}
                                      </h5>
                                      <p>
                                        Customer Since{" "}
                                        {moment(data.fld_created_on).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col xs="5" className="align-self-end">
                                    <img
                                      src={
                                        data.fld_image == null
                                          ? user1
                                          : data.fld_image
                                      }
                                      alt=""
                                      className="img-fluid customerProfile"
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </div>
                        <CardBody
                          className="pt-6"
                          style={{ paddingTop: "2rem" }}
                        >
                          <Row>
                            <Col xs="5">
                              <p>Date of Birth</p>
                              <p>Gender</p>
                              <p>Marital Status</p>
                              <p>Date of Anniversary</p>
                              <p>Mobile</p>
                              <p>Email</p>
                              <p>GSTIN</p>
                            </Col>
                            {this.state.CustomerData.map((data, i) => {
                              if (i == 0) {
                                return (
                                  <Col xs="7">
                                    <p>
                                      {data.fld_dob != null ? moment(data.fld_dob).format(
                                        "DD/MM/YYYY"
                                      ) : "-"}{" "}
                                    </p>
                                    <p>
                                      {data.fld_gender == null
                                        ? "-"
                                        : data.fld_gender}
                                    </p>
                                    <p>
                                      {data.maritalStatus == null
                                        ? "-"
                                        : data.maritalStatus}
                                    </p>
                                    <p>
                                      {data.fld_doa == null
                                        ? "-"
                                        : moment(data.fld_doa).format(
                                          "DD/MM/YYYY"
                                        )}
                                    </p>
                                    <p>{data.fld_phone_number}</p>
                                    <p>{data.fld_email_address}</p>
                                    <p>{data.fld_gstin}</p>
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid ">
                        <CardBody className="viewcustomer-top-card-size">
                          <Media>
                            <Media body>
                              {this.state.OrderData.map((data, i) => {
                                if (i === 1) {
                                  return (<><div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <h4 className="mb-0 productTitle">
                                          Order Summary
                                        </h4>
                                      </div>
                                      <div className="col-md-4 px-0">
                                        <i
                                          className="fa fa-list-alt OrdersIcons"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <div className="col-12 mt-3 mb-0 pb-0 px-0">
                                        <h6>
                                          <strong> Your last order was on</strong>
                                        </h6>
                                        <h6>{data.fld_order_date}</h6>
                                      </div>
                                    </div>
                                  </div><div className="row mt-3 view-customer-total-order">
                                      <div className="col-12 p-2 borderview-customer">
                                        <h6 className="mb-0">
                                          <strong> Total Orders</strong>
                                        </h6>
                                        <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                          {data.total_order}
                                        </p>
                                      </div>
                                      <div className="col-12 mt-3 p-2 borderview-customer">
                                        <h6 className="mb-0">
                                          <strong>Total Purchase Value</strong>
                                        </h6>
                                        <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                          {data.OrderValue}
                                        </p>
                                      </div>
                                      <div className="col-12 mt-3 p-2 borderview-customer">
                                        <h6 className="mb-0">
                                          <strong>You have Saved</strong>
                                        </h6>
                                        <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                          {data.total_discount}
                                        </p>
                                      </div>
                                    </div></>)

                                }
                              })}
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="5">
                      <OrderGraph />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <CustomerCardHistory />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <CustomerOrderList />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden ">
                        <CardBody className="pt-0 ">
                          <CardTitle className="usertitle">
                            <h4 className="mb-3">Address Book</h4>
                          </CardTitle>
                          {this.state.AddressData.length != 0 ? (
                            <Row>
                              {this.state.AddressData.map((data, i) => (
                                <Col xs="4">
                                  <CardBody className="categoryCard ">
                                    <CardTitle className="d-flex">
                                      {data.fld_contact_name}
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "30px",
                                        }}
                                      >
                                        <p>
                                          Address Type : {data.fld_address_type}
                                        </p>
                                      </span>
                                    </CardTitle>
                                    <Row className="card-row">
                                      <Col sm="12">
                                        <div className="">
                                          <p>
                                            {data.fld_address_1}
                                            <br />
                                            {data.fld_address_2}{" "}
                                            {data.fld_address_3}
                                            <br />
                                            {data.CityName} - {data.fld_pincode}
                                            . {data.CountryName}.
                                            <br />
                                            <b> Landmark:</b>{" "}
                                            {data.fld_landmark}
                                          </p>
                                          <p>
                                            <b> Mobile </b>{" "}
                                            {data.fld_contact_number}
                                            <br />
                                            <b> Email ID </b>{" "}
                                            {data.fld_email_address}
                                            <br /> <b> GSTIN </b>{" "}
                                            {data.fld_gstin}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <Row className="justify-content-center">
                              <Col sm="12" className="text-center">
                                <img
                                  src={Addressicon}
                                  alt="block user"
                                  className="customerhistory-icons ms-3 mt-1"
                                  style={{ marginRight: "5px" }}
                                />
                              </Col>
                              <Col sm="12" className="text-center my-3">
                                <h6>
                                  No Address found.
                                </h6>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <CustomerFrequentlyBought />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddUser;
