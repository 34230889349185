import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactChipInput from "react-chip-input";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import backicon from '../../assets/images/backicon.png';
import Saveicon from "../../assets/images/dashboardimages/filesave.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
class AddAttributeMaster extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.state)
    this.state = {
      Status: this.props.location.state !== undefined ? this.props.location.state.attributestatus == 'undefined' ? "Active" : this.props.location.state.attributestatus : "Active",
      CategoryData: [],
      CategorySelect: [],
      groupArray: [],
      SubCategory: [],
      AttributeData: [],
      Attribute: this.props.location.state !== undefined ? this.props.location.state.attributeid == 'undefined' ? "0" : this.props.location.state.attributeid : "0",
      subcat: [],
      chips: [],
      PredefinedSubcategory: [],
      AttributeName: this.props.location.state !== undefined ? this.props.location.state.attributename == 'undefined' ? "" : this.props.location.state.attributename : ""
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    // Notiflix.Loading.Dots("Please wait...");
    //get saved attribute details
    this.getAttributeDetails();
    GetApiCall.getRequest("GetAttributeMaster").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          CategoryData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    GetApiCall.getRequest("GetAttributeMasterDrop").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          AttributeData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  // add attribute master
  onSaveData() {
    if (this.state.AttributeName != null) {
      if (JSON.stringify(this.state.SubCategory) != "[]") {
        if (this.state.chips != "") {
          this.SaveAttributeMaster();
        } else {
          Notiflix.Notify.Failure("Please enter attribute value");
        }
      } else {
        Notiflix.Notify.Failure("Please select sub category.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter attribute name");
    }
  }
  SaveAttributeMaster = () => {
    // Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        attributeid: this.state.Attribute,
        attributename: this.state.AttributeName,
        status: this.state.Status,
        createdon: moment().format("YYYY-MM-DD"),
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
        createdby: details[0].fld_userid,
      },
      "AddAttributeMaster"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {

          Notiflix.Loading.Remove();
          this.SaveAttributeMapping(obj.data[0].attribute_id);
          Notiflix.Notify.Success("New Attribute successfully added.");
        } else {
          Notiflix.Notify.Failure(obj);
        }
      }, 2000)
    );
  };
  // save mapping
  SaveAttributeMapping = (attributeid) => {
    // Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    var addSubCategory = "";
    if (
      this.state.PredefinedSubcategory.length == this.state.SubCategory.length
    ) {
      for (var i = 0; i < this.state.SubCategory.length; i++) {
        if (i == 0) {
          addSubCategory = this.state.SubCategory[i].fld_subcategoryid;
        } else {
          addSubCategory =
            addSubCategory + "," + this.state.SubCategory[i].fld_subcategoryid;
        }
      }
    } else {
      for (var i = 0; i < this.state.SubCategory.length; i++) {
        if (i == 0) {
          addSubCategory = this.state.SubCategory[i].fld_subcategoryid;
        } else {
          addSubCategory =
            addSubCategory + "," + this.state.SubCategory[i].fld_subcategoryid;
        }
      }
    }
    PostApiCall.postRequest(
      {
        attributeid: attributeid,
        SubcategoryId: addSubCategory,
        active: this.state.Status,
        createdon: moment().format("YYYY-MM-DD"),
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
        createdby: details[0].fld_userid,
      },
      "AddAttributeMapping"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Attribute successfully mapped.");
          this.SaveAttributeValues(attributeid);
        } else {
          Notiflix.Notify.Failure("Obj");
        }
      })
    );
  };
  // save attribute value
  SaveAttributeValues = (attributeid) => {
    // Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        mappingId: attributeid,
        attributeValue: this.state.chips.join(","),
        status: this.state.Status,
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
      },
      "AddAttributeValue"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(" Attribute value successfully added.");
        } else {
          Notiflix.Notify.Failure(obj);
        }
      })
    );
  };
  groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  onImagePickerHanlder = (id, file, isvalid) => {
    this.setState({
      ...this.state,
      imageValue: file,
      isImageValid: isvalid,
    });
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };
  onChangeDescription(text) {
    this.setState({
      JobDescription: text.editor.getData(),
    });
  }
  getAttributeDetails() {
    this.setState(
      {
        // Attribute: this.props.location.state.attributeid == 'undefined' ? "0" : this.props.location.state.attributeid,
      },
      () => {
        // Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {
            id: this.state.Attribute,
          },
          "GetSubcategoryById"
        ).then((results2) =>
          results2.json().then((obj2) => {
            if (results2.status == 200 || results2.status == 201) {
              let arr = [];
              if (obj2.data) {
                obj2.data.map((item) => {
                  arr.push({ subcategory: item.fld_subcategoryname });
                });
              }
              this.setState({
                subcat: arr,
              });
              let arr2 = [];
              if (obj2.data) {
                obj2.data.map((item) => {
                  arr2.push({ fld_subcategoryid: item.FLD_SubCategoryID });
                });
              }
              this.setState({
                SubCategory: arr2,
                PredefinedSubcategory: arr2,
              });
              PostApiCall.postRequest(
                {
                  id: this.state.Attribute,
                },
                "GetAttributeValueById"
              ).then((results2) =>
                results2.json().then((obj2) => {
                  if (results2.status == 200 || results2.status == 201) {
                    let arr = [];
                    if (obj2.data) {
                      obj2.data.map((item) => {
                        arr.push(item.fld_attributesvalue);
                      });
                    }
                    this.setState({
                      chips: arr,
                    });
                    Notiflix.Loading.Remove();
                  }
                })
              );
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  addChip = (value) => {
    const chips = this.state.chips.slice();
    chips.push(value);
    this.setState({ chips });
  };
  removeChip = (index) => {
    const chips = this.state.chips.slice();
    chips.splice(index, 1);
    this.setState({ chips });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    let temp = [];
    this.state.CategoryData.map((data, i) => {
      temp.push(data.fld_categoryname);
    });
    temp = [...new Set(temp)];
    let groupTemp = this.groupBy(this.state.CategoryData, "fld_categoryname");
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              className="px-0"
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text">
                            Add New Attribute
                          </h5>
                        </div>
                      </Col>
                      <Col xs="9">
                        <Link to={`/attribute-master`}>
                          <button
                            // onClick={() => {
                            //   window.location.href = "/attribute-master";
                            // }}
                            className="btn align-items-center Bechofy-btn AddNewBtn"
                            style={{
                              marginTop: '11px'
                            }}
                          >
                            <span className="">
                              <img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
                            Back to Attribute Listing{" "}
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12" className="my-4 ">
                        {/* <Card className="my-4 attribute-card">
                          <CardBody> */}
                        <div className="row justify-content-between">
                          <div className="col-6">
                            <label className="my-1 mr-2">
                              Attribute Name
                            </label>
                            <input
                              className="form-control"
                              name="name"
                              type="text"
                              placeholder="Enter Attribute Name"
                              value={this.state.AttributeName}
                              onChange={(text) => {
                                this.setState({
                                  AttributeName: text.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-2 add-attribute-status">
                            <div className="form-group">
                              <label htmlFor="CategoryName">
                                Status
                                <span className="mandatory">*</span>
                              </label>
                              <br />
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Status == "Active"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Status: "Active",
                                    });
                                  }}
                                />{" "}
                                Active
                              </label>
                              <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                              >
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Status == "InActive"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Status: "InActive",
                                    });
                                  }}
                                />{" "}
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                        {/*  ****************** Assign Category ****************** */}
                        <h5 className="assign-attribute-font mt-4 py-0">
                          Assign Category & Sub Category to
                          Attribute
                        </h5>
                        <div className="row">
                          <div className="col-4">
                            <Card
                              className="my-4 attribute-card"
                            >
                              <CardBody>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <div className="row">
                                    {temp.map((info, index) => (
                                      <div
                                        className="col-md-12"
                                        style={{
                                          marginTop: "1%",
                                          marginBottom: "1%",
                                        }}
                                      >
                                        <span>
                                          {info}
                                          {groupTemp[info] !==
                                            undefined &&
                                            groupTemp[info].map(
                                              (data) => {
                                                return (
                                                  <div
                                                    className="col-md-12"
                                                    style={{
                                                      marginTop: "4px",
                                                    }}
                                                  >
                                                    {this.state.subcat
                                                      .length > 0 && (
                                                        <input
                                                          defaultChecked={
                                                            this.state
                                                              .subcat
                                                              .length > 0
                                                              ? this.state.subcat
                                                                .map(
                                                                  (
                                                                    item
                                                                  ) => {
                                                                    if (
                                                                      item.subcategory ===
                                                                      data.fld_subcategoryname
                                                                    ) {
                                                                      return true;
                                                                    } else {
                                                                      return false;
                                                                    }
                                                                  }
                                                                )
                                                                .filter(
                                                                  (
                                                                    item
                                                                  ) => {
                                                                    if (
                                                                      item ==
                                                                      true
                                                                    ) {
                                                                      return true;
                                                                    }
                                                                    if (
                                                                      item !==
                                                                      true
                                                                    ) {
                                                                      return false;
                                                                    }
                                                                  }
                                                                )[0] ==
                                                                true
                                                                ? true
                                                                : false
                                                                  ? null
                                                                  : null
                                                              : null
                                                          }
                                                          onClick={() => {
                                                            var ar = [
                                                              ...this
                                                                .state
                                                                .SubCategory,
                                                            ];
                                                            if (
                                                              ar.includes(
                                                                data
                                                              )
                                                            ) {
                                                              ar.splice(
                                                                ar.indexOf(
                                                                  data
                                                                ),
                                                                1
                                                              );
                                                            } else {
                                                              ar.push(
                                                                data
                                                              );
                                                            }
                                                            this.setState(
                                                              {
                                                                SubCategory:
                                                                  ar,
                                                              }
                                                            );
                                                          }}
                                                          type="checkbox"
                                                        />
                                                      )}
                                                    {this.state.subcat
                                                      .length == 0 && (
                                                        <input
                                                          checked={
                                                            this.state
                                                              .subcat
                                                              .length > 0
                                                              ? this.state.subcat
                                                                .map(
                                                                  (
                                                                    item
                                                                  ) => {
                                                                    if (
                                                                      item.subcategory ===
                                                                      data.fld_subcategoryname
                                                                    ) {
                                                                      return true;
                                                                    } else {
                                                                      return false;
                                                                    }
                                                                  }
                                                                )
                                                                .filter(
                                                                  (
                                                                    item
                                                                  ) => {
                                                                    if (
                                                                      item ==
                                                                      true
                                                                    ) {
                                                                      return true;
                                                                    }
                                                                    if (
                                                                      item !==
                                                                      true
                                                                    ) {
                                                                      return false;
                                                                    }
                                                                  }
                                                                )[0] ==
                                                                true
                                                                ? true
                                                                : false
                                                                  ? null
                                                                  : null
                                                              : null
                                                          }
                                                          onClick={() => {
                                                            var ar = [
                                                              ...this
                                                                .state
                                                                .SubCategory,
                                                            ];
                                                            if (
                                                              ar.includes(
                                                                data
                                                              )
                                                            ) {
                                                              ar.splice(
                                                                ar.indexOf(
                                                                  data
                                                                ),
                                                                1
                                                              );
                                                            } else {
                                                              ar.push(
                                                                data
                                                              );
                                                            }
                                                            this.setState(
                                                              {
                                                                SubCategory:
                                                                  ar,
                                                              }
                                                            );
                                                          }}
                                                          type="checkbox"
                                                        />
                                                      )}{" "}
                                                    <span>
                                                      {" "}
                                                      {
                                                        data.fld_subcategoryname
                                                      }
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>

                        </div>
                        {/* ************ Attribut Value**** */}
                        <Col xs="12" className="px-0">
                          <h5 className="assign-attribute-font">
                            Attribute Value
                          </h5>
                          <Row>
                            <Col xs="12">
                              <div className="row">
                                <div className="col col-12">
                                  <div className="form-group">
                                    <ReactChipInput
                                      classes="class1 class2"
                                      chips={this.state.chips}
                                      onSubmit={(value) =>
                                        this.addChip(value)
                                      }
                                      onRemove={(index) =>
                                        this.removeChip(index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* </CardBody>
                        </Card> */}
                        {/* *****Save btn******** */}
                        <Card className="attribute-card">
                          <CardBody
                          >
                            <Row>
                              <Col xs="12">
                                <Row>
                                  <div className="col-12">
                                    <button
                                      onClick={this.onSaveData.bind(this)}
                                      className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                      style={{
                                        marginTop: "5px",
                                        color: "white",
                                      }}
                                    >
                                      <span className="">
                                        <img
                                          src={Saveicon}
                                          alt="block user"
                                          className="btnicons"
                                          style={{ marginRight: "5px" }}
                                        ></img>{" "}
                                      </span>
                                      Save Attribute
                                    </button>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddAttributeMaster;
