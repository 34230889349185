import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import customersicon from "../../assets/images/cuatomers.png";
import Calendericon from "../../assets/images/dashboardimages/calendar.png";
import Downloadicon from "../../assets/images/dashboardimages/down-arrow.png";
import Filtericon from "../../assets/images/dashboardimages/filter.png";
import Refreshicon from "../../assets/images/dashboardimages/refresh.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import DatePicker from 'react-date-picker';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Skeletoncustomer = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between">
          <Col xs="4" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="6" className="px-4 my-auto">
            <div className="Bechofy-text" style={{ textAlign: "end" }}>
              <Skeleton className="py-2" style={{ width: "30%" }} />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="1" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0 px-0">
        <Row>
          <Col
            xs="12"
            className="latestreview-mdbtable lastestcustomer-table-responsive"
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
class customerList extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      FromDate: null,
      ToDate: null,
      OrderData: [],
      CustomerData: [],
      SearchField: null,
      fileData: [],
      count: "",
      ListAllCustomerData: "NotClicked",
      loader: true,
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: "",
        RecordCount: "Top 20 *",
      },
      "GetCustomerList"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            CustomerData: obj.data,
            count: obj.data.length,
            loader: false,
          });
          //
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  onPost = () => {
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where cast (fld_created_on as date) BETWEEN cast('${moment(
          this.state.FromDate == null ? null : this.state.FromDate
        ).format("MM-DD-YYYY")}' as date)
    AND cast('${moment(
          this.state.ToDate == null ? null : this.state.ToDate
        ).format("MM-DD-YYYY")}' as date)
    `,
      },
      "GetCustomerList"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            CustomerData: obj.data,
            ListAllCustomerData: "Clicked",
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  DownloadCSV = () => {
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: "",
        RecordCount: "*",
      },
      "GetCustomerList"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            // this.props.setstate()
            this.setState(
              {
                fileData: obj.data,
              },
              () => {
                setTimeout(() => {
                  this.csvLink.current.link.click();
                });
              }
            );
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  onPostSearch() {
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where upper(SearchFiled) LIKE isnull('%${this.state.SearchField == null ? "" : this.state.SearchField
          }%',upper(SearchFiled))
				`,
      },
      "GetCustomerList"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            CustomerData: obj.data,
            ListAllCustomerData: "Clicked",
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "disabled",
          width: 600,
        },
        {
          label: <div className="text-start">Email Address</div>,
          field: "email",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Mobile No.",
          field: "contact",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Registered On",
          field: "registeredOn",
          sort: "disabled",
          width: 200,
        },
        {
          label: "No. of Orders",
          field: "ordercount",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Source",
          field: "source",
          sort: "disabled",
          width: 300,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.CustomerData.filter((data) => {
        if (
          this.state.searchInput == "" &&
          this.state.ListAllCustomerData == "Clicked"
        ) {
          return data;
        }
        if (
          // this.state.searchInput !== "" &&
          data.fld_name
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
          // `${data.fld_email ? data.fld_email.toLowerCase() : ""
          // 	}`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_phone_number ? data.fld_phone_number.toLowerCase() : ""
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_email_address ? data.fld_email_address.toLowerCase() : ""
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.CityName != null ? data.CityName.toLowerCase() : null
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.FLD_Door_no != null ? data.FLD_Door_no.toLowerCase() : null
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_landmark != null ? data.fld_landmark.toLowerCase() : null
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.StateName != null ? data.StateName.toLowerCase() : null
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_pincode != null ? data.fld_pincode.toLowerCase() : null
            }`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_status ? data.fld_status.toLowerCase() : ""}`.includes(
            this.state.searchInput.toLowerCase()
          )
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          name: (
            <div className="text-start newcustomer-mdtablecol">{data.fld_name}</div>
          ),
          email: (
            <div className="text-start newcustomer-mdtablecol">
              {data.fld_email_address}
            </div>
          ),
          contact: data.fld_phone_number ? data.fld_phone_number : "N/A",
          registeredOn: data.fld_created_on ? moment(data.fld_created_on).format("DD/MM/YYYY") : "N/A",
          ordercount: data.total_Orders ? data.total_Orders : 0,
          source: data.fld_status,
          action: (
            <span className="">
              <Link to={`/customer-profile`}
                className="btn align-items-center btn Bechofy-btn "
                onClick={() => {
                  localStorage.setItem(
                    "customerDetails",
                    JSON.stringify(data.fld_customerid)
                  );
                  // window.location.href = "/customer-profile";
                }}
              >
                View Profile
              </Link>
            </span>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/customer-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-12 text-start">
                        <div className="form-group">
                          <h4
                            className="px-4 text-white text-start"
                            style={{ marginTop: "10px" }}
                          >
                            Filter Data By
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card style={{ padding: " 0 20px" }}>
                    <div className="row my-1 ">
                      <div className="col-lg-4 col-md-10 col-sm-10">
                        <div className="form-group ">
                          <div className="input-group">
                            <div className="input-group-prepend gridinput">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Filtericon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              value={this.state.SearchField}
                              onChange={(text) => {
                                this.setState({
                                  SearchField: text.target.value,
                                });
                              }}
                              className="form-control gridinput"
                              placeholder="Enter either name / mobile number / email / address"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-2 col-sm-2">
                        <Link
                          onClick={() => {
                            if (this.state.SearchField != null) {
                              this.onPostSearch();
                            } else {
                              Notiflix.Notify.failure(
                                "Please enter the name of Customer"
                              );
                            }
                          }}
                          style={{
                            marginTop: "15px",
                            color: "white",
                            marginLeft: "0px",
                            marginRight: "4px",
                          }}
                          className="btn align-items-center btn Bechofy-btn "
                        >
                          Search
                        </Link>
                      </div>
                      <div className="col-lg-2 col-md-3">
                        <div className="form-group ">
                          <DatePicker
                            placeholderText="From Date"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.FromDate}
                            onChange={(date) => {
                              this.setState({
                                FromDate: date,
                              });
                            }}
                            isClearable
                            className="form-control gridinput"
                            maxDate={new Date()}
                          />
                          <span
                            className="login-icon-change-pass"
                            style={{ bottom: "30px" }}
                          >
                            <img
                              src={Calendericon}
                              alt="datepicker"
                              className="btnicons"
                            ></img>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3">
                        <div className="form-group">
                          <DatePicker
                            placeholderText="To Date"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.ToDate}
                            onChange={(date) => {
                              this.setState({
                                ToDate: date,
                              });
                            }}
                            isClearable
                            className="form-control gridinput"
                            maxDate={new Date()}
                          />
                          <span
                            className="login-icon-change-pass"
                            style={{ bottom: "30px" }}
                          >
                            <img
                              src={Calendericon}
                              alt="datepicker"
                              className="btnicons"
                            ></img>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 mb-sm-3">
                        <Link
                          onClick={() => {
                            if (
                              this.state.SearchField != null ||
                              this.state.FromDate != null ||
                              this.state.ToDate != null
                            ) {
                              this.onPost();
                            } else {
                              Notiflix.Notify.Failure(
                                "Please select one search criteria"
                              );
                            }
                          }}
                          style={{
                            marginTop: "15px",
                            color: "white",
                            marginLeft: "0px",
                            marginRight: "4px",
                          }}
                          className="btn align-items-center btn Bechofy-btn "
                        >
                          Filter & Display
                        </Link>
                        <Link
                          onClick={() => {
                            // Notiflix.Loading.Dots("Please wait...");
                            PostApiCall.postRequest(
                              {
                                WhereClause: "",
                                RecordCount: "*",
                              },
                              "GetCustomerList"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (
                                  results.status == 200 ||
                                  results.status == 201
                                ) {
                                  this.setState({
                                    CustomerData: obj.data,
                                    ListAllCustomerData: "Clicked",
                                  });
                                  Notiflix.Loading.Remove();
                                }
                              })
                            );
                          }}
                          style={{
                            marginTop: "15px",
                            color: "white",
                            marginLeft: "0px",
                            marginRight: "4px",
                          }}
                          className="btn align-items-center btn Bechofy-btn "
                        >
                          List All Customers
                        </Link>
                        <Link
                          className="btn align-items-center btn Bechofy-btn "
                          style={{
                            marginTop: "15px",
                          }}
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <span className="">
                            <img
                              src={Refreshicon}
                              alt="block user"
                              className="btnicons"
                              style={{ marginRight: "5px" }}
                            ></img>{" "}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </Card>
                  {this.state.loader == true ? (
                    <>
                      <Skeletoncustomer cards={5} />
                    </>
                  ) : (
                    <Card>
                      <div
                        className="Bechofy-bg-soft-pink"
                        style={{ height: "60px" }}
                      >
                        <div className="row my-1">
                          <div className="col-6">
                            <div className="form-group">
                              <h4
                                className="px-4 text-white text-start"
                                style={{ marginTop: "15px" }}
                              >
                                {this.state.count == 0 ? 'Customer List' : <>Customer List [{this.state.count}]</>}
                              </h4>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="position-relative">
                              <Link
                                onClick={this.DownloadCSV.bind(this)}
                                className="align-items-center Bechofy-btn category-Add-btn categorybtnposition customer-download-btn"
                              >
                                <span className="">
                                  <img
                                    src={Downloadicon}
                                    alt="block user"
                                    className="btnicons"
                                    style={{ marginRight: "5px" }}
                                  ></img>{" "}
                                </span>
                                Download(.csv)
                              </Link>
                            </div>
                            <CSVLink
                              data={this.state.fileData}
                              filename="CustomerList.csv"
                              className="hidden"
                              ref={this.csvLink}
                              target="_blank"
                            />
                          </div>
                        </div>
                      </div>
                      <CardBody className="pt-0">
                        <Row className="text-center justify-content-center">
                          {this.state.CustomerData.length != 0 ? (
                            <>
                              <MDBCol
                                md="12"
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <span className="">
                                        <img
                                          src={Searchicon}
                                          alt="block user"
                                          className="btnicons"
                                        ></img>
                                      </span>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type the search phrase you wish to search within the grid"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => this.seachBarHandler(e)}
                                    value={this.state.searchInput}
                                  />
                                </div>
                              </MDBCol>
                              <Col
                                md="12"
                                className="lastestcustomer-table-responsive"
                              >
                                <MDBDataTable
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[10, 25, 50, 100]}
                                  className="customer-table-responsive"
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTable>
                              </Col>
                            </>
                          ) : (
                            <Row className="mt-4 pt-2">
                              <Col sm="12" className="text-center">
                                <img
                                  src={customersicon}
                                  alt="block user"
                                  className="customerhistory-icons ms-3 mt-1"
                                  style={{ marginRight: "5px" }}
                                />
                              </Col>
                              <Col sm="12" className="text-center my-3">
                                <h6>No new customers</h6>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default customerList;
