import Notiflix from "notiflix";
import React, { Component } from "react";
import Slider from "react-slick";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NoImage from "../../assets/images/NoImage.png";
import Trolley from "../../assets/images/trolley.png";
class Frequently extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      BestProduct: [],
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    // Notiflix.Loading.Dots("Please wait...");
    // PostApiCall.postRequest("GetFeaturedProduct").then((resultdes) =>
    //   resultdes.json().then((obj) => {
    //     //
    //     this.setState({
    //       BestProduct: obj.data,
    //     });
    //     Notiflix.Loading.Remove();
    //   })
    // );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 800,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <h4>Frequently Bought</h4>
            </CardTitle>
            {this.state.BestProduct.length != 0 ? (
              <Slider {...settings}>
                {this.state.BestProduct.map((data, i) => (
                  <div className="best-products">
                    <div className="product-item">
                      <div className="img-container" width="50%">
                        <img
                          src={
                            data.variant_list
                              .split("#")[0]
                              .split("$")[1]
                              .split(",")[7]
                              .split("~")[0]
                              ? data.variant_list
                                .split("#")[0]
                                .split("$")[1]
                                .split(",")[7]
                                .split("~")[0]
                              : NoImage
                          }
                          alt="product image"
                          className="img-fluid"
                        ></img>
                      </div>
                      <div className="product-desc">
                        <h4>
                          {
                            data.variant_list
                              .split("#")[0]
                              .split("$")[1]
                              .split(",")[0]
                          }{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" className="text-center">
                  <img
                    src={Trolley}
                    alt="block user"
                    className="customerhistory-icons ms-3 mt-1"
                    style={{ marginRight: "5px" }}
                  />
                </Col>
                <Col sm="12" className="text-center my-3">
                  <h6>No data found</h6>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default Frequently;
