import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import backicon from "../../assets/images/backicon.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				htmlFor="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);
class subCategory extends Component {
	constructor(props) {
		super(props);
		const { action } = props.match.params;
		this.state = {
			imagePreviewUrl:
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			ImageApiUrl: 'https://api.bechofy.in/AddImage',
			Status: "Yes",
			ImageData: [],
			CategoryId: null,
			CategoryDescription: "",
			SubCategoryName: "",
			SubCategoryId: null,
			Action: action,
			CategoryList: [],
			VerticalName: null,
			VerticalData: [],
			originalImage: [],
			originalLink: "",
			clicked: false,
			uploadImage: false,
			subCategoryImage: null,
		};
	}
	componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		//   this.props.setclearbrand()
		// Notiflix.Loading.Dots("Please wait...");
		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
		var det = localStorage.getItem("SubCategoryDetails");
		var SubCategoryData = JSON.parse(det);
		if (SubCategoryData != null) {
			if (this.state.Action == "update") {
				this.setState(
					{
						VerticalName: SubCategoryData.fld_vertical_id,
						CategoryId: SubCategoryData.fld_categoryid,
						SubCategoryId: SubCategoryData.fld_subcategoryid,
						Status:
							SubCategoryData.fld_showOnWebsite == "Active" ? "Yes" : "No",
						SubCategoryName: SubCategoryData.fld_subcategoryname,
					},
					() => {
						// Notiflix.Loading.Dots("Please wait...");
						PostApiCall.postRequest(
							{
								whereClause: `where fld_vertical_id=${this.state.VerticalName}`,
							},
							"Get_categorydropdown"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									this.setState({
										CategoryList: obj1.data,
									});
									Notiflix.Loading.Remove();
								}
							})
						);
					}
				);
				if (SubCategoryData.fld_image != null) {
					this.setState({
						imagePreviewUrl: SubCategoryData.fld_image,
						subCategoryImage: SubCategoryData.fld_image,
					});
				} else {
					this.setState({
						imagePreviewUrl:
							"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					});
				}
				new Promise((resolve, reject) => {
					setTimeout(resolve, 1000);
				}).then(() => {
					this.setState({
						CategoryDescription: SubCategoryData.fld_description,
					});
				});
			}
		}
	}
	// ==Image Upload onChange Function=======
	photoUpload = (e) => {
		e.preventDefault();
		const imageFile = e.target.files[0];
		this.setState({
			imagePreviewUrl: URL.createObjectURL(imageFile),
			originalImage: imageFile,
			outputFileName: imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
			uploadImage: true,
		});
	};
	onChangeDescription(text) {
		this.setState({
			CategoryDescription: text.editor.getData(),
		});
	}
	onSaveData() {
		if (this.state.VerticalName != null) {
			if (this.state.CategoryId != null) {
				// //
				if (this.state.SubCategoryName != "") {
					if (
						this.state.imagePreviewUrl !=
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
					) {
						this.onPost();
					} else {
						Notiflix.Notify.Failure("Please upload category image");
					}
				} else {
					Notiflix.Notify.Failure("Please enter the sub category name");
				}
			} else {
				Notiflix.Notify.Failure("Please select  category name");
			}
		} else {
			Notiflix.Notify.Failure("Please select  Vertical name");
		}
	}
	onPost = () => {
		// Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		if (this.state.subCategoryImage != this.state.imagePreviewUrl) {
			if (this.state.outputFileName != undefined) {
				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryId,
						subcategory: this.state.SubCategoryName,
						description: this.state.CategoryDescription,
						showOnWebsite: this.state.Status == "Yes" ? "Active" : "Inactive",
						createdon: moment().format("YYYY-MM-DD"),
						updatedon: moment().format("YYYY-MM-DD"),
						updatedby: details[0].fld_userid,
						action: this.state.Action,
						subcategoryid: this.state.SubCategoryId,
					},
					"AddSubCategory"
				).then((resultcategory) =>
					resultcategory.json().then((obj) => {
						if (resultcategory.status == 200 || resultcategory.status == 201) {
							this.onPostAddsubCategroyImage(obj);
							//  //
						} else {
							Notiflix.Loading.Remove();
							Notiflix.Notify.Failure(obj.data);
						}
					})
				);
			} else {
				Notiflix.Loading.Remove();
				Notiflix.Notify.Failure("Please Upload SubCategory Image");
			}
		} else {
			this.onUpdateSubCategory();
		}
	};
	onUpdateSubCategory = () => {
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				categoryid: this.state.CategoryId,
				subcategory: this.state.SubCategoryName,
				description: this.state.CategoryDescription,
				showOnWebsite: this.state.Status == "Yes" ? "Active" : "Inactive",
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				action: this.state.Action,
				subcategoryid: this.state.SubCategoryId,
			},
			"AddSubCategory"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					this.onPostAddsubCategroyImage(obj);
					//  //
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	};
	onPostAddsubCategroyImage(obj) {
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		if (this.state.subCategoryImage != this.state.imagePreviewUrl) {
			PostApiCall.postRequest(
				{
					id: JSON.parse(JSON.stringify(obj.data[0])).SubCategoryId,
					image: 'https://store.bechofy.in/images/' +
						JSON.parse(localStorage.getItem("ClientDetails"))[0]
							.fld_dbschema +
						"/subcategoryImages/" +
						this.state.SubCategoryName.trim().replace(/\s/g, "-") +
						"-" +
						this.state.outputFileName.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp',
					updatedon: moment().format("YYYY-MM-DD"),
					updatedby: details[0].fld_userid,
				},
				"UpdateSubCategoryImage"
			).then((results1) =>
				results1.json().then((obj1) => {
					if (results1.status == 200 || results1.status == 201) {
						// //
						Notiflix.Notify.Success("SubCategory successfully added. Image upload in process");
						this.onUploadImage(obj);
					}
				})
			);
		} else {
			PostApiCall.postRequest(
				{
					id: JSON.parse(JSON.stringify(obj.data[0])).SubCategoryId,
					image: this.state.imagePreviewUrl,
					updatedon: moment().format("YYYY-MM-DD"),
					updatedby: details[0].fld_userid,
				},
				"UpdateSubCategoryImage"
			).then((results1) =>
				results1.json().then((obj1) => {
					if (results1.status == 200 || results1.status == 201) {
						Notiflix.Loading.Remove();
						Notiflix.Notify.Success("SubCategory successfully updated");
						window.location.href = "/sub-category-management";
					} else {
						Notiflix.Loading.Remove();
						Notiflix.Notify.Failure(obj1.data);
					}
				})
			);
		}
	}
	async onUploadImage(obj) {
		Notiflix.Loading.Dots("Uploading Images...");
		let response;
		if (this.state.outputFileName != undefined) {
			var options = {
				maxSizeMB: 0.05,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};
			imageCompression(this.state.originalImage, options).then(
				(compressedFile) => {
					const form = new FormData();
					form.append("file", compressedFile);
					form.append("foldername", "subcategoryImages");
					form.append(
						"filename",
						this.state.SubCategoryName.trim().replace(/\s/g, "-") +
						"-" +
						compressedFile.name
					);
					response = fetch(this.state.ImageApiUrl, {
						method: "POST",
						body: form,
						headers: {
							"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
							"x-auth-token": JSON.parse(localStorage.getItem("access")),
							"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
						}
					})
						.then((results) => results.json()
							.then((data) => {
								if (results.status == 200 || results.status == 201) {
									Notiflix.Loading.Remove();
									Notiflix.Notify.Success("Image successfully uploaded.");
									window.location.href = "/sub-category-management";
								} else {
									Notiflix.Loading.Remove();
									Notiflix.Notify.Failure("Image upload failed please tyr again or contact support.");
									// window.location.href = "/sub-category-management";
								}
							}))
				}
			);
		}
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(
								window.location.pathname.replace("/", "")
							).replace("-", " ")}
							breadcrumbItem1={
								this.state.SubCategoryId == null
									? "Add New SubCategory"
									: "Update SubCategory"
							}
							urlPath={"/category-management"}
							urlPath1={
								this.state.SubCategoryId == null
									? "/add-sub-category/create"
									: "/add-sub-category/update"
							}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																{this.state.SubCategoryId == null ? (
																	<h5 className="Bechofy-text">
																		Add New Sub Category
																	</h5>
																) : (
																	<h5 className="Bechofy-text">
																		Update Sub Category
																	</h5>
																)}
															</div>
														</Col>
														<Col xs="9">
															<Link to={`/sub-category-management`}>
																<button
																	// onClick={() => {
																	// 	window.location.href = "/sub-category-management";
																	// }}
																	className="btn align-items-center Bechofy-btn AddNewBtn"
																	style={{
																		marginTop: "9px",
																	}}
																>
																	<span className="">
																		<img
																			src={backicon}
																			alt="block user"
																			className="btnicons"
																			style={{ marginRight: "5px" }}
																		/>{" "}
																	</span>
																	Back to Category List{" "}
																</button>
															</Link>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					htmlFor="inlineFormCustomSelectPref"
																				>
																					Vertical Name
																					<span className="mandatory">*</span>
																				</label>
																				<select
																					value={this.state.VerticalName}
																					onChange={(text) => {
																						this.setState(
																							{
																								VerticalName: text.target.value,
																							},
																							() => {
																								// Notiflix.Loading.Dots(
																								//   "Please wait..."
																								// );
																								PostApiCall.postRequest(
																									{
																										whereClause: `where fld_vertical_id=${this.state.VerticalName}`,
																									},
																									"Get_categorydropdown"
																								).then((results1) =>
																									results1
																										.json()
																										.then((obj1) => {
																											if (
																												results1.status ==
																												200 ||
																												results1.status == 201
																											) {
																												this.setState({
																													CategoryList:
																														obj1.data,
																												});
																												Notiflix.Loading.Remove();
																											}
																										})
																								);
																							}
																						);
																					}}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Vertical
																					</option>
																					{this.state.VerticalData.map(
																						(Verticle) => (
																							<option
																								key={Verticle.value}
																								value={Verticle.value}
																							>
																								{Verticle.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					htmlFor="inlineFormCustomSelectPref"
																				>
																					Category Name
																					<span className="mandatory">*</span>
																				</label>
																				<select
																					value={this.state.CategoryId}
																					onChange={(text) => {
																						this.setState({
																							CategoryId: text.target.value,
																						});
																					}}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option>Select Category</option>
																					{this.state.CategoryList.map(
																						(Category) => (
																							<option
																								key={Category.value}
																								value={Category.value}
																							>
																								{Category.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Sub Category Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SubCategoryName}
																						onChange={(text) => {
																							this.setState({
																								SubCategoryName:
																									text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group my-1 mr-sm-2">
																					<label htmlFor="CategoryName">
																						Show On Website
																						<span className="mandatory">*</span>
																					</label>
																					<br />
																					<label className="radio-inline">
																						<input
																							type="radio"
																							name="optradio"
																							checked={
																								this.state.Status == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						className="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="optradio"
																							checked={
																								this.state.Status == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>
																			<div className="col-12">
																				<div className="form-group">
																					<label htmlFor="sw-arrows-first-name">
																						Description(maximum 500 Characters)
																					</label>
																					<div className="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={
																								this.state.CategoryDescription
																							}
																							onChange={this.onChangeDescription.bind(
																								this
																							)}
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-1"></div>
																		<div className="col-md-5">
																			<label htmlFor="sw-arrows-first-name">
																				Upload Sub Category Image (Size &lt;
																				100kb, 500*500)
																				<span className="mandatory">*</span>
																			</label>
																			<div className="div1">
																				<ImgUpload
																					onChange={this.photoUpload}
																					src={this.state.imagePreviewUrl}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-3 offset-9">
																	<button
																		style={{ float: "right" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		{this.state.SubCategoryId == null ? (
																			<span>Save Sub Category</span>
																		) : (
																			<span>Update Sub Category</span>
																		)}
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default subCategory;
