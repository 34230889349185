import imageCompression from "browser-image-compression";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-modal/styles.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Closeicon from "../../assets/images/dashboardimages/close.png";
import deleteicon from "../../assets/images/dashboardimages/delete.png";
import Saveicon from "../../assets/images/dashboardimages/filesave.png";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
const Skeletonbrandmanagement = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between mb-0 pb-0">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4 className="Bechofy-text pb-0 mb-0" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                        <div className="div1">
                          <Skeleton
                            style={{ height: "130px", width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" style={{ marginBottom: "-10px" }}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Skeleton className="py-1" />
                        <Skeleton className="my-2 py-1" />
                        <Skeleton className="my-2 py-3" />
                        <Skeleton className="py-1" style={{ width: "60px" }} />
                      </div>
                      <div className="col-2 mb-3">
                        <Skeleton className="py-1 px-3" />
                      </div>
                      <div className="col-2 mb-3">
                        <Skeleton className="py-1 px-3" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-4 px-3" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-end">
                  <Col sm="3" className="text-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                        <div className="div1">
                          <Skeleton
                            style={{ height: "130px", width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" style={{ marginBottom: "-10px" }}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Skeleton className="py-1" />
                        <Skeleton className="py-1 my-3" />
                        <Skeleton className="py-1" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap brandimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
class BrandMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ImagePreviewUrl:
        "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
      ImageApiUrl:
        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress +
        "AddImage",
      Status: "Yes",
      ImageData: [],
      BrandName: "",
      BrandId: null,
      CompanyId: null,
      BrandData: [],
      SearchInput: "",
      OriginalImage: [],
      OriginalLink: "",
      Clicked: false,
      UploadImage: false,
      BrandLogo: null,
      ImageName: null,
      Description: null,
      editfocos: false,
      count: "",
      loader: true,
    };
  }
  componentDidMount() {
    this.getbrand();
  }

  getbrand = () => {
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "Get_BrandMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            BrandData: obj.data,
            count: obj.data.length,
            loader: false,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    this.setState({
      ImagePreviewUrl: URL.createObjectURL(imageFile),
      OriginalImage: imageFile,
      outputFileName: imageFile.name,
      UploadImage: true,
      loader: false,
    });
  };
  onSaveData = () => {
    if (this.state.BrandName != "") {
      if (
        this.state.ImagePreviewUrl !=
        "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
      ) {
        this.onPost();
      } else {
        Notiflix.Notify.Failure("Please upload brand image");
      }
    } else {
      Notiflix.Notify.Failure("Please enter the brand name");
    }
  };
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    this.setState({
      editfocos: false,
    });
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    // //
    PostApiCall.postRequest(
      {
        brandid: this.state.BrandId,
        name: this.state.BrandName,
        status: this.state.Status === "Yes" ? "Active" : "Inactive",
        createdon: moment().format("YYYY-MM-DD"),
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
        action: this.state.BrandId === null ? "INSERT" : "UPDATE",
        description: this.state.Description,
      },
      "AddBrand"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          PostApiCall.postRequest(
            {
              id: JSON.parse(JSON.stringify(obj.data[0])).BrandId,
              image: 'https://store.bechofy.in/images/' +
                JSON.parse(localStorage.getItem("ClientDetails"))[0]
                  .fld_dbschema +
                "/BrandImages/" +
                this.state.BrandName.trim().replace(/\s/g, "-") +
                "-" +
                this.state.outputFileName.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp',
              updatedon: moment().format("YYYY-MM-DD"),
              updatedby: details[0].fld_userid,
            },
            "UpdateBrandImage"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                // //
                Notiflix.Notify.Success(
                  "Brand successfully added. Image upload in process"
                );
                this.onUploadImage(obj);
                this.getbrand();
              } else {
                Notiflix.Loading.Remove();
                Notiflix.Notify.Failure(obj1.data);
              }
            })
          );
          //  //
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  async onUploadImage(obj) {
    let response;
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.state.BrandLogo != this.state.ImagePreviewUrl) {
      if (this.state.outputFileName != undefined) {
        var options = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        imageCompression(this.state.OriginalImage, options).then(
          (compressedFile) => {
            const form = new FormData();
            form.append("file", compressedFile);
            form.append("foldername", "BrandImages");
            form.append(
              "filename",
              this.state.BrandName.trim().replace(/\s/g, "-") +
              "-" +
              compressedFile.name
            );
            response = fetch(this.state.ImageApiUrl, {
              method: "POST",
              body: form,
              headers: {
                "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
                "x-auth-token": JSON.parse(localStorage.getItem("access")),
                "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
              }
            })
              .then((response) => response.json())
              .then((data) => {
                this.setState({
                  ImageName: data.Message.split(",")[2].split("=")[1].trim(),
                });
                Notiflix.Loading.Remove();
                Notiflix.Notify.Success("Image successfully uploaded.");
                window.location.reload();
              });
          }
        );
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Brand Image");
      }
    } else {
      PostApiCall.postRequest(
        {
          id: JSON.parse(JSON.stringify(obj.data[0])).BrandId,
          image: this.state.ImagePreviewUrl,
          updatedon: moment().format("YYYY-MM-DD"),
          updatedby: details[0].fld_userid,
        },
        "UpdateBrandImage"
      ).then((results1) =>
        results1.json().then((obj1) => {
          if (results1.status == 200 || results1.status == 201) {
            // //
            Notiflix.Loading.Remove();
            Notiflix.Notify.Success("Brand successfully added.");
            window.location.reload();
          } else {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure(obj1.data);
          }
        })
      );
    }
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, SearchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/brand-list"}
            />
            {/* ==========Vertical======= */}
            {this.state.loader == true ? (
              <>
                <Skeletonbrandmanagement />
              </>
            ) : (
              <Row>
                <Col xl="12">
                  <Card className="overflow-hidden pagebackground">
                    <div className="Bechofy-bg-soft-pink">
                      <Row>
                        <Col xs="6">
                          <div className="Bechofy-text p-3">
                            <h5 className="Bechofy-text" id="upload-card">
                              {this.state.count == 0 ? 'Brand List' : <>Brand List [{this.state.count}]</>}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Card style={{ padding: "10px 20px" }}>
                      <div className="row my-1 ">
                        <div className="col-12">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type the search phrase you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.SearchInput}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <CardBody className="pt-0">
                        <Row>
                          <Col xs="12" xl="4" lg="6" md="12">
                            <CardBody
                              className={
                                this.state.editfocos == true
                                  ? "categoryCard focuscard cardpadding-brand card-samesize-barandmanagment card-focus"
                                  : "categoryCard card-samesize-barandmanagment pt-4"
                              }
                            >
                              <Row className="card-row">
                                <Col sm="6">
                                  <div className="imagecard">
                                    <div className="form-group ">
                                      <label htmlFor="sw-arrows-first-name">
                                        Upload Brand Image
                                        <span className="mandatory">*</span>
                                      </label>
                                      <div className="div1">
                                        <ImgUpload
                                          onChange={this.photoUpload}
                                          src={this.state.ImagePreviewUrl}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6" style={{ marginBottom: "-10px" }}>
                                  <div className="row">
                                    {this.state.BrandId == null ? (
                                      <h5>Add New Brand</h5>
                                    ) : (
                                      <h5>Edit Brand</h5>
                                    )}
                                    <div className="col-12">
                                      <label className="my-1 mr-2">
                                        Brand Name*
                                      </label>
                                      <input
                                        className="form-control"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Brand Name"
                                        value={this.state.BrandName}
                                        onChange={(text) => {
                                          this.setState({
                                            BrandName: text.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="col-12"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <div className="form-group">
                                        <label htmlFor="CategoryName">
                                          Status
                                          <span className="mandatory">*</span>
                                        </label>
                                        <br />
                                        <label className="radio-inline">
                                          <input
                                            type="radio"
                                            name="optradio"
                                            checked={
                                              this.state.Status == "Yes"
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState({
                                                Status: "Yes",
                                              });
                                            }}
                                          />{" "}
                                          Active
                                        </label>
                                        <label
                                          className="radio-inline"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <input
                                            type="radio"
                                            name="optradio"
                                            checked={
                                              this.state.Status == "No"
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState({
                                                Status: "No",
                                              });
                                            }}
                                          />{" "}
                                          Inactive
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <hr />
                              </Row>
                              <Row>
                                <Col sm="12">
                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    name="name"
                                    type="text"
                                    placeholder="Short Description"
                                    value={this.state.Description}
                                    onChange={(text) => {
                                      this.setState({
                                        Description: text.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              <hr className="mb-1" />
                              <Row>
                                <Col
                                  sm="12"
                                  className="my-1 closebtn-brandmanagment"
                                >
                                  {this.state.BrandId == null ? (
                                    <a
                                      onClick={this.onSaveData.bind(this)}
                                      className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                      style={{
                                        marginTop: "5px",
                                        color: "white",
                                      }}
                                    >
                                      <span className="">
                                        <img
                                          src={Saveicon}
                                          alt="block user"
                                          className="btnicons"
                                          style={{ marginRight: "5px" }}
                                        ></img>{" "}
                                      </span>
                                      Save Brand
                                    </a>
                                  ) : (
                                    <>
                                      <a
                                        onClick={this.onSaveData.bind(this)}
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm"
                                        style={{
                                          marginTop: "5px",
                                          color: "white",
                                        }}
                                      >
                                        <span className="">
                                          <img
                                            src={Editicon}
                                            alt="block user"
                                            className="btnicons"
                                            style={{ marginRight: "5px" }}
                                          ></img>{" "}
                                        </span>
                                        Update Brand
                                      </a>
                                      <div
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm ml-3"
                                        style={{
                                          marginTop: "5px",
                                          color: "white",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            BrandName: "",
                                            BrandId: "",
                                            Status: null,
                                            editfocos: false,
                                            Description: "",
                                            ImagePreviewUrl:
                                              "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                          });
                                        }}
                                      >
                                        <span className="">
                                          <img
                                            src={Closeicon}
                                            alt="block user"
                                            className="btnicons"
                                            style={{ marginRight: "5px" }}
                                          ></img>{" "}
                                        </span>
                                        Close
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                          {/* ======Brand List */}
                          {this.state.BrandData.filter((data) => {
                            if (this.state.SearchInput == "") {
                              return data;
                            }
                            if (
                              this.state.SearchInput !== "" &&
                              (data.fld_name
                                .toLowerCase()
                                .includes(
                                  this.state.SearchInput.toLowerCase()
                                ) ||
                                `${data.fld_status
                                  ? data.fld_status.toLowerCase()
                                  : ""
                                  }`.includes(
                                    this.state.SearchInput.toLowerCase()
                                  ))
                            ) {
                              return data;
                            }
                          }).map((data, i) => (
                            <Col xs="12" xl="4" lg="6" md="12">
                              <CardBody className="categoryCard card-samesize-barandmanagment">
                                <Row className="card-row card-row-bottom">
                                  <Col sm="12">
                                    <h6>
                                      Last Updated on {data.fld_updatedon}
                                    </h6>
                                  </Col>
                                  <Col sm="5" style={{ marginTop: "15px" }}>
                                    <div className="imagecard">
                                      <div className="form-group custom-file-upload fas">
                                        <div className="div1 img-wrap brandimages">
                                          <img
                                            src={data.fld_logo}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              borderRadius: "5%",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="7">
                                    <div className="row">
                                      <div
                                        className="col-12"
                                        style={{ marginTop: "10px" }}
                                      >
                                        <p>
                                          <b>Brand Name</b> -{" "}
                                          <span>{data.fld_name}</span>
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p>
                                          <b>Total Items</b> -{" "}
                                          <span>{data.total_items}</span>
                                        </p>
                                        <p>
                                          <b>Total Variants</b> -{" "}
                                          <span>{data.total_variant}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-12 card-description-brand-overflow card-content-overflow-subcategory">
                                    <p>
                                      <b>Description</b> -{" "}
                                      <span>{data.fld_description}</span>
                                    </p>
                                  </div>
                                </Row>
                                <hr className="mb-1" />
                                <Row>
                                  <Col sm="6" className="BrandStatus">
                                    <p>
                                      <b>Status</b> -{" "}
                                      <span
                                        style={{
                                          color:
                                            data.fld_status == "Active"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {data.fld_status}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col sm="6">
                                    <span>
                                      <img
                                        src={deleteicon}
                                        alt="block user"
                                        className="btnicons"
                                        style={{
                                          float: "right",
                                          marginLeft: "5px",
                                          marginTop: "15px",
                                        }}
                                        onClick={() => {
                                          confirmAlert({
                                            title: "Confirm to Delete",
                                            message:
                                              "Are you sure you want to delete brand.",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  PostApiCall.postRequest(
                                                    {
                                                      id: data.fld_brand_id,
                                                      status: "Inactive",
                                                    },
                                                    "DeleteBrand"
                                                  ).then((results) =>
                                                    results
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          results.status ==
                                                          200 ||
                                                          results.status == 201
                                                        ) {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Success(
                                                            "Brand successfully deleted."
                                                          );
                                                          this.getbrand();
                                                        } else {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Failure(
                                                            "Something went wrong, try again later."
                                                          );
                                                        }
                                                      })
                                                  );
                                                },
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });
                                        }}
                                      ></img>
                                    </span>
                                    <span>
                                      <a
                                        onClick={() => {
                                          this.setState({
                                            BrandName: data.fld_name,
                                            BrandId: data.fld_brand_id,
                                            Status:
                                              data.fld_status == "Active"
                                                ? "Yes"
                                                : "No",
                                            editfocos: true,
                                          });
                                          if (data.fld_logo != null) {
                                            this.setState({
                                              ImagePreviewUrl: data.fld_logo,
                                              BrandLogo: data.fld_logo,
                                              Description: data.fld_description,
                                            });
                                          } else {
                                            this.setState({
                                              ImagePreviewUrl:
                                                "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                            });
                                          }
                                          window.location.href = "#upload-card";
                                        }}
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                        style={{
                                          marginTop: "10px",
                                          color: "white",
                                        }}
                                      >
                                        <span className="">
                                          <img
                                            src={Editicon}
                                            alt="block user"
                                            className="btnicons"
                                            style={{ marginRight: "5px" }}
                                          ></img>{" "}
                                        </span>
                                        Edit Brand
                                      </a>
                                    </span>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
          <div className={this.state.editfocos == true ? "fadePage" : ""}></div>
        </div>
      </React.Fragment>
    );
  }
}
export default BrandMaster;
