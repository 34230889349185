import { MDBDataTable } from "mdbreact";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
class RefundManagement extends Component {
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		const data = {
			columns: [
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 150,
				},
				{
					label: "Date",
					field: "date",
					sort: "asc",
					width: 150,
				},
				{
					label: "Order Number",
					field: "salary",
					sort: "asc",
					width: 100,
				},
				{
					label: "Reason",
					field: "salary",
					sort: "asc",
					width: 100,
				},
			],
			rows: []
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink mb-4" style={{ height: '60px' }}>
										<Row>
											<div className="col-12">
												<div className="form-group">
													<h4 className="FilterCard mx-4" style={{ marginTop: '17px' }}>Refund Mangement</h4>
												</div>
											</div>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<MDBDataTable striped bordered small data={data} />
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RefundManagement;
