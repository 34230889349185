import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import GetApiCall from '../../GetApi';
import moment from "moment";
import ReactStars from "react-rating-stars-component";
const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				htmlFor="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);
class AddUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl:
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			ImageApiUrl: 'https://api.bechofy.in/AddImage',
			Status: "Active",
			ImageData: [],
			Name: "",
			Feedback: "",
			Rating: 0,
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
	}
	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
		}
	};
	onSaveData() {
		if (this.state.Name != "") {
			if (this.state.Rating !== 0) {
				if (this.state.Feedback != "") {
					if (this.state.Status != "") {
						this.onPost();
					} else {
						Notiflix.Notify.Failure(
							"Please specify whether to the review should be visible on the website."
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter customer feedback / review");
				}
			} else {
				Notiflix.Notify.Failure("Please select customer rating");
			}
		} else {
			Notiflix.Notify.Failure("Please enter customer name");
		}
	}
	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				name: this.state.Name,
				feedback: this.state.Feedback,
				rating: this.state.Rating,
				status: this.state.Status,
				created_on: moment().format("YYYY-MM-DD"),
				updated_on: moment().format("YYYY-MM-DD"),
				updated_by: details[0].fld_userid,
			},
			"AddTestimonial"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//
					this.onUploadImage(obj);
				} else {
					this.onUploadImage(obj);
				}
			})
		);
	};
	onUploadImage(obj) {
		Notiflix.Loading.Dots("Please wait...");
		if (
			JSON.stringify(this.state.ImageData) != "[]" &&
			this.state.ImageData != undefined
		) {
			const form = new FormData();
			form.append("file", this.state.ImageData);
			form.append("foldername", "Testimonial");
			form.append(
				"filename",
				this.state.Name.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).TestimonialId
			);
			fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
				headers: {
					"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
					"x-auth-token": JSON.parse(localStorage.getItem("access")),
					"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
				}
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						//
						//
						PostApiCall.postRequest(
							{
								testimonialId: JSON.parse(JSON.stringify(obj.data[0]))
									.TestimonialId,
								imageUrl:
									'https://store.bechofy.in/images/' +
									JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema +
									this.state.Name.trim().replace(/\s/g, "-") +
									"-" +
									JSON.parse(JSON.stringify(obj.data[0])).TestimonialId + '.webp',
							},
							"AddTestimonialImage"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //
									Notiflix.Loading.Remove();
									Notiflix.Notify.Success("Testimonial successfully added.");
									// window.location.href = "/review-list";
								}
							})
						);
					});
			});
		} else {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Success("Testimonial successfully Added.");
			// window.location.href = "/review-list";
		}
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Review Management
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<Card className="overflow-hidden">
										<div className="Bechofy-bg-soft-pink">
											<Row>
												<Col xs="3">
													<div className="Bechofy-text  p-3">
														<h5 className="Bechofy-text">
															Add New Review
														</h5>
													</div>
												</Col>
											</Row>
										</div>
										<CardBody className="pt-0">
											<Row>
												<Col xs="12">
													<div className="form my-4">
														<div className="row">
															<div className="col col-12">
																<div className="row">
																	<div className="col-md-4">
																		<label htmlFor="sw-arrows-first-name">
																			Upload Image (Size &lt; 100kb, 500*500)
																			<span className="mandatory">*</span>
																		</label>
																		<div className="div1">
																			<ImgUpload
																				onChange={this.photoUpload}
																				src={this.state.imagePreviewUrl}
																			/>
																		</div>
																	</div>
																	<div className="col-md-8">
																		<div className="row">
																			<div className="col-6">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.Name}
																						onChange={(text) => {
																							this.setState({
																								Name: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Rating
																						<span className="mandatory">*</span>
																					</label>
																					<ReactStars
																						count={5}
																						onChange={(rating) => {
																							this.setState({
																								Rating: rating,
																							});
																							//
																						}}
																						value={this.state.Rating}
																						size={22}
																						fullIcon={
																							<i className="fa fa-star"></i>
																						}
																						activeColor="#ffd700"
																						style={{ padding: "6px!important" }}
																					/>
																				</div>
																			</div>
																			<div className="col-12">
																				<div className="form-group">
																					<label htmlFor="CategoryName">
																						Feedback
																						<span className="mandatory">*</span>
																					</label>
																					<textarea
																						required
																						rows={5}
																						type="text"
																						className="form-control"
																						id="validationCustom05"
																						value={this.state.Feedback}
																						onChange={(text) => {
																							this.setState({
																								Feedback: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
									<Card>
										<CardBody className="py-1    my-1">
											<Row>
												<Col xs="12">
													<div className="row">
														<div className="col-6">
															<div className="form-group my-1 mr-sm-2">
																<label htmlFor="CategoryName">
																	Review Status
																	<span className="mandatory">*</span>
																</label>
																<br />
																<label className="radio-inline">
																	<input
																		type="radio"
																		name="optradio"
																		//  disabled={!this.state.IsVisible}
																		checked={
																			this.state.Status == "Active"
																				? true
																				: false
																		}
																		onChange={() => {
																			this.setState({
																				Status: "Active",
																			});
																		}}
																	/>{" "}
																	Yes
																</label>
																<label
																	className="radio-inline"
																	style={{ marginLeft: "10px" }}
																>
																	<input
																		type="radio"
																		name="optradio"
																		//  disabled={!this.state.IsVisible}
																		checked={
																			this.state.Status == "Inactive"
																				? true
																				: false
																		}
																		onChange={() => {
																			this.setState({
																				Status: "Inactive",
																			});
																		}}
																	/>
																	No
																</label>
															</div>
														</div>
														<div className="col-6">
															<button
																style={{ float: "right", marginTop: "15px" }}
																className="btn align-items-center Bechofy-btn "
																onClick={this.onSaveData.bind(this)}
															>
																Save Review
															</button>
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>{" "}
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddUser;
