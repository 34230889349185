import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import refundicon from "../../assets/images/refund.png";
class SocialSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RefundListData: [],
    };
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "Get_DashboardRefundList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            RefundListData: obj.data,
          });
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Order ID",
          field: "orderid",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Customer Name",
          field: "name",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Request Date & Time",
          field: "requestdate",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Total Items",
          field: "totalitems",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
      ],
      rows: this.state.RefundListData.filter((data) => {
        {
          return data;
        }
      }).map((data, i) => {
        return {
          orderid: data.fld_order_id,
          name: (
            <div className="text-start newcustomer-mdtablecol">
              {data.customer_name}
            </div>
          ),
          requestdate: (
            <div className="text-start newcustomer-mdtablecol">
              {moment(data.fld_order_date).format("DD/MM/YYYY")}
            </div>
          ),
          totalitems: data.items_count,
          ordervalue: parseFloat(data.fld_order_amount).toFixed(2),
        };
      }),
    };
    return (
      <React.Fragment>
        <Card className="mini-stats-wid" style={{ height: "290px" }}>
          <CardBody>
            <Media>
              <Media body>
                <Row className="mb-3">
                  <Col xs="6" className="d-flex align-items-center">
                    <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                      Latest Return & Refund Request
                    </h4>
                  </Col>
                  <Col xs="6">
                    <div className="Bechofy-text">
                      <Link
                        to="/refund-management"
                        style={{
                          float: "right",
                          background: "black",
                          color: "white",
                          marginBottom: "5px",
                        }}
                        className="btn align-items-center btn Bechofy-btn "
                      >
                        View All Refund / Return Orders{" "}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="latestreview-mdbtable">
                  {this.state.RefundListData.length != 0 ? (
                    <MDBDataTable
                      hover
                      striped
                      bordered
                      data={data}
                      seachTop={false}
                      entriesOptions={[10, 25, 50, 100]}
                    >
                      <MDBTableHead
                        className="centered"
                        columns={data.columns}
                      />
                      <MDBTableBody rows={data.rows} />
                    </MDBDataTable>
                  ) : (
                    <Row className="mt-4 pt-2">
                      <Col sm="12" className="text-center">
                        <img
                          src={refundicon}
                          alt="block user"
                          className="customerhistory-icons ms-3 mt-1"
                          style={{ marginRight: "5px" }}
                        />
                      </Col>
                      <Col sm="12" className="text-center my-3">
                        <h6>No Latest Return & Refund</h6>
                      </Col>
                    </Row>
                  )}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default SocialSource;
