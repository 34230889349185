import { MDBCol, MDBDataTableV5 } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class cutomerList extends Component {
	state = {
		data: [],
		searchInput: "",
		AttributeData: [],
	};
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("GetAttruibuteMappingList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//
				this.setState({
					AttributeData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
	}
	//   FLD_Active: "Active    "
	// FLD_AttributeID: 6
	// FLD_MappingID: 2
	// FLD_SubCategoryID: 9
	// fld_attributename: "Material"
	// fld_subcategoryname: "Bookmarks "
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 120,
				},
				{
					label: "Attribute Name",
					field: "attributename",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Category",
					field: "category",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Sub Category",
					field: "subcat",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.AttributeData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_attributename
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						//   `${data.fld_firstName?data.fld_firstName:''} ${data.fld_lastName?data.fld_lastName:''} Course Name:${data.fld_courseName} `.replace(/\s/g, '').toLowerCase().includes(this.state.searchInput.replace(/\s/g, '').toLowerCase())||
						data.fld_categoryname
							.toLowerCase()
							.includes(this.state.searchInput.toLowerCase()) ||
						data.FLD_Active.toLowerCase().includes(
							this.state.searchInput.toLowerCase()
						) ||
						//  `${data.amtInWords?data.amtInWords.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())||
						//  `${data.fld_feeType?data.fld_feeType.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())||
						//   `${data.fld_editamount?data.fld_editamount.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())||
						//   `${data.fld_editremark?data.fld_editremark.toLowerCase():""}`.includes(this.state.searchInput.toLowerCase())||
						//   `${data.payto?data.payto.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())||
						`${data.fld_subcategoryname
							? data.fld_subcategoryname.toLowerCase()
							: ""
							}`.includes(this.state.searchInput.toLowerCase()))
				) {
					return data;
				}
			}).map((data, i) => {
				//
				return {
					serial: i + 1,
					attributename: data.fld_attributename,
					category: data.fld_categoryname,
					subcat: data.fld_subcategoryname,
					status: data.FLD_Active,
					action: (
						<span className="text-center">
							<span className="btn" style={{ marginTop: "-9px" }}>
								<i
									className="fa fa-trash py-auto "
									aria-hidden="true"
									style={{ fontSize: "15px" }}
									onClick={() => {
										confirmAlert({
											title: "Confirm to Delete",
											message:
												"Are you sure you want to delete attribute mapping data.",
											buttons: [
												{
													label: "Yes",
													onClick: () => {
														Notiflix.Loading.Dots("");
														PostApiCall.postRequest(
															{
																id: data.FLD_MappingID,
																status: "Disabled",
															},
															"DeleteAttributeMapping"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (
																	results.status == 200 ||
																	results.status == 201
																) {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Success(
																		"Raw Material successfully deleted."
																	);
																	window.location.reload();
																} else {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Failure(
																		"Something went wrong, try again later."
																	);
																}
															})
														);
													},
												},
												{
													label: "No",
													// onClick: () => alert('Click No')
												},
											],
										});
									}}
								></i>
							</span>
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									localStorage.setItem("MappingDetails", JSON.stringify(data));
									window.location.href = "/edit-attribute-mapping";
								}}
							/>
						</span>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Attribute Mapping List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<Link
																	to="/add-attribute"
																	style={{
																		float: "right", marginTop: "-5px", background: 'white',
																		color: 'grey'
																	}}
																	className="btn align-items-center btn Bechofy-btn "
																>
																	Map New Attribute{" "}
																	<i
																		className="fa fa-plus"
																		aria-hidden="true"
																	></i>
																</Link>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row></Row>
												</CardBody>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<MDBCol md="3" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) =>
																		this.setState({
																			searchInput: e.target.value,
																		})
																	}
																	value={this.state.searchInput}
																/>
															</MDBCol>
															<MDBDataTableV5
																striped
																bordered
																small
																data={data}
																searchBottom={false}
																seachTop={true}
																className="tablecol"
															/>
														</Col>
													</Row>
												</CardBody>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default cutomerList;
