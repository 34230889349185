import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notiflix from "notiflix";
import { Modal } from "react-bootstrap";
import PostApiCall from "../../Api";
var width = '100%'
var height = 665
var widthm = 358
var heightm = 253
var widthapp = 250
var heightapp = 150
const ImgUploadCover = ({
	onChange,
	src
}) =>
	<label htmlFor="photo-upload-1" className="custom-file-upload fas" style={{ width: width, height: height + 'px', borderRadius: '10px' }}>
		<div className="img-wrap1 img-upload1" style={{ width: width - 10, height: height - 15 + 'px', borderRadius: '10px' }}>
			<img htmlFor="photo-upload-1" src={src} style={{ width: width, height: height - 15 + 'px', borderRadius: '10px' }} />
		</div>
		{/* <input
			accept="image/*"
			id="photo-upload-1" type="file" onChange={onChange} /> */}
	</label>
const ImgUploadCover1 = ({
	onChange1,
	src1
}) =>
	<label htmlFor="photo-upload-2" className="custom-file-upload fas" style={{ width: widthm, height: heightm, borderRadius: '10px' }}>
		<div className="img-wrap1 img-upload1" style={{ width: widthm - 15, height: heightm - 15, borderRadius: '10px' }}>
			<img htmlFor="photo-upload-2" src={src1} style={{ width: widthm - 15, height: heightm - 15, borderRadius: '10px' }} />
		</div>
		{/* <input
			accept="image/*"
			id="photo-upload-2" type="file" onChange={onChange1} /> */}
	</label>

class AddNewBanner extends Component {

	constructor(props) {
		super(props);
		this.state = {
			PageName: [
				{ value: '', label: 'Select Page' },
				{ value: 'Home', label: 'Home' },
			],
			TypeData: [
				{ value: '', label: 'Select page' },
				{ value: 'Main', label: 'Main' },
				{ value: 'Parallax', label: 'Parallax' },
			],
			location: '',
			page: '',
			Url: '',
			ShowOnWebsite: 'Yes',
			IsType: false,
			WebSizeText: '',
			MobileSizeText: '',
			MobileAppSizeText: '',
			ImageDataCover: null,
			ImageDataCover1: null,
			ImageDataCover2: [],
			file: '',
			file1: '',
			file2: '',
			imagePreviewUrlCover: '../../assets/images/Cover-bechofylogo.png',
			imagePreviewUrlCover1: '../../assets/images/Cover-bechofylogo.png',
			imagePreviewUrlCover2: '../../assets/images/Cover-bechofylogo.png',
			ImageApiUrl: 'https://api.bechofy.in/' + "AddImage",
			image: null,
			imageMobile: null,
			imageCropperModalMobile: false,
			imageCropperModal: false,
			cropdata: '#',
			cropdataMobile: '#',
			showModal: false,
			bannerId: null,
			bannerimages: [],
			imageUrl: 'https://store.bechofy.in/images',
			mobileimageid: null,
			desktopimageid: null,
			croppedImage: null,
			croppedImageMobile: null,
			desktopImageUrl: "",
			mobileImageUrl: "",
			heading: "",
			title: "",
			pageHeading: "Add New Banner", // for thedynamic heading of the form 
			buttonText: "Save Banner" // for the button text
		};
	}


	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		let bannerdetails = localStorage.getItem("BannerDetails")
		// console.log(bannerdetails);
		let banners = JSON.parse(bannerdetails)
		if (bannerdetails === null || bannerdetails.trim() === "") {
			this.setState({
				pageHeading: "Add New Banner",
				buttonText: "Save Banner"
			})
		} else {
			this.setState({
				pageHeading: "Update Banner",
				buttonText: "Update Banner"
			})
		}


		this.setState({
			imagePreviewUrlCover: banners?.image.split("$")[0].split("#")[0],
			imagePreviewUrlCover1: banners?.image.split("$")[1].split("#")[0],
			bannerId: banners?.id,
			desktopimageid: banners?.image.split("$")[0].split("#")[3],
			mobileimageid: banners?.image.split("$")[1].split("#")[3],
			Url: banners?.slug,
			title: banners?.title,
			heading: banners?.heading,
			page: banners?.pagename,
			location: banners?.location

		})
		if (banners?.location == 'Main') {
			this.setState({
				WebSizeText: `(Banner must be 1600px X ${height} in dimension, less than 300 kb)`,
				MobileSizeText: `(Banner must be ${widthm} X ${heightm} in dimension, less than 300 kb)`,
				MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
			})
			width = '100%'
			height = 665
			widthm = 358
			heightm = 253
			widthapp = 250
			heightapp = 150
		} else if (banners?.location == 'Parallax') {
			this.setState({
				WebSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
				MobileSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
				MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
			})
			width = '100%'
			height = 665
			widthm = 400
			heightm = 800
			widthapp = 250
			heightapp = 150
		}
		this.setState({
			location: banners?.location
		})
	}
	cropperRef = React.createRef();
	// desktop
	handleImageChange(e) {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			let filename = `${Math.floor(Math.random() * 10000)}-${file.name}`
			this.setState({
				desktopImageUrl: filename
			})
			let foldername = "banners"
			const url = this.state.imageUrl + "/" +
				JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema +
				"/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp'
			const reader = new FileReader();
			reader.onload = () => {
				this.setState({
					ImageDataCover: file,
					image: reader.result,
					bannerimages: [...this.state.bannerimages, { imageid: this.state.desktopimageid, bannerid: this.state.bannerId, url: url, status: "Active", height: height, width: width, bannerfor: "Desktop" }]
				});
			};
			reader.readAsDataURL(file);
		}
	}
	async onUploadImage(obj) {
		Notiflix.Loading.Dots("Uploading Images...");
		const filename = this.state.desktopImageUrl;
		let response;

		const form = new FormData();
		form.append("file", this.state.cropdata);
		form.append("foldername", "banners");
		form.append("filename", filename.trim().replace(/\s/g, "-"));
		response = fetch(this.state.ImageApiUrl, {
			method: "POST",
			body: form,
			headers: {
				"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
				"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
			}
		})
			.then((results) => results.json()
				.then((data) => {
					if (results.status == 200 || results.status == 201) {
						Notiflix.Loading.Remove();
					} else {
						Notiflix.Loading.Remove();
					}
				}))

	}
	// Mobile
	handleImageChangeMobile(e) {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			let filename = `${Math.floor(Math.random() * 10000)}-${file.name}`
			this.setState({
				mobileImageUrl: filename
			})
			let foldername = "banners"
			const url = this.state.imageUrl + "/" +
				JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema +
				"/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp'
			const reader = new FileReader();
			reader.onload = () => {
				this.setState({
					ImageDataCover1: file,
					imageMobile: reader.result,
					bannerimages: [...this.state.bannerimages, { imageid: this.state.mobileimageid, bannerid: this.state.bannerId, url: url, status: "", height: heightm, width: widthm, bannerfor: "Mobile" }]
				});
			};
			reader.readAsDataURL(file);
		}
	}
	async onUploadImageMobile() {
		Notiflix.Loading.Dots("Uploading Images...");
		const filename = this.state.mobileImageUrl;
		let response;

		const form = new FormData();
		form.append("file", this.state.cropdataMobile);
		form.append("foldername", "banners");
		form.append("filename", filename.trim().replace(/\s/g, "-"));
		response = fetch(this.state.ImageApiUrl, {
			method: "POST",
			body: form,
			headers: {
				"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
				"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
			}
		})
			.then((results) => results.json()
				.then((data) => {
					if (results.status == 200 || results.status == 201) {
						Notiflix.Loading.Remove();
					} else {
						Notiflix.Loading.Remove();
					}
				}))

	}

	onSaveData() {
		// if (this.state.locationId != "") {
		// 	if (this.state.CategoryName != "") {
		// 		if (
		// 			this.state.imagePreviewUrl !=
		// 			"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
		// 		) {
		this.onSaveData();
		// 		} else {
		// 			Notiflix.Notify.Failure("Please upload category image");
		// 		}
		// 	} else {
		// 		Notiflix.Notify.Failure("Please enter the category name");
		// 	}
		// } else {
		// 	Notiflix.Notify.Failure("Please enter the location name");
		// }
	}
	onSaveData() {
		if (this.state.ImageDataCover !== null && this.state.ImageDataCover1 !== null) {
			this.onUploadImage();
			this.onUploadImageMobile();
			this.onPost();
		} else if (this.state.ImageDataCover !== null) {
			this.onUploadImage();
			this.onPost();
		} else if (this.state.ImageDataCover1 !== null) {
			this.onUploadImageMobile();
			this.onPost();
		} else {
			this.onPost();
		}
	}
	onPost = () => {
		console.log(this.state.bannerimages)
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				bannerid: parseInt(this.state.bannerId),
				bannerlocation: this.state.location,
				bannerstatus: "Active",
				pagename: this.state.page,
				title: this.state.title,
				slug: this.state.Url,
				images: this.state.bannerimages,
				heading: this.state.heading
			},
			"UpdateBanners"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Notify.Success("Banner Updated");
					// window.location.href = '/banner-list'
					Notiflix.Loading.Remove();
					localStorage.removeItem("BannerDetails")
				} else {
					Notiflix.Notify.Failure("Image Upload Failed")
				}
			})
		);
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	// Desktop Cropper
	openCropperModal() {
		this.setState({
			imageCropperModal: true,
		});
	}
	closeCropperModal() {
		this.setState({
			imageCropperModal: false,
		});
	}
	handleCrop() {
		if (this.cropperRef.current) {
			const cropper = this.cropperRef.current.cropper;
			const croppedImage = cropper.getCroppedCanvas().toBlob((blob) => {
				this.setState({
					cropdata: blob,
				});
			});
			this.setState({
				croppedImage: cropper.getCroppedCanvas().toDataURL()
			})
		}
	}

	// Mobile Cropper
	openCropperModalMobile() {
		this.setState({
			imageCropperModalMobile: true,
		});
	}
	closeCropperModalMobile() {
		this.setState({
			imageCropperModalMobile: false,
		});
	}
	handleCropMobile() {
		if (this.cropperRef.current) {
			const cropper = this.cropperRef.current.cropper;
			const croppedImage = cropper.getCroppedCanvas().toBlob((blob) => {
				this.setState({
					cropdataMobile: blob,
				});
			});
			this.setState({
				croppedImageMobile: cropper.getCroppedCanvas().toDataURL()
			})
		}
	}
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														{this.state.pageHeading}
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody>
										<div className="row">
											<div className="col-6">
												<label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
													Page Name
												</label>
												<select type="text" className="form-control"
													value={this.state.page}
													onChange={(text) => {
														this.setState({
															page: text.target.value
														})
													}}
												>
													{this.state.PageName.map(title => (
														<option key={title.value} value={title.value}>
															{title.label}
														</option>
													))}
												</select>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label htmlFor="CategoryName">
														Location
														<span className="mandatory">*</span>
													</label>
													<select type="text" className="form-control"
														value={this.state.location}
														onChange={(text) => {
															if (text.target.value == 'Main') {
																this.setState({
																	WebSizeText: `(Banner must be 1600px X ${height} in dimension, less than 300 kb)`,
																	MobileSizeText: `(Banner must be ${widthm} X ${heightm} in dimension, less than 300 kb)`,
																	MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
																})
																width = '100%'
																height = 665
																widthm = 358
																heightm = 253
																widthapp = 250
																heightapp = 150
															} else if (text.target.value == 'Parallax') {
																this.setState({
																	WebSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
																	MobileSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
																	MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
																})
																width = '100%'
																height = 665
																widthm = 400
																heightm = 800
																widthapp = 250
																heightapp = 150
															}
															this.setState({
																location: text.target.value
															})
														}}
													>
														{this.state.TypeData.map(title => (
															<option key={title.value} value={title.value}>
																{title.label}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label htmlFor="sw-arrows-first-name">
														URL (URL of the page to which banner should navigate when it is clicked.)
														<span className="mandatory">*</span>
													</label>
													<input type="text" className="form-control" id="validationCustom05"
														value={this.state.Url}
														onChange={(text) => {
															this.setState({
																Url: text.target.value
															})
														}}
													/>
												</div>
											</div>
											<div className="col-3">
												<div className="form-group">
													<label htmlFor="sw-arrows-first-name">
														Heading
													</label>
													<input type="text" className="form-control" id="validationCustom05"
														value={this.state.heading}
														onChange={(text) => {
															this.setState({
																heading: text.target.value
															})
														}}
													/>
												</div>
											</div>
											<div className="col-3">
												<div className="form-group">
													<label htmlFor="sw-arrows-first-name">
														Title
														<span className="mandatory">*</span>
													</label>
													<input type="text" className="form-control" id="validationCustom05"
														value={this.state.title}
														onChange={(text) => {
															this.setState({
																title: text.target.value
															})
														}}
													/>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<Card>
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Upload Website Banner
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<div
														onClick={() => this.openCropperModal()}
														className="align-items-center Bechofy-btn category-Add-btn categorybtnposition"
													>
														Upload Image{" "}
													</div>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-md-12">
														<div className="form-group mb-2">
															<label htmlFor="validationCustom05">Website Banner<span className="mandatory">* </span>
																<span> {this.state.WebSizeText}</span>
															</label>
															<div>
																<ImgUploadCover src={this.state.imagePreviewUrlCover} />
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Upload Mobile Banner
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<div
														onClick={() => this.openCropperModalMobile()}
														className="align-items-center Bechofy-btn category-Add-btn categorybtnposition"
													>
														Upload Image{" "}
													</div>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-md-12">
														<div className="form-group mb-2">
															<div>
																<label htmlFor="validationCustom05"> Mobile Banner<span className="mandatory">* </span>
																	<span> {this.state.MobileSizeText}</span>
																</label>
																<div>
																	<ImgUploadCover1 src1={this.state.imagePreviewUrlCover1} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={this.onSaveData.bind(this)}
														>
															{this.state.buttonText}
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				{/* Desktop cropper */}
				<Modal
					show={this.state.imageCropperModal} // Use the state variable to control visibility
					onHide={() => this.closeCropperModal()} // Close the modal when needed
					size="lg"
					centered
					className="cropper-modal"
				>
					<Modal.Header closeButton>
						Cropper
					</Modal.Header>
					<Modal.Body>
						<div className="crop-input">
							<input
								type="file"
								accept="image/*"
								style={{ display: "block" }}
								onChange={(e) => {
									this.handleImageChange(e);
								}}
							/>
							{this.state.image && (
								<div className="cropper-main">
									<Cropper
										style={{ height: 400, width: "100%" }}
										ref={this.cropperRef}
										zoomTo={1}
										initialAspectRatio={4}
										preview=".img-preview"
										src={this.state.image}
										viewMode={1}
										aspectRatio={width / height}
										background={false}
										checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
										guides={true}
									/>
									<button
										className="btn align-items-center Bechofy-btn "
										onClick={() => this.handleCrop()} // Use the function to handle cropping
									>
										Crop
									</button>
								</div>
							)}
							{this.state.croppedImage && (
								<div>
									<h4 className="tab-section-title">Cropped Image Preview</h4>
									<img className="cropper-img-preview" src={this.state.croppedImage} alt="Cropped Image" />
									<div className="mt-3 text-center">
										<button className="btn align-items-center Bechofy-btn " onClick={() => {
											this.setState({ imagePreviewUrlCover: this.state.croppedImage })
											this.closeCropperModal()
										}}>Save</button>
									</div>
								</div>
							)}
						</div>
					</Modal.Body>
				</Modal>
				{/*  Mobile banner cropper */}
				<Modal
					show={this.state.imageCropperModalMobile} // Use the state variable to control visibility
					onHide={() => this.closeCropperModalMobile()} // Close the modal when needed
					size="lg"
					centered
					className="cropper-modal"
				>
					<Modal.Header closeButton>
						Cropper
					</Modal.Header>
					<Modal.Body>
						<div className="crop-input">
							<input
								type="file"
								accept="image/*"
								style={{ display: "block" }}
								onChange={(e) => {
									this.handleImageChangeMobile(e);
								}}
							/>
							{this.state.imageMobile && (
								<div className="cropper-main">
									<Cropper
										style={{ height: 400, width: "100%" }}
										ref={this.cropperRef}
										zoomTo={1}
										initialAspectRatio={4}
										preview=".img-preview"
										src={this.state.imageMobile}
										viewMode={1}
										aspectRatio={widthm / heightm}
										background={false}
										checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
										guides={true}
									/>
									<button
										variant="danger"
										onClick={() => this.handleCropMobile()} // Use the function to handle cropping
										className="mt-3"
									>
										Crop
									</button>
								</div>
							)}
							{this.state.croppedImageMobile && (
								<div>
									<h4 className="tab-section-title">Cropped Image Preview</h4>
									<img className="cropper-img-preview" src={this.state.croppedImageMobile} alt="Cropped Image" />
									<div className="mt-3 text-center">
										<button variant="primary" onClick={() => {
											this.setState({ imagePreviewUrlCover1: this.state.croppedImageMobile })
											this.closeCropperModalMobile()
										}}>Save</button>
									</div>
								</div>
							)}
						</div>
					</Modal.Body>
				</Modal>

			</React.Fragment>
		);
	}
}
export default AddNewBanner;
