import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../../Api";
import Searchicon from "../../../assets/images/dashboardimages/search.png";
import Stockicon from "../../../assets/images/dashboardimages/stock.png";
import Uploadicon from "../../../assets/images/dashboardimages/upload-file.png";
import stocklisticon from "../../../assets/images/stock.png";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

class stocklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      StockData: [],
      StateData: [],
      StockState: "",
      WarehouseData: [],
      Warehouse: "",
    };``
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    // Notiflix.Loading.Dots("");
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            this.setState({
              StateData: objstate.data,
            });
          }
          // Notiflix.Loading.Dots("Please wait...");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_status='Active' AND fld_state=${objstate.data[0].value}`,
              recordCount: "fld_name as label,fld_warehouseid as value",
            },
            "GetWarehouseByState"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {
                  this.setState({
                    WarehouseData: obj.data,
                  });
                }
              }
            })
          );
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  onPost = () => {
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        warehouse: this.state.Warehouse,
      },
      "downloadStockcsv"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            StockData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "SKU",
          field: "sku",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Product Name",
          field: "name",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Variant Name",
          field: "variant",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Qty Available",
          field: "qty",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Updated Qty",
          field: "upqty",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Batch No.",
          field: "batchnum",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Cost Price",
          field: "cp",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Expiry Date",
          field: "exp",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Manufacture Date",
          field: "mnfcture",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Purchase Order No.",
          field: "pon",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Total Amount",
          field: "tamt",
          sort: "disabled",
          width: 400,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.StockData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.VariantName
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_subcategoryname
              ? data.fld_subcategoryname.toLowerCase()
              : ""
              }`.includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          sku: data.SKU,
          name: data.ProductName,
          variant: data.VariantName,
          qty: data.QuantityAvailable,
          upqty: data.UpdatedQuantity,
          batchnum: data.batchno,
          cp: data.costprice == null ? 0 : "₹" + data.costprice,
          exp: data.expirydate,
          mnfcture: data.manufacturedate,
          pon: data.purchseorderno,
          tamt: data.totalamt == null ? 0 : "₹" + data.totalamt,
          action: (
            <td
              className="text-center actionuser"
              style={{ border: "0px!important", paddingTop: "0px" }}
            >
              <button
                onClick={() => {
                  localStorage.setItem(
                    "variantid",
                    JSON.stringify(data.VariantId_Dontupdate)
                  );
                  localStorage.setItem(
                    "warehouseId",
                    JSON.stringify(this.state.Warehouse)
                  );
                  window.location.href = "/stocks";
                }}
                style={{
                  color: "white",
                }}
                className="btn align-items-center btn Bechofy-btn "
              >
                Add Stock
              </button>
              <button
                style={{
                  marginLeft: "5px",
                  marginTop: "3px",
                  color: "white",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "variantid",
                    JSON.stringify(data.VariantId_Dontupdate)
                  );
                  localStorage.setItem(
                    "warehouseId",
                    JSON.stringify(this.state.Warehouse)
                  );
                  window.location.href = "/reduce-stock";
                }}
                className="btn align-items-center btn Bechofy-btn "
              >
                Reduce Stock
              </button>
            </td>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              ).replace("-", " ")}
              urlPath={"/customer-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-2">
                        <div className="form-group">
                          <h4
                            className="FilterCard"
                            style={{ marginTop: "10px" }}
                          >
                            Filter Data By
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card
                    style={{
                      paddingLeft: "10px",
                      paddingBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="row my-1 ">
                      <div className="col-4">
                        <select
                          className="form-control gridinput"
                          value={this.state.StockState}
                          onChange={(text) => {
                            this.setState(
                              {
                                StockState: text.target.value,
                              },
                              () => {
                                // Notiflix.Loading.Dots("Please wait...");
                                PostApiCall.postRequest(
                                  {
                                    whereClause: `where fld_status='Active' AND fld_state=${this.state.StockState}`,
                                    recordCount:
                                      "fld_name as label,fld_warehouseid as value",
                                  },
                                  "GetWarehouseByState"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    if (
                                      results.status == 200 ||
                                      results.status == 201
                                    ) {
                                      if (obj.data.length != 0) {
                                        this.setState({
                                          WarehouseData: obj.data,
                                        });
                                      }
                                      Notiflix.Loading.Remove();
                                    }
                                  })
                                );
                              }
                            );
                          }}
                        >
                          <option value="">Select State</option>
                          {this.state.StateData.map((data) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-4">
                        <select
                          className="form-control gridinput"
                          value={this.state.Warehouse}
                          onChange={(text) => {
                            this.setState({
                              Warehouse: text.target.value,
                            });
                          }}
                        >
                          <option value="">Select Warehouse</option>
                          {this.state.WarehouseData.map((data) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-3">
                        <button
                          onClick={() => {
                            if (this.state.StockState != "") {
                              if (this.state.Warehouse != "") {
                                this.onPost();
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please select warehouse"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure("Please select state");
                            }
                          }}
                          style={{
                            marginTop: "15px",
                            color: "white",
                            marginLeft: "0px",
                            marginRight: "4px",
                          }}
                          className="btn align-items-center btn Bechofy-btn "
                        >
                          Filter & Display
                        </button>
                      </div>
                    </div>
                  </Card>
                  <Card className="pagebackground">
                    <div
                      className="Bechofy-bg-soft-pink"
                      style={{ height: "60px" }}
                    >
                      <div className="row my-1">
                        <div className="col-2">
                          <div className="form-group">
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              Stock List
                            </h4>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="position-relative">
                            <a
                              href="/upload-stock"
                              style={{
                                float: "right",
                                marginTop: "10px",
                                background: "white",
                                color: "grey",
                                marginRight: "20px",
                              }}
                              className="btn align-items-center btn Bechofy-btn ">
                              <span className="">
                                <img
                                  src={Uploadicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginRight: "5px" }}
                                ></img>{" "}
                              </span>
                              Upload Stock
                            </a>
                            <button
                              onClick={() => {
                                window.location.href = "/add-purchase-order";
                              }}
                              style={{
                                float: "right",
                                marginTop: "10px",
                                background: "white",
                                color: "grey",
                                marginRight: "20px",
                              }}
                              className="btn align-items-center btn Bechofy-btn "
                            >
                              <span className="">
                                <img
                                  src={Stockicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginLeft: "5px" }}
                                ></img>{" "}
                              </span>
                              Add Purchase Order{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.StockData.length != 0 ? (
                      <>
                        <Card style={{ padding: "10px 20px" }}>
                          <div className="row my-1 ">
                            <div className="col-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <span className="">
                                      <img
                                        src={Searchicon}
                                        alt="block user"
                                        className="btnicons"
                                      ></img>
                                    </span>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type the search phrase you wish to search within the grid"
                                  aria-label="Search"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) => this.seachBarHandler(e)}
                                  value={this.state.searchInput}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                        <Card>
                          <CardBody className="pt-0">
                            <Row>
                              <Col md="12">
                                <MDBDataTable
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[
                                    20, 40, 60, 80, 100, 120, 140,
                                  ]}
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTable>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      <Row className="mt-4 pt-2">
                        <Col sm="12" className="text-center">
                          <img
                            src={stocklisticon}
                            alt="block user"
                            className="customerhistory-icons ms-3 mt-1"
                            style={{ marginRight: "5px" }}
                          />
                        </Col>
                        <Col sm="12" className="text-center my-3">
                          <h6>Stock list no found</h6>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default stocklist;
