import Notiflix from "notiflix";
import React from 'react';
import {
    CardBody
} from "reactstrap";
import PostApiCall from "../../Api";



const CommentsList = ({ comment, allComments }) => {
    var childComments;
    var commentvalue;
    var childComments = JSON.parse(allComments)?.childcomments?.filter(c => c.fld_parent_comment === comment.fld_id);

    return (
        <><CardBody>
            <div className="row">
                <div className="col-12">
                    <div className="best-products">
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong>{comment.fld_name} - {comment.fld_createdon}</strong></p>
                        </div>
                        <p>{comment.fld_comment}</p>
                        <div className="row">
                            <div className="col-12">
                                <div className={'form-group'}>
                                    <label htmlFor="BlogTitle">
                                        Reply to this Comment
                                    </label>
                                    <input
                                        type="text"
                                        id="BlogTitle"
                                        className="form-control"
                                        placeholder="Write your comment here..."
                                        onChange={(e) => {
                                            commentvalue = e.target.value;
                                        }}
                                    />
                                    <button
                                        style={{
                                            float: "right",
                                            marginTop: "10px",
                                        }}
                                        onClick={(e) => {
                                            PostApiCall.postRequest({
                                                id: null,
                                                parentcommentid: comment.fld_id,
                                                userid: (JSON.parse(localStorage.getItem("CustomerData")))[0].fld_customerid,
                                                comment: commentvalue,
                                                blogid: comment.fld_blog_id
                                            }, "AddUpdateBlogComments").then(
                                                (results) => {
                                                    results.json().then((obj) => {
                                                        if (results.status == 200 || results.status == 201) {
                                                            Notiflix.Notify.success("Comment successfully added")
                                                            window.location.reload();
                                                        }
                                                    });
                                                }
                                            );
                                        }}
                                        className="btn align-items-center Bechofy-btn"
                                    >
                                        Reply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {childComments.map(c => (
                <CommentsList
                    key={c.fld_id}
                    comment={c}
                    allComments={allComments}
                />
            ))}
        </CardBody>
        </>
    )
}
export default CommentsList