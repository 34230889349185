import { MDBCol, MDBDataTableV5 } from "mdbreact";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
// import { Link } from "react-router-dom";
import moment from "moment";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import PostApiCall from "../../Api";
class verticleMater extends Component {
    state = {
        data: [],
        searchInput: "",
        VerticleData: [],
        ViewClick: "true",
        open: false,
        Status: "Active",
        Name: "",
        openedit: false,
        Id: "",
    };
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        Notiflix.Loading.Dots("");
        GetApiCall.getRequest("GetVerticleMaster").then((resultdes) =>
            resultdes.json().then((obj) => {
                //
                this.setState({
                    VerticleData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
    }
    SaveAttribute() {
        if (this.state.Name !== "") {
            this.onPost();
        } else {
            Notiflix.Notify.Failure("Please enter verticle name");
        }
    }
    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        PostApiCall.postRequest(
            {
                verticlename: this.state.Name,
                status: this.state.Status,
                createdon: moment().format("YYYY-MM-DD"),
                updatedon: moment().format("YYYY-MM-DD"),
                updatedby: details[0].fld_userid,
                createdby: details[0].fld_userid,
            },
            "AddVerticleMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status === 200 || resultcategory.status === 201) {
                    //   //
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("New Verticle successfully added.");
                    window.location.reload();
                } else {
                    Notiflix.Notify.Failure(obj);
                }
            })
        );
    };
    UpdateAttribute() {
        if (this.state.Name !== "") {
            this.onPost2();
        } else {
            Notiflix.Notify.Failure("Please enter verticlename");
        }
    }
    onPost2 = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        PostApiCall.postRequest(
            {
                id: this.state.Id,
                verticlename: this.state.Name,
                status: this.state.Status,
                updatedon: moment().format("YYYY-MM-DD"),
                updatedby: details[0].fld_userid,
            },
            "UpdateVerticleMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status === 200 || resultcategory.status === 201) {
                    //   //
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("Vertical successfully updated.");
                    window.location.reload();
                } else {
                    Notiflix.Notify.Failure(obj);
                }
            })
        );
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 120,
                },
                {
                    label: "Banner Type",
                    field: "type",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Banner Size",
                    field: "size",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.VerticleData.map((data, item) => {
                return {
                    serial: item + 1,
                    type: data.fld_verticlename,
                    size: <td></td>,
                    status: data.fld_status,
                    action: (
                        <span className="text-center">
                            <span className="btn" style={{ marginTop: "-9px" }}>
                                <i
                                    className="fa fa-trash py-auto "
                                    aria-hidden="true"
                                    style={{ fontSize: "15px" }}
                                    onClick={() => {
                                        confirmAlert({
                                            title: "Confirm to Delete",
                                            message: "Are you sure you want to delete vertical data.",
                                            buttons: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        Notiflix.Loading.Dots("");
                                                        PostApiCall.postRequest(
                                                            {
                                                                id: data.fld_verticleid,
                                                                status: "Disabled",
                                                            },
                                                            "DeleteVerticleMaster"
                                                        ).then((results) =>
                                                            // const objs = JSON.parse(result._bodyText)
                                                            results.json().then((obj) => {
                                                                if (
                                                                    results.status === 200 ||
                                                                    results.status === 201
                                                                ) {
                                                                    Notiflix.Loading.Remove();
                                                                    Notiflix.Notify.Success(
                                                                        "Vertical successfully deleted."
                                                                    );
                                                                    window.location.reload();
                                                                } else {
                                                                    Notiflix.Loading.Remove();
                                                                    Notiflix.Notify.Failure(
                                                                        "Something went wrong, try again later."
                                                                    );
                                                                }
                                                            })
                                                        );
                                                    },
                                                },
                                                {
                                                    label: "No",
                                                    // onClick: () => alert('Click No')
                                                },
                                            ],
                                        });
                                    }}
                                ></i>
                            </span>
                            <i
                                className="fas fa-edit btn"
                                style={{ fontSize: "15px", marginTop: "-11px" }}
                                onClick={() => {
                                    this.setState({
                                        Status: data.fld_status,
                                        openedit: true,
                                        Id: data.fld_verticleid,
                                        Name: data.fld_verticlename,
                                    });
                                    //    this.props.setfootweartype(data.fld_type)
                                }}
                            />
                        </span>
                    ),
                };
            }),
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Modal
                            open={this.state.open}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                            center
                        >
                            <div className="modal-content modelcontent2">
                                <div
                                    className="modal-header Bechofy-bg-soft-pink"
                                    style={{ background: "#777f80" }}
                                >
                                    <h4 className="modal-title text-white">Add New Banner</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="validationCustom01">
                                                        Banner Type<span className="mandatory">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.Name}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Name: text.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="validationCustom01">
                                                        Banner Size<span className="mandatory">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.Name}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Name: text.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="validationCustom01">
                                                Status<span className="mandatory">*</span>
                                            </label>
                                            <br />
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="optradio"
                                                    checked={this.state.Status === "Active" ? true : false}
                                                    onChange={() => {
                                                        this.setState({
                                                            Status: "Active",
                                                        });
                                                    }}
                                                />{" "}
                                                Active
                                            </label>
                                            <label
                                                className="radio-inline"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                <input
                                                    type="radio"
                                                    name="optradio"
                                                    checked={
                                                        this.state.Status === "Inactive" ? true : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({
                                                            Status: "Inactive",
                                                        });
                                                    }}
                                                />{" "}
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn align-items-center Bechofy-btn"
                                        type="submit"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            this.setState({
                                                open: false,
                                                Status: "Active",
                                            });
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        style={{ float: "right" }}
                                        className="btn align-items-center Bechofy-btn "
                                        onClick={this.SaveAttribute.bind(this)}
                                    >
                                        Save New Banner Size
                                    </button>
                                    <span></span>
                                </div>
                            </div>
                        </Modal>
                        {/* ========Update */}
                        <Modal
                            open={this.state.openedit}
                            onClose={() => {
                                this.setState({ openedit: false });
                            }}
                            center
                        >
                            <div className="modal-content modelcontent2">
                                <div
                                    className="modal-header Bechofy-bg-soft-pink"
                                    style={{ background: "#777f80" }}
                                >
                                    <h4 className="modal-title text-white">Update Banner Size</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="validationCustom01">
                                                        Banner Type<span className="mandatory">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.Name}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Name: text.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="validationCustom01">
                                                        Size<span className="mandatory">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.Name}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Name: text.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="validationCustom01">
                                                Status<span className="mandatory">*</span>
                                            </label>
                                            <br />
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="optradio"
                                                    checked={this.state.Status === "Active" ? true : false}
                                                    onChange={() => {
                                                        this.setState({
                                                            Status: "Active",
                                                        });
                                                    }}
                                                />{" "}
                                                Active
                                            </label>
                                            <label
                                                className="radio-inline"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                <input
                                                    type="radio"
                                                    name="optradio"
                                                    checked={
                                                        this.state.Status === "Inactive" ? true : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({
                                                            Status: "Inactive",
                                                        });
                                                    }}
                                                />{" "}
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn align-items-center Bechofy-btn"
                                        type="submit"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            this.setState({
                                                openedit: false,
                                                Status: "Active",
                                            });
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        style={{ float: "right" }}
                                        className="btn align-items-center Bechofy-btn "
                                        onClick={this.UpdateAttribute.bind(this)}
                                    >
                                        Update Size
                                    </button>
                                    <span></span>
                                </div>
                            </div>
                        </Modal>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
                        />
                        <Row>
                            <Col xl="12">
                                <Card className="overflow-hidden">
                                    <Row>
                                        <Col xl="12">
                                            <Card className="overflow-hidden">
                                                <div className="Bechofy-bg-soft-pink">
                                                    <Row>
                                                        <Col xs="6">
                                                            <div className="Bechofy-text p-3">
                                                                <h5 className="Bechofy-text">
                                                                    Banner List
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="Bechofy-text p-3">
                                                                <a onClick={() => {
                                                                    this.setState({
                                                                        open: true,
                                                                    });
                                                                }}
                                                                    style={{
                                                                        float: "right", marginTop: "-5px", background: 'white', color: 'grey'
                                                                    }}
                                                                    className="btn align-items-center btn Bechofy-btn "
                                                                >
                                                                    Add New Size{" "}
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <CardBody className="pt-0">
                                                    <Row></Row>
                                                </CardBody>
                                                <CardBody className="pt-0">
                                                    <Row>
                                                        <Col xs="12">
                                                            <MDBCol md="3" style={{ marginBottom: "10px" }}>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            searchInput: e.target.value,
                                                                        })
                                                                    }
                                                                    value={this.state.searchInput}
                                                                />
                                                            </MDBCol>
                                                            <MDBDataTableV5
                                                                striped
                                                                bordered
                                                                small
                                                                data={data}
                                                                searchBottom={false}
                                                                seachTop={true}
                                                                className="tablecol"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            {/* <WelcomeComp /> */}
                                        </Col>
                                    </Row>
                                </Card>
                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default verticleMater;
