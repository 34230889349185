import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import PostApiCall from '../../Api';
import Notiflix from "notiflix";
import GetApiCall from '../../GETAPI';
import "./Card.css";
function ShopByCategory({ product }) {
    const [isOpen, setIsOpen] = useState(false);
    const [heading1, setHeading1] = useState("")
    const [description1, setDescription1] = useState("")
    const [description2, setDescription2] = useState("")
    const [status, setStatus] = useState("")
    const [sequence, setSequence] = useState("")
    const [itemToBeDisplayed, setItemToBeDisplayed] = useState(null);
    const [sectionId, setSectionId] = useState(null)
    const [categoryData, setCategoryData] = useState([])
    const [variantId, setVariantId] = useState(null)
    const variantImages = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 800,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    };
    useEffect(() => {
        setSectionId(product.fld_id)
        setHeading1(product.fld_headline1)
        setDescription1(product.fld_headline2);
        setDescription2(product.fld_headline3);
        setSequence(product.fld_sequence);
        setItemToBeDisplayed(product.fld_total_item_to_be_displayed);
        setStatus(product.fld_status);
    }, [product])

    useEffect(() => {
        getVerticalData();
    }, []);
    const getVerticalData = () => {
        GetApiCall.getRequest("GetVerticleMaster").then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setCategoryData(obj.data);
                }
            });
        });
    }
    const AddWebsiteHomeSection = () => {
        Notiflix.Loading.Dots("")
        PostApiCall.postRequest({
            id: sectionId,
            sequence: sequence,
            sectionname: "Shop By Category",
            status: status,
            headline1: heading1,
            headline2: description1,
            headline3: description2,
            totalitemtobedisplayed: itemToBeDisplayed,
            // slideitemcount: slideitemcount,
        }, "AddWebsiteHomeSection"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    GetWebsiteHomeSection();
                    Notiflix.Loading.Remove();
                }
            });
        });
    }

    const GetWebsiteHomeSection = () => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetHomepageSetup"
        ).then((homepagesetupresult) => {
            homepagesetupresult.json().then((obj) => {
                obj.data.filter(
                    (value) => value.fld_section_name == "Shop By Category"
                ).map(filtereddata => {
                    setSectionId(filtereddata.fld_id)
                    setHeading1(filtereddata.fld_headline1)
                    setDescription1(filtereddata.fld_headline2);
                    setDescription2(filtereddata.fld_headline3);
                    setSequence(filtereddata.fld_sequence);
                    setItemToBeDisplayed(filtereddata.fld_total_item_to_be_displayed);
                    setStatus(filtereddata.fld_status);
                }
                );



            });
        });
    }
    return (
        <Container fluid>
            <h2 className='text-center'>Shop By Category</h2>
            <div className='row'>
                <div className="col-12">
                    <div className="form-group">
                        <label>
                            Section Heading
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={heading1}
                            onChange={(e) => { setHeading1(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>
                            Section Description 1
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={description1}
                            onChange={(e) => { setDescription1(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>
                            Section Description 2
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={description2}
                            onChange={(e) => { setDescription2(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>
                            Sequence
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={sequence}
                            onChange={(e) => { setSequence(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>
                            Item To Be Displayed
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={itemToBeDisplayed}
                            onChange={(e) => { setItemToBeDisplayed(e.target.value) }}
                        />
                    </div>
                </div>

                <div className='col-4'>
                    <label>
                        Show on website
                        <span className="mandatory">*</span>
                    </label>
                    <select
                        value={status}
                        onChange={(e) => { setStatus(e.target.value) }}
                        className="custom-select"
                    >
                        <option value="">
                            Select
                        </option>
                        <option value="Active">Yes</option>
                        <option value="InActive">No</option>
                    </select>
                </div>
            </div>
            <div className='text-right mb-4'>
                <button className="btn align-items-center Bechofy-btn" onClick={() => setIsOpen(true)}>Click here to select categories</button>
            </div>
            <Slider
                {...variantImages}
                className="product-image-slider"
            >
                {categoryData.map((product) => (
                    <div>
                        <div className="mx-2">
                            <div className="text-center bg-light ">
                                <div className="card-image">
                                    <img src={product.fld_image} className="img-fluid w-100 product-image" />
                                </div>
                                <div className="p-3">
                                    <h6 className="mb-lg-2 mb-3 fw-bold">
                                        {product.fld_name}
                                    </h6>
                                    {/* <input
                                        checked={product.fld_status == "Active" ? true : false}
                                        type="checkbox"
                                        value={product.fld_verticleid}
                                        onChange={handlechnage}
                                    /><span style={{ color: product.fld_status == "Active" ? "green" : "red" }}>{product.fld_status}</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </Slider>
            <div className='text-right mb-4'>
                <button className="btn align-items-center Bechofy-btn" onClick={AddWebsiteHomeSection}>Save</button>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
                lockBackgroundScroll="no"
                width={450}
                className="sidebar-verticalmaster drawer"
            >
                <div className='row overflow-y-scroll'>
                    {categoryData.map((product) => (
                        <div className='col-6 mt-3'>
                            <div className="mx-2">
                                <div className="text-center bg-light ">
                                    <div className="card-image">
                                        <img src={product.fld_image} className="img-fluid w-100 product-image" />
                                    </div>
                                    <div className="p-3">
                                        <h6 className="mb-lg-2 mb-3 fw-bold">
                                            {product.fld_name}
                                        </h6>
                                        <input
                                            checked={product.fld_status == "Active" ? true : false}
                                            type="checkbox"
                                            value={product.fld_verticleid}
                                            onChange={(e) => {
                                                let VerticleId = e.target.value
                                                var login = localStorage.getItem("LoginDetail");
                                                var details = JSON.parse(login);
                                                Notiflix.Loading.Dots("...")
                                                PostApiCall.postRequest(
                                                    {
                                                        verticleid: VerticleId,
                                                        verticlename: product.fld_name,
                                                        status: product.fld_status === "Active" ? "Inactive" : "Active",
                                                        updatedby: details[0].fld_userid,
                                                        verticalimage: product.fld_image,
                                                        action: VerticleId == null ? "insert" : "update",
                                                        description: product.fld_description,
                                                    },
                                                    "AddVerticleMaster"
                                                ).then((resultcategory) =>
                                                    resultcategory.json().then((obj) => {
                                                        if (resultcategory.status == 200 || resultcategory.status == 201) {
                                                            Notiflix.Loading.Remove();
                                                            Notiflix.Notify.Success(" Vertical successfully updated.");
                                                            getVerticalData();
                                                        } else {
                                                            Notiflix.Loading.Remove();
                                                            Notiflix.Notify.Failure(obj.data);
                                                        }
                                                    })
                                                )

                                            }}
                                        /><span style={{ color: product.fld_status == "Active" ? "green" : "red" }}>{product.fld_status}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Drawer>
        </Container>
    );
}

export default ShopByCategory;