import { MDBCol } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import GtLogo from '../../assets/images/bechofylogo.png';
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png';
import Processicon from '../../assets/images/dashboardimages/processing.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';
import UploadBulk from "./UploadBulk";
class BulkProductsUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: "",
            FileData: [],
            CustomerData: [],
            SearchField: null,
            StateData: [],
            SelectedFile: [],
            selectFileID: [],
            SelectAll: false,
            selectAllData: [],
            AllFile: false
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        this.getNewFile()
        // this.getAllFile()
    }
    getNewFile = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereclause: `where fld_filestatus='NEW'`,
            },
            "GetItemUploadFileDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //
                    this.setState({
                        FileData: obj.data,
                    });
                    let arr = [];
                    if (obj.data) {
                        obj.data.map((item) => {
                            arr.push({ FileID: item.fld_fileid });
                        });
                    }
                    // //
                    this.setState({
                        selectFileID: arr,
                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }
    getAllFile = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereclause: ''
            },
            "GetStockUploadFileDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        FileData: obj.data,
                    });
                    let arr = [];
                    if (obj.data) {
                        obj.data.map((item) => {
                            arr.push({ FileID: item.fld_fileid });
                        });
                    }
                    // //
                    this.setState({
                        selectFileID: arr,
                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <ul className="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
                                        <li>Stock Management</li>
                                        <li>Upload bulk</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
                            </div>
                        </div>
                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <Card>
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Upload bulk</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardBody>
                                            <h4 style={{ fontFamily: '-webkit-body' }}>
                                                Manage products data for your online store in a very easy way. You can use the below section to update the products using CSV File uploads.
                                                The Pre-formatted CSV files help you to ensure that you have a seamless way of updating products data.
                                            </h4>
                                        </CardBody>
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <h4 style={{ fontFamily: '-webkit-body', fontWeight: 'bold', wordSpacing: '2px', letterSpacing: '1px' }}>
                                                How to manage the products of your store?
                                            </h4>
                                        </CardBody>
                                        <UploadBulk />
                                    </Card>
                                    <Card>
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Pending for Process</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                            onChange={(e) => this.seachBarHandler(e)}
                                                            value={this.state.searchInput}
                                                        />
                                                    </div>
                                                </MDBCol>
                                                <Col md="12">
                                                    <div style={{ float: 'right' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.AllFile}
                                                            onChange={() => {
                                                                if (this.state.AllFile) {
                                                                    this.setState({
                                                                        AllFile: false,
                                                                    }, () => {
                                                                        this.getNewFile()
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        AllFile: true,
                                                                    }, () => {
                                                                        this.getAllFile()
                                                                    });
                                                                }
                                                            }}
                                                            style={{ verticalAlign: 'middle' }}
                                                        />
                                                        <label> &nbsp; Show All</label>
                                                    </div>
                                                    {this.state.FileData.length != 0 ?
                                                        <div>
                                                            <table className="table table-bordered table-striped mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No.</th>
                                                                        <th>File Name</th>
                                                                        <th>Total Records</th>
                                                                        <th>Processed</th>
                                                                        <th>Error</th>
                                                                        <th>File Status</th>
                                                                        <th>File Upload Date</th>
                                                                        <th><input
                                                                            type="checkbox"
                                                                            checked={this.state.SelectAll}
                                                                            onChange={() => {
                                                                                if (this.state.SelectAll) {
                                                                                    this.setState({
                                                                                        SelectAll: false,
                                                                                        SelectedFile: []
                                                                                    }, () => {
                                                                                        window.location.reload()
                                                                                    });
                                                                                } else {
                                                                                    this.setState({
                                                                                        SelectAll: true,
                                                                                        SelectedFile: this.state.FileData
                                                                                    });
                                                                                }
                                                                            }}
                                                                            style={{ verticalAlign: 'middle' }}
                                                                        /> Action</th>
                                                                        <th>Download (.csv)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.FileData.map((data, i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{(i + 1)}</td>
                                                                                <td>{data.fld_filename}</td>
                                                                                <td>{data.fld_totalrecords}</td>
                                                                                <td>{data.fld_processedrecords}</td>
                                                                                <td>{data.fld_failedrecords}</td>
                                                                                <td>{data.fld_filestatus}</td>
                                                                                <td>{data.fld_createdon}</td>
                                                                                <td>
                                                                                    {this.state.SelectAll == false ?
                                                                                        <input
                                                                                            defaultChecked={
                                                                                                this.state.SelectedFile.length > 0
                                                                                                    ? this.state.SelectedFile.map((item) => {
                                                                                                        if (
                                                                                                            item.fld_fileid ===
                                                                                                            data.fld_fileid
                                                                                                        ) {
                                                                                                            return true;
                                                                                                        } else {
                                                                                                            return false;
                                                                                                        }
                                                                                                    }).filter((item) => {
                                                                                                        if (item == true) {
                                                                                                            return true;
                                                                                                        }
                                                                                                        if (item !== true) {
                                                                                                            return false;
                                                                                                        }
                                                                                                    })[0] == true
                                                                                                        ? true
                                                                                                        : false
                                                                                                            ? null
                                                                                                            : null
                                                                                                    : null
                                                                                            }
                                                                                            onClick={() => {
                                                                                                var ar = [...this.state.SelectedFile];
                                                                                                if (ar.includes(data)) {
                                                                                                    ar.splice(ar.indexOf(data), 1);
                                                                                                } else {
                                                                                                    ar.push(data);
                                                                                                }
                                                                                                this.setState({
                                                                                                    SelectedFile: ar,
                                                                                                });
                                                                                                // //
                                                                                            }}
                                                                                            type="checkbox"
                                                                                        />
                                                                                        :
                                                                                        <input
                                                                                            defaultChecked={
                                                                                                this.state.selectFileID.length > 0
                                                                                                    ? this.state.selectFileID.map((item) => {
                                                                                                        if (
                                                                                                            item.FileID ===
                                                                                                            data.fld_fileid
                                                                                                        ) {
                                                                                                            return true;
                                                                                                        } else {
                                                                                                            return false;
                                                                                                        }
                                                                                                    }).filter((item) => {
                                                                                                        if (item == true) {
                                                                                                            return true;
                                                                                                        }
                                                                                                        if (item !== true) {
                                                                                                            return false;
                                                                                                        }
                                                                                                    })[0] == true
                                                                                                        ? true
                                                                                                        : false
                                                                                                            ? null
                                                                                                            : null
                                                                                                    : null
                                                                                            }
                                                                                            onClick={() => {
                                                                                                var ar = [...this.state.SelectedFile];
                                                                                                if (ar.includes(data)) {
                                                                                                    ar.splice(ar.indexOf(data), 1);
                                                                                                } else {
                                                                                                    ar.push(data);
                                                                                                }
                                                                                                this.setState({
                                                                                                    SelectedFile: ar,
                                                                                                });
                                                                                                // //
                                                                                            }}
                                                                                            type="checkbox"
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <a href={`${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path}/CSV/PriceCSV/${data.fld_filename}`} target="blank">
                                                                                        <img src={Downloadicon} alt="block user" className="btnicons"
                                                                                        ></img>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}							</tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <p>No Data Found</p>}
                                                </Col>
                                            </Row>
                                            {this.state.SelectedFile.length != 0 ?
                                                <button className="btn downloadcsv-button mt-3 mb-1" style={{ float: 'right' }}
                                                    onClick={() => {
                                                        confirmAlert({
                                                            title: 'Confirm to Process ',
                                                            message: 'Are you sure you want to process the selected file.',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes',
                                                                    onClick: () => {
                                                                        Notiflix.Loading.Dots('Please wait...');
                                                                        for (var i = 0; i < this.state.SelectedFile.length; i++) {
                                                                            //
                                                                            PostApiCall.postRequest(
                                                                                {
                                                                                    fileid: this.state.SelectedFile[i].fld_fileid,
                                                                                    filename: this.state.SelectedFile[i].fld_filename,
                                                                                },
                                                                                "UploadItems"
                                                                            ).then((results1) =>
                                                                                results1.json().then((obj1) => {
                                                                                    if (results1.status == 200 || results1.status == 201) {
                                                                                        //
                                                                                        Notiflix.Loading.Remove();
                                                                                        Notiflix.Notify.Success("File successfully processed.");
                                                                                        // this.getNewFile();
                                                                                        window.location.reload();
                                                                                    }
                                                                                    else {
                                                                                        Notiflix.Loading.Remove();
                                                                                        Notiflix.Notify.Failure(obj1.data);
                                                                                    }
                                                                                })
                                                                            );
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    label: 'No',
                                                                    // onClick: () => alert('Click No')
                                                                }
                                                            ]
                                                        });
                                                    }}
                                                >
                                                    <span className=""><img src={Processicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                                    Process File(S)
                                                </button> : ''}
                                        </CardBody>
                                    </Card>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default BulkProductsUpload;
