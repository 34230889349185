import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import DatePicker from 'react-date-picker';
class AllReviews extends Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef()
        this.state = {
            ReviewData: [],
            fileData: [],
            searchInput: ""
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        PostApiCall.postRequest({
            whereClause: "order by fld_review_id desc",
            recordCount: "*"
        }, "GetReviews").then((results) =>
            results.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {

                    if (obj1.data.length != 0) {
                        this.setState({
                            ReviewData: obj1.data,
                            AverageRating: obj1.data[0].avg_rating,
                            Loading: false
                        });
                    } else {
                        this.setState({
                            ReviewData: obj1.data,
                            Loading: false
                        })
                    }
                }
            })
        );
    }
    DownloadCSV = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereClause: '',
                recordCount: "*"
            },
            "GetReviews"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        // this.props.setstate()
                        this.setState({
                            fileData: obj.data,
                        }, () => {
                            setTimeout(() => {
                                this.csvLink.current.link.click();
                            });
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            }))
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Customer Name",
                    field: "name",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Customer Email",
                    field: "email",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Variant Name",
                    field: "variantname",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Rating",
                    field: "rating",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Review",
                    field: "review",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Review Date",
                    field: "reviewdate",
                    sort: "disabled",
                    width: 300,
                },
            ],
            rows: this.state.ReviewData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_variantname
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_review_description
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    name: (data.fld_name),
                    email: (data.fld_email),
                    variantname: (data.fld_variantname),
                    rating: (data.fld_rating),
                    review: (data.fld_review_description),
                    reviewdate: (moment(data.fld_review_date).format("DD/MM/YYYY")),
                }
            })
        }
        capitalizeWords = (str) => {
            return str
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} urlPath={"/viewallreviews"} />
                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <Card >
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Review List</h4>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="position-relative">
                                                        <a
                                                            onClick={this.DownloadCSV.bind(this)}
                                                            style={{
                                                                float: "right",
                                                                marginTop: "10px",
                                                                background: 'white',
                                                                color: 'grey',
                                                                marginRight: '20px'
                                                            }}
                                                            className="btn align-items-center btn Bechofy-btn "
                                                        >
                                                            <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                                            Download(.csv)
                                                        </a>
                                                    </div>
                                                    <CSVLink
                                                        data={this.state.fileData}
                                                        filename="ReviewList.csv"
                                                        className="hidden"
                                                        ref={this.csvLink}
                                                        target="_blank"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                            onChange={(e) => this.seachBarHandler(e)}
                                                            value={this.state.searchInput}
                                                        />
                                                    </div>
                                                </MDBCol>
                                                <Col md="12">
                                                    {this.state.ReviewData.length != 0 ?
                                                        <MDBDataTable
                                                            hover
                                                            // scrollY
                                                            striped
                                                            bordered
                                                            data={data}
                                                            seachTop={false}
                                                            entriesOptions={[10, 25, 50, 100]}
                                                        >
                                                            <MDBTableHead columns={data.columns} />
                                                            <MDBTableBody rows={data.rows} />
                                                        </MDBDataTable>
                                                        :
                                                        <p>No Data Found</p>}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Card>
                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>
							</Col>
						</Row> */}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AllReviews;
