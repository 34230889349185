import React, { useContext, useEffect, useState } from 'react';
import GetApiCall from '../../GETAPI';
import PostApiCall from '../../Api';
import MainSlider from './MainSlider';
import ShopByCategory from './ShopByCategory';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import ProductCard from './ProductCard';
import { Container } from 'react-bootstrap';
import FeaturedProducts from './FeaturedProducts';
import OurCollectionHomeSection from './OurCollectionHomeSection';
import BestSellerHomeSection from './BestSellerHomeSection';
import ParallaxSection from './ParallaxSection';
import WebsiteFooter from './WebsiteFooter';
import OurCollectionSection from './OurCollectionSection';


function WebsiteCms() {
    const [shopByCategorySection, setShopByCategorySection] = useState("");
    const [featuredHomeProducts, setFeaturedHomeProducts] = useState([])
    const [newArrivals, setNewArrivals] = useState([])
    const [bestSeller, setBestSeller] = useState([]);
    const [featuredSection, setFeaturedSection] = useState([]);
    const [newArrivalSection, setNewArrivalSection] = useState([]);
    const [bestSellerSection, setBestSellerSection] = useState([]);
    const [updateProductCards, setUpdateProductCards] = useState(false);
    const [allproducts, setAllproducts] = useState([]);
    const [response, setResponse] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    };

    useEffect(() => {
        GetWebsiteHomeSection();
    }, [response])
    const GetWebsiteHomeSection = () => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetHomepageSetup"
        ).then((homepagesetupresult) => {
            homepagesetupresult.json().then((obj) => {
                obj.data.filter(
                    (value) => value.fld_section_name == "Shop By Category"
                ).map(filtereddata => {
                    setShopByCategorySection(filtereddata);
                    // setSectionId(filtereddata.fld_id)
                    // setHeading1(filtereddata.fld_headline1)
                    // setDescription1(filtereddata.fld_headline2);
                    // setDescription2(filtereddata.fld_headline3);
                    // setSequence(filtereddata.fld_sequence);
                    // setItemToBeDisplayed(filtereddata.fld_total_item_to_be_displayed);
                    // setStatus(filtereddata.fld_status);
                }
                );


                obj.data.filter(
                    (value) => value.fld_section_name == "Featured Products"
                ).map(filtereddata => {
                    setFeaturedSection(filtereddata)
                    PostApiCall.postRequest({
                        sectionid: filtereddata.fld_id
                    },
                        "GetWebsiteHomePageItems"
                    ).then((results) => {
                        results.json()?.then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                setFeaturedHomeProducts(obj.data);
                            }
                        });
                    });
                }
                );

                obj.data.filter(
                    (value) => value.fld_section_name == "New Arrivals"
                ).map(filtereddata => {
                    setNewArrivalSection(filtereddata)
                    PostApiCall.postRequest({
                        sectionid: filtereddata.fld_id
                    },
                        "GetWebsiteHomePageItems"
                    ).then((results) => {
                        results.json()?.then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                setNewArrivals(obj.data);
                            }
                        });
                    });
                }
                );

                obj.data.filter(
                    (value) => value.fld_section_name == "Best Seller"
                ).map(filtereddata => {
                    setBestSellerSection(filtereddata)
                    PostApiCall.postRequest({
                        sectionid: filtereddata.fld_id
                    },
                        "GetWebsiteHomePageItems"
                    ).then((results) => {
                        results.json()?.then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                setBestSeller(obj.data);
                            }
                        });
                    });
                }
                );
            });
        });
    }
    useEffect(() => {

        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetItemsDetails"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setAllproducts(obj.data);
                }
            });
        });
    }, [updateProductCards]);

    return (
        <>
            <MainSlider />
            <Container fluid>
                <ShopByCategory product={shopByCategorySection} />
                <FeaturedProducts
                    HomeProducts={featuredHomeProducts}
                    allproducts={allproducts}
                    sectionname="Featured Products"
                    sectiondetails={featuredSection}
                    response={response}
                    setResponse={setResponse}
                />
                <FeaturedProducts
                    HomeProducts={newArrivals}
                    allproducts={allproducts}
                    sectionname="New Arrivals"
                    sectiondetails={newArrivalSection}
                    response={response}
                    setResponse={setResponse}
                />
                <FeaturedProducts
                    HomeProducts={bestSeller}
                    allproducts={allproducts}
                    sectionname="Best Seller"
                    sectiondetails={bestSellerSection}
                    response={response}
                    setResponse={setResponse}
                />
                {/* <OurCollectionHomeSection newArrivals={newArrivals} allproducts={allproducts} />
                <BestSellerHomeSection bestSeller={bestSeller} allproducts={allproducts} /> */}
                <ParallaxSection />
                <OurCollectionSection />
                <WebsiteFooter />
            </Container>
            {/* <BlogSection /> */}


        </>
    );
}

export default WebsiteCms;