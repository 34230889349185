import { MDBCol, MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class OfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,
      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      OfferData: [],
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.Dots("");
    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          OfferData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Offer Name",
          field: "offername",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Offer Caption",
          field: "offercaption",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Code",
          field: "code",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Discount (%)",
          field: "price",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Fixed Discount Amount (Rs.)",
          field: "max",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Min Order Amount (Rs.)",
          field: "minapplicableamount",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Max Applicable Discount (Rs.)",
          field: "maxapplicableamount",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Start Date",
          field: "start",
          sort: "disabled",
          width: 150,
        },
        {
          label: "End Date",
          field: "end",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Offer Status",
          field: "offerstatus",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.OfferData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (`${data.fld_name ? data.fld_name.toLowerCase() : ""}`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_caption ? data.fld_caption.toLowerCase() : ""}`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_code ? data.fld_code.toLowerCase() : ""}`.includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          offername: data.fld_name,
          offercaption: data.fld_caption,
          code: data.fld_code,
          price: data.fld_pricepercent,
          max: data.fld_maxdisprice,
          minapplicableamount: data.fld_minapplyamnt,
          maxapplicableamount: data.fld_maxapplyamnt,
          start: moment(data.fld_startdate).format("DD/MM/YYYY"),
          end: moment(data.fld_enddate).format("DD/MM/YYYY"),
          offerstatus: data.fld_showonwebsite,
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace("/", "")).replace("-", " ").replace("_", " ")} />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink" style={{ background: "#777f80" }}>
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text text-white">Offer Management</h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <a
                            onClick={() => {
                              window.location.href = "/add-offer";
                            }}
                            style={{
                              float: "right",
                              marginTop: "-5px",
                              color: "#777f80",
                              backgroundColor: "#fff",
                            }}
                            className="btn align-items-center btn Bechofy-btn "
                          >
                            Add New Offer <i className="fa fa-plus" aria-hidden="true"></i>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <CardBody className="pt-0">
                          <Row></Row>
                        </CardBody>
                        <CardBody className="pt-0">
                          <Row>
                            <Col md="12">
                              <div className="row">
                                {this.state.OfferData.map((record, index) => {
                                  return (
                                    <div className="col-6 my-3">
                                      <div className="p-4 offer-box-bg">
                                        <p className="d-flex">
                                          <div className="offerlistcard offerlist-dynamicdataname">
                                            <span> S.No.</span>
                                          </div>
                                          <div className="offerlist-dynamicdata">{index + 1}</div>
                                        </p>
                                        <p className="d-flex">
                                          <div className="offerlist-dynamicdataname offerlistcard">Offer Name</div>
                                          <div className="offerlist-dynamicdata">{record.fld_name}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Offer Caption</div>
                                          <div className="offerlist-dynamicdata">{record.fld_caption}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Code</div>
                                          <div className="offerlist-dynamicdata">{record.fld_code}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Discount (%)</div>
                                          <div className="offerlist-dynamicdata">{record.fld_pricepercent}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Fixed Discount Amount (Rs.)</div>
                                          <div className="offerlist-dynamicdata">{record.fld_maxdisprice}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Min Order Amount (Rs.)</div>
                                          <div className="offerlist-dynamicdata">{record.fld_minapplyamnt}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Max Applicable Discount (Rs.)</div>
                                          <div className="offerlist-dynamicdata">{record.fld_maxapplyamnt}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Start Date</div>
                                          <div className="offerlist-dynamicdata">{moment(record.fld_startdate).format("DD/MM/YYYY")}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">End Date</div>
                                          <div className="offerlist-dynamicdata">{moment(record.fld_enddate).format("DD/MM/YYYY")}</div>
                                        </p>

                                        <p className="d-flex justify-content-between">
                                          <div className="offerlist-dynamicdataname offerlistcard">Offer Status</div>
                                          <div className="offerlist-dynamicdata offerlist-column">{record.fld_showonwebsite}</div>
                                        </p>

										<p className="d-flex justify-content-between" style={{borderTop: '2px solid #80808017'}}>
                                          <div className="offerlist-dynamicdataname offerlistcard offerlist-column mt-3">	
										  Action									  
										  </div>
                                          <div className="offerlist-dynamicdata mt-3">
										  <i
                                                className="fas fa-edit btn px-0"
                                                style={{ fontSize: "15px", marginTop: "-11px" }}
                                                onClick={() => {
                                                  localStorage.setItem("OfferDetails", JSON.stringify({ ...record }));
                                                  window.location.href = "/edit-offer";
                                                }}
                                              ></i>
										  <span className="btn mx-4" style={{ marginTop: "-9px" }}>
                                                <i
                                                  className="fa fa-trash py-auto "
                                                  aria-hidden="true"
                                                  style={{ fontSize: "15px" }}
                                                  onClick={() => {
                                                    confirmAlert({
                                                      title: "Confirm to Delete",
                                                      message: "Are you sure you want to delete offer.",
                                                      buttons: [
                                                        {
                                                          label: "Yes",
                                                          onClick: () => {
                                                            Notiflix.Loading.Dots("");
                                                            PostApiCall.postRequest(
                                                              {
                                                                offer_id: record.fld_offerid,
                                                                type:
                                                                  this.state.CategoryName != "" && this.state.SubCategory == ""
                                                                    ? "Category"
                                                                    : this.state.SubCategory != "" && this.state.ItemName == ""
                                                                    ? "SubCategory"
                                                                    : this.state.ItemName != ""
                                                                    ? "Variant"
                                                                    : "Vertical",
                                                                typeid:
                                                                  this.state.CategoryName != "" && this.state.SubCategory == ""
                                                                    ? this.state.CategoryName
                                                                    : this.state.SubCategory != "" && this.state.ItemName == ""
                                                                    ? this.state.SubCategory
                                                                    : this.state.ItemName != ""
                                                                    ? this.state.ItemName
                                                                    : this.state.VerticalName,
                                                                name: this.state.OfferName,
                                                                caption: this.state.OfferCaption,
                                                                pricepercent: this.state.OfferPrice,
                                                                maximumdiscountprice: this.state.MaximumDiscountPrice,
                                                                minapplyamnt: this.state.MinimumAmountApplicable,
                                                                maxapplyamnt: this.state.MaximumAmountApplicable,
                                                                minimumproduct: this.state.MinimumProducts,
                                                                freegift: this.state.FreeGifts,
                                                                firstorder: this.state.FirstOrder,
                                                                allorder: this.state.AllOrders,
                                                                onetime: this.state.OneTime,
                                                                freeshipping: this.state.FreeShipping,
                                                                refferaldiscount: this.state.RefferalDiscount,
                                                                description: this.state.OfferDescription,
                                                                code: this.state.OfferCode,
                                                                startdate: this.state.StartDate,
                                                                enddate: this.state.EndDate,
                                                                termscondition: this.state.Terms,
                                                                showonwebsite: "Delete",
                                                                userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_userid,
                                                              },
                                                              "AddOffer"
                                                            ).then((results) =>
                                                              results.json().then((obj) => {
                                                                if (results.status == 200 || results.status == 201) {
                                                                  Notiflix.Loading.Remove();
                                                                  Notiflix.Notify.Success("Offer successfully deleted.");
                                                                  window.location.reload();
                                                                } else {
                                                                  Notiflix.Loading.Remove();
                                                                  Notiflix.Notify.Failure("Something went wrong, try again later.");
                                                                }
                                                              })
                                                            );
                                                          },
                                                        },
                                                        {
                                                          label: "No",
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                ></i>
                                              </span>
										  </div>
                                        </p>                                        
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default OfferList;
