import React, { useEffect, useState } from 'react';
import GetApiCall from '../../GETAPI';
import PostApiCall from '../../Api';
import HomePageProducts from './HomePageProducts';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import ProductCard from './ProductCard';
import { Container } from 'react-bootstrap';
import Notiflix from "notiflix";


function FeaturedProducts({ HomeProducts, allproducts, sectionname, sectiondetails, setResponse, response }) {
    const [cardType, setCardType] = useState("");
    const [updateProductCards, setUpdateProductCards] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [sectionId, setSectionId] = useState(null);
    const [heading1, setHeading1] = useState("")
    const [description1, setDescription1] = useState("")
    const [description2, setDescription2] = useState("")
    const [status, setStatus] = useState("")
    const [sequence, setSequence] = useState("")
    const [itemToBeDisplayed, setItemToBeDisplayed] = useState(null)
    const [mediaType, setMediaType] = useState("")
    const [showItemName, setShowItemName] = useState("")
    const [showItemPrice, setShowItemPrice] = useState("")
    const [showItemDescription, setShowItemDescription] = useState("")
    const [showAddToCartBtn, setShowAddToCartBtn] = useState("")

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    };
    // console.log(sectiondetails)
    // useEffect(() => {
    //     PostApiCall.postRequest({
    //         customerid:
    //             localStorage.getItem("CustomerData") ==
    //                 null
    //                 ? null
    //                 : logindetails[0].fld_customerid,
    //         categoryid: null,
    //         productid: null,
    //         variantid: null,
    //         sectionname: "Featured Products",
    //         orderid: 0,
    //         itemchoice: "Select",
    //     },
    //         "GetWebsiteHomePageItems"
    //     ).then((results) => {
    //         results.json()?.then((obj) => {
    //             if (results.status == 200 || results.status == 201) {
    //                 // ;
    //                 setFeaturedProducts(obj.data);
    //             }
    //         });
    //     })
    //     // PostApiCall.postRequest(
    //     //     {
    //     //         whereClause: "",
    //     //     },
    //     //     "GetItemsDetails"
    //     // ).then((results) => {
    //     //     results.json()?.then((obj) => {
    //     //         if (results.status == 200 || results.status == 201) {
    //     //             setFeaturedProducts(obj.data);
    //     //         }
    //     //     });
    //     // });
    // }, [updateProductCards]);

    useEffect(() => {
        setSectionId(sectiondetails.fld_id)
        setHeading1(sectiondetails.fld_headline1)
        setDescription1(sectiondetails.fld_headline2);
        setDescription2(sectiondetails.fld_headline3);
        setSequence(sectiondetails.fld_sequence);
        setItemToBeDisplayed(sectiondetails.fld_total_item_to_be_displayed);
        setMediaType(sectiondetails.fld_media_type);
        setStatus(sectiondetails.fld_status);
        setShowItemName(sectiondetails.fld_show_item_name);
        setShowItemPrice(sectiondetails.fld_show_price);
        setShowItemDescription(sectiondetails.fld_show_short_description);
        setShowAddToCartBtn(sectiondetails.fld_show_add_to_cart)
        // GetWebsiteHomeSection();
    }, [sectiondetails])
    const GetWebsiteHomeSection = () => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetHomepageSetup"
        ).then((homepagesetupresult) => {
            homepagesetupresult.json().then((obj) => {
                obj.data.filter(
                    (value) => value.fld_section_name == "Featured Products"
                ).map(filtereddata => {
                    setSectionId(filtereddata.fld_id)
                    setHeading1(filtereddata.fld_headline1)
                    setDescription1(filtereddata.fld_headline2);
                    setDescription2(filtereddata.fld_headline3);
                    setSequence(filtereddata.fld_sequence);
                    setItemToBeDisplayed(filtereddata.fld_total_item_to_be_displayed);
                    setMediaType(filtereddata.fld_media_type);
                    setStatus(filtereddata.fld_status);
                    setShowItemName(filtereddata.fld_show_item_name);
                    setShowItemPrice(filtereddata.fld_show_price);
                    setShowItemDescription(filtereddata.fld_show_short_description);
                    setShowAddToCartBtn(filtereddata.fld_show_add_to_cart)
                }
                );



            });
        });
    }


    const AddWebsiteHomeSection = () => {
        Notiflix.Loading.Dots("")
        PostApiCall.postRequest({
            id: sectionId,
            sequence: sequence,
            sectionname: sectionname,
            status: status,
            headline1: heading1,
            headline2: description1,
            headline3: description2,
            mediatype: mediaType,
            totalitemtobedisplayed: itemToBeDisplayed,
            slideitemcount: null,
            showitemname: showItemName,
            showshortdescription: showItemDescription,
            showprice: showItemPrice,
            showaddtocart: showAddToCartBtn,
        }, "AddWebsiteHomeSection"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setResponse(!response)
                    GetWebsiteHomeSection()
                    Notiflix.Loading.Remove();
                }
            });
        });
    }

    return (
        <>
            <Container fluid>
                <h2 className='text-center'>{sectionname}</h2>
                <div className='row'>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Section Heading
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={heading1}
                                onChange={(e) => { setHeading1(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Section Description 1
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={description1}
                                onChange={(e) => { setDescription1(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Section Description 2
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={description2}
                                onChange={(e) => { setDescription2(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Sequence
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={sequence}
                                onChange={(e) => { setSequence(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Item To Be Displayed
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={itemToBeDisplayed}
                                onChange={(e) => { setItemToBeDisplayed(e.target.value) }}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Media Type
                                <span className="mandatory">*</span>
                            </label>
                            <select
                                value={mediaType}
                                onChange={(e) => { setMediaType(e.target.value) }}
                                className="custom-select"
                            >
                                <option value="">
                                    Select
                                </option>
                                <option value="Photo">Photo</option>
                                <option value="Video">Video</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show on website
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={status}
                            onChange={(e) => { setStatus(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="Active">Yes</option>
                            <option value="InActive">No</option>
                        </select>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show Item Name
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={showItemName}
                            onChange={(e) => { setShowItemName(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show Item Price
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={showItemPrice}
                            onChange={(e) => { setShowItemPrice(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show Item Short Description
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={showItemDescription}
                            onChange={(e) => { setShowItemDescription(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show Add To Cart Button
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={showAddToCartBtn}
                            onChange={(e) => { setShowAddToCartBtn(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className='text-right mb-4'>
                    <button className='btn align-items-center Bechofy-btn' onClick={() => {
                        setIsOpen(true)
                        setCardType("featured")
                    }}>Click here to add products</button>
                </div>
                <HomePageProducts
                    productdata={HomeProducts}
                    cardType={"featured"}
                    id={sectionId}
                    showItemName={showItemName}
                    showItemDescription={showItemDescription}
                    showItemPrice={showItemPrice}
                    showAddToCartBtn={showAddToCartBtn}
                />
                <div className='text-right mb-4'>
                    <button className='btn align-items-center Bechofy-btn' onClick={AddWebsiteHomeSection}>Save</button>
                </div>
            </Container>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
                lockBackgroundScroll="no"
                width={450}
                className="sidebar-verticalmaster drawer"
            >
                <div className='row overflow-y-scroll'>
                    {allproducts.map((product) => (
                        <div className='col-6 mt-3'>
                            <ProductCard
                                product={product}
                                cardType={"featured"}
                                sectionId={sectionId}
                                sequence={sequence}
                                sectionname={sectionname}
                                sectionstatus={status}
                                heading1={heading1}
                                description1={description1}
                                description2={description2}
                                mediaType={mediaType}
                                itemToBeDisplayed={itemToBeDisplayed}
                                showItemName={showItemName}
                                showItemDescription={showItemDescription}
                                showItemPrice={showItemPrice}
                                showAddToCartBtn={showAddToCartBtn}
                                showCheckbox="true"
                                setResponse={setResponse}
                                response={response}
                            // checkbox={featuredHomeProducts.fld_id.includes(product.fld_id) ? "true" : "false"}
                            />
                        </div>
                    ))}
                </div>
            </Drawer>
        </>
    );
}


export default FeaturedProducts;