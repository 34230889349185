import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Closeicon from "../../assets/images/dashboardimages/close.png";
import deleteicon from "../../assets/images/dashboardimages/delete.png";
import Saveicon from "../../assets/images/dashboardimages/filesave.png";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Sequentialize from "../../assets/images/dashboardimages/Sequentialize.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DragDropList from "../../components/CommonForBoth/Utilities/DragDropList";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
const Skeletonverticalmaster = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between mb-0 pb-0">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                        <div className="div1">
                          <Skeleton
                            style={{ height: "130px", width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" style={{ marginBottom: "-10px" }}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Skeleton className="py-1" />
                        <Skeleton className="my-2 py-1" />
                        <Skeleton className="my-2 py-3" />
                        <Skeleton className="py-1" style={{ width: "60px" }} />
                      </div>
                      <div className="col-2 mb-3">
                        <Skeleton className="py-1 px-3" />
                      </div>
                      <div className="col-2 mb-3">
                        <Skeleton className="py-1 px-3" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-4 px-3" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-end">
                  <Col sm="3" className="text-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                        <div className="div1">
                          <Skeleton
                            style={{ height: "130px", width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" style={{ marginBottom: "-10px" }}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Skeleton className="py-1" />
                        <Skeleton className="py-1 my-3" />
                        <Skeleton className="py-1" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                        <div className="div1">
                          <Skeleton
                            style={{ height: "130px", width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" style={{ marginBottom: "-10px" }}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Skeleton className="py-1" />
                        <Skeleton className="py-1 my-3" />
                        <Skeleton className="py-1" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "5px" }}
  >
    <div className="img-wrap brandimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
class verticleMater extends Component {
  state = {
    data: [],
    SearchInput: "",
    VerticleData: [],
    ViewClick: "true",
    Open: false,
    Name: "",
    OpenEdit: false,
    Id: "",
    Isvisible: false,
    ImagePreviewUrl:
      "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
    ImageApiUrl: 'https://api.bechofy.in/AddImage',
    Status: "Yes",
    VerticalName: null,
    OriginalImage: [],
    OriginalLink: "",
    Clicked: false,
    UploadImage: false,
    VerticleId: null,
    VerticalLogo: null,
    Description: null,
    IsOpen: false,
    editfocos: false,
    count: "",
    loader: true,
    RandomNumber: randomnumber,
  };
  componentDidMount() {
    this.getVertical();
  }
  getVertical = () => {
    GetApiCall.getRequest("GetVerticleMaster").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticleData: obj.data,
          count: obj.data.length,
          loader: false,
          VerticalName: "",
          VerticleId: null,
          Status: null,
          editfocos: false,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  toggleDrawer = () => {
    this.setState({
      IsOpen: !this.state.IsOpen,
    });
  };
  // ==Image Upload onChange Function=======
  photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    this.setState({
      ImagePreviewUrl: URL.createObjectURL(imageFile),
      OriginalImage: imageFile,
      outputFileName: imageFile.name,
      UploadImage: true,
      loader: false,
    });
  };
  // ===function for input field validation
  onSaveData() {
    if (this.state.VerticalName != null) {
      if (
        this.state.ImagePreviewUrl !=
        "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
      ) {
        this.onPostVertical();
      } else {
        Notiflix.Notify.Failure("Please upload vertical image");
      }
    } else {
      Notiflix.Notify.Failure("Please enter vertical name");
    }
  }
  onPostVertical = () => {
    Notiflix.Loading.Dots("Please wait...");
    this.setState({
      editfocos: false,
      loader: false,
    });
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.state.VerticalLogo != this.state.ImagePreviewUrl) {
      if (this.state.outputFileName != undefined) {
        PostApiCall.postRequest(
          {
            verticleid: this.state.VerticleId,
            verticlename: this.state.VerticalName,
            status: this.state.Status === "Yes" ? "Active" : "Inactive",
            updatedby: details[0].fld_userid,
            createdby: details[0].fld_userid,
            verticalimage: 'https://store.bechofy.in/images/' +
              JSON.parse(localStorage.getItem("ClientDetails"))[0]
                .fld_dbschema +
              "/VerticalImages/" +
              this.state.VerticalName.trim().replace(/\s/g, "-") +
              "-" + this.state.RandomNumber +
              this.state.outputFileName.split('.').slice(0, -1).join('.') + '.webp',
            action: this.state.VerticleId == null ? "insert" : "update",
            description: this.state.Description,
          },
          "AddVerticleMaster"
        ).then((resultcategory) =>
          resultcategory.json().then((obj) => {
            if (resultcategory.status == 200 || resultcategory.status == 201) {
              Notiflix.Loading.Remove();
              Notiflix.Notify.Success(
                "Vertical successfully added. Image upload in process"
              );
              this.onUploadImage(obj);
            } else {
              Notiflix.Loading.Remove();
              Notiflix.Notify.Failure(obj.data);
            }
          })
        );
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Vertical Image");
      }
    } else {
      this.updateVerticalWithOldImage();
    }
  };
  // =====Upload image on server====
  async onUploadImage(obj) {
    Notiflix.Loading.Dots("Uploading Images...");
    let response;
    if (this.state.BrandLogo != this.state.ImagePreviewUrl) {
      if (this.state.outputFileName != undefined) {

        const form = new FormData();
        form.append("file", this.state.OriginalImage);
        form.append("foldername", "VerticalImages");
        form.append(
          "filename",
          this.state.VerticalName.trim().replace(/\s/g, "-") +
          "-" + this.state.RandomNumber +
          this.state.outputFileName
        );
        response = fetch(this.state.ImageApiUrl, {
          method: "POST",
          body: form,
          headers: {
            "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
            "x-auth-token": JSON.parse(localStorage.getItem("access")),
            "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              ImageName: data.Message.split(",")[2].split("=")[1].trim(),
            });
            Notiflix.Loading.Remove();
            Notiflix.Notify.Success("Image successfully uploaded.");
            this.getVertical()
          });
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Brand Image");
      }
    } else {
      this.updateVerticalWithOldImage();
    }
  }
  // ====Update vertical data without image change
  updateVerticalWithOldImage = () => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        verticleid: this.state.VerticleId,
        verticlename: this.state.VerticalName,
        status: this.state.Status === "Yes" ? "Active" : "Inactive",
        updatedby: details[0].fld_userid,
        createdby: details[0].fld_userid,
        verticalimage: this.state.ImagePreviewUrl,
        action: this.state.VerticleId == null ? "insert" : "update",
        description: this.state.Description,
      },
      "AddVerticleMaster"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(" Vertical successfully updated.");
          this.getVertical()
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, SearchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <Drawer
          open={this.state.IsOpen}
          onClose={this.toggleDrawer.bind(this)}
          direction="right"
          lockBackgroundScroll="no"
          width={450}
          className="sidebar-verticalmaster drawer"
        >
          <DragDropList
            verticalData={this.state.VerticleData}
            for="Vertical"
            htmlFor="verticle"
          />
        </Drawer>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              ).replace("-", " ")}
              urlPath={"/vertical-master"}
            />
            {/* ==========Vertical======= */}
            {this.state.loader == true ? (
              <>
                <Skeletonverticalmaster />
              </>
            ) : (
              <Row>
                <Col xl="12">
                  <Card className="overflow-hidden pagebackground">
                    <div className="Bechofy-bg-soft-pink">
                      <Row>
                        <Col xs="6">
                          <div className="Bechofy-text p-3">
                            <h5 className="Bechofy-text" id="upload-card">
                              {/* Vertical List [{this.state.count}] */}
                              {this.state.count == 0 ? (
                                "Vertical List"
                              ) : (
                                <>Vertical List [{this.state.count}]</>
                              )}
                            </h5>
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="Bechofy-text position-relative">
                            <h5
                              className="Bechofy-text sequ-btn-tooltip"
                              id="upload-card"
                            >
                              <a
                                onClick={this.toggleDrawer.bind(this)}
                                className="align-items-center mt-1 Bechofy-btn vertical-sequentialize-btn"
                                id="app-title"
                              >
                                <span>
                                  <img
                                    src={Sequentialize}
                                    alt="block user"
                                    className="btnicons"
                                    style={{ marginRight: "5px" }}
                                  ></img>{" "}
                                </span>
                                Sequentialize
                              </a>
                              <ReactTooltip
                                anchorId="app-title"
                                place="left"
                                content="Click here to change the sequence in which the data has to be displayed."
                              />
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Card style={{ padding: "10px 20px" }}>
                      <div className="row my-1 ">
                        <div className="col-12">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type the search phrase you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.SearchInput}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <CardBody className="pt-4">
                        <Row>
                          <Col xs="12" xl="4" lg="6" md="12">
                            <CardBody
                              className={
                                this.state.editfocos == true
                                  ? "categoryCard card-samesize-verticalmaster focuscardvertical vertical-card-focos pt-0"
                                  : "categoryCard card-samesize-verticalmaster pt-0 "
                              }
                            >
                              <Row className="card-row pt-4">
                                <Col sm="6">
                                  <div className="imagecard">
                                    <div className="form-group ">
                                      <label htmlFor="sw-arrows-first-name">
                                        Upload Vertical Image
                                        <span className="mandatory">*</span>
                                      </label>
                                      <div className="div1">
                                        <ImgUpload
                                          onChange={this.photoUpload}
                                          src={this.state.ImagePreviewUrl}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="row">
                                    <h5>Add New Vertical</h5>
                                    <div className="col-12">
                                      <label className="my-1 mr-2">
                                        Vertical Name*
                                      </label>
                                      <input
                                        className="form-control"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Vertical Name"
                                        value={this.state.VerticalName}
                                        onChange={(text) => {
                                          this.setState({
                                            VerticalName: text.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="col-12"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <div className="form-group">
                                        <label htmlFor="CategoryName">
                                          Status
                                          <span className="mandatory">*</span>
                                        </label>
                                        <br />
                                        <label className="radio-inline">
                                          <input
                                            type="radio"
                                            name="optradio"
                                            checked={
                                              this.state.Status == "Yes"
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState({
                                                Status: "Yes",
                                              });
                                            }}
                                          />{" "}
                                          Active
                                        </label>
                                        <label
                                          className="radio-inline"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <input
                                            type="radio"
                                            name="optradio"
                                            checked={
                                              this.state.Status == "No"
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState({
                                                Status: "No",
                                              });
                                            }}
                                          />{" "}
                                          Inactive
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="12">
                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    name="name"
                                    type="text"
                                    placeholder="Short Description"
                                    value={this.state.Description}
                                    onChange={(text) => {
                                      this.setState({
                                        Description: text.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              <hr className="mt-3 mb-1" />
                              <Row>
                                <Col
                                  sm="12"
                                  className="mb-0  closebtn-brandmanagment"
                                >
                                  <Row className="justify-content-end">
                                    <Col xs="5">
                                      <a
                                        onClick={this.onSaveData.bind(this)}
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm"
                                        style={{
                                          marginTop: "5px",
                                          color: "white",
                                          display:
                                            this.state.editfocos == false
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <span className="">
                                          <img
                                            src={Saveicon}
                                            alt="block user"
                                            className="btnicons"
                                          ></img>{" "}
                                        </span>
                                        Save Vertical
                                      </a>
                                    </Col>
                                  </Row>
                                  <div
                                    style={{
                                      display:
                                        this.state.editfocos == true
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <a
                                      onClick={this.onSaveData.bind(this)}
                                      className="btn Bechofy-btn waves-effect waves-light btn-sm"
                                      style={{
                                        marginTop: "5px",
                                        color: "white",
                                      }}
                                    >
                                      <span className="">
                                        <img
                                          src={Saveicon}
                                          alt="block user"
                                          className="btnicons"
                                        ></img>{" "}
                                      </span>
                                      Save Vertical
                                    </a>
                                    <div
                                      className="btn Bechofy-btn waves-effect waves-light btn-sm ml-3"
                                      style={{
                                        marginTop: "5px",
                                        color: "white",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          VerticalName: "",
                                          VerticleId: "",
                                          Status: null,
                                          editfocos: false,
                                          Description: "",
                                          ImagePreviewUrl:
                                            "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                        });
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={Closeicon}
                                          alt="block user"
                                          className="btnicons"
                                        ></img>{" "}
                                      </span>
                                      Close
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                          {/* ======Brand List */}
                          {this.state.VerticleData.filter((data) => {
                            if (this.state.SearchInput == "") {
                              return data;
                            }
                            if (
                              this.state.SearchInput !== "" &&
                              (data.fld_verticlename
                                .toLowerCase()
                                .includes(
                                  this.state.SearchInput.toLowerCase()
                                ) ||
                                `${data.fld_status
                                  ? data.fld_status.toLowerCase()
                                  : ""
                                  }`.includes(
                                    this.state.SearchInput.toLowerCase()
                                  ))
                            ) {
                              return data;
                            }
                          }).map((data, i) => (
                            <Col xs="12" xl="4" lg="6" md="12">
                              <CardBody className="categoryCard card-samesize-verticalmaster">
                                <Row className="card-row vertical-mastercard-border">
                                  <Col sm="12">
                                    <h6>
                                      Last Updated on
                                    </h6>
                                  </Col>
                                  <Col
                                    sm="5"
                                    xl="5"
                                    md="5"
                                    lg="6"
                                    style={{ marginTop: "15px" }}
                                  >
                                    <div className="imagecard">
                                      <div className="form-group custom-file-upload fas">
                                        <div className="div1 img-wrap brand-img-vertical-master">
                                          <img
                                            src={data.fld_verticalImage}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              borderRadius: "5%",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    sm="7"
                                    xl="7"
                                    md="7"
                                    lg="6"
                                    className="px-0"
                                  >
                                    <div className="row">
                                      <div
                                        className="col-12"
                                        style={{ marginTop: "10px" }}
                                      >
                                        <p>
                                          <b>Vertical Name</b> -{" "}
                                          <span>{data.fld_verticlename}</span>
                                        </p>
                                      </div>
                                      <div className="col-12">
                                        <p>
                                          <b>Total Items</b> -{" "}
                                          <span>{data.total_items}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    lg="12"
                                    className="px-3 card-description-vert-overflow card-content-overflow-subcategory"
                                  >
                                    <p>
                                      <b>Description</b> -{" "}
                                      <span>{data.fld_description}</span>
                                    </p>
                                  </Col>
                                </Row>
                                <hr className="mt-4 mb-1" />
                                <Row>
                                  <Col sm="6" className="BrandStatus">
                                    <p>
                                      <b>Status</b> -{" "}
                                      <span
                                        style={{
                                          color:
                                            data.fld_status == "Active"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {data.fld_status}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col
                                    sm="6"
                                    className="d-flex justify-content-end"
                                  >
                                    <span>
                                      <a
                                        onClick={() => {
                                          this.setState({
                                            VerticalName: data.fld_verticlename,
                                            VerticleId: data.fld_verticleid,
                                            Description: data.fld_description,
                                            Status:
                                              data.fld_status == "Active"
                                                ? "Yes"
                                                : "No",
                                            editfocos: true,
                                          });
                                          if (data.fld_verticalImage != null) {
                                            this.setState({
                                              ImagePreviewUrl:
                                                data.fld_verticalImage,
                                              VerticalLogo:
                                                data.fld_verticalImage,
                                            });
                                          } else {
                                            this.setState({
                                              ImagePreviewUrl:
                                                "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                            });
                                          }
                                          window.location.href = "#upload-card";
                                        }}
                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-left"
                                        style={{
                                          marginTop: "5px",
                                          color: "white",
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={Editicon}
                                            alt="block user"
                                            className="btnicons"
                                            style={{ marginRight: "5px" }}
                                          ></img>{" "}
                                        </span>
                                        Edit Vertical
                                      </a>
                                    </span>
                                    <span className="ml-1">
                                      <img
                                        src={deleteicon}
                                        alt="block user"
                                        className="btnicons float-right mt-2"
                                        onClick={() => {
                                          confirmAlert({
                                            title: "Confirm to Delete",
                                            message:
                                              "Are you sure you want to delete vertical.",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  var login =
                                                    localStorage.getItem(
                                                      "LoginDetail"
                                                    );
                                                  var details =
                                                    JSON.parse(login);
                                                  PostApiCall.postRequest(
                                                    {
                                                      verticleid:
                                                        data.fld_verticleid,
                                                      verticlename:
                                                        data.fld_verticlename,
                                                      status: "Inactive",
                                                      updatedby:
                                                        details[0].fld_userid,
                                                      createdby:
                                                        details[0].fld_userid,
                                                      verticalimage:
                                                        data.fld_verticalImage,
                                                      action: "DELETE",
                                                    },
                                                    "AddVerticleMaster"
                                                  ).then((resultcategory) =>
                                                    resultcategory
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          resultcategory.status ==
                                                          200 ||
                                                          resultcategory.status ==
                                                          201
                                                        ) {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Success(
                                                            "This Vertical successfully deleted."
                                                          );
                                                          this.getVertical()
                                                        } else {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Failure(
                                                            obj.data
                                                          );
                                                        }
                                                      })
                                                  );
                                                },
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });
                                        }}
                                      ></img>
                                    </span>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
          <div className={this.state.editfocos == true ? "fadePage" : ""}></div>
        </div>
      </React.Fragment>
    );
  }
}
export default verticleMater;
