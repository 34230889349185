import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import Warehouselisticon from "../../assets/images/inventory.png";
class WarehouseMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WarehouseName: "",
      WarehouseState: null,
      StateData: [],
      WarehouseData: [],
      PinCodeData: [],
      Pincodes: [],
      PincodesData: [],
      Status: "Active",
      MappingData: [],
      SearchField: null,
      WarehousePincodeData: [],
      searchInput: "",
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      results.json().then((objcon) => {
        if (results.status == 200 || results.status == 201) {
          if (objcon.data.length != 0) {
            var dt = [];
            dt.push({ label: "All", value: 0 });
            var newdat = [...dt, ...objcon.data];
            this.setState({
              StateData: newdat,
            });
            Notiflix.Loading.Remove();
          }
        }
      })
    );
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: `where fld_status='Active'`,
        recordCount: "fld_name as label,fld_warehouseid as value",
      },
      "GetWarehouseByState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              WarehouseData: obj.data,
            });
          }
          Notiflix.Loading.Remove();
        }
      })
    );
    // ========
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: "",
        RecordCount: "*",
      },
      "GetWarehousePincode"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            //
            this.setState({
              WarehousePincodeData: obj.data,
            });
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  onSaveData() {
    //
    if (this.state.WarehouseName != "") {
      if (this.state.WarehouseState != null) {
        if (this.state.Pincodes.length > 0 || this.state.WarehouseState == 0) {
          this.onPost();
        } else {
          Notiflix.Notify.Failure("Please select at least one Pincode");
        }
      } else {
        Notiflix.Notify.Failure("Please select state");
      }
    } else {
      Notiflix.Notify.Failure("Please select Warehouse Name");
    }
  }
  onPost = () => {
    // Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    var addpincode = "";
    for (var i = 0; i < this.state.Pincodes.length; i++) {
      if (i == 0) {
        addpincode = this.state.Pincodes[i].value;
      } else {
        addpincode = addpincode + ", " + this.state.Pincodes[i].value;
      }
    }
    PostApiCall.postRequest(
      {
        warehouseid: this.state.WarehouseName,
        stateid: this.state.WarehouseState,
        pincode: addpincode,
        active: this.state.Status,
        CreatedBy: details[0].fld_userid,
      },
      "add-warehouse-pincode"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(
            "warehouse is successfully Mapped with pincode"
          );
          window.location.reload();
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  getPincode = () => {
    PostApiCall.postRequest(
      {
        id: this.state.WarehouseState,
      },
      "GetPincodeByState"
    ).then((results3) => {
      // //
      results3.json().then((obj3) => {
        if (results3.status == 200 || results3.status == 201) {
          this.setState({
            PincodesData: obj3.data,
          });
          Notiflix.Loading.Remove();
        }
      });
    });
  };
  getPincodebyState = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_warehouseid=${this.state.WarehouseName} AND fld_stateid=${this.state.WarehouseState}`,
        RecordCount: "*",
      },
      "GetWarehousePincode"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            for (var i = 0; i < obj.data.length; i++) {
              var sp = [];
              for (var j = 0; j < obj.data[i].pincode.split(",").length; j++) {
                sp.push({
                  label: obj.data[i].pincode.split(",")[j],
                  value: obj.data[i].pincode.split(",")[j],
                });
              }
              this.setState({
                Pincodes: sp,
              });
            }
          }
        }
        Notiflix.Loading.Remove();
      })
    );
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Warehouse Name",
          field: "name",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Pincode",
          field: "pincode",
          sort: "disabled",
          width: 600,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.WarehousePincodeData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.pincode
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_name
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          name: data.fld_name,
          pincode: data.pincode,
          action: (
            <img
              src={Editicon}
              alt="Edit mapping"
              style={{ marginLeft: "2px" }}
              className="btnicons"
              onClick={() => {
                this.setState(
                  {
                    WarehouseName: data.fld_warehouseid,
                    WarehouseState: data.fld_stateid,
                  },
                  () => {
                    this.getPincode();
                    this.getPincodebyState();
                  }
                );
              }}
            ></img>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/warehouse-pincode"}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <div className="Bechofy-bg-soft-pink col-12">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text  p-3">
                          <h5 className="Bechofy-text">
                            Warehouse Mapping with Pincode
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0 usercarddata">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="CategoryName">
                                  Warehouse Name
                                  <span className="mandatory">*</span>
                                </label>
                                <select
                                  type="text"
                                  id="CategoryName"
                                  className="form-control"
                                  name="Name"
                                  value={this.state.WarehouseName}
                                  onChange={(text) => {
                                    this.setState({
                                      WarehouseName: text.target.value,
                                    });
                                  }}
                                >
                                  <option>Select Warehouse</option>
                                  {this.state.WarehouseData.map((warehouse) => (
                                    <option
                                      key={warehouse.value}
                                      value={warehouse.value}
                                    >
                                      {warehouse.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="CategoryName">
                                  State
                                  <span className="mandatory">*</span>
                                </label>
                                <select
                                  value={this.state.WarehouseState}
                                  onChange={(text) => {
                                    this.setState(
                                      {
                                        WarehouseState: text.target.value,
                                      },
                                      () => {
                                        this.getPincode();
                                        this.getPincodebyState();
                                      }
                                    );
                                  }}
                                  className="form-control"
                                >
                                  <option value="">Select State </option>
                                  {this.state.StateData.map((attri) => (
                                    <option
                                      key={attri.value}
                                      value={attri.value}
                                    >
                                      {attri.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{
                                display:
                                  this.state.WarehouseState == 0
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="CategoryName">
                                  Pincode
                                  <span className="mandatory">*</span>
                                </label>
                                <Select
                                  options={this.state.PincodesData}
                                  value={this.state.Pincodes}
                                  onChange={(as) => {
                                    this.setState({ Pincodes: as });
                                  }}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="py-1    my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group my-1 mr-sm-2">
                              <label htmlFor="CategoryName">Status</label>
                              <br />
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  name="optradio"
                                  //  disabled={!this.state.IsVisible}
                                  checked={
                                    this.state.Status == "Active" ? true : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Status: "Active",
                                    });
                                  }}
                                />{" "}
                                Active
                              </label>
                              <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                              >
                                <input
                                  type="radio"
                                  name="optradio"
                                  //  disabled={!this.state.IsVisible}
                                  checked={
                                    this.state.Status == "Inactive"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Status: "Inactive",
                                    });
                                  }}
                                />{" "}
                                Inactive
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            <button
                              style={{ float: "right", marginTop: "13px" }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={this.onSaveData.bind(this)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-4">
                        <div className="form-group">
                          <h4
                            className="text-white px-3"
                            style={{ marginTop: "10px" }}
                          >
                            {" "}
                            Warehouse Mapping with Pincode List
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="pt-0">
                    {/* {this.state.WarehousePincodeData.length != 0 ? ( */}
                    <Row>
                      <MDBCol
                        md="12"
                        style={{ marginBottom: "15px", marginTop: "15px" }}
                      >
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <span className="">
                                <img
                                  src={Searchicon}
                                  alt="block user"
                                  className="btnicons"
                                ></img>
                              </span>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type the search phrase you wish to search within the grid"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={(e) => this.seachBarHandler(e)}
                            value={this.state.searchInput}
                          />
                        </div>
                      </MDBCol>
                      <Col md="12">
                        <MDBDataTable
                          hover
                          // scrollY
                          striped
                          bordered
                          data={data}
                          seachTop={false}
                          entriesOptions={[25, 50, 100]}
                        >
                          <MDBTableHead columns={data.columns} />
                          <MDBTableBody rows={data.rows} />
                        </MDBDataTable>
                      </Col>
                    </Row>
                    {/* ) : (
                     <Row className="mt-4 pt-2">
                       <Col sm="12" className="text-center">
                         <img
                          src={Warehouselisticon}
                          alt="block user"
                          className="customerhistory-icons ms-3 mt-1"
                          style={{ marginRight: "5px" }}
                        />
                      </Col>
                      <Col sm="12" className="text-center my-3">
                        <h6>Stock list no found</h6>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default WarehouseMaster;
