import React, { Component } from "react";
import { CardBody, CardTitle, Container } from "reactstrap";
//import SideBar from "./SideBar";
import { MDBCol, MDBDataTableV5 } from 'mdbreact';
class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchInput: "",
        }
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        const data = {
            columns: [
                {
                    label: 'S.No.',
                    field: 'serial',
                    sort: 'disabled',
                    width: 120
                },
                {
                    label: 'Order Id',
                    field: 'orderid',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Product Name',
                    field: 'productname',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Customer Name',
                    field: 'customername',
                    sort: 'disabled',
                    width: 250
                },
                {
                    label: 'Transaction ID',
                    field: 'transactionid',
                    sort: 'disabled',
                    width: 250
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'disabled',
                    width: 250
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'disabled',
                    width: 250
                }
            ],
            rows: this.state.data.filter(data => {
                //
                if (this.state.searchInput.length == 0) {
                    return data
                }
            }).map((item, i) => {
                return {
                    serial: <td>1</td>,
                    orderid: <td>hjdjhbhjsd</td>,
                    productname: <td>hjdjhbhjsd</td>,
                    date: <td>hjdjhbhjsd</td>,
                    price: <td>hjdjhbhjsd</td>,
                    customername: <td>hjdjhbhjsd</td>,
                    transactionid: <td>hjdjhbhjsd</td>,
                    status: <td>hjdjhbhjsd</td>,
                    action: <td>
                        <button>Refund</button>
                    </td>
                }
            })
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid >
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-0">
                                        {/* <SideBar /> */}
                                    </div>
                                    <div className="col-lg-9 col-md-12">
                                        <CardBody>
                                            <CardTitle className="usertitle sidebar-heading my-4 card-title">
                                                Order History
                                            </CardTitle>
                                            <div className="table-responsive table dt-responsive nowrap card p-4">
                                                <MDBCol md="5">
                                                    <input
                                                        className="form-control mb-3"
                                                        type="text"
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                        onChange={(e) => this.setState({ searchInput: e.target.value })}
                                                        value={this.state.searchInput}
                                                        style={{ marginLeft: '-11px' }}
                                                    />
                                                </MDBCol>
                                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25, 50]} entries={5} pagesAmount={4} data={data} searchBottom={false} striped bordered />
                                            </div>
                                        </CardBody>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
export default OrderHistory;