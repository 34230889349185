import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import ProductsIcon from "../../assets/images/dashboardimages/brand-identity.png";
import UserIcon from "../../assets/images/dashboardimages/profile.png";
import OrdersIcon from "../../assets/images/dashboardimages/shopping-cart.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LatestCustomers from "./LatestCustomers";
import MonthlyEarning from "./MonthlyEarning";
import NewOrderList from "./NewOrderList";
import RefundList from "./NewRefundList";
import OrderSummary from "./OrderSummary";
import Refund from "./Refund";
import SalesSummary from "./SalesSummary";
import moment from "moment";
const SkeletonHomeCustomers = ({ cards }) => {
  return (
    <Row>
      <Col md="12" lg="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10 px-0">
                      <h4 className="mb-0">
                        <Skeleton className="mt-2" />
                      </h4>
                    </div>
                    <div
                      className="col-md-2"
                      style={{
                        justifyContent: "end",
                        display: "flex",
                        alignItems: " center",
                      }}
                    >
                      <div>
                        <Skeleton
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {Array(cards)
                    .fill(0)
                    .map((item) => (
                      <>
                        <div className="col-md-10 mb-2">
                          <h4 className="mb-0">
                            <Skeleton types={5} className="py-2 mt-1" />
                          </h4>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <Skeleton
                              style={{
                                height: "30px",
                                width: "50px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
      <Col md="12" lg="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10 px-0">
                      <h4 className="mb-0">
                        <Skeleton className="mt-2" />
                      </h4>
                    </div>
                    <div
                      className="col-md-2"
                      style={{
                        justifyContent: "end",
                        display: "flex",
                        alignItems: " center",
                      }}
                    >
                      <div>
                        <Skeleton
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {Array(cards)
                    .fill(0)
                    .map((item) => (
                      <>
                        <div className="col-md-10 mb-2">
                          <h4 className="mb-0">
                            <Skeleton types={5} className="py-2 mt-1" />
                          </h4>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <Skeleton
                              style={{
                                height: "30px",
                                width: "50px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
      <Col md="12" lg="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10 px-0">
                      <h4 className="mb-0">
                        <Skeleton className="mt-2" />
                      </h4>
                    </div>
                    <div
                      className="col-md-2"
                      style={{
                        justifyContent: "end",
                        display: "flex",
                        alignItems: " center",
                      }}
                    >
                      <div>
                        <Skeleton
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {Array(cards)
                    .fill(0)
                    .map((item) => (
                      <>
                        <div className="col-md-10 mb-2">
                          <h4 className="mb-0">
                            <Skeleton types={5} className="py-2 mt-1" />
                          </h4>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <Skeleton
                              style={{
                                height: "30px",
                                width: "50px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      TablesRecords: [],
      OrdersCount: [],
      TotalSales: [],
      CustomerData: [],
      OrderData: [],
      ProductData: [],
      loader: true
    };
    this.togglemodal.bind(this);
  }
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  componentDidMount() {
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "Get_DasboradCustomerCard"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            loader: false,
            CustomerData: obj.data,
          });
        }
      })
    );
    // ====ProductCard====
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "Get_DasboradProductCard"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // //
          this.setState({
            ProductData: obj1.data,
          });
        }
      })
    );
    // ====OrderCard====
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "Get_DasboradOrderCard"
    ).then((results) =>
      results.json().then((obj2) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            OrderData: obj2.data,
          });
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} urlPath={"/dashboard"} />
            <Row>
              <Col xl="12">
                {this.state.loader == true ? (
                  <>
                    <SkeletonHomeCustomers cards={5} />
                  </>
                ) : (
                  <Row>
                    <Col md="12" lg="4">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-8">
                                    <h4 className="mb-0 productTitle">
                                      Customers
                                    </h4>
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      src={UserIcon}
                                      alt="customer"
                                      className="ProductIcons"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Customers
                                {this.state.CustomerData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Total_Customer}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Today's New Customers
                                {this.state.CustomerData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Today_Customer}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                New Customers this Month
                                {this.state.CustomerData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.This_month_Customer}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Ordered Today
                                {this.state.CustomerData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Today_Customer_Order}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Customers not Ordered
                                {this.state.CustomerData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Customer_Not_Ordered}
                                  </span>
                                ))}
                              </p>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* =========Orders======== */}
                    <Col md="12" lg="4">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-8">
                                    <h4 className="mb-0 productTitle">
                                      Orders
                                    </h4>
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      src={OrdersIcon}
                                      alt="customer"
                                      className="ProductIcons"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Orders
                                {this.state.OrderData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Total_order}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                New Orders Today
                                {this.state.OrderData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Today_Order}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Orders this Week
                                {this.state.OrderData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.order_this_week}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Orders this Month
                                {this.state.OrderData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.order_this_month}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Orders this Year
                                {this.state.OrderData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.order_this_year}
                                  </span>
                                ))}
                              </p>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* ======Products========= */}
                    <Col md="12" lg="4">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-8">
                                    <h4 className="mb-0 productTitle">
                                      Products
                                    </h4>
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      src={ProductsIcon}
                                      alt="customer"
                                      className="ProductIcons"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Brands
                                <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                  {this.state.ProductData.map((data, i) => (
                                    <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                      {data.Total_brand}
                                    </span>
                                  ))}
                                </span>
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Verticals
                                {this.state.ProductData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Today_Vertical}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Categories
                                {this.state.ProductData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Total_Categories}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Sub Categories
                                {this.state.ProductData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Today_sub_Categories}
                                  </span>
                                ))}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Items Variants
                                {this.state.ProductData.map((data, i) => (
                                  <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                                    {data.Total_Variants}
                                  </span>
                                ))}
                              </p>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg="4">
                    <MonthlyEarning />
                  </Col>
                  <Col lg="8">
                    <SalesSummary />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <OrderSummary />
              </Col>
              <Col xl="8">
                <NewOrderList />
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Refund />
              </Col>
              <Col xl="8">
                <RefundList />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <LatestCustomers />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Dashboard);
