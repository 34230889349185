import { AvForm } from "availity-reactstrap-validation";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
class RiderResigtrationForm extends Component {
	state = {
		fname: {
			value: "",
			error: false,
			errormassege: "",
		},
		mname: {
			value: "",
			error: false,
			errormassege: "",
		},
		lname: {
			value: "",
			error: false,
			errormassege: "",
		},
		cAddress: {
			value: "",
			error: false,
			errormassege: "",
		},
		pAddress: {
			value: "",
			error: false,
			errormassege: "",
		},
		email: {
			value: "",
			error: false,
			errormassege: "",
		},
		MobileNumber: {
			value: "",
			error: false,
			errormassege: "",
		},
		city: {
			value: "",
			error: false,
			errormassege: "",
		},
		state: {
			value: "",
			error: false,
			errormassege: "",
		},
		pin: {
			value: "",
			error: false,
			errormassege: "",
		},
		adharNum: {
			value: "",
			error: false,
			errormassege: "",
		},
		landmark: {
			value: "",
			error: false,
			errormassege: "",
		},
	};
	changeHandler = (name) => (event) => {
		let massege = "";
		if (name == "MobileNumber") {
			massege = "Mobile Number";
		} else if (name == "adharNum") {
			massege = "Mobile Number";
		} else if (name == "cAddress") {
			massege = "Current Address";
		} else if (name == "pAddress") {
			massege = "Present Address";
		} else if (name == " ") {
			massege = "Present Address";
		} else if (name == "pAddress") {
			massege = "Present Address";
		} else if (name == "pAddress") {
			massege = "Present Address";
		} else {
			massege = "";
		}
		if (event.target.value.length == 0) {
			this.setState({
				...this.state,
				[name]: {
					error: true,
					errormassege: `${massege != "" ? massege : name} must not be empty`,
				},
			});
		} else {
			this.setState({
				...this.state,
				[name]: {
					error: false,
					errormassege: "",
					value: event.target.value,
				},
			});
		}
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													bike
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Rider Registration Form
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="p-2">
													<AvForm
														className="form-horizontal"
														onValidSubmit={this.handleValidSubmit}
													>
														<div className="form-row">
															<div className="col-4">
																<label htmlFor="fname">First Name:</label>
																<input
																	value={this.state.fname.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("fname")}
																	id="fname"
																	placeholder="Enter first name"
																	name="fname"
																/>
																{this.state.fname.error && (
																	<div className="is-invalid text-danger">
																		{this.state.fname.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="mname">Middle Name:</label>
																<input
																	value={this.state.mname.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("mname")}
																	id="mname"
																	placeholder="Enter middle name"
																	name="mname"
																/>
																{this.state.mname.error && (
																	<div className="is-invalid text-danger">
																		{this.state.email.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="lname">Last Name:</label>
																<input
																	value={this.state.lname.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("lname")}
																	id="lname"
																	placeholder="Enter last name"
																	name="lname"
																/>
																{this.state.lname.error && (
																	<div className="is-invalid text-danger">
																		{this.state.lname.errormassege}
																	</div>
																)}
															</div>
														</div>
														<div className="form-row">
															<div className="col-6">
																<label htmlFor="cAddress">Current Address</label>
																<textarea
																	value={this.state.cAddress.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("cAddress")}
																	placeholder="Enter Current"
																	name="cAddress"
																/>
																{this.state.cAddress.error && (
																	<div className="is-invalid text-danger">
																		{this.state.cAddress.errormassege}
																	</div>
																)}
															</div>
															<div className="col-6">
																<label htmlFor="pAddress">Permanent Address</label>
																<textarea
																	value={this.state.pAddress.value}
																	type="number"
																	className="form-control"
																	onChange={this.changeHandler("pAddress")}
																	placeholder="Enter mobile numer"
																	name="pAddress"
																/>
																{this.state.pAddress.error && (
																	<div className="is-invalid text-danger">
																		{this.state.pAddress.errormassege}
																	</div>
																)}
															</div>
														</div>
														<div className="form-row">
															<div className="col-4">
																<label htmlFor="email">Email</label>
																<input
																	value={this.state.email.value}
																	type="email"
																	className="form-control"
																	onChange={this.changeHandler("email")}
																	id="email"
																	placeholder="Enter email name"
																	name="email"
																/>
																{this.state.email.error && (
																	<div className="is-invalid text-danger">
																		{this.state.email.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="MobileNumber">Mobile Number:</label>
																<input
																	value={this.state.MobileNumber.value}
																	type="number"
																	className="form-control"
																	onChange={this.changeHandler("MobileNumber")}
																	id="Mname"
																	placeholder="Enter middle name"
																	name="MobileNumber"
																/>
																{this.state.MobileNumber.error && (
																	<div className="is-invalid text-danger">
																		{this.state.MobileNumber.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="city">City</label>
																<input
																	value={this.state.city.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("city")}
																	id="city"
																	placeholder="Enter City name"
																	name="city"
																/>
																{this.state.city.error && (
																	<div className="is-invalid text-danger">
																		{this.state.city.errormassege}
																	</div>
																)}
															</div>
														</div>
														<div className="form-row">
															<div className="col-4">
																<label htmlFor="state">State</label>
																<input
																	value={this.state.state.value}
																	type="state"
																	className="form-control"
																	onChange={this.changeHandler("state")}
																	id="state"
																	placeholder="Enter State name"
																	name="state"
																/>
																{this.state.state.error && (
																	<div className="is-invalid text-danger">
																		{this.state.city.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="pin">Pincode</label>
																<input
																	value={this.state.pin.value}
																	type="number"
																	className="form-control"
																	onChange={this.changeHandler("pin")}
																	id="pin"
																	placeholder="Enter  Pincode"
																	name="pin"
																/>
																{this.state.pin.error && (
																	<div className="is-invalid text-danger">
																		{this.state.pin.errormassege}
																	</div>
																)}
															</div>
															<div className="col-4">
																<label htmlFor="adharNum">AdhaarCard Number</label>
																<input
																	value={this.state.adharNum.value}
																	type="number"
																	className="form-control"
																	onChange={this.changeHandler("adharNum")}
																	id="adharNum"
																	placeholder="Enter AdhaarCard Number"
																	name="adharNum"
																/>
																{this.state.adharNum.error && (
																	<div className="is-invalid text-danger">
																		{this.state.adharNum.errormassege}
																	</div>
																)}
															</div>
															<div className="col-6 ">
																<label htmlFor="landmark">Landmark</label>
																<textarea
																	value={this.state.landmark.value}
																	type="text"
																	className="form-control"
																	onChange={this.changeHandler("landmark")}
																	id="landmark"
																	placeholder="Enter Landmark name"
																	name="landmark"
																/>
																{this.state.landmark.error && (
																	<div className="is-invalid text-danger">
																		{this.state.landmark.errormassege}
																	</div>
																)}
															</div>
														</div>
													</AvForm>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RiderResigtrationForm;
