import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import PostApiCall from "../../Api";
import moment from "moment";
import Notiflix from "notiflix";
class ImpersonateDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

    }


    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}
export default withNamespaces()(ImpersonateDashboard);
