import Notiflix from "notiflix";
import React from "react";
import { CardBody, CardTitle, Container } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
//import SideBar from "./SideBar";
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      Name: "",
      Mobile: "",
      FlatNo: "",
      Area: "",
      Landmark: "",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: "",
      State: "",
      City: "",
      Pincode: "",
    }
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    GetApiCall.getRequest("GetCountry").then(resultdes =>
      resultdes.json().then(obj => {
        this.setState({
          CountryData: obj.data,
        })
        if (obj.data.length != 0) {
          this.setState({
            CountryId: 101,
            Country: obj.data[102].value
          })
        }
        PostApiCall.postRequest({
          countryid: obj.data[102].value,
        }, "GetState").then((results) =>
          // const objs = JSON.parse(result._bodyText)
          results.json().then(objstate => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                // this.props.setstate()
                this.setState({
                  StateId: objstate.data[0].value,
                  StateData: objstate.data,
                  State: objstate.data[0].value
                })
              }
              PostApiCall.postRequest({
                stateid: objstate.data[0].value,
              }, "GetCity").then((resultscity) =>
                resultscity.json().then(objcity => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      // this.props.setcity(objcity.data[0].label)
                      this.setState({
                        CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        City: objcity.data[0].value
                      })
                      Notiflix.Loading.Remove()
                    }
                  }
                }))
            }
          }))
      })
    );
  }
  onChangeCountry(country) {
    this.setState({
      CountryId: country.target.value,
      Country: this.state.CountryData[parseInt(country.target.value) + 1].value
    })
    Notiflix.Loading.Dots('Please wait...');
    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country: this.state.CountryData[parseInt(country.target.value) + 1].value
      },
      "GetState"
    ).then(results =>
      results.json().then(obj => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            StateId: obj.data[0].value,
            State: obj.data[0].value
          })
          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value
            },
            "GetCity"
          ).then(resultscity =>
            resultscity.json().then(objcity => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data
                })
                if (objcity.data.length > 0) {
                  this.setState({
                    CityId: objcity.data[0].value,
                    City: objcity.data[0].value
                  })
                }
                Notiflix.Loading.Remove()
              }
            })
          );
        }
      })
    );
  }
  onChangeState(state) {
    this.setState({
      StateId: state.target.value
    })
    Notiflix.Loading.Dots('Please wait...');
    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
      if (this.state.StateData[i].value == state.target.value) {
        this.setState({
          State: this.state.StateData[i].value
        })
      }
    }
    PostApiCall.postRequest(
      {
        stateid: state.target.value
      },
      "GetCity"
    ).then(results =>
      results.json().then(obj => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              City: obj.data[0].value
            })
          }
          Notiflix.Loading.Remove()
          // this.props.cityData(obj.data)
        }
      })
    );
  }
  onChangeCity(city) {
    // this.props.setcity(city.target.value)
    this.setState({
      CityId: city.target.value
    })
    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          City: this.state.CityData[i].value
        })
        // this.props.setcity(this.state.CityData[i].label);
      }
    }
  }
  onSaveData() {
    if (this.state.Title != "") {
      if (this.state.Name != "") {
        if (this.state.Mobile != "") {
          if (this.state.Mobile.length == 10) {
            if (this.state.FlatNo != "") {
              if (this.state.Area != "") {
                if (this.state.Country != "") {
                  if (this.state.State != "") {
                    if (this.state.City != "") {
                      if (this.state.Pincode != "") {
                        if (this.state.Pincode.length == 6) {
                          // this.onPost()
                        } else {
                          Notiflix.Notify.Failure(
                            "Please enter valid pincode"
                          );
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter pincode");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter city");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter state");
                  }
                } else {
                  Notiflix.Notify.Failure("Please select country");
                }
              } else {
                Notiflix.Notify.Failure("Please enter Area/Colony/Street/Sector/Village");
              }
            } else {
              Notiflix.Notify.Failure("Please enter Flat/House/Company/Building/Apartment No.");
            }
          } else {
            Notiflix.Notify.Failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.Failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your Full Name");
      }
    } else {
      Notiflix.Notify.Failure("Please select Address Title");
    }
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid style={{ backgroundColor: "white" }}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-0">
                    {/* <SideBar /> */}
                  </div>
                  <div className="col-lg-9 col-md-12">
                    <CardBody>
                      <CardTitle className="usertitle sidebar-heading my-4">
                        Add or Edit Address
                      </CardTitle>
                      <form>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 center">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Address Title<span className="mandatory">*</span>
                                </label>
                                <br />
                                <label className="radio-inline">
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={this.state.Title === "Office" ? true : false}
                                    onChange={() => {
                                      this.setState({
                                        Title: "Office",
                                      });
                                    }}
                                  />{" "}
                                  Office
                                </label>
                                <label
                                  className="radio-inline"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      this.state.Title === "Home" ? true : false
                                    }
                                    onChange={() => {
                                      this.setState({
                                        Title: "Home",
                                      });
                                    }}
                                  />{" "}
                                  Home
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Full Name<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.Name}
                                  onChange={(text) => {
                                    this.setState({
                                      Name: text.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Mobile Number<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="CategoryName"
                                  className="form-control"
                                  value={this.state.Mobile}
                                  onChange={(mobile) => {
                                    if (
                                      this.state.NumRegex.test(
                                        mobile.target.value
                                      ) &&
                                      mobile.target.value
                                        .length <= 10
                                    ) {
                                      this.setState({
                                        Mobile:
                                          mobile.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 md-12">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Flat/House/Building No.<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.FlatNo}
                                  onChange={(text) => {
                                    this.setState({
                                      FlatNo: text.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 md-12">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Area/Street/Sector/Village<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.Area}
                                  onChange={(text) => {
                                    this.setState({
                                      Area: text.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 md-12">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Landmark<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.Landmark}
                                  onChange={(text) => {
                                    this.setState({
                                      Landmark: text.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Country<span className="mandatory">*</span>
                                </label>
                                <select
                                  className="custom-select my-1 mr-sm-2"
                                  id="inlineFormCustomSelectPref"
                                  name="UserType"
                                  value={this.state.CountryId}
                                  onChange={this.onChangeCountry.bind(
                                    this
                                  )}
                                >
                                  {this.state.CountryData.map(
                                    (schedule) => (
                                      <option
                                        key={schedule.label}
                                        value={schedule.value}
                                      >
                                        {schedule.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  State<span className="mandatory">*</span>
                                </label>
                                <select
                                  className="custom-select my-1 mr-sm-2"
                                  id="inlineFormCustomSelectPref"
                                  name="UserType"
                                  value={this.state.StateId}
                                  onChange={this.onChangeState.bind(
                                    this
                                  )}
                                >
                                  {this.state.StateData.map(
                                    (schedule) => (
                                      <option
                                        key={schedule.label}
                                        value={schedule.value}
                                      >
                                        {schedule.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  City<span className="mandatory">*</span>
                                </label>
                                <select
                                  className="custom-select my-1 mr-sm-2"
                                  id="inlineFormCustomSelectPref"
                                  name="UserType"
                                  value={this.state.CityId}
                                  onChange={this.onChangeCity.bind(
                                    this
                                  )}
                                >
                                  {this.state.CityData.map(
                                    (schedule) => (
                                      <option
                                        key={schedule.label}
                                        value={schedule.value}
                                      >
                                        {schedule.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label htmlFor="validationCustom01">
                                  Pincode<span className="mandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="CategoryName"
                                  className="form-control my-1 mr-sm-2"
                                  value={this.state.Pincode}
                                  onChange={(pincode) => {
                                    if (
                                      this.state.NumRegex.test(
                                        pincode.target.value
                                      ) &&
                                      pincode.target.value
                                        .length <= 6
                                    ) {
                                      this.setState({
                                        Pincode:
                                          pincode.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <button
                                style={{ float: "right", marginTop: "5px" }}
                                className="btn align-items-center Bechofy-btn "
                                onClick={this.onSaveData.bind(this)}
                              >
                                Save Address  {"  "}{" "}
                                <i className="fa fa-bookmark" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </CardBody>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Form;