import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import GtLogo from "../../assets/images/bechofylogo.png";
class VendorList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			CompanyData: [],
			Id: "",
			searchInput: "",
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		PostApiCall.postRequest(
			{
				whereClause: '',
			},
			"GetCompanyList"
		).then((results) => {
			if (results.status == 200 || results.status == 201) {
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						if (obj.data.length != 0) {
							//
							this.setState({
								CompanyData: obj.data,
							});
						}
						Notiflix.Loading.Remove();
					}
				})
			}
		})
	}
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Company Logo",
					field: "logo",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Company Name",
					field: "name",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Contact Email",
					field: "email",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Contact Phone",
					field: "phone",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Country",
					field: "country",
					sort: "disabled",
					width: 200,
				},
				{
					label: "State",
					field: "state",
					sort: "disabled",
					width: 200,
				},
				{
					label: "City",
					field: "city",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Pincode",
					field: "pincode",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Manufactured By",
					field: "manufactured_by",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Marketing By",
					field: "marketing_by",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Vendored By",
					field: "vendored_by",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.CompanyData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_city_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_country_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_state_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: (i + 1),
					logo: (<img src={data.fld_image} alt="company logo" style={{ width: '60%' }}></img>),
					name: (data.fld_name),
					email: (data.fld_email),
					phone: (data.fld_contact_phone),
					country: (data.fld_country_name),
					state: (data.fld_state_name),
					city: (data.fld_city_name),
					pincode: (data.fld_pincode),
					option_selected: (data.fld_about),
					manufactured_by: (data.fld_Manufacturer),
					marketing_by: (data.fld_marketer),
					vendored_by: (data.fld_vendor),
					action: (
						<i
							className="fas fa-edit btn"
							style={{ fontSize: "15px", marginTop: "-11px" }}
							onClick={() => {
								localStorage.setItem("companyId", JSON.stringify(data.fld_company_id));
								window.location.href = "/add-company";
							}}
						></i>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">
								<div>
									<ul className="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>Master Management</li>
										<li>Company Master List</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
							</div>
						</div>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink" style={{ height: '75px' }}>
													<div className="row my-1">
														<div className="col-2">
															<div className="form-group">
																<h4 className="FilterCard" style={{ marginTop: '25px' }}>Company List</h4>
															</div>
														</div>
													</div>
												</div>
												<Card className="overflow-hidden">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text  p-3">
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) => this.seachBarHandler(e)}
																	value={this.state.searchInput}
																/>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text  p-3">
																<button className="btn align-items-center btn Bechofy-btn"
																	style={{ float: 'right' }}
																	onClick={() => {
																		window.location.href = '/add-company'
																	}}
																>
																	Add New Company
																</button>
															</div>
														</Col>
													</Row>
													<CardBody className="pt-0">
														<Row>
															<MDBCol md="6" style={{ marginBottom: "10px" }}>
															</MDBCol>
															<Col md="12">
																<MDBDataTable
																	hover
																	scrollY
																	striped
																	bordered
																	data={data}
																	seachTop={false}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTable>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default VendorList;
