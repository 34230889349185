import imageCompression from "browser-image-compression";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
    Card,
    CardBody,
    CardTitle, Col, Container,
    Row
} from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import GtLogo from "../../assets/images/bechofylogo.png";
const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                htmlFor="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*,video/mp4" id="photo-upload" type="file" onChange={onChange} />
    </label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
class AddImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImageValid: null,
            imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            imagedata: [],
            VariantImageID: null,
            VariantName: [],
            VariantData: [],
            Photos: [],
            VariantID: null,
            VariantImage: null,
            ProductVariantId: null,
            RandomNumber: randomnumber
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        // Notiflix.Loading.Dots("Please wait...");

        if (localStorage.getItem('VariantImageID') != null) {
            PostApiCall.postRequest(
                {
                    whereClause: `where fld_variantimageid=${JSON.parse(localStorage.getItem('VariantImageID'))}`
                },
                "Get_VariantImage"
            ).then((results8) =>
                results8.json().then((obj8) => {

                    if (
                        results8.status == 200 ||
                        results8.status == 201
                    ) {
                        if (obj8.data.length != 0) {
                            this.setState({
                                imagePreviewUrl: obj8.data[0].fld_imageurl,
                                VariantImageID: obj8.data[0].fld_variantimageid,
                                Photos: obj8.data,
                                VariantImage: obj8.data[0].fld_imageurl,
                                VariantID: obj8.data[0].fld_variantid,
                                ProductVariantId: null
                            })
                        }
                    }
                }))
        }
        if (localStorage.getItem('VariantIdForImage') != null) {
            var VariantData = JSON.parse(localStorage.getItem('VariantIdForImage'))
            this.setState({
                VariantID: VariantData.fld_variantid,
                VariantName: VariantData.fld_variantname,
                ProductVariantId: VariantData.fld_variantid,
            })
            PostApiCall.postRequest(
                {
                    whereClause: `where fld_variantid=${VariantData.fld_variantid}`
                },
                "Get_VariantImage"
            ).then((results8) =>
                results8.json().then((obj8) => {
                    if (
                        results8.status == 200 ||
                        results8.status == 201
                    ) {
                        this.setState({
                            Photos: obj8.data
                        })
                    }
                }))
        }
    }
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        //
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };
    onSaveVariant = () => {
        if (this.state.imagePreviewUrl != 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png') {
            this.onAddImage();
        } else {
            Notiflix.Notify.Failure("Please upload Variant image");
        }
    }
    onAddImage = () => {
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        if (this.state.VariantImage != this.state.imagePreviewUrl) {
            if (this.state.outputFileName != undefined) {
                //
                PostApiCall.postRequest(
                    {
                        variantimageid: this.state.VariantImageID,
                        variantid: this.state.VariantID,
                        imageurl:
                            JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
                            this.state.RandomNumber + "-" + this.state.outputFileName.split('.').slice(0, -1).join('.') + '.webp',
                        createdOn: moment().format("YYYY-MM-DD"),
                        updatedOn: moment().format("YYYY-MM-DD"),
                        updatedBy: details[0].fld_userid,
                    },
                    "AddVariantImage"
                ).then((results1) =>
                    results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {
                            localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Image Uploading in Process");
                            this.onUploadImage();
                            // window.location.reload();
                        }
                        else {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Failure(obj1);
                        }
                    })
                );
            }
            else {
            }
        }
        else {
            this.onUpdateVariantImage()
        }
    }
    onUploadImage = () => {
        Notiflix.Loading.Dots("Please wait...");
        let response;
        if (this.state.outputFileName != undefined) {
            var options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {
                    // //
                    const form = new FormData();
                    form.append("file", compressedFile);
                    form.append("foldername", "ProductImages");
                    form.append(
                        "filename", this.state.RandomNumber +
                        "-" + compressedFile.name
                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                        headers: {
                            "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
                            "x-auth-token": JSON.parse(localStorage.getItem("access")),
                            "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
                        }
                    }).then(response => response.json())
                        .then(data => {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Variant image is successfully added");
                            window.location.reload();
                        });
                })
        } else {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure("Please Upload Variant Image");
        }
    }
    onUpdateVariantImage = () => {
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        PostApiCall.postRequest(
            {
                variantimageid: this.state.VariantImageID,
                variantid: this.state.VariantID,
                imageurl: this.state.imagePreviewUrl,
                createdOn: moment().format("YYYY-MM-DD"),
                updatedOn: moment().format("YYYY-MM-DD"),
                updatedBy: details[0].fld_userid,
            },
            "AddVariantImage"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    // //
                    localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("Variant image is successfully added.");
                    window.location.reload();
                }
                else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        // //
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 800,
            autoplay: true,
            slidesToShow: 4,
            slidesToScroll: 1,
        };
        let progessed = 0
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <ul className="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Variant List</a></li>
                                        <li><a href='/add-new-variant'>Add New Variant</a></li>
                                        <li>Add New Variant Image</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
                            </div>
                        </div>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <div
                                        className="Bechofy-bg-soft-pink" >
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        Upload Product Image
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <h5>Variant Name: {this.state.VariantName}</h5>
                                                <div className="row" style={{ marginTop: '1%' }}>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="validationCustom01"
                                                                className="productimg"
                                                            >
                                                                Upload Product Image
                                                            </label>
                                                            <div className=" div1 row">
                                                                <ImgUpload
                                                                    onChange={this.photoUpload}
                                                                    src={this.state.imagePreviewUrl}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card style={{ display: this.state.ProductVariantId == null ? 'none' : 'block' }}>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Variant Images
                                        </CardTitle>
                                        <div className="row">
                                            {this.state.Photos.map((data, i) => (
                                                <div className="col-md-3">
                                                    <div className="best-products">
                                                        <div className="product-item">
                                                            <div className="img-container"
                                                            //  width="50%"
                                                            >
                                                                <img src={data.fld_imageurl} className="img-fluid" alt="product image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-6">
                                                    </div>
                                                    <div className="col-6">
                                                        <button
                                                            style={{
                                                                float: "right",
                                                                marginTop: "5px",
                                                            }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveVariant.bind(this)}
                                                        >
                                                            Save & Add More
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* -row */}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddImage;
