import CKEditor from "ckeditor4-react";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { TiDeleteOutline } from "react-icons/ti";
import {
  Card,
  CardBody, Col, Container,
  Row
} from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import ImageUploadIcon from "../../assets/images/Upload.jpg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      {src.split(/[#?]/)[0].split('.').pop().trim() !== 'mp4' ?
        <img
          htmlFor="photo-upload"
          src={src}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
        /> :
        <video htmlFor="photo-upload"
          src={src} autoPlay loop autoFocus autoCorrect='true' muted style={{ width: "100%", height: "100%", borderRadius: "5%" }} />
      }
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
const VideoUpload = ({ onChange, src }) => (
  <label htmlFor="video-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      {src.split(/[#?]/)[0].split('.').pop().trim() !== 'mp4' ?
        <img
          htmlFor="video-upload"
          src={src}
          style={{ width: "100%", height: "100%", borderRadius: "5%" }}
        /> :
        <video htmlFor="video-upload"
          src={src} autoPlay loop autoFocus autoCorrect='true' muted style={{ width: "100%", height: "100%", borderRadius: "5%" }} />
      }
    </div>
    <input accept="video/mp4" id="video-upload" type="file" onChange={onChange} />
  </label>
);
const ImgUploadNew = ({ onChange, src }) => (
  <label htmlFor="photo-upload-new" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload-new"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload-new" type="file" onChange={onChange} />
  </label>
);
const VideoUploadNew = ({ onChange, src }) => (
  <label htmlFor="video-upload-new" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="video-upload-new"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="video/mp4" id="video-upload-new" type="file" onChange={onChange} />
  </label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
class AddVariant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      startDate: new Date(),
      endDate: new Date(),
      imageValue: "",
      isImageValid: null,
      Photos: [],
      Videos: [],
      CategoryName: "",
      CategoryData: [],
      SubCatgeoryData: [],
      SubCategory: "",
      AttributData: [],
      ItemName: "",
      HSNCode: "",
      GSTRate: 0.00,
      GSTData: [
        { value: "5", label: "5" },
        { value: "10", label: "10" },
        { value: "12", label: "12" },
        { value: "15", label: "15" },
        { value: "18", label: "18" },
      ],
      UOM: "",
      Status: "Yes",
      SKU: "",
      Barcode: "",
      VariantName: "",
      ShortDescription: "",
      LongDescription: "",
      AttributeValue: [],
      StockInHand: 0,
      CostPrice: 0,
      MRP: 0,
      SellingPrice: 0,
      Websitediscountprice: 0,
      ItemStatus: "Active",
      ProductID: "",
      PRoductData: [],
      SaveVariantData: [],
      FormStatus: "",
      fields: "",
      finaldata: "",
      name: "",
      groupArray: [],
      AttributeMappingId: null,
      VariantimageId: null,
      VariantPriceId: null,
      VariantID: null,
      Variantsdata: "",
      ItemData: [],
      VariantIdForImage: null,
      VariantDataForimage: [],
      ShippingCharge: 0,
      ImageUrl: "",
      DisplayAttribute: "",
      DisplayVariant: "",
      DisplayPrice: "",
      DisplayImages: "",
      DisableItemName: false,
      VariantData: [],
      AttributeValuesEdit: "",
      VariantImageID: null,
      outputFileName: "",
      imagePreviewUrl: ImageUploadIcon,
      ImageApiUrl: 'https://api.bechofy.in/AddImage',
      originalImage: "",
      RandomNumber: randomnumber,
      PriceState: 0,
      StateData: [],
      GST: 0,
      BasePrice: 0,
      ShippingChargeGST: 0,
      BaseShippingCharge: 0,
      MinQty: 0,
      MaxQty: 0,
      USDPrice: 0,
      ItemTypeData: [],
      ItemTypeSelected: "",
      IsCustomizable: ""
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    GetApiCall.getRequest("GetItemType").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          ItemTypeData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );

    this.setState({
      VariantID: this.props.sVariantId,
      AttributeValuesEdit: this.props.sAttributeValues,
      ProductID: this.props.sProductId
    })
    if (this.props.sProductId != "") {
      PostApiCall.postRequest(
        {
          whereClause: `where fld_productid=${this.props.sProductId}`,
          recordCount: "*",
        },
        "GetProductMaster"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {

            this.setState({
              ProductData: obj.data,
            });
            if (obj.data.length != 0) {
              this.setState({
                ItemName: obj.data[0].fld_itemname,
              });
              Notiflix.Loading.Dots("Please wait...");
              PostApiCall.postRequest(
                {
                  categoryid: obj.data[0].fld_categoryid,
                },
                "Get_subcategoryDropdown"
              ).then((results1) =>
                results1.json().then((obj1) => {
                  if (results1.status == 200 || results1.status == 201) {
                    this.setState({
                      SubCatgeoryData: obj1.data,
                    });
                    Notiflix.Loading.Remove();
                  }
                })
              );
            }
          }
        }
        )
      )
    }
    if (this.props.sfor === 'variant') {
      this.setState({
        DisplayAttribute: "d-none",
        DisplayVariant: "",
        DisplayPrice: "d-none",
        DisplayImages: "d-none",
        DisableItemName: true
      })
    }
    else if (this.props.sfor === 'attribute') {
      this.setState({
        DisplayAttribute: "",
        DisplayVariant: "d-none",
        DisplayPrice: "d-none",
        DisplayImages: "d-none",
        DisableItemName: true
      })
    } else if (this.props.sfor === 'price') {
      this.setState({
        DisplayAttribute: "d-none",
        DisplayVariant: "d-none",
        DisplayPrice: "",
        DisplayImages: "d-none",
        DisableItemName: true
      })
    } else if (this.props.sfor === 'image') {
      this.setState({
        DisplayAttribute: "d-none",
        DisplayVariant: "d-none",
        DisplayPrice: "d-none",
        DisplayImages: "",
        DisableItemName: true
      })
    }
    localStorage.removeItem("VariantImageID")
    localStorage.removeItem("VariantIdForImage")
    GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          ItemData: obj.data,
        });
      })
    );
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            this.setState({
              StateData: objstate.data,
            });
          }
          PostApiCall.postRequest(
            {
              whereClause: `where fld_status='Active' AND fld_state=${objstate.data[0].value}`,
              recordCount: "fld_name as label,fld_warehouseid as value",
            },
            "GetWarehouseByState"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {
                  this.setState({
                    WarehouseData: obj.data,
                  });
                }
              }
            })
          );
          Notiflix.Loading.Remove();
        }
      })
    );
    if (this.props.sVariantId != null) {
      // get variant price
      PostApiCall.postRequest(
        {
          variantid: this.props.sVariantId,
          stateid: localStorage.getItem("ClientDetails")[0].fld_pricing_type === 'Statewise' ? this.state.PriceState : 0,
        },
        "StateWisePrice"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {

            this.setState({
              VariantData: obj.data,
              ViewVariantList: true,
              ViewProductList: false
            });
            if (obj.data.length != 0) {
              this.setState({
                CostPrice: obj.data[0].CostPrice,
                MRP: obj.data[0].MRP,
                SellingPrice: obj.data[0].SellingPrice,
                Websitediscountprice: obj.data[0].WebsiteDiscountPrice,
                BasePrice: obj.data[0].BasePrice,
                ShippingCharge: obj.data[0].ShippingAmount,
                ShippingChargeGST: obj.data[0].ShippingGst,
                GST: obj.data[0].ItemGST,
                USDPrice: obj.data[0].USDPrice,
                BaseShippingCharge: obj.data[0].ShippingBaseAmount,
                MinQty: obj.data[0].MinimumQty,
                MaxQty: obj.data[0].MaximumQty,
              })
            }
          }
        }
        )
      )
      PostApiCall.postRequest(
        {
          whereClause: `where fld_variantid=${this.props.sVariantId}`,
          recordCount: '*'
        },
        "GetVariantData"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {

            this.setState({
              VariantData: obj.data,
              ViewVariantList: true,
              ViewProductList: false
            });
            if (obj.data.length != 0) {
              this.setState({
                VariantID: obj.data[0].fld_variantid,
                VariantName: obj.data[0].fld_variantname,
                SKU: obj.data[0].fld_sku,
                Barcode: obj.data[0].fld_barcode,
                Status: obj.data[0].fld_status == 'TRUE' ? 'Yes' : 'No',
                AttributeMappingId: obj.data[0].fld_attributemappingid,
                ItemStatus: obj.data[0].fld_status,
                GSTRate: obj.data[0].fld_Gstrate,
                ItemTypeSelected: obj.data[0].fld_item_type,
                IsCustomizable: obj.data[0].fld_is_customizable
              })
              // populate attribute
              PostApiCall.postRequest(
                {
                  id: obj.data[0].fld_categoryid,
                  sfor: 'NA'
                },
                "GetCategoryAttributeValue"
              ).then((results2) =>
                // const objs = JSON.parse(result._bodyText)
                results2.json().then((obj2) => {

                  if (results2.status == 200 || results2.status == 201) {
                    this.setState({
                      AttributData: obj2.data,
                    });
                    Notiflix.Loading.Remove();
                  }
                })
              );
              var item = []
              item.push({
                label: obj.data[0].fld_itemname,
                value: obj.data[0].fld_productid
              })
              new Promise((resolve, reject) => {
                setTimeout(resolve, 1000);
              }).then(() => {
                this.setState({
                  ShortDescription: obj.data[0].fld_short_description,
                  LongDescription: obj.data[0].fld_long_description,
                  // ItemName: item
                  // TermsCondition:OfferData.fld_termscondition,
                });
              });
              if (obj.data) {
                obj.data.map((data, i) => {
                  this.setState({
                    VariantDataForimage: data
                  })
                })
              }
            }
          }
          Notiflix.Loading.Remove();
        }
        ))
      PostApiCall.postRequest(
        {
          whereClause: `where fld_variantid=${this.props.sVariantId} and fld_type = 'image'`
        },
        "Get_VariantImage"
      ).then((results8) =>
        results8.json().then((obj8) => {
          if (
            results8.status == 200 ||
            results8.status == 201
          ) {

            if (obj8.data != null) {
              this.setState({
                Photos: obj8.data,
              });
            }
          }
        }))
      PostApiCall.postRequest(
        {
          whereClause: `where fld_variantid=${this.props.sVariantId} and fld_type = 'video'`
        },
        "Get_VariantImage"
      ).then((results8) =>
        results8.json().then((obj8) => {
          if (
            results8.status == 200 ||
            results8.status == 201
          ) {

            if (obj8.data != null) {
              this.setState({
                Videos: obj8.data,
              });
            }
          }
        }))
    }
    if (localStorage.getItem("ProductID") != null) {
      PostApiCall.postRequest(
        {
          whereClause: `where fld_productid=${JSON.parse(localStorage.getItem('ProductID'))}`,
          recordCount: '*'
        },
        "GetVariantData"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            //
            this.setState({
              SaveVariantData: obj.data
            }
            );
            Notiflix.Loading.Remove();
          }
        }))
    }
  }
  onChangesubCategory(text) {
    this.setState(
      {
        SubCategory: text.target.value,
      },
      () => {
        PostApiCall.postRequest(
          {
            id: this.state.SubCategory,
          },
          "Get_Category_Attribute_value"
        ).then((results2) =>
          results2.json().then((obj2) => {

            if (results2.status == 200 || results2.status == 201) {
              this.setState({
                AttributData: obj2.data,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  onPostVariantData = () => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        productid: this.state.ProductID,
        varientid: this.state.VariantID,
        sku: this.state.SKU,
        barcode: this.state.Barcode,
        longdescripiton: this.state.LongDescription,
        variantname: this.state.VariantName,
        shortdescripiton: this.state.ShortDescription,
        shipping: this.state.ShippingCharge,
        stockInHand: this.state.StockInHand,
        status: this.state.ItemStatus,
        createdOn: moment().format("YYYY-MM-DD"),
        updatedOn: moment().format("YYYY-MM-DD"),
        updatedBy: details[0].fld_userid,
        iscustomizable: this.state.IsCustomizable,
        itemtype: this.state.ItemTypeSelected
      },
      "AddVarient"
    ).then((resultcategory1) =>
      resultcategory1.json().then((obj1) => {
        if (resultcategory1.status == 200 || resultcategory1.status == 201) {
          Notiflix.Notify.Success("Variant Details Successfully Added");
          Notiflix.Loading.Remove();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj1.data);
        }
      })
    );
  };
  groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  };
  onPostGetAttribute = () => {
    PostApiCall.postRequest(
      {
        id: this.state.ItemName.value,
        sfor: 'ProductID'
      },
      "GetCategoryAttributeValue"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          //
          this.setState({
            AttributData: obj2.data,
          });
        }
      })
    );
  }
  //upload image start
  onUploadImage = (type) => {
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      // imageCompression(this.state.originalImage, options)
      //   .then((compressedFile) => {
      //     // //
      const form = new FormData();
      form.append("file", this.state.originalImage);
      form.append("foldername", "ProductImages");
      form.append(
        "filename", this.state.RandomNumber +
        "-" + this.state.outputFileName
      );
      response = fetch(this.state.ImageApiUrl, {
        method: "POST",
        body: form,
        headers: {
          "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
          "x-auth-token": JSON.parse(localStorage.getItem("access")),
          "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
        }
      }).then(response => response.json())
        .then(data => {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Variant image is successfully added");
        });
      PostApiCall.postRequest(
        {
          whereClause: `where fld_variantid=${this.props.sVariantId} and fld_type = '${type}'`
        },
        "Get_VariantImage"
      ).then((results8) =>
        results8.json().then((obj8) => {
          if (
            results8.status == 200 ||
            results8.status == 201
          ) {

            if (obj8.data != null) {
              if (type === 'image') {
                this.setState({
                  Photos: obj8.data,
                });
              }
              else {
                this.setState({
                  Videos: obj8.data,
                });
              }

            }
          }
        }))

    } else {
      Notiflix.Loading.Remove();
      Notiflix.Notify.Failure("Please Upload Variant Image");
    }
  }
  //upload image end
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 800,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    const variantImages = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 800,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let temp = [];
    this.state.AttributData.map((data, i) => {
      temp.push(data.fld_attributename);
    });
    temp = [...new Set(temp)];
    let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");
    let KeysArray = Object.keys(groupTemp);
    let ValuesArray = Object.values(groupTemp);
    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            urlPath={"/customer-management"}
          />
          <Container fluid>
            <Row>
              <Col xl="12">
                <Card className={'overflow-hidden ' + this.state.DisplayVariant}>
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="4">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0">
                            Variant Information
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="form my-3">
                          <div className="row">
                            <div className="col col-12">
                              <div className="row">
                                <div className="col-6">
                                  <div className={'form-group '}>
                                    <label htmlFor="CategoryName">
                                      Item Name
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      disabled="true"
                                      className="form-control"
                                      value={this.state.ItemName}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Item Sku
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control"
                                      value={this.state.SKU}
                                      onChange={(text) => {
                                        this.setState({
                                          SKU: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Barcode
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.Barcode}
                                      onChange={(text) => {
                                        this.setState({
                                          Barcode: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Variant Name
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.VariantName}
                                      onChange={(text) => {
                                        this.setState({
                                          VariantName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Item Type
                                    </label>
                                    <select
                                      value={this.state.ItemTypeSelected}
                                      onChange={(text) => {
                                        this.setState(
                                          {
                                            ItemTypeSelected: text.target.value,
                                          }
                                        );
                                      }}
                                      className="custom-select my-1 mr-sm-2"
                                    >
                                      <option value="">
                                        Select Item Type
                                      </option>
                                      {this.state.ItemTypeData.map(
                                        (Verticle) => (
                                          <option
                                            key={Verticle.fld_name}
                                            value={Verticle.fld_type_id}
                                          >
                                            {Verticle.fld_name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Is Customizable?
                                    </label>
                                    <br />
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="optradio3"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.IsCustomizable == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            IsCustomizable: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      className="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="optradio3"

                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.IsCustomizable == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            IsCustomizable: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>


                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Short Description(maximum 300 Character)
                                      <span className="mandatory">*</span>
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.ShortDescription}
                                        onChange={(text) => {
                                          this.setState({
                                            ShortDescription:
                                              text.editor.getData(),
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Item Description(maximum 700 Character)
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.LongDescription}
                                        onChange={(text) => {
                                          this.setState({
                                            LongDescription:
                                              text.editor.getData(),
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <button
                                    style={{
                                      float: "right",
                                      marginTop: "5px",
                                    }}
                                    onClick={() => {
                                      if (this.state.SKU != "") {
                                        if (this.state.VariantName != "") {
                                          if (this.state.ShortDescription != "") {
                                            if (this.state.ShortDescription.split(" ").length <= 300) {
                                              // if (this.state.LongDescription != "") {
                                              if (this.state.ShortDescription.split(" ").length <= 700) {
                                                this.onPostVariantData();
                                              }
                                              else {
                                                Notiflix.Notify.Failure(
                                                  "Please enter long Description with less than 700 characters"
                                                );
                                              }
                                            }
                                            //   else {
                                            //     Notiflix.Notify.Failure("Please enter long Description");
                                            //   }
                                            // }
                                            else {
                                              Notiflix.Notify.Failure(
                                                "Please enter short description with less than 300 characters."
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.Failure("Please enter ShortDescription");
                                          }
                                        } else {
                                          Notiflix.Notify.Failure("Please enter variant name");
                                        }
                                      }
                                      else {
                                        Notiflix.Notify.Failure("Please enter sku");
                                      }
                                    }}
                                    className="btn align-items-center Bechofy-btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className={'overflow-hidden ' + this.state.DisplayAttribute}>
                  <div className="Bechofy-bg-soft-pink" style={{ height: '45px' }}>
                    <Row>
                      <Col xs="12">
                        <div className="Bechofy-text px-3">
                          <h5 className="Bechofy-text mb-0" style={{ marginTop: "12px" }}>
                            Attribute Information
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          {KeysArray.map((attri, aindex) => (
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="CategoryName">{attri}</label>
                                <select
                                  className="custom-select my-1 mr-sm-2"
                                  value={attri.selectedvalue}
                                  onChange={(e) => {
                                    let tempObj = {
                                      name: attri,
                                      value: e.target.value,
                                    };
                                    let index =
                                      this.state.AttributeValue.findIndex(
                                        (data) => {
                                          return tempObj.name == data.name;
                                        }
                                      );
                                    let TempAttributeArray = [
                                      ...this.state.AttributeValue,
                                    ];
                                    if (index !== -1) {
                                      TempAttributeArray.splice(index, 1);
                                      TempAttributeArray.push(tempObj);
                                    } else {
                                      TempAttributeArray.push(tempObj);
                                    }
                                    this.setState({
                                      ...this.state,
                                      AttributeValue: TempAttributeArray,
                                    });
                                  }}
                                >
                                  <option value="">Select</option>
                                  {ValuesArray[aindex].map((data, i) => {
                                    return (
                                      <option
                                        key={data.label}
                                        value={data.value}
                                      >
                                        {data.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          ))}
                          <div className="col-12">
                            <button
                              style={{
                                float: "right",
                                marginTop: "5px",
                              }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={() => {
                                var login = localStorage.getItem('LoginDetail');
                                var details = JSON.parse(login)
                                var addattribute = ''
                                for (var i = 0; i < this.state.AttributeValue.length; i++) {
                                  if (i == 0) {
                                    addattribute = this.state.AttributeValue[i].value
                                  } else {
                                    addattribute = addattribute + ', ' + this.state.AttributeValue[i].value
                                  }
                                }
                                PostApiCall.postRequest({
                                  attributemappingid: this.state.AttributeMappingId,
                                  productid: this.state.ProductID,
                                  variantid: this.state.VariantID,
                                  attributeValue: addattribute,
                                  createdOn: moment().format("YYYY-MM-DD"),
                                  updatedOn: moment().format("YYYY-MM-DD"),
                                  updatedBy: details[0].fld_userid
                                }, "AddAttributeMasterMapping").then((resultcategory3 =>
                                  resultcategory3.json().then(obj3 => {
                                    if (resultcategory3.status == 200 || resultcategory3.status == 201) {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Success("Product Attributes Saved Successfully");
                                    } else {
                                      Notiflix.Loading.Remove();
                                      Notiflix.Notify.Failure(obj3.data);
                                    }
                                  })
                                ))
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className={'overflow-hidden ' + this.state.DisplayAttribute}>
                  <div className="Bechofy-bg-soft-pink" style={{ height: "45px" }}>
                    <Row>
                      <Col xs="12">
                        <div className="Bechofy-text px-3">
                          <h5 className="Bechofy-text mb-0" style={{ marginTop: "12px" }}>
                            Current Attributes
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        {this.state.AttributeValuesEdit != null ? this.state.AttributeValuesEdit.split(",").map((attr, i) => {
                          return (
                            <div className="black-text">
                              <p className="mb-0"><b>{attr.split("#")[0]}</b>: {attr.split("#")[1]}</p>
                            </div>
                          );
                        }) : "asdsa"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className={'overflow-hidden ' + this.state.DisplayPrice}>
                  <div className="Bechofy-bg-soft-pink" style={{ height: "45px" }}>
                    <Row>
                      <Col xs="12">
                        <div className="Bechofy-text px-3">
                          <h5 className="Bechofy-text mb-0" style={{ marginTop: "12px" }}>Price</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0 px-0">
                    {JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_pricing_type === 'Statewise' ?
                      <Row>
                        <Col xl="12">
                          <div className="row mt-1 mb-3">
                            <div className="col-12">
                              <select
                                className="form-control gridinput"
                                value={this.state.PriceState}
                                onChange={(text) => {
                                  this.setState(
                                    {
                                      PriceState: text.target.value,
                                    },
                                  );
                                }}
                              >
                                <option value="">Select State</option>
                                {this.state.StateData.map((data) => (
                                  <option key={data.value} value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row> : ""}
                    <Row>
                      <Col xs="12">
                        <div className="form my-3">
                          <div className="row  ">
                            <div className="col col-12">
                              <div className="row">
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      MRP
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.MRP}
                                      onChange={(text) => {
                                        this.setState({
                                          MRP: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Cost Price
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.CostPrice}
                                      onChange={(text) => {
                                        this.setState({
                                          CostPrice: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* INR Selling price */}
                                {JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'Both' ||
                                  JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'INR' ?
                                  <><div className="col-3">
                                    <div className="form-group">
                                      <label htmlFor="CategoryName">
                                        INR Selling Price(Without GST)
                                        <span className="mandatory">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="CategoryName"
                                        disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? true : false}
                                        className="form-control my-1 mr-sm-2"
                                        value={this.state.BasePrice}
                                        onChange={(text) => {
                                          var newval = (text.target.value * parseFloat(this.state.GSTRate) / 100)
                                          var gstval = newval.toFixed(2);
                                          var gst = gstval;
                                          var totalaftergst = (parseFloat(text.target.value)) + parseFloat(gstval)
                                          this.setState({
                                            Websitediscountprice: totalaftergst,
                                            GST: gst,
                                            BasePrice: text.target.value
                                          });
                                        }} />
                                    </div>
                                  </div><div className="col-3">
                                      <div className="form-group">
                                        <label htmlFor="CategoryName">
                                          INR GST
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          id="CategoryName"
                                          disabled="true"
                                          className="form-control my-1 mr-sm-2"
                                          value={this.state.GST}
                                          onChange={(text) => {
                                            this.setState({
                                              GST: text.target.value,
                                            });
                                          }} />
                                      </div>
                                    </div><div className="col-3">
                                      <div className="form-group">
                                        <label htmlFor="CategoryName">
                                          INR Selling Price With GST
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          id="CategoryName"
                                          disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? false : true}
                                          className="form-control my-1 mr-sm-2"
                                          value={this.state.Websitediscountprice}
                                          onChange={(text) => {
                                            var newval = (text.target.value * parseFloat(this.state.GSTRate)) / (1 + parseFloat(this.state.GSTRate) / 100) / 100
                                            var gstval = newval.toFixed(2);
                                            var gst = Math.round(gstval * 100) / 100
                                            var gstby2 = newval / 2;
                                            gstby2 = gstby2.toFixed(2);
                                            var baseprice = (parseFloat(text.target.value)) - parseFloat(gstval)
                                            this.setState({
                                              Websitediscountprice: text.target.value,
                                              GST: gst,
                                              BasePrice: baseprice
                                            });
                                          }} />
                                      </div>
                                    </div></>
                                  : ""}
                                {/* INR Selling price */}
                                {JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'Both' ||
                                  JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_currency_type === 'USD' ?
                                  <div className="col-3">
                                    <div className="form-group">
                                      <label htmlFor="CategoryName">
                                        USD Selling Price
                                        <span className="mandatory">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="CategoryName"
                                        className="form-control my-1 mr-sm-2"
                                        value={this.state.USDPrice}
                                        onChange={(text) => {
                                          this.setState({
                                            USDPrice:
                                              text.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  : ""}
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Shipping Charge(Without GST)
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? true : false}
                                      value={this.state.BaseShippingCharge}
                                      onChange={(text) => {
                                        var newval = (text.target.value * parseFloat(this.state.GSTRate) / 100)
                                        var gstval = newval.toFixed(2);
                                        var gst = gstval;
                                        var totalaftergst = (parseFloat(text.target.value)) + parseFloat(gstval)
                                        this.setState({
                                          ShippingCharge: totalaftergst,
                                          ShippingChargeGST: gst,
                                          BaseShippingCharge: text.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Shipping Charge GST
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      disabled="true"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.ShippingChargeGST}
                                      onChange={(text) => {
                                        this.setState({
                                          ShippingChargeGST: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Shipping Charge
                                    </label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1 mr-sm-2"
                                      disabled={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_gst_type === 'Inclusive' ? false : true}
                                      value={this.state.ShippingCharge}
                                      onChange={(text) => {
                                        var newval = (text.target.value * parseFloat(this.state.GSTRate)) / (1 + parseFloat(this.state.GSTRate) / 100) / 100
                                        var gstval = newval.toFixed(2);
                                        var gst = Math.round(gstval * 100) / 100
                                        var gstby2 = newval / 2;
                                        gstby2 = gstby2.toFixed(2);
                                        var baseprice = (parseFloat(text.target.value)) - gstval
                                        this.setState({
                                          ShippingCharge: text.target.value,
                                          ShippingChargeGST: gst,
                                          BaseShippingCharge: baseprice
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Mininum Quantity
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="MinQty"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.MinQty}
                                      onChange={(text) => {
                                        this.setState({
                                          MinQty: text.target.value
                                        });
                                      }} />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="CategoryName">
                                      Maximum Quantity
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="MinQty"
                                      className="form-control my-1 mr-sm-2"
                                      value={this.state.MaxQty}
                                      onChange={(text) => {
                                        this.setState({
                                          MaxQty: text.target.value
                                        });
                                      }} />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <button
                                    style={{
                                      float: "right",
                                      marginTop: "5px",
                                    }}
                                    className="btn align-items-center Bechofy-btn "
                                    onClick={() => {
                                      var login = localStorage.getItem("LoginDetail");
                                      var details = JSON.parse(login);
                                      PostApiCall.postRequest(
                                        {
                                          variantpriceid: this.state.VariantID,
                                          variantid: this.state.VariantID,
                                          mrp: this.state.MRP,
                                          costPrice: this.state.CostPrice,
                                          sellingPrice: this.state.Websitediscountprice,
                                          websiteDiscountPrice: this.state.Websitediscountprice,
                                          baseprice: this.state.BasePrice,
                                          baseshippingcharge: this.state.BaseShippingCharge,
                                          gst: this.state.GST,
                                          shippinggst: this.state.ShippingChargeGST,
                                          shippingcharge: this.state.ShippingCharge,
                                          usdprice: this.state.USDPrice,
                                          createdOn: moment().format("YYYY-MM-DD"),
                                          updatedOn: moment().format("YYYY-MM-DD"),
                                          updatedBy: details[0].fld_userid,
                                          stateid: this.state.PriceState,
                                          MinQty: this.state.MinQty,
                                          MaxQty: this.state.MaxQty,
                                        },
                                        "AddVariantPrice"
                                      ).then((resultcategory2) =>
                                        resultcategory2.json().then((obj2) => {
                                          if (resultcategory2.status == 200 || resultcategory2.status == 201) {
                                            Notiflix.Notify.Success(
                                              "Price Updated Successfully."
                                            );
                                            Notiflix.Loading.Remove();
                                          } else {
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Failure(obj2.data);
                                          }
                                        })
                                      );
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card
                  className={'overflow-hidden ' + this.state.DisplayImages}
                >
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0">Variant Images</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <div className="row">
                      {this.state.Photos.map((data, i) => (
                        <div className="col-3">
                          <div className="best-products">
                            <div className="img-container" width="50%">
                              <ImgUpload
                                onChange={(e) => {
                                  e.preventDefault();
                                  const imageFile = e.target.files[0];
                                  const size = e.target.files[0].size;
                                  if (size < 2e6) {
                                    this.setState({
                                      imagePreviewUrl: URL.createObjectURL(imageFile),
                                      originalImage: imageFile,
                                      outputFileName: imageFile.name
                                    });
                                    var login = localStorage.getItem("LoginDetail");
                                    var details = JSON.parse(login);
                                    if (imageFile.name != undefined) {
                                      //
                                      PostApiCall.postRequest(
                                        {
                                          variantimageid: data.fld_variantimageid,
                                          variantid: this.state.VariantID,
                                          imageurl: 'https://store.bechofy.in/images/' +
                                            JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema + "/ProductImages/" +
                                            this.state.RandomNumber + "-" + imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
                                          createdOn: moment().format("YYYY-MM-DD"),
                                          updatedOn: moment().format("YYYY-MM-DD"),
                                          updatedBy: details[0].fld_userid,
                                          type: "image"
                                        },
                                        "AddVariantImage"
                                      ).then((results1) =>
                                        results1.json().then((obj1) => {
                                          if (results1.status == 200 || results1.status == 201) {
                                            localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Success("Image Uploading in Process");
                                            this.onUploadImage('image');
                                          }
                                          else {
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Failure(obj1);
                                          }
                                        })
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                                  }
                                }
                                }
                                src={data.fld_imageurl}
                                type=""
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  left: "13px",
                                  top: "0px",
                                  cursor: "pointer",
                                  background: "black"
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      ImageUrl: data.fld_imageurl,
                                    })
                                  PostApiCall.postRequest(
                                    {
                                      variantimageid:
                                        data.fld_variantimageid,
                                    },
                                    "DeleteVariantImage"
                                  ).then((results) =>
                                    results.json().then((obj) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Success(
                                          "Image successfully deleted."
                                        );
                                        PostApiCall.postRequest(
                                          {
                                            whereClause: `where fld_variantid=${this.props.sVariantId}  and fld_type = 'image'`
                                          },
                                          "Get_VariantImage"
                                        ).then((results8) =>
                                          results8.json().then((obj8) => {
                                            if (
                                              results8.status == 200 ||
                                              results8.status == 201
                                            ) {

                                              if (obj8.data != null) {
                                                this.setState({
                                                  Photos: obj8.data,
                                                });
                                              }
                                            }
                                          }))
                                      } else {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Failure(
                                          "Something went wrong, try again later."
                                        );
                                      }
                                    }))
                                }
                                }
                              >
                                <span className="material-symbols-outlined">
                                  <TiDeleteOutline />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                      <div className="col-3">
                        <div className="best-products">
                          <ImgUploadNew
                            onChange={(e) => {
                              e.preventDefault();
                              const imageFile = e.target.files[0];
                              const size = e.target.files[0].size;
                              if (size < 2e6) {
                                this.setState({
                                  imagePreviewUrl: URL.createObjectURL(imageFile),
                                  originalImage: imageFile,
                                  outputFileName: imageFile.name
                                });
                                var login = localStorage.getItem("LoginDetail");
                                var details = JSON.parse(login);
                                if (imageFile.name != undefined) {
                                  //
                                  PostApiCall.postRequest(
                                    {
                                      variantimageid: null,
                                      variantid: this.state.VariantID,
                                      imageurl: 'https://store.bechofy.in/images/' +
                                        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema + "/ProductImages/" +
                                        this.state.RandomNumber + "-" + imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
                                      createdOn: moment().format("YYYY-MM-DD"),
                                      updatedOn: moment().format("YYYY-MM-DD"),
                                      updatedBy: details[0].fld_userid,
                                      type: "image"
                                    },
                                    "AddVariantImage"
                                  ).then((results1) =>
                                    results1.json().then((obj1) => {
                                      if (results1.status == 200 || results1.status == 201) {
                                        localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Success("Image Uploading in Process");
                                        this.onUploadImage('image');
                                      }
                                      else {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Failure(obj1);
                                      }
                                    })
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                              }
                            }

                            }
                            src={ImageUploadIcon}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          style={{
                            float: "right",
                            marginTop: "5px",
                          }}
                          className="btn align-items-center Bechofy-btn "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card
                  className={'overflow-hidden ' + this.state.DisplayImages}
                >
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0">Variant Videos</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <div className="row">
                      {this.state.Videos.map((data, i) => (
                        <div className="col-3">
                          <div className="best-products">
                            <div className="img-container" width="50%">
                              <VideoUpload
                                onChange={(e) => {
                                  e.preventDefault();
                                  const imageFile = e.target.files[0];
                                  const size = e.target.files[0].size;
                                  if (size < 2e6) {
                                    this.setState({
                                      imagePreviewUrl: URL.createObjectURL(imageFile),
                                      originalImage: imageFile,
                                      outputFileName: imageFile.name
                                    });
                                    var login = localStorage.getItem("LoginDetail");
                                    var details = JSON.parse(login);
                                    if (imageFile.name != undefined) {
                                      //
                                      PostApiCall.postRequest(
                                        {
                                          variantimageid: data.fld_variantimageid,
                                          variantid: this.state.VariantID,
                                          imageurl: 'https://store.bechofy.in/images/' +
                                            JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema + "/ProductImages/" +
                                            this.state.RandomNumber + "-" + imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
                                          createdOn: moment().format("YYYY-MM-DD"),
                                          updatedOn: moment().format("YYYY-MM-DD"),
                                          updatedBy: details[0].fld_userid,
                                          type: "video"
                                        },
                                        "AddVariantImage"
                                      ).then((results1) =>
                                        results1.json().then((obj1) => {
                                          if (results1.status == 200 || results1.status == 201) {
                                            localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Success("Image Uploading in Process");
                                            this.onUploadImage('video');
                                          }
                                          else {
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Failure(obj1);
                                          }
                                        })
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                                  }
                                }
                                }
                                src={data.fld_imageurl}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  left: "13px",
                                  top: "0px",
                                  cursor: "pointer",
                                  background: "black"
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      ImageUrl: data.fld_imageurl,
                                    })
                                  PostApiCall.postRequest(
                                    {
                                      variantimageid:
                                        data.fld_variantimageid,
                                    },
                                    "DeleteVariantImage"
                                  ).then((results) =>
                                    results.json().then((obj) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Success(
                                          "Image successfully deleted."
                                        );
                                        PostApiCall.postRequest(
                                          {
                                            whereClause: `where fld_variantid=${this.props.sVariantId} and fld_type = 'video'`
                                          },
                                          "Get_VariantImage"
                                        ).then((results8) =>
                                          results8.json().then((obj8) => {
                                            if (
                                              results8.status == 200 ||
                                              results8.status == 201
                                            ) {

                                              if (obj8.data != null) {
                                                this.setState({
                                                  Photos: obj8.data,
                                                });
                                              }
                                            }
                                          }))
                                      } else {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Failure(
                                          "Something went wrong, try again later."
                                        );
                                      }
                                    }))
                                }
                                }
                              >
                                <span className="material-symbols-outlined">
                                  <TiDeleteOutline />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                      <div className="col-3">
                        <div className="best-products">
                          <VideoUploadNew
                            onChange={(e) => {
                              e.preventDefault();
                              const imageFile = e.target.files[0];
                              const size = e.target.files[0].size;
                              if (size < 2e6) {
                                this.setState({
                                  imagePreviewUrl: URL.createObjectURL(imageFile),
                                  originalImage: imageFile,
                                  outputFileName: imageFile.name
                                });
                                var login = localStorage.getItem("LoginDetail");
                                var details = JSON.parse(login);
                                if (imageFile.name != undefined) {
                                  //
                                  PostApiCall.postRequest(
                                    {
                                      variantimageid: null,
                                      variantid: this.state.VariantID,
                                      imageurl: 'https://store.bechofy.in/images/' +
                                        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema + "/ProductImages/" +
                                        this.state.RandomNumber + "-" + imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
                                      createdOn: moment().format("YYYY-MM-DD"),
                                      updatedOn: moment().format("YYYY-MM-DD"),
                                      updatedBy: details[0].fld_userid,
                                      type: "video"
                                    },
                                    "AddVariantImage"
                                  ).then((results1) =>
                                    results1.json().then((obj1) => {
                                      if (results1.status == 200 || results1.status == 201) {
                                        localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Success("Image Uploading in Process");
                                        this.onUploadImage('video');
                                      }
                                      else {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Failure(obj1);
                                      }
                                    })
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                              }
                            }

                            }
                            src={ImageUploadIcon}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          style={{
                            float: "right",
                            marginTop: "5px",
                          }}
                          className="btn align-items-center Bechofy-btn "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddVariant;
