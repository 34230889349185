import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import addicon from "../../assets/images/dashboardimages/product.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import DatePicker from 'react-date-picker';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const SkeletonSection = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between">
          <Col xs="4" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="6" className="px-4 my-auto">
            <div className="Bechofy-text" style={{ textAlign: "end" }}>
              <Skeleton className="py-2" style={{ width: "30%" }} />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="1" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0 px-0">
        <Row>
          <Col
            xs="12"
            className="latestreview-mdbtable lastestcustomer-table-responsive"
          >
            <table className="table">

              <tbody>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
class customerList extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      SectionData: [],
      SearchField: null,
      fileData: [],
      loader: true,
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetHomepageSetup"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            SectionData: obj.data,
            count: obj.data.length,
            loader: false,
          });
          //
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Section Name",
          field: "name",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Edit",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.SectionData.filter((data) => {
        if (
          this.state.searchInput == ""
        ) {
          return data;
        }
        if (
          // this.state.searchInput !== "" &&
          data.fld_name
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
          // `${data.fld_email ? data.fld_email.toLowerCase() : ""
          // 	}`.includes(this.state.searchInput.toLowerCase()) ||
          `${data.fld_status ? data.fld_status.toLowerCase() : ""
            }`.includes(
              this.state.searchInput.toLowerCase()
            )
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          name: (
            <div className="text-center newcustomer-mdtablecol">{data.fld_section_name}</div>
          ),
          status: data.fld_status,
          action: (
            <span className="">
              <Link to={`/custom-section/${data.fld_id}`}
                className="btn align-items-center btn Bechofy-btn "
              >
                Edit
              </Link>
            </span>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/custom-section"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">

                  {this.state.loader == true ? (
                    <>
                      <SkeletonSection cards={5} />
                    </>
                  ) : (
                    <Card>
                      <div
                        className="Bechofy-bg-soft-pink"
                        style={{ height: "60px" }}
                      >
                        <div className="row my-1">
                          <div className="col-6">
                            <div className="form-group">
                              <h4
                                className="px-4 text-white text-start"
                                style={{ marginTop: "15px" }}
                              >
                                {this.state.count == 0 ? 'Section List' : <>Section List [{this.state.count}]</>}
                              </h4>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="position-relative">
                              <Link
                                to="/custom-section/0"
                                className="align-items-center Bechofy-btn category-Add-btn categorybtnposition customer-download-btn"
                              >
                                <span className="">
                                  <img
                                    src={addicon}
                                    alt="block user"
                                    className="btnicons"
                                    style={{ marginRight: "5px" }}
                                  ></img>{" "}
                                </span>
                                Add New Section
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CardBody className="pt-0">
                        <Row className="text-center justify-content-center">
                          {this.state.SectionData.length != 0 ? (
                            <>
                              <MDBCol
                                md="12"
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <span className="">
                                        <img
                                          src={Searchicon}
                                          alt="block user"
                                          className="btnicons"
                                        ></img>
                                      </span>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type the search phrase you wish to search within the grid"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => this.seachBarHandler(e)}
                                    value={this.state.searchInput}
                                  />
                                </div>
                              </MDBCol>
                              <Col
                                md="12"
                                className="lastestcustomer-table-responsive"
                              >
                                <MDBDataTable
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[10, 25, 50, 100]}
                                  className="customer-table-responsive"
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTable>
                              </Col>
                            </>
                          ) : (
                            <Row className="mt-4 pt-2">
                              <Col sm="12" className="text-center">
                                <img
                                  src={customersicon}
                                  alt="block user"
                                  className="customerhistory-icons ms-3 mt-1"
                                  style={{ marginRight: "5px" }}
                                />
                              </Col>
                              <Col sm="12" className="text-center my-3">
                                <h6>No Section</h6>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default customerList;
