import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import barChart from "../../assets/images/bar-chart.png";
import {
	Card, CardBody,
	Media
} from "reactstrap";
import PostApiCall from "../../Api";
class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					data: []
				}
			],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "Month",
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					],
				},
				tooltip: {
					x: {
						format: "₹",
					},
				},
			},
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Custom("");
		var customer = localStorage.getItem("customerDetails");
		var CustomerDetails = JSON.parse(customer);
		PostApiCall.postRequest(
			{
				WhereClause: ` where fld_customer_id = ${CustomerDetails} `
			},
			"Get_DashboardCustomerYearlySales"
		).then((results) =>
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						SalesData: obj2.data,
					});
					const categories = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
					this.setState({
						categories: categories,
					});
					const data = new Array(12).fill(0);
					for (var i = 0; i < obj2.data.length; i++) {
						for (var j = 0; j < categories.length; j++) {
							if (obj2.data[i].month == categories[j]) {
								data[j] = obj2.data[i].total_sales
							}

						}
					}

					this.setState({
						series: [
							{ name: "Order Summary", data: data },
						],
					});
					Notiflix.Loading.Remove();
				}
			}))
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				<Card className="mini-stats-wid">
					<CardBody className="viewcustomer-top-card-size">
						<Media>
							<Media body>
								<h4 className="mb-3">Order Summary Chart </h4>
								{this.state.series.length != 0 ?
									<>
										<ReactApexChart
											options={this.state.options}
											series={this.state.series}
											type="area"
											height={260}
											width={535}
										/>
									</>
									:
									<>
										<p className="justify-content-center text-center">
											<img src={barChart} style={{ width: '120px' }} />
										</p>
										<p className="justify-content-center text-center">
											Order summary Chart no found
										</p>
									</>
								}
							</Media>
						</Media>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}
export default Chart;
