import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";

class BannerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			UserData: [],
			Id: "",
			searchInput: "",
			Banners: [],
			searchBarInput: "", //for filter purpose
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest(
			"GetBannerImages"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					// get the data
					this.setState({
						Banners: obj1.data
					});
				}
			})
		);
	}
	// newly added function to filter out the searched data
	seachBarHandler = (e) => {
		this.setState({
			searchBarInput: e.target.value
		})
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Location",
					field: "location",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Page Name",
					field: "pagename",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Desktop Banner",
					field: "DesktopBanner",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Mobile Banner",
					field: "mobilebanner",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Title",
					field: "title",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Show on website",
					field: "show",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			// map data according to the serach input
			rows: this.state.Banners.filter((data) => {
				if (this.state.searchBarInput == "") {
					return data;
				}
				if (
					this.state.searchBarInput !== "" &&
					String(data.location)
						.toLowerCase()
						.includes(this.state.searchBarInput.toLowerCase())
				) {
					return data;
				}
				if (
					this.state.searchBarInput !== "" &&
					String(data.pagename)
						.toLowerCase()
						.includes(this.state.searchBarInput.toLowerCase())
				) {
					return data;
				}
				if (
					this.state.searchBarInput !== "" &&
					String(data.title)
						.toLowerCase()
						.includes(this.state.searchBarInput.toLowerCase())
				) {
					return data;
				}

			}).map((data, i) => {
				return {
					serial: i + 1,
					location: data.location,
					pagename: data.pagename,
					mobilebanner: <img src={data.image.split("$")[1].split("#")[0]} style={{ width: "100px" }} alt={data.title} />,
					DesktopBanner: <img src={data.image.split("$")[0].split("#")[0]} style={{ width: "100px" }} alt={data.title} />,
					title: data.title,
					show: 'yes',
					action: (
						<td className="text-center actionuser" style={{ border: 'none' }}>
							<i
								className="fas fa-eye btn"
								style={{ fontSize: "15px", }}
								onClick={() => {
									localStorage.setItem("BannerDetails", JSON.stringify(data)); // to edit 
									window.location.href = "/add-banner";
								}}
							></i>
						</td>
					)
				}
			})
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Banner List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-2">
																<button className="btn align-items-center btn Bechofy-btn bg-white text-dark"
																	style={{ float: 'right' }}
																	onClick={() => {
																		window.location.href = '/add-banner'
																	}}
																>
																	Add New Banner
																</button>
															</div>
														</Col>
													</Row>
												</div>

												<Card className="overflow-hidden mt-3">
													<CardBody className="pt-0">
														<Row>
															<MDBCol md="6" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Type the search phrase you wish to search within the grid"
																	aria-label="Search"
																	onChange={(e) => this.seachBarHandler(e)}
																	value={this.state.searchBarInput}
																/>
															</MDBCol>
															<Col md="12">
																{/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
																<MDBDataTable
																	hover
																	// scrollY
																	striped
																	bordered
																	data={data}
																	seachTop={false}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTable>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default BannerList;
