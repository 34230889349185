import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Downloadicon from "../../assets/images/dashboardimages/down-arrow.png";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import DatePicker from 'react-date-picker';
class SeoList extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      FromDate: null,
      ToDate: null,
      OrderData: [],
      SearchField: null,
      fileData: [],
      SEOData: [],
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereclause: "",
      },
      "GetSEOData"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            SEOData: obj.data,
          });

          Notiflix.Loading.Remove();
        }
      })
    );
  }
  DownloadCSV = () => {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: "",
        RecordCount: "*",
      },
      "GetSEOData"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            // this.props.setstate()
            this.setState(
              {
                fileData: obj.data,
              },
              () => {
                setTimeout(() => {
                  this.csvLink.current.link.click();
                });
              }
            );
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 75,
        },
        {
          label: "Type",
          field: "type",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Type Name",
          field: "typename",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Meta Title",
          field: "metatitle",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Meta Description",
          field: "metadescription",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Keywords",
          field: "keywords",
          sort: "disabled",
          width: 150,
        },
        {
          label: "OG Title",
          field: "ogtitle",
          sort: "disabled",
          width: 200,
        },
        {
          label: "OG Description",
          field: "ogdescription",
          sort: "disabled",
          width: 100,
        },
        // Add new value og image and canonical
        {
          label: "OG Image",
          field: "ogimage",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Canonical Tag",
          field: "canonical",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.SEOData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.fld_type == null
            ? ""
            : data.fld_type
                .toLowerCase()
                .includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.type_name == null
            ? ""
            : data.type_name
                .toLowerCase()
                .includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          type: data.fld_type,
          typename: (
            <>
              {data.item_name && data.item_name + " - "}
              {data.type_name}
            </>
          ),
          metatitle: data.fld_meta_tile,
          metadescription: data.fld_meta_description,
          keywords: data.fld_keywords,
          ogtitle: data.fld_og_title,
          ogdescription: data.fld_og_description,
          ogimage:(data.fld_og_image),
          canonical:(data.FLD_CANONICAL),
          status: data.fld_status,
          action: (
            <span className="" style={{ cursor: "pointer" }}>
              <img
                src={Editicon}
                alt="Edit SEO Information"
                className="btnicons"
                onClick={() => {
                  window.location.href = `/seo-information/update/${data.fld_seoid}`;
                }}
              ></img>
              {/* <img src={Editicon} alt="Edit user" style={{ marginLeft: '2px' }} className="btnicons" onClick={() => {
								localStorage.setItem("customerDetails", JSON.stringify(data.fld_customerid));
								window.location.href = "/customer-profile";
							}}></img> */}
            </span>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/seo-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">
                  <Card>
                    <div
                      className="Bechofy-bg-soft-pink"
                      style={{ height: "60px" }}
                    >
                      <div className="row my-1">
                        <div className="col-4">
                          <div className="form-group">
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              SEO Information List
                            </h4>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="position-relative">
                            <a
                              onClick={() => {
                                window.location.href =
                                  "/seo-information/add/null";
                              }}
                              style={{
                                float: "right",
                                marginTop: "10px",
                                background: "white",
                                color: "grey",
                                marginRight: "20px",
                              }}
                              className="btn align-items-center btn Bechofy-btn "
                            >
                              Add SEO Information
                            </a>
                            <a
                              onClick={this.DownloadCSV.bind(this)}
                              style={{
                                float: "right",
                                marginTop: "10px",
                                background: "white",
                                color: "grey",
                                marginRight: "20px",
                              }}
                              className="btn align-items-center btn Bechofy-btn "
                            >
                              <span className="">
                                <img
                                  src={Downloadicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginRight: "5px" }}
                                ></img>{" "}
                              </span>
                              Download List (.csv)
                            </a>
                          </div>
                          <CSVLink
                            data={this.state.SEOData}
                            filename="SEOList.csv"
                            className="hidden"
                            ref={this.csvLink}
                            target="_blank"
                          />
                        </div>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <MDBCol
                          md="12"
                          style={{ marginBottom: "15px", marginTop: "15px" }}
                        >
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type the search phrase you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}
                            />
                          </div>
                        </MDBCol>
                        <Col md="12" className="seotablescroll">
                          {this.state.SEOData.length != 0 ? (
                            <MDBDataTable
                              hover
                              // scrollY
                              striped
                              bordered
                              data={data}
                              seachTop={false}
                              entriesOptions={[10, 25, 50, 100]}
                            >
                              <MDBTableHead columns={data.columns} />
                              <MDBTableBody rows={data.rows} />
                            </MDBDataTable>
                          ) : (
                            <p>No Data Found</p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Card>
                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* </Card>
							</Col>
						</Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default SeoList;
