import React from "react";
import { BiSupport } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { Col, Container, Row } from "reactstrap";
import Bechofylogo from '../../assets/images/bechofylogo.png';
const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer footer-bottom-border">
                <Container fluid={true}>
                    <Row className="justify-content-between align-items-center">
                        <Col md={6} style={{ marginTop: '-10px' }}>
                            Powered by
                            <a href="https://www.bechofy.in/" style={{ color: '#777f80' }} target="_blank">
                                <img src={Bechofylogo} alt="" style={{ width: '60px' }} /></a>
                        </Col>
                        <Col md={6} className="d-flex text-end justify-content-end">
                            <div className="d-flex flex-row-reverse">
                                <a href="tel:7982828789">
                                    <div className="support-parent mx-2 d-flex justify-content-center align-items-center">
                                        <BiSupport className="whatsapp-font" />
                                    </div>
                                </a>
                                <div className="d-flex align-items-center text-right">
                                    <div className="footer-content">
                                        <p className="mb-0 suppport-font"><strong> Support </strong></p>
                                        <p className="mt-0"><a href="tel:7982828789">+91 79828 28789</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="whatsapp-parent d-flex justify-content-center align-items-center">
                                <a href="https://wa.me/+917982828789" target="_blank">
                                    <FaWhatsapp className="whatsapp-font" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};
export default Footer;
