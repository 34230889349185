import { MDBCol, MDBDataTableV5 } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-responsive-modal/styles.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class cutomerList extends Component {
	state = {
		data: [],
		searchInput: "",
		VerticleData: [],
		ViewClick: "true",
		open: false,
		Status: "Active",
		Name: "",
		openedit: false,
		Id: "",
		CategoryData: [],
		Category: [],
		VerticleDrop: [],
		Cat: [],
		PredefinedCategory: [],
	};
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("GetVerticalMapping").then((resultdes) =>
			resultdes.json().then((obj) => {
				//
				this.setState({
					VerticleData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		// //
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 120,
				},
				{
					label: "Vertical Name",
					field: "job",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},
			],
			rows: this.state.VerticleData.map((data, item) => {
				return {
					serial: item + 1,
					job: data.fld_verticlename,
					status: data.fld_status,
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Vertical Mapping Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Vertical Mapping List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<a
																	href="/add-vertical-mapping"
																	style={{
																		float: "right",
																		marginTop: "-5px",
																		color: "white",
																	}}
																	className="btn align-items-center btn Bechofy-btn "
																>
																	Map New Vertical{" "}
																	<i
																		className="fa fa-plus"
																		aria-hidden="true"
																	></i>
																</a>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row></Row>
												</CardBody>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<MDBCol md="3" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) =>
																		this.setState({
																			searchInput: e.target.value,
																		})
																	}
																	value={this.state.searchInput}
																/>
															</MDBCol>
															<MDBDataTableV5
																striped
																bordered
																small
																data={data}
																searchBottom={false}
																seachTop={true}
																className="tablecol"
															/>
														</Col>
													</Row>
												</CardBody>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default cutomerList;
