import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Searchicon from '../../assets/images/dashboardimages/search.png';
import ImageUploadIcon from "../../assets/images/Upload.jpg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const ImgUploadNew = ({ onChange, src }) => (
	<label htmlFor="photo-upload-new" className="custom-file-upload fas">
		<div className="img-wrap img-upload" style={{ width: "300px" }}>
			<img
				htmlFor="photo-upload-new"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload-new" type="file" onChange={onChange} />
	</label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
class OrderList extends Component {
	constructor(props) {
		super(props);
		const { type } = props.match.params;
		this.csvLink = React.createRef()
		this.state = {
			ItemDetails: [],
			SearchField: null,
			fileData: [],
			ReportType: type,
			searchInput: "",
			IsOpen: false,
			variantImages: [],
			filteredVariantImages: [],
			ImageCheckbox: false,
			SelectedImages: [],
			imagePreviewUrl: ImageUploadIcon,
			originalImage: "",
			outputFileName: "",
			VariantID: null,
			RandomNumber: randomnumber,
			ImageApiUrl: 'https://api.bechofy.in/AddImage',
			VariantName: ""
		};
	}
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	closeDrawer = () => {
		this.setState({
			IsOpen: false,
			filteredVariantImages: []
		});
		PostApiCall.postRequest({
			whereClause: `where not exists (select * from ${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema}.Product_Variant_Image pvi where pvi.fld_variantid = udv.fld_variantid)`
		}, "GetItemsDetails").then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//
					this.setState({
						ItemDetails: obj.data,
						Loading: false
					});
				}
			}))
	};
	openDrawer = () => {
		this.setState({
			IsOpen: true,
		});
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("Please wait...");
		if (this.state.ReportType === 'outofstock') {
			PostApiCall.postRequest({
				whereClause: `where isnull(fld_qty,0) = 0`
			}, "GetItemStockDetailsReport").then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						console.log(obj.data)
						this.setState({
							ItemDetails: obj.data,
							Loading: false
						});
					}
				}))
		}
		if (this.state.ReportType === 'inactiveitems') {
			PostApiCall.postRequest({
				whereClause: `where fld_status = 'Inactive'`
			}, "GetItemsDetails").then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						//
						this.setState({
							ItemDetails: obj.data,
							Loading: false
						});
					}
				}))
		}
		if (this.state.ReportType === 'withoutimage') {
			PostApiCall.postRequest({
				whereClause: `where not exists (select * from ${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema}.Product_Variant_Image pvi where pvi.fld_variantid = udv.fld_variantid)`
			}, "GetItemsDetails").then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						//
						this.setState({
							ItemDetails: obj.data,
							Loading: false
						});
					}
				}))
		}
		if (this.state.ReportType === 'withoutprice') {
			PostApiCall.postRequest({
				whereClause: `where isnull(fld_selling_price,0) = 0`
			}, "GetItemsDetails").then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						//
						this.setState({
							ItemDetails: obj.data,
							Loading: false
						});
					}
				}))
		}

		PostApiCall.postRequest(
			{
				variantid: "",
			},
			"GetVariantImage"
		).then((results8) =>
			results8.json().then((obj8) => {
				if (results8.status == 200 || results8.status == 201) {
					this.setState({
						variantImages: obj8.data
					})
					Notiflix.Loading.Remove();
				}
			})
		);

	}

	onUploadImage = (type) => {
		let response;
		if (this.state.outputFileName != undefined) {
			const form = new FormData();
			form.append("file", this.state.originalImage);
			form.append("foldername", "ProductImages");
			form.append(
				"filename", this.state.RandomNumber +
				"-" + this.state.outputFileName
			);
			response = fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
				headers: {
					"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
					"x-auth-token": JSON.parse(localStorage.getItem("access")),
					"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
				}
			}).then(response => response.json())
				.then(data => {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Variant image is successfully added");
				});
			PostApiCall.postRequest(
				{
					variantid: "",
				},
				"GetVariantImage"
			).then((results8) =>
				results8.json().then((obj8) => {
					if (results8.status == 200 || results8.status == 201) {
						this.setState({
							variantImages: obj8.data,
							filteredVariantImages: obj8.data.filter((dat) => {
								if (dat.fld_imageurl.toLowerCase().includes(this.state.VariantName.split(" ")[0].toLowerCase()) || dat.fld_variantid == this.state.VariantID) {
									return dat
								}
							}),
						})

					}
				})
			);

		} else {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Failure("Please Upload Variant Image");
		}
	}
	render() {
		const showImagesField = window.location.pathname.includes("withoutimage");
		const columns = [
			{
				label: "S.No.",
				field: "sn",
				sort: "asc",
				width: 150,
			},
			{
				label: "Item Name",
				field: "itemname",
				sort: "asc",
				width: 270,
			},
			{
				label: "Vertical Name",
				field: "verticalname",
				sort: "asc",
				width: 270,
			},
			{
				label: "Category",
				field: "Category",
				sort: "asc",
				width: 200,
			},
			{
				label: "SubCategory",
				field: "SubCategory",
				sort: "asc",
				width: 200,
			},
			{
				label: "Price",
				field: "Price",
				sort: "asc",
				width: 100,
			},
			{
				label: "Status",
				field: "Status",
				sort: "asc",
				width: 150,
			}
		];

		if (showImagesField) {
			columns.push({
				label: "Images",
				field: "MappedImages",
				sort: "asc",
				width: 150,
			});
		}
		const data = {
			columns: columns,
			rows: this.state.ItemDetails.filter((data) => {
				if (this.state.searchInput == "" && data.fld_variantname != null && data.fld_selling_price > 1) {
					return data;
				}
				if (this.state.searchInput !== undefined && this.state.searchInput != "" && data.fld_variantname != null && data.fld_selling_price > 1 &&
					(data.fld_variantname !== "" && data.fld_variantname != null ? data.fld_variantname.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) : ""
					)
				) {
					return data;
				}
				if (this.state.searchInput !== undefined && this.state.searchInput != "" && data.fld_variantname != null && data.fld_selling_price > 1 &&
					(data.fld_categoryname !== "" && data.fld_categoryname != null ? data.fld_categoryname.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) : ""
					)
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					sn: (i + 1),
					itemname: (data.fld_variantname),
					Category: (data.fld_categoryname),
					SubCategory: (data.fld_subcategoryname),
					Price: parseFloat(data.fld_selling_price === null ? 0 : data.fld_selling_price).toFixed(2),
					Status: (data.fld_status),
					verticalname: data.fld_verticlename,
					MappedImages:
						<>
							<button className="btn align-items-center Bechofy-btn" onClick={() => {
								this.setState({
									filteredVariantImages: this.state.variantImages.filter((dat) => {
										if (dat.fld_imageurl.toLowerCase().includes(data.fld_variantname.split(" ")[0].toLowerCase()) || dat.fld_variantid == data.fld_variantid) {
											return dat
										}
									}),
									VariantID: data.fld_variantid,
									VariantName: data.fld_variantname
								})
								this.openDrawer()
							}}
							>Select / Upload Image</button>
						</>
				}
			})
		}
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} urlPath={"/dashboard/"} />
						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<Card >
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-2">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>{this.state.ReportType}</h4>
													</div>
												</div>

											</div>
										</div>
										<CardBody className="pt-0">
											<Row>
												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div className="input-group mb-3">
														<div className="input-group-prepend">
															<span className="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" className="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}
														/>
													</div>
												</MDBCol>
												<Col md="12">
													{this.state.ItemDetails.length != 0 ?
														<MDBDataTable
															hover
															striped
															bordered
															data={data}
															seachTop={false}
															entries={25} // Set the default number of entries to 25
															entriesOptions={[25, 50, 100]}

														>
															<MDBTableHead columns={data.columns} />
															<MDBTableBody rows={data.rows} />
														</MDBDataTable>
														:
														<p>No Data Found</p>}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				<Drawer
					open={this.state.IsOpen}
					onClose={this.closeDrawer}
					direction="right"
					lockBackgroundScroll="no"
					width={700}
					className="sidebar-verticalmaster drawer sidebar-inactive selectimage-sidebar"
				>
					<ImgUploadNew
						onChange={(e) => {
							e.preventDefault();
							const imageFile = e.target.files[0];
							const size = e.target.files[0].size;
							if (size < 2e6) {
								this.setState({
									imagePreviewUrl: URL.createObjectURL(imageFile),
									originalImage: imageFile,
									outputFileName: imageFile.name
								});
								var login = localStorage.getItem("LoginDetail");
								var details = JSON.parse(login);
								if (imageFile.name != undefined) {
									//
									PostApiCall.postRequest(
										{
											variantimageid: null,
											variantid: this.state.VariantID,
											imageurl: 'https://store.bechofy.in/images/' +
												JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema + "/ProductImages/" +
												this.state.RandomNumber + "-" + imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
											createdOn: moment().format("YYYY-MM-DD"),
											updatedOn: moment().format("YYYY-MM-DD"),
											updatedBy: details[0].fld_userid,
											type: "image"
										},
										"AddVariantImage"
									).then((results1) =>
										results1.json().then((obj1) => {
											if (results1.status == 200 || results1.status == 201) {
												Notiflix.Loading.Remove();
												Notiflix.Notify.Success("Image Uploading in Process");
												this.onUploadImage('image');
											}
											else {
												Notiflix.Loading.Remove();
												Notiflix.Notify.Failure(obj1);
											}
										})
									);
								}
							} else {
								Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
							}
						}

						}
						src={ImageUploadIcon}
					/>
					{this.state.filteredVariantImages.map((data) => {
						return (
							<div className="inactive-box">
								<img src={data.fld_imageurl} className="img-fluid" />
								<input type="checkbox"
									value={data.fld_variantimageid}
									checked={data.fld_variantid == this.state.VariantID}
									onClick={(e) => {
										Notiflix.Loading.Dots("");
										var login = localStorage.getItem("LoginDetail");
										var details = JSON.parse(login);
										PostApiCall.postRequest(
											{
												variantimageid: null,
												variantid: this.state.VariantID,
												imageurl: data.fld_imageurl,
												createdOn: moment().format("YYYY-MM-DD"),
												updatedOn: moment().format("YYYY-MM-DD"),
												updatedBy: details[0].fld_userid,
												type: "image"
											},
											"AddVariantImage"
										).then((results1) =>
											results1.json().then((obj1) => {
												if (results1.status == 200 || results1.status == 201) {
													PostApiCall.postRequest(
														{
															variantid: "",
														},
														"GetVariantImage"
													).then((results8) =>
														results8.json().then((obj8) => {
															if (results8.status == 200 || results8.status == 201) {
																this.setState({
																	variantImages: obj8.data,
																	filteredVariantImages: obj8.data.filter((dat) => {
																		if (dat.fld_imageurl.toLowerCase().includes(this.state.VariantName.split(" ")[0].toLowerCase()) || dat.fld_variantid == this.state.VariantID) {
																			return dat
																		}
																	}),
																})
																Notiflix.Loading.Remove();
																Notiflix.Notify.Success("Variant image is successfully added");
															}
														})
													);
												}
												else {
													Notiflix.Loading.Remove();
													Notiflix.Notify.Failure(obj1);
												}
											})
										);

									}} />
							</div>
						)
					})}
				</Drawer>
			</React.Fragment>
		);
	}
}
export default OrderList;
