import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import Download from "../../assets/images/Download.jpg"
// import DownloadIcon from "../../assets/images/dashboardimages/cloud-computing.png";
class DownloadCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StateData: []
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        // Notiflix.Loading.Dots("Please wait...");
        GetApiCall.getRequest("Get_State").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    StateData: obj.data,
                });
                Notiflix.Loading.Remove();
            }))
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <div className="mt-3 price-card py-5 col-10">
                        <div>
                            <h3 className="stepper-heading d-flex justify-content-center align-items-center">To update pricing you need to download the respective states pre-formatted CSV File</h3><br />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 justify-content-center">
                                    <Card className="d-flex justify-content-center align-items-center download-container">
                                        <a className="download-csv">
                                            <img className="download-image" src={Download} style={{ height: '250px', width: '250px' }}></img>
                                        </a>
                                        <button className="btn align-items-center downloadcsv-button mt-4 mb-4">Download CSV</button>
                                    </Card>
                                    <h4 className="d-flex justify-content-center">Now Insert the prices in downloaded CSV to update them.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default DownloadCsv