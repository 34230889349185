import moment from 'moment';
import Notiflix from "notiflix";
import React, { Component } from 'react';
import PostApiCall from "../../Api";
import Downloadicon from '../../assets/images/dashboardimages/upload-file.png';
class UploadCsvDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StateData: [],
            UploadCSVURL: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            CSVFile: [],
            PriceState: [],
            StateId: [],
            ItemData: [],
            ItemId: [],
        };
    }
    uploadFile = () => {
        if (JSON.stringify(this.state.CSVFile) != "[]") {
            Notiflix.Loading.Dots("Please wait...");
            const form = new FormData();
            form.append("file", this.state.CSVFile);
            form.append("foldername", "docs/ItemsCSV");
            form.append(
                "filename", moment().format('mmsshh') + (this.state.CSVFile.name.replace(/ /g, "-")).replace(/'/g, '')
            );
            fetch(this.state.UploadCSVURL, {
                method: "POST",
                body: form,
            }).then((image) => {
                image
                    .json()
                    .then((data) => ({
                        data: data,
                        status: image.status,
                    }))
                    .then((res) => {
                        PostApiCall.postRequest(
                            {
                                id: null,
                                filename: res.data.Message.split(",")[1].split("=")[1].trim(),
                                filelocation: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/docs/ItemsCSV/`,
                                filestatus: 'NEW',
                                localpath: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/docs/ItemsCSV/`
                            },
                            "addItemsMaster"
                        ).then((results1) =>
                            results1.json().then((obj1) => {
                                if (results1.status == 200 || results1.status == 201) {
                                    // //
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Success("Variant Stock successfully added.");
                                    window.location.reload();
                                }
                                else {
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Failure(obj1.data);
                                }
                            })
                        );
                    })
            })
        }
        else {
            Notiflix.Notify.Failure('Please upload document')
        }

    }

    onChangeItem(Item) {
        this.setState({
            ItemId: Item,
        });
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center" style={{ height: '300px', marginBottom: '25px' }}>
                    <div className="mt-1 price-card py-5 col-10">
                        {/* <div style={{ marginTop: '-25px' }}>
                            <h4 className="stepper-heading d-flex justify-content-center align-items-center">To update stock, upload the CSV file with the latest stock of products.</h4><br />
                        </div>
                        <div className="col-12">
                            <label className="mt-2">Select Item</label>
                            <Select
                                options={this.state.ItemData}
                                value={this.state.ItemId}
                                onChange={this.onChangeItem.bind(this)}
                                isSearchable={true}
                                isClearable={true} />
                        </div> */}
                        <div className="col-12" style={{ marginTop: '3%' }}>
                            <div className="form-group">
                                <label htmlFor="CategoryName">
                                    Upload (.csv)
                                </label>
                                <input
                                    type="file"
                                    id="CategoryName"
                                    className="form-control"
                                    style={{ display: 'block' }}
                                    accept="application/csv"
                                    onChange={(e) => {
                                        if (
                                            e.target.files[0].size <
                                            9000000
                                        ) {
                                            const reader = new FileReader();
                                            const file =
                                                e.target.files[0];
                                            reader.onloadend = () => {
                                                this.setState({
                                                    CSVFile: file,
                                                });
                                            };
                                            reader.readAsDataURL(file);
                                        } else {
                                            Notiflix.Notify.Failure(
                                                "File too large, upload file less than 9 Mb."
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <button className="btn downloadcsv-button mt-1 mb-1" style={{ float: 'right' }}
                                onClick={this.uploadFile}
                            >
                                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                Upload Items</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default UploadCsvDocument;