import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Media } from "reactstrap";
import PostApiCall from "../../Api";
import "../Authentication/login.css";
const SkeletonSummarychart = () => {
	return (
		<Card className="mini-stats-wid summery-size-comon">
			<CardBody>
				<Media>
					<Media body className="sales-summery-responsive">
						<h4 className="mb-0">
							<Skeleton style={{ width: '30%' }} />
						</h4>
					</Media>
				</Media>
			</CardBody>
		</Card>
	);
};
class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: true,
			series: [
				{
					data: [],
				},
			],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "Month",
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					],
				},
				tooltip: {
					x: {
						format: "₹",
					},
				},
			},
		};
	}
	componentDidMount() {
		// Notiflix.Loading.Init({
		// 	svgColor: "#777f80",
		// });
		// Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				WhereClause: "",
			},
			"Get_DashboardYearlySales"
		).then((results) =>
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						SalesData: obj2.data,
						loader: false,
					});
					const categories = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
					this.setState({
						categories: categories,
					});
					const data = new Array(12).fill(0);
					for (var i = 0; i < obj2.data.length; i++) {
						for (var j = 0; j < categories.length; j++) {
							if (obj2.data[i].month == categories[j]) {
								data[j] = obj2.data[i].total_sales
							}

						}
					}
					this.setState({
						series: [
							{ name: "Order Summary", data: data },
						],
					});
					Notiflix.Loading.Remove();
				}
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				{this.state.loader == true ? (
					<>
						<SkeletonSummarychart />
					</>
				) : (
					<Card className="mini-stats-wid summery-size-comon">
						<CardBody>
							<Media>
								<Media body className="sales-summery-responsive">
									<h4 className="mb-0">Sales Summary Chart </h4>
									<ReactApexChart
										options={this.state.options}
										series={this.state.series}
										type="area"
										height={210}
										width={910}
									/>
								</Media>
							</Media>
						</CardBody>
					</Card>
				)}
			</React.Fragment>
		);
	}
}
export default Chart;
