import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../../Api";
import Editicon from "../../../assets/images/dashboardimages/pencil.png";
import Searchicon from "../../../assets/images/dashboardimages/search.png";
import Uploadicon from "../../../assets/images/dashboardimages/upload-file.png";
import pricelisticon from "../../../assets/images/price-list.png";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import GetApiCall from "../../../GETAPI";
class PriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      PriceData: [],
      StateData: [],
      PriceState: "",
      VariantData: [],
      Variant: [],
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("");
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "fld_variantid as value,fld_variantname as label",
      },
      "GetVariantData"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            VariantData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("GetPriceState").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          StateData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  onPost = () => {
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        stateid: this.state.PriceState,
        variantid: this.state.Variant.value,
      },
      "downloadcsv"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            PriceData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "SKU",
          field: "sku",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Product Name",
          field: "name",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Variant Name",
          field: "variant",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Pricing",
          field: "Pricing",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Shipping",
          field: "Shipping",
          sort: "disabled",
          width: 400,
        },
        {
          label: "USD Price",
          field: "USDPrice",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Max qty",
          field: "MaxQty",
          sort: "disabled",
          width: 400,
        }, {
          label: "Min Qty",
          field: "MinQty",
          sort: "disabled",
          width: 400,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Edit Item",
          field: "edit",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.PriceData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (data.SKU
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase()) ||
            `${data.ProductName ? data.ProductName.toLowerCase() : ""
              }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.VariantName
              ? data.VariantName.toLowerCase()
              : ""
              }`.includes(this.state.searchInput.toLowerCase()))
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          sku: data.SKU,
          name: data.ProductName,
          variant: (<><b>{data.VariantName}</b><br />{data.fld_attributes.split(",").length <= 0 ?
            <div style={{ color: '#000', fontWeight: '600' }}>
              {data.fld_attributes.split("#")[0]}-{data.fld_attributes.split("#")[1]}<br />
            </div>
            : data.fld_attributes.split(",").map((info) => {
              return (
                <div style={{ color: '#000', fontWeight: '300' }}>
                  {info.split("#")[0]} - {info.split("#")[1]}<br />
                </div>
              );
            })}</>),
          Pricing: (<table style={{ border: "1px solid !important" }} ><tr>
            <td>
              MRP -
            </td>
            <td>
              {data.MRP == null ? 0 : "₹" + data.MRP}
            </td>
          </tr>
            <tr>
              <td>
                CostPrice -
              </td>
              <td>
                {data.CostPrice == null ? 0 : "₹" + data.CostPrice}
              </td>
            </tr>
            <tr>
              <td>
                BasePrice -
              </td>
              <td>
                {data.BasePrice == null ? 0 : "₹" + data.BasePrice}
              </td>
            </tr>
            <tr>
              <td>
                GST -
              </td>
              <td>
                {data.ItemGST == null ? 0 : "₹" + data.ItemGST}
              </td>
            </tr>
            <tr>
              <td>
                Selling Price  -
              </td>
              <td>
                {data.WebsiteDiscountPrice == null ? 0 : "₹" + data.WebsiteDiscountPrice}
              </td>
            </tr>
          </table>),
          Shipping: (
            <><table style={{ border: "1px solid !important" }}><tr>
              <td>
                Base -
              </td>
              <td>
                {data.ShippingBaseAmount == null ? 0 : "₹" + data.ShippingBaseAmount}
              </td>
            </tr>
              <tr>
                <td>
                  GST -
                </td>
                <td>
                  {data.ShippingGst == null ? 0 : "₹" + data.ShippingGst}
                </td>
              </tr>
              <tr>
                <td>
                  Shipping Cost   -
                </td>
                <td>
                  {data.ShippingAmount == null ? 0 : "₹" + data.ShippingAmount}
                </td>
              </tr>
            </table></>),
          USDPrice: data.USDPrice == null ? 0 : "$" + data.USDPrice,
          GST: data.BasePrice == null ? 0 : "₹" + data.ItemGST,
          MaxQty: data.MaximumQty == null ? 0 : data.MaximumQty,
          MinQty: data.MinimumQty == null ? 0 : data.MinimumQty,
          action: (
            <td
              className="text-center actionuser"
              style={{ border: "0px!important", paddingTop: "0px" }}
            >
              <button
                onClick={() => {
                  localStorage.setItem(
                    "variantid",
                    JSON.stringify(data.VariantId_Dontupdate)
                  );
                  localStorage.setItem(
                    "stateId",
                    JSON.stringify(this.state.PriceState)
                  );
                  window.location.href = "/add-Price";
                }}
                style={{
                  color: "white",
                }}
                className="btn align-items-center btn Bechofy-btn "
              >
                Add Price
              </button>
            </td>
          ),
          edit: (
            <span>
              <img
                src={Editicon}
                alt="Edit Variant"
                className="btnicons"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem(
                    "VariantDetails",
                    JSON.stringify(data.VariantId_Dontupdate)
                  );
                  window.location.href = "/add-new-variant";
                }}
              ></img>
            </span>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              ).replace("-", " ")}
              urlPath={"/customer-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="pagebackground">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-2">
                        <div className="form-group">
                          <h4
                            className="FilterCard"
                            style={{ marginTop: "10px" }}
                          >
                            Filter Data By
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card
                    style={{
                      paddingLeft: "10px",
                      paddingBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="row my-1 ">
                      <div className="col-4">
                        <select
                          className="form-control gridinput"
                          value={this.state.PriceState}
                          onChange={(text) => {
                            this.setState({
                              PriceState: text.target.value,
                            });
                          }}
                        >
                          <option value="">Select State</option>
                          {this.state.StateData.map((data) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-4">
                        <Select
                          className="gridinput"
                          options={this.state.VariantData}
                          placeholder="Select Variant"
                          value={this.state.Variant}
                          onChange={(value) => {
                            this.setState({
                              Variant: value,
                            });
                          }}
                          isSearchable={true}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-3">
                        <button
                          onClick={() => {
                            if (this.state.PriceState != "") {
                              this.onPost();
                            } else {
                              Notiflix.Notify.Failure("Please select state");
                            }
                          }}
                          style={{
                            marginTop: "15px",
                            color: "white",
                            marginLeft: "0px",
                            marginRight: "4px",
                          }}
                          className="btn align-items-center btn Bechofy-btn "
                        >
                          Filter & Display
                        </button>
                      </div>
                    </div>
                  </Card>
                  <Card className="pagebackground">
                    <div
                      className="Bechofy-bg-soft-pink"
                      style={{ height: "60px" }}
                    >
                      <div className="row my-1">
                        <div className="col-2">
                          <div className="form-group">
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              Price List
                            </h4>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="position-relative">
                            <a
                              href="/upload-price"
                              style={{
                                float: "right",
                                marginTop: "10px",
                                background: "white",
                                color: "grey",
                                marginRight: "20px",
                              }}
                              className="btn align-items-center btn Bechofy-btn "
                            >
                              <span className="">
                                <img
                                  src={Uploadicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginRight: "5px" }}
                                ></img>{" "}
                              </span>
                              Upload Price
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.PriceData.length != 0 ? (
                      <>
                        <Card style={{ padding: "10px 20px" }}>
                          <div className="row my-1 ">
                            <div className="col-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <span className="">
                                      <img
                                        src={Searchicon}
                                        alt="block user"
                                        className="btnicons"
                                      ></img>
                                    </span>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type the search phrase you wish to search within the grid"
                                  aria-label="Search"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) => this.seachBarHandler(e)}
                                  value={this.state.searchInput}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                        <Card>
                          <CardBody className="pt-0">
                            <Row>
                              <Col md="12">
                                <MDBDataTable
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[
                                    10, 20, 40, 60, 80, 100, 120, 140,
                                  ]}
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTable>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      <Row className="mt-4 pt-2">
                        <Col sm="12" className="text-center">
                          <img
                            src={pricelisticon}
                            alt="block user"
                            className="customerhistory-icons ms-3 mt-1"
                            style={{ marginRight: "5px" }}
                          />
                        </Col>
                        <Col sm="12" className="text-center my-3">
                          <h6>Price list no found</h6>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default PriceList;
