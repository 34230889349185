import Parser from "html-react-parser";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import addicon from "../../assets/images/dashboardimages/add.png";
import deleteicon from "../../assets/images/dashboardimages/delete.png";
import editicon from "../../assets/images/dashboardimages/edit.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import selecticon from "../../assets/images/dashboardimages/select.png";
import searchdata from "../../assets/images/search-engine.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
const Skeletonsubcategorymaster = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between mb-0 pb-0 ">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="12">
                    <div className="d-flex mb-3">
                      <Skeleton className="py-1 px-5" /> /{" "}
                      <Skeleton className="py-1 px-5" />{" "}
                    </div>
                    <div className="imagecard">
                      <div className="form-group ">
                        <Skeleton
                          className="mx-0 mb-3 mt-2"
                          style={{ height: "20px", width: "200px" }}
                        />
                        <div className="div1">
                          <Skeleton
                            style={{ height: "200px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                    <Skeleton className="py-2 px-3" />
                    <Skeleton className="py-2 px-3 my-2" />
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                    <Skeleton className="py-2 px-2 ml-3" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};
class SubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubCategoryData: [],
      CategoryData: [],
      selectedCategory: "",
      searchInput: "",
      count: "",
      loader: true,
    };
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_showOnWebsite ='Active' OR fld_showOnWebsite = 'True'`,
      },
      "Get_categorydropdown"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            CategoryData: obj.data,
            loader: false,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("Get_Subcategory").then((resultdes1) =>
      resultdes1.json().then((obj1) => {
        this.setState({
          SubCategoryData: obj1.data,
          count: obj1.data.length,
          loader: false,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  OnSelectHandler = (e) => {
    this.setState({ ...this.state, selectedCategory: e.target.value });
  };
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ').replace('-', ' ')}
              urlPath={"/sub-category-management"}
            />
            <div className="overflow-hidden pagebackground">
              <div className="Bechofy-bg-soft-pink">
                <Row>
                  <Col xs="3">
                    <div className="Bechofy-text p-3">
                      <h5 className="Bechofy-text">Filter</h5>
                    </div>
                  </Col>
                </Row>
              </div>
              <Card style={{ padding: " 0 20px" }}>
                <div className="row my-1 ">
                  <div className="col-lg-10 col-md-8">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend gridinput">
                          <span className="input-group-text" id="basic-addon1">
                            <span className="">
                              <img
                                src={selecticon}
                                alt="block user"
                                className="btnicons"
                              ></img>
                            </span>
                          </span>
                        </div>
                        <select
                          onChange={(e) => {
                            this.OnSelectHandler(e);
                          }}
                          className="form-control gridinput"
                        >
                          <option value="">Select Category</option>
                          {this.state.CategoryData.map((data, i) => {
                            return (
                              <option value={data.value}>{data.label}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4">
                    <div className="position-relative">
                      <a
                        style={{
                          marginTop: "15px",
                          color: "white",
                        }}
                        className="btn align-items-center btn Bechofy-btn "
                      >
                        Filter & Display
                      </a>
                    </div>
                  </div>
                </div>
              </Card>
              {this.state.loader == true ? (
                <>
                  <Skeletonsubcategorymaster />
                </>
              ) : (
                <Card>
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-6">
                        <div className="form-group">
                          <h4
                            className="px-4 text-white text-start"
                            style={{ marginTop: "14px" }}
                          >
                            {this.state.count == 0 ? 'Sub Category List' : <>Sub Category List [{this.state.count}]</>}
                          </h4>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="Bechofy-text px-3 mt-1 py-2">
                          <Link
                            to="/add-sub-category/create"
                            className="align-items-center Bechofy-btn category-Add-btn categorybtnposition"
                          >
                            <span className="">
                              <img
                                src={addicon}
                                alt="block user"
                                className="btnicons"
                                style={{ marginRight: "5px" }}
                              ></img>{" "}
                            </span>
                            Add Sub Category{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <div
                        className="input-group mb-3"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <span className="">
                              <img
                                src={Searchicon}
                                alt="block user"
                                className="btnicons"
                              ></img>
                            </span>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type the search phrase you wish to search within the grid"
                          aria-label="Search"
                          aria-describedby="basic-addon1"
                          onChange={(e) => this.seachBarHandler(e)}
                          value={this.state.searchInput}
                        />
                      </div>
                      {this.state.selectedCategory === ""
                        ? this.state.SubCategoryData.filter((data) => {
                          let name = [
                            ...data.fld_categoryname
                              .toLowerCase()
                              .matchAll(this.state.searchInput.toLowerCase()),
                          ];
                          let status = [
                            ...data.fld_showOnWebsite
                              .toLowerCase()
                              .matchAll(this.state.searchInput.toLowerCase()),
                          ];
                          let subcategory = [
                            ...data.fld_subcategoryname
                              .toLowerCase()
                              .matchAll(this.state.searchInput.toLowerCase()),
                          ];
                          if (this.state.searchInput.length > 0 && name[0]) {
                            return data;
                          }
                          if (
                            this.state.searchInput.length > 0 &&
                            status[0]
                          ) {
                            return data;
                          }
                          if (
                            this.state.searchInput.length > 0 &&
                            subcategory[0]
                          ) {
                            return data;
                          }
                          if (this.state.searchInput.length == 0) {
                            return data;
                          }
                        }).map((data, i) => {
                          return (
                            <Col xs="12" md="12" lg="6" xl="4">
                              <CardBody className="categoryCard">
                                <CardTitle className="mb-1">
                                  <nav
                                    aria-label="breadcrumb"
                                    className="px-1 py-1 m-0  justify-content-start"
                                    style={{ background: "none" }}
                                  >
                                    <ol
                                      className="breadcrumb p-0 mb-2"
                                      style={{
                                        background: "none",
                                        color: "#495057",
                                      }}
                                    >
                                      <li
                                        className="breadcrumb-item"
                                        style={{ color: "#495057" }}
                                      >
                                        <span>{data.fld_categoryname}</span>
                                      </li>
                                      <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                      >
                                        {data.fld_subcategoryname}
                                      </li>
                                    </ol>
                                  </nav>
                                  <Row>
                                    <Col sm="12" className="mx-1 mb-2">
                                      <h6>
                                        Last Updated on {data.fld_created_on}
                                      </h6>
                                    </Col>
                                  </Row>
                                </CardTitle>
                                <Row className="card-row">
                                  <Col
                                    sm="12"
                                    className="my-2"
                                  >
                                    <div className="imagecard">
                                      <img
                                        src={data.fld_image}
                                        className="categoryimage categorymanagment-img-style"
                                      ></img>
                                    </div>
                                  </Col>
                                  <Col
                                    sm="12"
                                    className="card-content-overflow-subcategory"
                                  >
                                    <div className="">
                                      <p className="categorydesc px-0 pt-1 subcategory-card-content">
                                        {" "}
                                        {
                                          data.fld_description == ""
                                            ? <div className="tex-center category-description-empty">
                                              <img src={searchdata} />
                                              <p>Details not available</p>
                                            </div>
                                            : Parser(
                                              data.fld_description
                                                .replace(/font-family/g, "")
                                                .replace(/<p>/g, "")
                                            )}
                                      </p>
                                    </div>
                                  </Col>
                                  <hr />
                                </Row>
                                <Row>
                                  <Col sm="4">
                                    <p className="categorystatus">
                                      <b>Status</b> -{" "}
                                      <span
                                        style={{
                                          color:
                                            data.fld_showOnWebsite == "Active"
                                              ? "Green"
                                              : "Red",
                                        }}
                                      >
                                        {data.fld_showOnWebsite}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col sm="">
                                    <span className="">
                                      <img
                                        src={deleteicon}
                                        alt="block user"
                                        className="btnicons deleteicons"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          confirmAlert({
                                            title: "Confirm to Inatcive",
                                            message:
                                              "Are you sure you want to Inactive the sub-category.",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  // Notiflix.Loading.Dots("");
                                                  PostApiCall.postRequest(
                                                    {
                                                      id: data.fld_subcategoryid,
                                                      showOnWebsite:
                                                        "Inactive",
                                                    },
                                                    "DeleteSubcategory"
                                                  ).then((results) =>
                                                    results
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          results.status ==
                                                          200 ||
                                                          results.status ==
                                                          201
                                                        ) {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Success(
                                                            "Sub Category successfully deleted."
                                                          );
                                                          window.location.reload();
                                                        } else {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Failure(
                                                            "Something went wrong, try again later."
                                                          );
                                                        }
                                                      })
                                                  );
                                                },
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });
                                        }}
                                      ></img>
                                    </span>
                                    <Link to={`/add-sub-category/update`}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "SubCategoryDetails",
                                          JSON.stringify(data)
                                        );
                                      }}
                                      className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                      style={{
                                        marginTop: "10px",
                                        color: "white",
                                      }}
                                    >
                                      <span className="">
                                        <img
                                          src={editicon}
                                          alt="block user"
                                          className="btnicons"
                                          style={{ marginRight: "5px" }}
                                        ></img>{" "}
                                      </span>
                                      Edit Sub Category
                                    </Link>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Col>
                          );
                        })
                        : this.state.SubCategoryData.filter((data) => {
                          if (
                            data.fld_categoryid == this.state.selectedCategory
                          ) {
                            return data;
                          }
                        }).map((data, index) => (
                          <Col xs="4">
                            <CardBody className="categoryCard">
                              <CardTitle className="mb-4">
                                {data.fld_categoryname}
                                <p className="subcategoryname">
                                  {data.fld_subcategoryname}
                                </p>
                              </CardTitle>
                              <Row className="card-row">
                                <Col sm="4">
                                  <div className="imagecard">
                                    <img
                                      src={data.fld_image}
                                      className="categoryimage"
                                    ></img>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="">
                                    <p className="categorydesc">
                                      {" "}
                                      {data.fld_description == null
                                        ? ""
                                        : Parser(
                                          data.fld_description
                                            .replace(/font-family/g, "")
                                            .replace(/<p>/g, "")
                                        )}
                                    </p>
                                  </div>
                                </Col>
                                <hr />
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <p className="categorystatus">
                                    <b>Status</b> -{" "}
                                    <span
                                      style={{
                                        color:
                                          data.fld_showOnWebsite == "Active"
                                            ? "Green"
                                            : "Red",
                                      }}
                                    >
                                      {data.fld_showOnWebsite}
                                    </span>
                                  </p>
                                </Col>
                                <Col sm="8">
                                  <span className="">
                                    <img
                                      src={deleteicon}
                                      alt="block user"
                                      className="btnicons deleteicons"
                                      onClick={() => {
                                        confirmAlert({
                                          title: "Confirm to Delete",
                                          message:
                                            "Are you sure you want to delete category.",
                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () => {
                                                PostApiCall.postRequest(
                                                  {
                                                    id: data.fld_subcategoryid,
                                                    showOnWebsite: "Disabled",
                                                  },
                                                  "DeleteSubcategory"
                                                ).then((results) =>
                                                  results
                                                    .json()
                                                    .then((obj) => {
                                                      if (
                                                        results.status ==
                                                        200 ||
                                                        results.status == 201
                                                      ) {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Success(
                                                          "Sub Category successfully deleted."
                                                        );
                                                        window.location.reload();
                                                      } else {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Failure(
                                                          "Something went wrong, try again later."
                                                        );
                                                      }
                                                    })
                                                );
                                              },
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                        });
                                      }}
                                    ></img>{" "}
                                  </span>
                                  <Link to={`/add-sub-category/update`}
                                    onClick={() => {
                                      localStorage.setItem(
                                        "SubCategoryDetails",
                                        JSON.stringify(data)
                                      );

                                    }}
                                    className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                    style={{
                                      marginTop: "10px",
                                      color: "white",
                                    }}
                                  >
                                    <span className="">
                                      <img
                                        src={editicon}
                                        alt="block user"
                                        className="btnicons"
                                        style={{ marginRight: "5px" }}
                                      ></img>{" "}
                                    </span>
                                    Edit Sub Category
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        ))}
                    </Row>
                  </CardBody>
                </Card>
              )}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default SubCategory;
