import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import PostApiCall from "../../Api";
import orderemptyicon from "../../assets/images/box.png";
import "../Authentication/login.css";
class SocialSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderListData: [],
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    PostApiCall.postRequest(
      {
        WhereClause: "order by fld_order_date desc",
      },
      "Get_DashboardOrderList"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {

          this.setState({
            OrderListData: obj.data.filter(
              (value) =>
                value.fld_payment_status == "Successful" ||
                value.fld_payment_status == "success"
            ),
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Order Number",
          field: "ordernumber",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Date & Time",
          field: "orderdate",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Customer Name",
          field: "name",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Total Items",
          field: "totalitems",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Status",
          field: "orderstatus",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Payment Status",
          field: "paymentstatus",
          sort: "disabled",
          width: 200,
        },
      ],
      rows: this.state.OrderListData.filter((data) => {
        {
          return data;
        }
      }).map((data, i) => {
        return {
          ordernumber: data.fld_order_number,
          orderdate: moment(data.fld_order_date).format("DD/MM/YYYY"),
          name: (
            <div className="text-start newcustomer-mdtablecol">
              {data.customer_name}
            </div>
          ),
          totalitems: data.items_count,
          ordervalue: parseFloat(data.fld_order_amount).toFixed(2),
          orderstatus: data.fld_order_status,
          paymentstatus: data.fld_payment_status,
        };
      }),
    };
    return (
      <React.Fragment>
        {this.state.loader == true ? (
          <>
            <Skeletonlatestcustomers />
          </>
        ) : (
          <Card>
            <div>
              <Row className="mb-3">
                <Col xs="6" className="px-4">
                  <div className="Bechofy-text py-3 px-2">
                    <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                      Latest Orders
                    </h4>
                  </div>
                </Col>
                <Col xs="6" className="px-4">
                  <div className="Bechofy-text">
                    <Link
                      to="/order_list"
                      style={{
                        float: "right",
                        background: "black",
                        color: "white",
                        marginTop: "13px",
                        marginBottom: "13px",
                      }}
                      className="btn align-items-center btn Bechofy-btn "
                    >
                      View All Orders{" "}
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody className="pt-0 px-4">
              <Row>
                <Col
                  xs="12"
                  className="latestreview-mdbtable lastestcustomer-table-responsive"
                >
                  {this.state.OrderListData.length != 0 ? (
                    <MDBDataTable
                      hover
                      // scrollY
                      striped
                      bordered
                      data={data}
                      seachTop={false}
                      className="customer-table-responsive"
                      entriesOptions={[10, 25, 50, 100]}
                    >
                      <MDBTableHead
                        className="centered"
                        columns={data.columns}
                      />
                      <MDBTableBody rows={data.rows} />
                    </MDBDataTable>
                  ) : (
                    <Row className="mt-5 pt-5">
                      <Col sm="12" className="text-center">
                        <img
                          src={orderemptyicon}
                          alt="block user"
                          className="customerhistory-icons ms-3 mt-1"
                          style={{ marginRight: "5px" }}
                        />
                      </Col>
                      <Col sm="12" className="text-center my-3">
                        <h6>No Latest Orders</h6>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </React.Fragment>
    );
  }
}
export default SocialSource;
