import React, { Component } from "react";
//import SideBar from "./SideBar";
import { CardBody, CardTitle, Col, Container, Row } from "reactstrap";
const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                htmlFor="photo-upload"
                src={require("../../assets/images/upload.png")}
                style={{
                    width: "100%", borderRadius: "5%", height: '85%', marginRight: 'auto',
                    marginLeft: 'auto',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: '5%'
                }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid style={{ backgroundColor: "white" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-0">
                                        {/* <SideBar /> */}
                                    </div>
                                    <div className="col-lg-9 col-md-12">
                                        <form>
                                            <CardBody>
                                                <CardTitle className="usertitle sidebar-heading">
                                                    My Profile
                                                </CardTitle>
                                                <Row>
                                                    <Col lg="12" xs="12" md="12">
                                                        <div className="form my-4">
                                                            <div className="row profile-form">
                                                                <div className="col col-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-sm-12 col-xm-12 img-upload">
                                                                            <label htmlFor="sw-arrows-first-name " style={{ float: 'left' }}>
                                                                                Upload User Image
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <div className="div1">
                                                                                <ImgUpload
                                                                                    onChange={this.photoUpload}
                                                                                    src={this.state.imagePreviewUrl}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-12 col-xm-12">
                                                                            <div className="row">
                                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xm-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="CategoryName">
                                                                                            Name
                                                                                            <span className="mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="CategoryName"
                                                                                            className="form-control"
                                                                                            name="Name"
                                                                                            value={this.state.Name}
                                                                                            onChange={(text) => {
                                                                                                this.setState({
                                                                                                    Name: text.target.value,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="CategoryName">
                                                                                            Email
                                                                                            <span className="mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="email"
                                                                                            id="CategoryName"
                                                                                            className="form-control"
                                                                                            name="Email"
                                                                                            value={this.state.Email}
                                                                                            onChange={(text) => {
                                                                                                this.setState({
                                                                                                    Email: text.target.value,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="CategoryName">
                                                                                            Mobile no
                                                                                            <span className="mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="CategoryName"
                                                                                            className="form-control"
                                                                                            value={this.state.Mobile}
                                                                                            onChange={(mobile) => {
                                                                                                if (
                                                                                                    this.state.NumRegex.test(
                                                                                                        mobile.target.value
                                                                                                    ) &&
                                                                                                    mobile.target.value
                                                                                                        .length <= 10
                                                                                                ) {
                                                                                                    this.setState({
                                                                                                        Mobile:
                                                                                                            mobile.target.value,
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                                                                                    <div className="form-group">
                                                                                        <label
                                                                                            className="my-1 mr-2"
                                                                                            htmlFor="inlineFormCustomSelectPref"
                                                                                        >
                                                                                            Gender
                                                                                            <span className="mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <select
                                                                                            className="custom-select my-1 mr-sm-2"
                                                                                            id="inlineFormCustomSelectPref"
                                                                                            value={this.state.Gender}
                                                                                            onChange={(text) => {
                                                                                                this.setState({
                                                                                                    Gender: text.target.value,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <option>Select Gender</option>
                                                                                            {/* {this.state.GenderData.map(
																									(gender) => (
																										<option
																											key={gender.value}
																											value={gender.value}
																										>
																											{gender.label}
																										</option>
																									)
																								)} */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="CategoryName">
                                                                                            Date of Birth
                                                                                            <span className="mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="date"
                                                                                            id="CategoryName"
                                                                                            className="form-control my-1 mr-sm-2"
                                                                                            value={this.state.DOB}
                                                                                            onChange={(text) => {
                                                                                                this.setState({
                                                                                                    DOB: text.target.value,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <button
                                                                                        style={{ float: "right", marginTop: "5px" }}
                                                                                        className="btn align-items-center Bechofy-btn "
                                                                                    // onClick={this.onSaveData.bind(this)}
                                                                                    >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
export default Profile;