import Notiflix from "notiflix";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import Select from 'react-select';
import PostApiCall from "../../Api";
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png';

class CsvDownload extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      StateData: [],
      StateId: [],
      WarehouseData: [],
      WareHouseId: [],
      fileData: [],
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: `where fld_status='Active'`,
        recordCount: 'fld_name as label,fld_warehouseid as value'
      },
      "GetWarehouseByState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              WarehouseData: obj.data,
            });
          }
          Notiflix.Loading.Remove();
        }
      }))
  }
  onChangeWareHouse(warehouse) {
    this.setState({
      //WareHouseId: warehouse.target.value,
      WareHouseId: warehouse,
    });
  }
  // function to download csv file
  DownalodStockCSV = () => {
    if (JSON.stringify(this.state.WareHouseId) != "[]") {
      Notiflix.Loading.Dots("Please wait...");
      PostApiCall.postRequest(
        {
          warehouse: this.state.WareHouseId.value
        },
        "downloadStockcsv"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            if (obj.data.length != 0) {
              // this.props.setstate()
              this.setState({
                fileData: obj.data,
              }, () => {
                setTimeout(() => {
                  this.csvLink.current.link.click();
                });
              });
            }
            Notiflix.Loading.Remove();
          }
        }))
    }
    else {
      Notiflix.Notify.Failure('Please select Warehouse')
    }

  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center" style={{ height: '370px', marginBottom: '25px' }}>
          <div className="mt-1 price-card py-5 col-10">
            <div style={{ marginTop: '-25px' }}>
              <h4 className="stepper-heading d-flex justify-content-center align-items-center">To update stock upload the CSV file with the latest stock of products.</h4><br />
            </div>
            <div className="col-12">
              <label className="mt-2">Select Warehouse</label>
              <Select
                options={this.state.WarehouseData}
                value={this.state.WareHouseId} // this id is the warehouse itself
                onChange={this.onChangeWareHouse.bind(this)}
                isSearchable={true}
                isClearable={true} />
            </div>
            <div>
              <button className="btn downloadcsv-button mt-4 mb-4" style={{ float: 'right' }}
                onClick={this.DownalodStockCSV.bind(this)}
              >
                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                Download CSV</button>
              <CSVLink
                data={this.state.fileData}
                filename={this.state.WareHouseId?.label + '.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default CsvDownload;
