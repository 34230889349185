import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
const Skeletonrefund = ({ cards }) => {
	return (
		<Row>
			<Col md="12" lg="12">
				<Card style={{ height: "400px" }}>
					<CardBody>
						<Media>
							<Media body>
								<div className="row">
									<div className="col-md-4">
										<h4 className="mt-0 mb-3">
											<Skeleton className="py-1" />
										</h4>
									</div>
									{Array(cards)
										.fill(0)
										.map((item, i) => (
											<>
												<div className="col-md-10" key={i}>
													<h4 className="mt-0 mb-3">
														<Skeleton className="py-2" />
													</h4>
												</div>
											</>
										))}
								</div>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};
class SocialSource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			socials: [
				{ title: "Facebook", bgColor: "bg-primary", iconClass: "mdi-facebook", description: "125" },
				{ title: "Twitter", bgColor: "bg-info", iconClass: "mdi-twitter", description: "112" },
				{ title: "Instagram", bgColor: "bg-pink", iconClass: "mdi-instagram", description: "104" }
			],
			RefundSummaryAmount: [],
			RefundSummaryAll: [],
			RefundOrderThisMonth: [],
			RefundOrderThisYear: [],
			loader: true,
		};
	}
	componentDidMount() {
		// Notiflix.Loading.Custom("");
		PostApiCall.postRequest(
			{
				WhereClause: 'All'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//
					this.setState({
						RefundSummaryAll: obj.data,
						loader: false
					});
					// Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund Amount'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//
					this.setState({
						RefundSummaryAmount: obj.data,
					});
					// Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund This Month'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//
					this.setState({
						RefundOrderThisMonth: obj.data,
					});
					// Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund This Year'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//
					this.setState({
						RefundOrderThisYear: obj.data,
					});
					// Notiflix.Loading.Remove();
				}
			}))
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				{this.state.loader == true ? (
					<>
						<Skeletonrefund cards={4} />
					</>
				) : (
					<Card className="mini-stats-wid">
						<CardBody>
							<Media>
								<Media body>
									<Row>
										<Col xl="6">
											<h4 className="mb-0">Refund Summary
											</h4>
										</Col>
									</Row>
									<hr />
									<p className="text-muted font-weight-medium">
										All Refund Orders
										{this.state.RefundSummaryAll.map((data, i) => {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count}
												</span>
											)
										})}
									</p>
									<hr />
									<p className="text-muted font-weight-medium">
										Refund Amount
										{this.state.RefundSummaryAmount.map((data, i) => {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count == null ? 0 : data.order_count}
												</span>
											)
										})}
									</p>
									<hr />
									<p className="text-muted font-weight-medium">
										Refund Order this Month
										{this.state.RefundOrderThisMonth.map((data, i) => {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count}
												</span>
											)
										})}
									</p>
									<hr />
									<p className="text-muted font-weight-medium">
										Refund Order this Year
										{this.state.RefundOrderThisYear.map((data, i) => {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count}
												</span>
											)
										})}
									</p>
								</Media>
							</Media>
						</CardBody>
					</Card>
				)}
			</React.Fragment>
		);
	}
}
export default SocialSource;