import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PostApiCall from '../../Api';
import Notiflix from "notiflix";
import GetApiCall from '../../GETAPI';
import WebsiteFooter from './WebsiteFooter';
import CKEditor from "ckeditor4-react";

function ClientInfoInternalPages(props) {
    const image_url = '../../assets/images/wedding-rings-pair (1).jpeg';
    const [isOpen, setIsOpen] = useState(false);
    const [heading1, setHeading1] = useState("")
    const [description1, setDescription1] = useState("")
    const [description2, setDescription2] = useState("")
    const [status, setStatus] = useState("")
    const [sequence, setSequence] = useState("")
    const [itemToBeDisplayed, setItemToBeDisplayed] = useState(null);
    const [sectionId, setSectionId] = useState(null)

    const AddWebsiteHomeSection = () => {
        Notiflix.Loading.Dots("")
        PostApiCall.postRequest({
            id: sectionId,
            sequence: sequence,
            sectionname: "Parallax Section",
            status: status,
            headline1: heading1,
            headline2: description1,
        }, "AddWebsiteHomeSection"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    GetWebsiteHomeSection();
                    Notiflix.Loading.Remove();
                }
            });
        });
    }
    useEffect(() => {
        GetWebsiteHomeSection();
    }, [])
    const GetWebsiteHomeSection = () => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetHomepageSetup"
        ).then((homepagesetupresult) => {
            homepagesetupresult.json().then((obj) => {
                obj.data.filter(
                    (value) => value.fld_section_name == "Parallax Section"
                ).map(filtereddata => {
                    setSectionId(filtereddata.fld_id)
                    setHeading1(filtereddata.fld_headline1)
                    setDescription1(filtereddata.fld_headline2);
                    setDescription2(filtereddata.fld_headline3);
                    setSequence(filtereddata.fld_sequence);
                    setStatus(filtereddata.fld_status);
                }
                );



            });
        });
    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {/* <h2 className='text-center'>Parallax Section</h2> */}


                    <section className="parallax height-35" style={{ background: `url(https://meluhahome.com/images/ProductImages/523447-Mango%20Flower%20Full%201.webp)` }}>
                        <h1 className="text-white fw-bold">{heading1}</h1>
                    </section>
                    <div className='row'>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="BasePrice">
                                    Section Heading
                                    <span className="mandatory">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="BasePrice"
                                    className="form-control"
                                    value={heading1}
                                    onChange={(e) => { setHeading1(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <label>
                                Show on website
                                <span className="mandatory">*</span>
                            </label>
                            <select
                                value={status}
                                onChange={(e) => { setStatus(e.target.value) }}
                                className="custom-select"
                            >
                                <option value="">
                                    Select
                                </option>
                                <option value="Active">Yes</option>
                                <option value="InActive">No</option>
                            </select>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="sw-arrows-first-name">
                                    Short Description(maximum 300 Character)
                                    <span className="mandatory">*</span>
                                </label>
                                <div className="niceeditors">
                                    <CKEditor
                                        config={{
                                            extraPlugins:
                                                "justify,font,colorbutton",
                                        }}
                                    // data={this.state.ShortDescription}
                                    // onChange={(text) => {
                                    //     this.setState({
                                    //         ShortDescription:
                                    //             text.editor.getData(),
                                    //     });
                                    // }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='text-right mb-4'>
                        <button className="btn align-items-center Bechofy-btn" onClick={AddWebsiteHomeSection}>Save</button>
                    </div>


                    <WebsiteFooter />
                </Container>
            </div>
        </>
    );
}

export default ClientInfoInternalPages;




