import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import addicon from "../../assets/images/dashboardimages/product.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const Skeletonattributelist = () => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between mb-0 pb-0">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};
class AttributePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchInput: "",
      AttributeData: [],
      ViewClick: "true",
      open: false,
      Status: "Active",
      Name: "",
      openedit: false,
      Id: null,
      AttributeName: null,
      count: "",
      loader: true
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    GetApiCall.getRequest("GetAttributeMasterList").then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        this.setState({
          AttributeData: obj.data,
          count: obj.data.length,
          loader: false
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              urlPath={"/Attribute-ist"}
            />
            <Row>
              <Col xl="12">
                {this.state.loader == true ? (
                  <>
                    <Skeletonattributelist />
                  </>
                ) : (
                  <Card className="overflow-hidden pagebackground">
                    <div className="Bechofy-bg-soft-pink">
                      <Row>
                        <Col xs="3">
                          <div className="Bechofy-text p-3">
                            <h5 className="Bechofy-text pb-0 mb-0" id="upload-card">
                              {this.state.count == 0 ? 'Attribute List' : <>Attribute List [{this.state.count}]</>}
                            </h5>
                          </div>
                        </Col>
                        <Col xs="9">
                          <Link to={`/add-attribute`}>
                            <button
                              className="btn align-items-center btn Bechofy-btn AddNewBtn"
                            >
                              <span className="">
                                <img
                                  src={addicon}
                                  alt="block user"
                                  className="btnicons"
                                  style={{ marginRight: "5px" }}
                                ></img>{" "}
                              </span>
                              Add New Attribute{" "}
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                    <Card style={{ padding: "10px 20px" }}>
                      <div className="row my-1 ">
                        <div className="col-12">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type the search phrase you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Row>
                      <Col xl="12">
                        <Row>
                          {/* ======Attribute List */}
                          {this.state.AttributeData.filter((data) => {
                            if (this.state.searchInput == "") {
                              return data;
                            }
                            if (
                              this.state.searchInput !== "" &&
                              (data.fld_attributename
                                .toLowerCase()
                                .includes(
                                  this.state.searchInput.toLowerCase()
                                ) ||
                                `${data.fld_status
                                  ? data.fld_status.toLowerCase()
                                  : ""
                                  }`.includes(
                                    this.state.searchInput.toLowerCase()
                                  ))
                            ) {
                              return data;
                            }
                          }).map((data, i) => (
                            <Col xs="4">
                              <Card>
                                <CardBody className="categoryCard attribut-list-border">
                                  <Row className="card-row">
                                    <Col sm="12" className="px-0 mb-3">
                                      <h6>
                                        <b> Last Updated on </b>
                                        {moment(data.fld_created_on).format(
                                          "DD/MM/YYYY"
                                        )}{" "}
                                      </h6>
                                    </Col>
                                    <Col sm="12">
                                      <div className="row">
                                        <div className="col-12 px-0">
                                          <p>
                                            <b>Attribute Name</b> -{" "}
                                            <span>
                                              {data.fld_attributename}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="row">
                                        <div className="col-12 px-0">
                                          <p>
                                            <b>Attribute Values</b> -{" "}
                                            <span>
                                              {data.attribute_values}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                    <hr className="my-3" />
                                  </Row>
                                  <Row className="mt-3">
                                    <Col sm="6" className="px-0">
                                      <div className="form-group">
                                        <p>
                                          <b>Status</b> -{" "}
                                          <span
                                            style={{
                                              color:
                                                data.fld_status == "Active"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {data.fld_status}
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm="6" className="">
                                      <Link to={{
                                        pathname: `/add-attribute`, state: {
                                          attributeid: data.fld_attributeid,
                                          attributename: data.fld_attributename,
                                          attributestatus: data.fld_status
                                        }
                                      }}>
                                        <button
                                          onClick={() => {
                                            this.setState({
                                              Status: data.fld_status,
                                              Id: data.fld_attributeid,
                                              AttributeName:
                                                data.fld_attributename,
                                            });
                                          }}
                                          className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                          style={{
                                            marginTop: "5px",
                                            color: "white",
                                          }}
                                        >
                                          <span className="">
                                            <img
                                              src={Editicon}
                                              alt="block user"
                                              className="btnicons"
                                              style={{ marginRight: "5px" }}
                                            ></img>{" "}
                                          </span>
                                          Edit Attribute
                                        </button>
                                      </Link>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AttributePage;
