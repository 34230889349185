import Notiflix from "notiflix";
import React, { Component } from "react";
import Slider from "react-slick";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import Carticon from "../../assets/images/empty-cart.png";
import NoImage from "../../assets/images/NoImage.png";
import "../Authentication/login.css";
class TopCities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      CartData: [],
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    var customer = localStorage.getItem("customerDetails");
    var CustomerDetails = JSON.parse(customer);
    // Notiflix.Loading.Dots("Please wait...");
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    //
    PostApiCall.postRequest(
      {
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();

          this.setState({ CartData: res1.data });
        }
      });
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 800,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <h6 className="shopping-cart-font">
                Items in Cart
              </h6>
            </CardTitle>
            {this.state.CartData.length != 0 ? (
              <Slider {...settings}>
                <div className="best-products">
                  {this.state.CartData.map((data, i) => (
                    <div className="product-item">
                      <div className="img-container" width="50%">
                        <img
                          src={data.VariantImage ? data.VariantImage : NoImage}
                          alt="product image"
                        ></img>
                      </div>
                      <div className="product-desc">
                        <h4>{data.fld_variantname} </h4>
                      </div>
                    </div>
                  ))}
                </div>
              </Slider>
            ) : (
              <Row>
                <Col sm="12" className="text-center">
                  <img
                    src={Carticon}
                    alt="block user"
                    className="customerhistory-icons ms-3 mt-1"
                    style={{ marginRight: "5px" }}
                  />
                </Col>
                <Col sm="12" className="text-center my-3">
                  <h6>Shopping Cart is Empty</h6>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default TopCities;
