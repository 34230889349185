import React, { Component } from "react";
import { Container } from "reactstrap";
class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <nav className="navbar navbar-expand-xl navbar-dark  navbar-togglable  fixed-top">
              <div className="container">
                {/* Brand */}
                <a className="navbar-brand" href="index.html">
                </a>
                {/* Toggler */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                {/* Collapse */}
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item-divider">
                      <span className="nav-link">
                        <span></span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="fab fa-github"></i>
                        <span className="d-xl-none ml-2">
                          Github
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="fab fa-twitter"></i>
                        <span className="d-xl-none ml-2">
                          Twitter
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="fab fa-instagram"></i>
                        <span className="d-xl-none ml-2">
                          Instagram
                        </span>
                      </a>
                    </li>
                  </ul>
                  {/* Links */}
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarWelcome" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Welcome
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarWelcome">
                        <a className="dropdown-item  active " href="index.html">
                          Header: Image
                        </a>
                        <a className="dropdown-item " href="index-header-carousel.html">
                          Header: Carousel
                        </a>
                        <a className="dropdown-item " href="index-header-parallax.html">
                          Header: Parallax
                        </a>
                        <a className="dropdown-item " href="index-header-video.html">
                          Header: Video
                        </a>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarLandings" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Landings
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarLandings">
                        <a className="dropdown-item " href="app.html">
                          App
                        </a>
                        <a className="dropdown-item " href="company.html">
                          Company
                        </a>
                        <a className="dropdown-item " href="fullpage.html">
                          Fullpage
                        </a>
                        <a className="dropdown-item " href="product.html">
                          Product
                        </a>
                        <a className="dropdown-item " href="real-estate.html">
                          Real estate
                        </a>
                        <a className="dropdown-item " href="restaurant.html">
                          Restaurant
                        </a>
                        <a className="dropdown-item " href="service.html">
                          Service
                        </a>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarPages" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Pages
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarPages">
                        <li className="dropright">
                          <a className="dropdown-item dropdown-toggle" href="#" id="pagesBlog" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Blog
                          </a>
                          <div className="dropdown-menu" aria-labelledby="pagesBlog">
                            <a className="dropdown-item " href="blog.html">
                              Blog
                            </a>
                            <a className="dropdown-item " href="blog-post.html">
                              Blog: Post
                            </a>
                          </div>
                        </li>
                        <li className="dropright">
                          <a className="dropdown-item dropdown-toggle" href="#" id="pagesShop" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Shop
                          </a>
                          <div className="dropdown-menu" aria-labelledby="pagesShop">
                            <a className="dropdown-item " href="shop.html">
                              Shop
                            </a>
                            <a className="dropdown-item " href="shop-item.html">
                              Shop: Item
                            </a>
                            <a className="dropdown-item " href="cart.html">
                              Shop: Cart
                            </a>
                            <a className="dropdown-item " href="checkout.html">
                              Shop: Checkout
                            </a>
                          </div>
                        </li>
                        <li>
                          <a className="dropdown-item " href="about.html">
                            About
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="contact.html">
                            Contact
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="faq.html">
                            FAQ
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="pricing.html">
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="sign-in.html">
                            Sign In
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="sign-up.html">
                            Sign Up
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item " href="404.html">
                            404
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarComponents" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Components
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarComponents">
                        <a className="dropdown-item " href="components-theme.html">
                          Theme
                        </a>
                        <a className="dropdown-item " href="components-bootstrap.html">
                          Bootstrap
                        </a>
                      </div>
                    </li>
                    <li className="nav-item ">
                      <a href="documentation.html" className="nav-link">
                        Documentation
                      </a>
                    </li>
                    <li className="nav-item-divider">
                      <span className="nav-link">
                        <span></span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <a href="https://themes.getbootstrap.com/product/incline-landing-page-set/" target="_blank" className="nav-link">
                        Purchase now
                      </a>
                    </li>
                  </ul>
                </div>
                {/* navbar-collapse */}
              </div>
              {/* container */}
            </nav>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
export default MenuBar