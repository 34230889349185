import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
class AddSEOData extends Component {
  constructor(props) {
    const { action, id } = props.match.params;
    super(props);
    this.state = {
      Action: action,
      ID: id,
      CategoryName: null,
      CategoryData: [],
      SubCatgeoryData: [],
      SubCategory: null,
      AttributData: [],
      ItemName: null,
      HSNCode: "",
      GSTRate: "",
      GSTData: [],
      BrandName: "",
      BrandData: [],
      VendorName: "",
      VendorData: [],
      MarketedBy: "",
      VariantNamefromDropdown: "",
      ManufacturedBy: "",
      ManufacturedByData: [],
      Importedby: "",
      CountryOrigindata: [],
      CountryOrigin: "",
      UnitofMeasurementData: [],
      UOM: "",
      Status: "Yes",
      SKU: "",
      MetaTitle: "",
      MetaDescription: "",
      OGTitle: "",
      OGDescription: "",
      imagePreviewUrl:
        "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",
      Keywords: "",
      ItemStatus: "Active",
      FormStatus: "",
      name: "",
      VerticalName: null,
      VerticalData: [],
      MarketedByByData: [],
      ItemData: [],

      // New state
      OGImage: "",
      canonical: "",
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    if (this.state.Action == "update") {
      PostApiCall.postRequest(
        {
          whereClause: `where fld_seoid = ${this.state.ID}`,
        },
        "GetSEOData"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              MetaTitle: obj.data[0].fld_meta_tile,
              MetaDescription: obj.data[0].fld_meta_description,
              OGTitle: obj.data[0].fld_og_title,
              OGDescription: obj.data[0].fld_og_description,
              Keywords: obj.data[0].fld_keywords,
              canonical: obj.data[0].FLD_CANONICAL,
              OGImage: obj.data[0].fld_og_image,
              ItemName: obj.data[0].varient_id,
              VerticalName: obj.data[0].verticle_id,
              CategoryName: obj.data[0].category_id,
              SubCategory: obj.data[0].sub_category_id,
              SeoId: obj.data[0].fld_seoid,
            });
            Notiflix.Loading.Dots("Please wait...");
            PostApiCall.postRequest(
              {
                whereClause: `where fld_vertical_id=${obj.data[0].verticle_id}`,
              },
              "Get_categorydropdown"
            ).then((results1) =>
              results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                  this.setState({
                    CategoryData: obj1.data,
                  });
                  Notiflix.Loading.Remove();
                }
              })
            );
            Notiflix.Loading.Dots("Please wait...");
            PostApiCall.postRequest(
              {
                categoryid: obj.data[0].category_id,
              },
              "Get_subcategoryDropdown"
            ).then((results1) =>
              results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                  this.setState({
                    SubCatgeoryData: obj1.data,
                  });
                  Notiflix.Loading.Remove();
                }
              })
            );
            PostApiCall.postRequest(
              {
                recordCount: "*",
                whereClause: `where fld_subcategoryid=${obj.data[0].sub_category_id}`,
              },
              "GetProductDropdownFilter"
            ).then((results1) =>
              results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                  this.setState({
                    ItemData: obj1.data,
                  });
                  Notiflix.Loading.Remove();
                }
              })
            );
            Notiflix.Loading.Remove();
          }
        })
      );
    }
    Notiflix.Loading.Dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_productid=${JSON.parse(
          localStorage.getItem("itemDetails")
        )}`,
        recordCount: "*",
      },
      "GetProductMaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ProductData: obj.data,
          });
          if (obj.data.length != 0) {
            // this.setState({
            //     CategoryName: obj.data[0].fld_categoryid,
            //     SubCategory: obj.data[0].fld_subcategoryid,
            //     ItemName: obj.data[0].fld_itemname,
            //     HSNCode: obj.data[0].fld_hsncode,
            //     GSTRate: obj.data[0].fld_Gstrate,
            //     BrandName: obj.data[0].fld_brandid,
            //     VendorName: obj.data[0].VendorName,
            //     MarketedBy: obj.data[0].MarketedBy,
            //     ManufacturedBy: obj.data[0].ManufacturedBy,
            //     Importedby: obj.data[0].fld_importedbyId,
            //     CountryOrigin: obj.data[0].fld_origin_country_id,
            //     UOM: obj.data[0].fld_uom,
            //     ProductID: obj.data[0].fld_productid,
            //     VerticalName: obj.data[0].fld_verticleid
            // })
            // Notiflix.Loading.Dots("Please wait...");
            // PostApiCall.postRequest(
            //     {
            //         categoryid: obj.data[0].fld_categoryid,
            //     },
            //     "Get_subcategoryDropdown"
            // ).then((results1) =>
            //     results1.json().then((obj1) => {
            //         if (results1.status == 200 || results1.status == 201) {
            //             this.setState({
            //                 SubCatgeoryData: obj1.data,
            //             });
            //             Notiflix.Loading.Remove();
            //         }
            //     })
            // );
            // Notiflix.Loading.Dots("Please wait...");
            // PostApiCall.postRequest(
            //     {
            //         whereClause: `where fld_vertical_id=${obj.data[0].fld_verticleid}`,
            //     },
            //     "Get_categorydropdown"
            // ).then((results1) =>
            //     results1.json().then((obj1) => {
            //         if (results1.status == 200 || results1.status == 201) {
            //             this.setState({
            //                 CategoryData: obj1.data,
            //             });
            //             Notiflix.Loading.Remove();
            //         }
            //     })
            // );
          }
          Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticalData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    // GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
    //     resultdes.json().then((obj) => {
    //         this.setState({
    //             ItemData: obj.data,
    //         });
    //     })
    // );
  }
  onChangeCategory(text) {
    this.setState(
      {
        CategoryName: text.target.value,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {
            categoryid: this.state.CategoryName,
          },
          "Get_subcategoryDropdown"
        ).then((results1) =>
          results1.json().then((obj1) => {
            if (results1.status == 200 || results1.status == 201) {
              this.setState({
                SubCatgeoryData: obj1.data,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  onChangesubCategory(text) {
    this.setState(
      {
        SubCategory: text.target.value,
      },
      () => {
        Notiflix.Loading.Dots();
        PostApiCall.postRequest(
          {
            recordCount: "*",
            whereClause: `where fld_subcategoryid=${this.state.SubCategory}`,
          },
          "GetProductDropdownFilter"
        ).then((results1) =>
          results1.json().then((obj1) => {
            if (results1.status == 200 || results1.status == 201) {
              this.setState({
                ItemData: obj1.data,
              });
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }
  onChangeVariantName(text) {
    this.setState({
      ItemName: text.target.value,
    });
  }
  photoUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0].size < 300000) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          ImageData: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
    }
  };
  onSaveData() {
    // var type
    // if (this.state.CategoryName != "" && this.state.SubCategory == '') {
    //     type = 'Category'
    // }
    // else if (this.state.SubCategory != '' && this.state.ItemName == '') {
    //     type = 'Sub Category'
    // }
    // else if (this.state.ItemName != '') {
    //     type = 'Variant'
    // }
    // else {
    //     type = 'Vertical'
    // }
    //
    //
    if (this.state.VerticalName != null) {
      // if (this.state.imagePreviewUrl != "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png") {
      if (this.state.MetaTitle != "") {
        if (this.state.MetaDescription != "") {
          if (this.state.OGTitle != "") {
            if (this.state.OGDescription != "") {
              if (this.state.OGImage != "") {
                if (this.state.Keywords != "") {
                  if (this.state.canonical != "") {
                    this.onPost();
                  } else {
                    Notiflix.Notify.Failure("Please enter the canonical tag");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter the Keywords");
                }
              } else {
                Notiflix.Notify.Failure("Please enter the OG Image");
              }
            } else {
              Notiflix.Notify.Failure("Please enter the OG Description");
            }
          } else {
            Notiflix.Notify.Failure("Please enter OG Title");
          }
        } else {
          Notiflix.Notify.Failure("Please enter the Meta Description");
        }
      } else {
        Notiflix.Notify.Failure("Please enter Meta Title");
      }
      // } else {
      //     Notiflix.Notify.Failure("Please upload OG Image")
      // }
    } else {
      Notiflix.Notify.Failure(
        "Please select atleast one type (vertical/category/subcategory/variantname) from dropdown"
      );
    }
  }
  onPost() {
    Notiflix.Loading.Dots();
    PostApiCall.postRequest(
      {
        id: parseInt(this.state.ID),
        type:
          this.state.CategoryName != null && this.state.SubCategory == null
            ? "Category"
            : this.state.SubCategory != null && this.state.ItemName == null
            ? "SubCategory"
            : this.state.ItemName != null
            ? "Variant"
            : "Vertical",
        typeid:
          this.state.CategoryName != null && this.state.SubCategory == null
            ? this.state.CategoryName
            : this.state.SubCategory != null && this.state.ItemName == null
            ? this.state.SubCategory
            : this.state.ItemName != null
            ? this.state.ItemName
            : this.state.VerticalName,
        metaTitle: this.state.MetaTitle,
        metaDescription: this.state.MetaDescription,
        ogTitle: this.state.OGTitle,
        ogDescription: this.state.OGDescription,
        keywords: this.state.Keywords,
        canonical: this.state.canonical,
        OGImage: this.state.OGImage,
        status: "Active",
      },
      "AddSEOData"
    ).then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Your SEO Data has been saved successfully");
          window.location.href = "/seo-management";
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      });
    });
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/seo-management"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="6">
                        <div className="globalTrendzEcomm-text p-3">
                          <h5 className="globalTrendzEcomm-text text-white">
                            Add SEO Data
                          </h5>
                        </div>
                      </Col>
                      <Col xs="6"></Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <CardBody className="pt-0">
                          <Row>
                            <Col xs="12">
                              <div className="form my-4">
                                <div className="row  ">
                                  <div className="col col-12">
                                    <div className="row">
                                      <div className="col col-3">
                                        <label
                                          className="my-1 mr-2"
                                          htmlFor="inlineFormCustomSelectPref"
                                        >
                                          Vertical
                                        </label>
                                        <select
                                          value={this.state.VerticalName}
                                          onChange={(text) => {
                                            this.setState(
                                              {
                                                VerticalName: text.target.value,
                                              },
                                              () => {
                                                Notiflix.Loading.Dots(
                                                  "Please wait..."
                                                );
                                                PostApiCall.postRequest(
                                                  {
                                                    whereClause: `where fld_vertical_id=${this.state.VerticalName}`,
                                                  },
                                                  "Get_categorydropdown"
                                                ).then((results1) =>
                                                  results1
                                                    .json()
                                                    .then((obj1) => {
                                                      if (
                                                        results1.status ==
                                                          200 ||
                                                        results1.status == 201
                                                      ) {
                                                        this.setState({
                                                          CategoryData:
                                                            obj1.data,
                                                        });
                                                        Notiflix.Loading.Remove();
                                                      }
                                                    })
                                                );
                                              }
                                            );
                                          }}
                                          className="custom-select my-1 mr-sm-2"
                                        >
                                          <option value="">
                                            Select Vertical
                                          </option>
                                          {this.state.VerticalData.map(
                                            (Verticle) => (
                                              <option
                                                key={Verticle.value}
                                                value={Verticle.value}
                                              >
                                                {Verticle.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className="col col-3">
                                        <label
                                          className="my-1 mr-2"
                                          htmlFor="inlineFormCustomSelectPref"
                                        >
                                          Category
                                        </label>
                                        <select
                                          value={this.state.CategoryName}
                                          onChange={this.onChangeCategory.bind(
                                            this
                                          )}
                                          className="custom-select my-1 mr-sm-2"
                                        >
                                          <option value="Select Category">
                                            Select Category
                                          </option>
                                          {this.state.CategoryData.map(
                                            (Category) => (
                                              <option
                                                key={Category.value}
                                                value={Category.value}
                                              >
                                                {Category.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className="col col-3">
                                        <label
                                          className="my-1 mr-2"
                                          htmlFor="inlineFormCustomSelectPref"
                                        >
                                          Sub Category
                                        </label>
                                        <select
                                          value={this.state.SubCategory}
                                          onChange={this.onChangesubCategory.bind(
                                            this
                                          )}
                                          className="custom-select my-1 mr-sm-2"
                                        >
                                          <option value="">
                                            Select Sub Category
                                          </option>
                                          {this.state.SubCatgeoryData.map(
                                            (subcat) => (
                                              <option
                                                key={subcat.value}
                                                value={subcat.value}
                                              >
                                                {subcat.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className="col col-3">
                                        <label
                                          className="my-1 mr-2"
                                          htmlFor="inlineFormCustomSelectPref"
                                        >
                                          Variant Name
                                        </label>
                                        <select
                                          value={this.state.ItemName}
                                          onChange={this.onChangeVariantName.bind(
                                            this
                                          )}
                                          className="custom-select my-1 mr-sm-2"
                                        >
                                          <option value="">
                                            Select Variant Name
                                          </option>
                                          {this.state.ItemData.map(
                                            (variantname) => (
                                              <option
                                                key={variantname.value}
                                                value={variantname.value}
                                              >
                                                {variantname.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      {/* <div className="col col-2">
                                                                                <label
                                                                                    className="my-1 mr-2"
                                                                                    htmlFor="inlineFormCustomSelectPref"
                                                                                >
                                                                                    Page Name
                                                                                </label>
                                                                                <select
                                                                                    value={this.state.SubCategory}
                                                                                    onChange={this.onChangeName.bind(
                                                                                        this
                                                                                    )}
                                                                                    className="custom-select my-1 mr-sm-2"
                                                                                >
                                                                                    <option value="">
                                                                                        Select Page Name
                                                                                    </option>
                                                                                    {this.state.ItemData.map(
                                                                                        (variantname) => (
                                                                                            <option
                                                                                                key={variantname.value}
                                                                                                value={variantname.value}
                                                                                            >
                                                                                                {variantname.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div> */}
                                      <div className="col col-4 mt-4">
                                        <div className="form-group">
                                          <label htmlFor="Meta Title">
                                            Meta Title
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="MetaTitle"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.MetaTitle}
                                            onChange={(text) => {
                                              this.setState({
                                                MetaTitle: text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col col-4 mt-4">
                                        <div className="form-group">
                                          <label htmlFor="Meta Description">
                                            Meta Description
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="MetaDescription"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.MetaDescription}
                                            onChange={(text) => {
                                              this.setState({
                                                MetaDescription:
                                                  text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col col-4 mt-4">
                                        <div className="form-group">
                                          <label htmlFor="OG Title">
                                            OG Title
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="OGTitle"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.OGTitle}
                                            onChange={(text) => {
                                              this.setState({
                                                OGTitle: text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col col-6 mt-4">
                                        <div className="form-group">
                                          <label htmlFor="OG Description">
                                            OG Description
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="OGDescription"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.OGDescription}
                                            onChange={(text) => {
                                              this.setState({
                                                OGDescription:
                                                  text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                       {/* **** Add new section og image and canonical tag **** */}

                                       <div className="col col-6 mt-4">
                                        <div className="form-group">
                                          <label for="OG Image">
                                            OG Image
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="OGImage"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.OGImage}
                                            onChange={(text) => {
                                              this.setState({
                                                OGImage: text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col col-6 mt-4">
                                        <div className="form-group">
                                          <label for="Canonical Tag">
                                            Canonical Tag
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            id="canonical"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.canonical}
                                            onChange={(text) => {
                                              this.setState({
                                                canonical: text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      {/* **** End new section og image and canonical tag **** */}
                                      
                                      <div className="col col-6 mt-4">
                                        <div className="form-group">
                                          <label htmlFor="Keywords">
                                            Keywords (seperate keywords using
                                            comma (,))
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="textarea"
                                            id="Keywords"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.Keywords}
                                            onChange={(text) => {
                                              this.setState({
                                                Keywords: text.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col col-4 mt-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="sw-arrows-first-name">
                                                                                        Upload OG Image (Size &lt; 100kb,
                                                                                        500*500)
                                                                                        <span className="mandatory">*</span>
                                                                                    </label>
                                                                                    <div className="div1">
                                                                                        <ImgUpload
                                                                                            onChange={this.photoUpload}
                                                                                            src={this.state.imagePreviewUrl}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody className="py-1    my-1">
                          <Row>
                            <Col xs="12">
                              <div className="row">
                                <div className="col-12">
                                  {this.state.Action == "Update" ? (
                                    <button
                                      style={{
                                        float: "right",
                                        marginTop: "5px",
                                      }}
                                      className="btn align-items-center Bechofy-btn "
                                      onClick={this.onSaveData.bind(this)}
                                    >
                                      Save SEO Data
                                    </button>
                                  ) : (
                                    <button
                                      style={{
                                        float: "right",
                                        marginTop: "5px",
                                      }}
                                      className="btn align-items-center Bechofy-btn "
                                      onClick={this.onSaveData.bind(this)}
                                    >
                                      Update SEO Data
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddSEOData;
