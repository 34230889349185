import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-modern-drawer/dist/index.css';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Refreshicon from "../../assets/images/dashboardimages/refresh.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import varianticon from "../../assets/images/dashboardimages/variant.png";
import variantlist from "../../assets/images/dashboardimages/variantlist.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const Skeletonfeatured = ({ cards }) => {
    return (
        <Card>
            <div>
                <Row className="justify-content-between">
                    <Col xs="4" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                    <Col xs="6" className="px-4 my-auto">
                        <div className="Bechofy-text" style={{ textAlign: "end" }}>
                            <Skeleton className="py-2" style={{ width: "30%" }} />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col xs="12" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col xs="1" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                </Row>
            </div>
            <CardBody className="pt-0 px-0">
                <Row>
                    <Col
                        xs="12"
                        className="latestreview-mdbtable lastestcustomer-table-responsive"
                    >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
class FeatureProduct extends Component {


    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
        this.state = {
            open: false,
            suggestions: [],
            BrandData: [],
            ProductData: [],
            ProductName: null,
            VariantData: [],
            VerticalData: [],
            CategoryData: [],
            SubCatgeoryData: [],
            UserData: [],
            Id: "",
            searchInput: "",
            FromDate: null,
            ToDate: null,
            OrderData: [],
            FeaturedItemsData: [],
            SearchField: null,
            fileData: [],
            count: "",
            ListAllFeaturedItemsData: "NotClicked",
            loader: true,
            ShowAddNewVaraintButton: false,
            isOpen: false,
            Brand: null,
            Vertical: null,
            Category: null,
            SubCategory: null,
            Product: null
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        localStorage.removeItem("VariantImageID");
        localStorage.removeItem("VariantIdForImage");
        localStorage.removeItem("itemDetails");
        localStorage.removeItem("VariantDetails");
        GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    BrandData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
        GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    VerticalData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
        PostApiCall.postRequest(
            {
                WhereClause: ""
            },
            "GetFeaturedVariantData"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        VariantData: obj.data,
                        count: obj.data.length,
                        loader: false,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
    }
    repeatLastSearch = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_vertical_id=${localStorage.getItem("lastsearchitems").split(",")[1]}`,
            },
            "Get_categorydropdown"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (
                    results1.status == 200 ||
                    results1.status == 201
                ) {
                    this.setState({
                        CategoryData: obj1.data,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                categoryid: localStorage.getItem("lastsearchitems").split(",")[2],
            },
            "Get_subcategoryDropdown"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    this.setState({
                        SubCatgeoryData: obj1.data,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: ` where fld_subcategoryid =  ${localStorage.getItem("lastsearchitems").split(",")[3]}`,
                recordCount: " fld_productid,fld_itemname,fld_status ",
            },
            "GetProductMaster"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        ProductData: obj.data,
                        count: obj.data.length,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        this.setState({
            Brand: localStorage.getItem("lastsearchitems").split(",")[0],
            Vertical: localStorage.getItem("lastsearchitems").split(",")[1],
            Category: localStorage.getItem("lastsearchitems").split(",")[2],
            SubCategory: localStorage.getItem("lastsearchitems").split(",")[3],
            Product: localStorage.getItem("lastsearchitems").split(",")[4]
        })
        this.getVariantData();
    }
    getVariantData = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_brandid = isnull(${localStorage.getItem("lastsearchitems").split(",")[0]},fld_brandid) and fld_productid= isnull(${localStorage.getItem("lastsearchitems").split(",")[4]},fld_productid)`,
                recordCount: "*",
            },
            "GetVariantData"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        VariantData: obj.data,
                        ViewVariantList: true,
                        ViewProductList: false,
                        ShowAddNewVaraintButton: true
                    });
                    Notiflix.Loading.Remove();
                }
            })
        )
    }
    onChangeCategory(text) {
        this.setState(
            {
                Category: text.target.value,
            },
            () => {
                PostApiCall.postRequest(
                    {
                        categoryid: this.state.Category,
                    },
                    "Get_subcategoryDropdown"
                ).then((results1) =>
                    results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {
                            this.setState({
                                SubCatgeoryData: obj1.data,
                            });
                            Notiflix.Loading.Remove();
                        }
                    })
                );
            }
        );
    }
    onChangeSubCategory(text) {
        this.setState(
            {
                SubCategory: text.target.value,
            },
            () => {
                // ======Get Item List==========
                PostApiCall.postRequest(
                    {
                        whereClause: ` where fld_subcategoryid =  ${this.state.SubCategory}`,
                        recordCount: " fld_productid,fld_itemname,fld_status ",
                    },
                    "GetProductMaster"
                ).then((results) =>
                    results.json().then((obj) => {
                        if (results.status == 200 || results.status == 201) {
                            this.setState({
                                ProductData: obj.data,
                                count: obj.data.length,
                            });
                            Notiflix.Loading.Remove();
                        }
                    })
                );
                Notiflix.Loading.Remove();
            }
        );
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };


    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    toggleDrawer = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    render() {
        const { isOpen } = this.state;
        const data = {
            columns: [
                {
                    label: "Image",
                    field: "image",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Variant Name",
                    field: "VariantName",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Featured",
                    field: "Featured",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Best Seller",
                    field: "BestSeller",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "New Arrival",
                    field: "NewArrivals",
                    sort: "disabled",
                    width: 300,
                }
            ],
            rows: this.state.VariantData.filter((data) => {
                if (
                    this.state.searchInput == ""
                ) {
                    return data;
                }
                if (
                    data.fld_variantname
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()) ||
                    `${data.fld_variantname ? data.fld_variantname.toLowerCase() : ""
                        }`.includes(
                            this.state.searchInput.toLowerCase()
                        )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    image: (
                        <img
                            className="sequentialize-img"
                            src={data.VariantImage.split("#")[0]}
                        />
                    ),
                    VariantName: data.fld_variantname,
                    Featured: (
                        <Form>
                            <Form.Check
                                type="switch"
                                checked={data.fld_featured === "true" ? true : false}
                                id={"custom-switch-featured" + data.fld_variantid}
                                onClick={(e) => {
                                    PostApiCall.postRequest(
                                        {
                                            featured: e.target.checked === true ? "true" : "false",
                                            bestseller: null,
                                            newarrivals: null,
                                            variantid: data.fld_variantid,
                                        },
                                        "UpdateFeaturedItems"
                                    ).then((results) =>
                                        results.json().then((obj) => {
                                            if (results.status == 200 || results.status == 201) {
                                                Notiflix.Notify.Success("Status Updated Successfully");
                                                this.repeatLastSearch();
                                                Notiflix.Loading.Remove();
                                            } else {
                                                Notiflix.Notify.Failure(obj.data);
                                                Notiflix.Loading.Remove();
                                            }
                                        })
                                    );
                                }}
                            />
                        </Form>),
                    BestSeller: (<Form><Form.Check
                        type="switch"
                        checked={data.fld_best_seller === "true" ? true : false}
                        id={"custom-switch-bestseller" + data.fld_variantid}
                        onClick={(e) => {
                            PostApiCall.postRequest(
                                {
                                    featured: null,
                                    bestseller: e.target.checked === true ? "true" : "false",
                                    newarrivals: null,
                                    variantid: data.fld_variantid,
                                },
                                "UpdateFeaturedItems"
                            ).then((results) =>
                                results.json().then((obj) => {
                                    if (results.status == 200 || results.status == 201) {
                                        Notiflix.Notify.Success("Status Updated Successfully");
                                        this.repeatLastSearch();
                                        Notiflix.Loading.Remove();
                                    } else {
                                        Notiflix.Notify.Failure(obj.data);
                                        Notiflix.Loading.Remove();
                                    }
                                })
                            );
                        }}
                    /></Form>),
                    NewArrivals: (<Form><Form.Check
                        type="switch"
                        checked={data.fld_new_arrival === "true" ? true : false}
                        id={"custom-switch-newarrival" + data.fld_variantid}
                        onClick={(e) => {
                            PostApiCall.postRequest(
                                {
                                    featured: null,
                                    bestseller: null,
                                    newarrivals: e.target.checked === true ? "true" : "false",
                                    variantid: data.fld_variantid,
                                },
                                "UpdateFeaturedItems"
                            ).then((results) =>
                                results.json().then((obj) => {
                                    if (results.status == 200 || results.status == 201) {
                                        Notiflix.Notify.Success("Status Updated Successfully");
                                        this.repeatLastSearch();
                                        Notiflix.Loading.Remove();
                                    } else {
                                        Notiflix.Notify.Failure(obj.data);
                                        Notiflix.Loading.Remove();
                                    }
                                })
                            );
                        }}
                    /></Form>)
                };
            }),
        }
        const variantImages = {
            dots: false,
            infinite: false,
            arrows: true,
            speed: 800,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (

            <React.Fragment>

                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
                            urlPath={"/feature-products"}
                        />
                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    {this.state.loader == true ? (
                                        <>
                                            <Skeletonfeatured cards={5} />
                                        </>
                                    ) : (
                                        <Card>
                                            <div
                                                className="Bechofy-bg-soft-pink"
                                                style={{ height: "60px" }}
                                            >
                                                <div className="row my-1">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <h4
                                                                className="px-4 text-white text-start"
                                                                style={{ marginTop: "15px" }}
                                                            >
                                                                Featured Products
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="position-relative">
                                                            <button
                                                                onClick={this.toggleDrawer}
                                                                className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                            >
                                                                <span className="">
                                                                    <img
                                                                        src={varianticon}
                                                                        alt="block user"
                                                                        className="btnicons"
                                                                        style={{ marginRight: "5px" }}
                                                                    ></img>{" "}
                                                                </span>
                                                                Add New Product{" "}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Card style={{ padding: " 0 20px" }}>
                                                <div className="row my-1 ">
                                                    <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                        <div className="form-group mt-lg-3">
                                                            <select
                                                                className="form-control"
                                                                placeholder="Select Brand"
                                                                value={this.state.Brand}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Brand: text.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option>Select Brand</option>
                                                                {this.state.BrandData.map((brand) => (
                                                                    <option key={brand.value} value={brand.value}>
                                                                        {brand.label} - {brand.fld_status}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                        <div className="form-group mt-lg-3">
                                                            <select
                                                                className="form-control"
                                                                placeholder="Select Vertical"
                                                                value={this.state.Vertical}
                                                                onChange={(text) => {
                                                                    this.setState(
                                                                        {
                                                                            Vertical: text.target.value,
                                                                        },
                                                                        () => {
                                                                            PostApiCall.postRequest(
                                                                                {
                                                                                    whereClause: `where fld_vertical_id=${this.state.Vertical}`,
                                                                                },
                                                                                "Get_categorydropdown"
                                                                            ).then((results1) =>
                                                                                results1.json().then((obj1) => {
                                                                                    if (
                                                                                        results1.status == 200 ||
                                                                                        results1.status == 201
                                                                                    ) {
                                                                                        this.setState({
                                                                                            CategoryData: obj1.data,
                                                                                        });
                                                                                        Notiflix.Loading.Remove();
                                                                                    }
                                                                                })
                                                                            );
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                <option value="">Select Vertical</option>
                                                                {this.state.VerticalData.map((Verticle) => (
                                                                    <option
                                                                        key={Verticle.value}
                                                                        value={Verticle.value}
                                                                    >
                                                                        {Verticle.label} - {Verticle.fld_status}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                        <div className="form-group mt-lg-3">
                                                            <select
                                                                className="form-control"
                                                                placeholder="Select Vertical"
                                                                value={this.state.Category}
                                                                onChange={this.onChangeCategory.bind(this)}
                                                            >
                                                                <option value="">Select Category</option>
                                                                {this.state.CategoryData.map((Category) => (
                                                                    <option
                                                                        key={Category.value}
                                                                        value={Category.value}
                                                                    >
                                                                        {Category.label} - {Category.fld_status}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                        <div className="form-group mt-lg-3">
                                                            <select
                                                                className="form-control"
                                                                placeholder="Select Vertical"
                                                                value={this.state.SubCategory}
                                                                onChange={this.onChangeSubCategory.bind(this)}
                                                            >
                                                                <option>Select Sub Category</option>
                                                                {this.state.SubCatgeoryData.map((subcat) => (
                                                                    <option key={subcat.value} value={subcat.value}>
                                                                        {subcat.label} - {subcat.fld_status}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                        <div className="form-group mt-lg-3">
                                                            <select
                                                                className="form-control"
                                                                placeholder="Select Vertical"
                                                                value={this.state.Product}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Product: text.target.value
                                                                    });
                                                                }}
                                                            >
                                                                <option>Select Product</option>
                                                                {this.state.ProductData.map((product) => (
                                                                    <option key={product.fld_itemname} value={product.fld_productid}>
                                                                        {product.fld_itemname} - {product.fld_status}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-6 mb-md-3 mt-lg-0">
                                                        <button
                                                            onClick={() => {
                                                                PostApiCall.postRequest(
                                                                    {
                                                                        whereClause: `where fld_brandid = isnull(${this.state.Brand},fld_brandid)
                                                                        and fld_verticleid = isnull(${this.state.Vertical},fld_verticleid)
                                                                        and fld_categoryid = isnull(${this.state.Category},fld_categoryid)
                                                                        and fld_subcategoryid= isnull(${this.state.SubCategory},fld_subcategoryid)`,
                                                                        recordCount: "*",
                                                                    },
                                                                    "GetVariantData"
                                                                ).then((results) =>
                                                                    results.json().then((obj) => {
                                                                        if (results.status == 200 || results.status == 201) {

                                                                            localStorage.setItem("lastsearchitems", this.state.Brand + "," + this.state.Vertical + "," + this.state.Category + "," + this.state.SubCategory + "," + this.state.Product)
                                                                            if (obj.data.length > 0) {
                                                                                localStorage.setItem("lastsearchitemsvalue", " Brand - " + obj.data[0].fld_brand_name + " Vertical - " + obj.data[0].fld_verticlename + " Category - " + obj.data[0].fld_categoryname + " SubCategory - " + obj.data[0].fld_subcategoryname + " Item - " + obj.data[0].fld_itemname)
                                                                            }
                                                                            this.setState({
                                                                                VariantData: obj.data,
                                                                                ViewVariantList: true,
                                                                                ViewProductList: false,
                                                                                ShowAddNewVaraintButton: true
                                                                            });
                                                                            Notiflix.Loading.Remove();
                                                                        }
                                                                    })
                                                                );
                                                            }}
                                                            className="btn align-items-center btn Bechofy-btn FilterCardBtn3 mt-lg-3"
                                                        >
                                                            <span className="">
                                                                <img
                                                                    src={variantlist}
                                                                    alt="block user"
                                                                    className="btnicons"
                                                                    style={{ marginRight: "5px" }}
                                                                ></img>{" "}
                                                            </span>
                                                            Get Variants
                                                        </button>
                                                        <button
                                                            className="btn align-items-center btn refresh-item-responsive-btn Bechofy-btn FilterCardBtn4 mt-lg-3"
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            <span className="">
                                                                <img
                                                                    src={Refreshicon}
                                                                    alt="block user"
                                                                    className="btnicons"
                                                                    style={{ marginRight: "5px" }}
                                                                ></img>{" "}
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row my-1">
                                                    <h6>Last Search:<a onClick={() => {
                                                        this.repeatLastSearch();
                                                    }}>{localStorage.getItem("lastsearchitemsvalue")}</a>
                                                    </h6>
                                                </div>
                                            </Card>
                                            <CardBody className="pt-0">
                                                <Row className="text-center justify-content-center">
                                                    <>
                                                        <MDBCol
                                                            md="12"
                                                            style={{
                                                                marginBottom: "15px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <span className="">
                                                                            <img
                                                                                src={Searchicon}
                                                                                alt="block user"
                                                                                className="btnicons"
                                                                            ></img>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Type the search phrase you wish to search within the grid"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                    onChange={(e) => this.seachBarHandler(e)}
                                                                    value={this.state.searchInput}
                                                                />
                                                            </div>
                                                        </MDBCol>
                                                        <Col
                                                            md="12"
                                                            className="lastestcustomer-table-responsive"
                                                        >
                                                            <MDBDataTable
                                                                hover
                                                                // scrollY
                                                                striped
                                                                bordered
                                                                data={data}
                                                                seachTop={false}
                                                                entriesOptions={[10, 25, 50, 100]}
                                                                className="customer-table-responsive"
                                                            >
                                                                <MDBTableHead columns={data.columns} />
                                                                <MDBTableBody rows={data.rows} />
                                                            </MDBDataTable>
                                                        </Col>
                                                    </>

                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default FeatureProduct;
