import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    SlSocialFacebook,
    SlSocialInstagram, SlSocialYoutube
} from "react-icons/sl";
import { Link } from "react-router-dom";
import GetApiCall from "../../GETAPI";
import "./WebsiteFooter.css";

function WebsiteFooter() {
    const [storeName, setStoreName] = useState("");

    // useEffect(() => {
    //     GetApiCall.getRequest("GetClientInfo").then((ClientInfo) => {
    //         ClientInfo.json().then((obj) => {
    //             setStoreName(obj.data[0].store_name)
    //         })
    //     })
    // })

    return (
        <>
            <footer className="bg-footer">
                <div className="px-2 py-4">
                    <Container fluid>
                        <Row className="d-flex flex-column justify-content-center align-items-center">
                            {/* <img src={Logo} className="logo-footer mb-lg-2 mb-3" alt="" /> */}
                            <div className="social-ul mb-0 py-lg-4">
                                <a href="https://www.facebook.com/Aamnliving?mibextid=ZbWKwL" target="_blank">
                                    <div className="social-ul-link">
                                        <SlSocialFacebook />
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/meluha_home/?igshid=NTdlMDg3MTY%3D" target="_blank">
                                    <div className="social-ul-link">
                                        <SlSocialInstagram />
                                    </div>
                                </a>
                                {/* <a href="">
                  <div className="social-ul-link">
                    <SlSocialLinkedin />
                  </div>
                </a> */}
                                {/* <a href="/">
                  <div className="social-ul-link">
                    <SlSocialYoutube />
                  </div>
                </a> */}
                            </div>
                            <div className="col-lg-4 pb-lg-4">
                                <ul className="footer-ul mb-0 text-white">
                                    <li className="footer-ul-link">
                                        <Link to="/clientinfo/return-policy" className="text-capitalize">
                                            return policy
                                        </Link>
                                    </li>
                                    {"|"}
                                    <li className="footer-ul-link">
                                        <Link to="/clientinfo/terms-and-conditions" className="text-capitalize">
                                            terms of use
                                        </Link>
                                    </li>
                                    {"|"}
                                    <li className="footer-ul-link">
                                        <Link to="/clientinfo/privacy-policy" className="text-capitalize">
                                            privacy policy
                                        </Link>
                                    </li>
                                    {"|"}
                                    <li className="footer-ul-link">
                                        <Link to="/clientinfo/frequently-asked-questions" className="text-capitalize">
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Row>
                    </Container>
                </div>
                <div className="bg-top-infobar text-white py-lg-2 py-2">
                    <Container fluid className="px-4">
                        <Row>
                            <Col
                                lg={6}
                                className="text-lg-start text-center order-lg-1 order-2"
                            >
                                <div className="copyright small">
                                    Powered by{" "}
                                    <a
                                        href="https://bechofy.in/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-white text-decoration-none"
                                    >
                                        Bechofy
                                    </a>
                                </div>
                            </Col>
                            <Col
                                lg={6}
                                className="text-lg-end text-center order-lg-2 order-1"
                            >
                                <div className="copyright small">
                                    &copy; {moment().format("YYYY")} {storeName}. All right
                                    reserved.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    );
}

export default WebsiteFooter;