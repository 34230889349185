import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  CardBody, Col, Container,
  Row
} from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import ImageUploadIcon from "../../assets/images/Upload.jpg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
import CommentsList from "./CommentsList";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload-blog fas">
    <div className="img-wrap-blog img-upload">
      <img
        htmlFor="photo-upload"
        className="custom-file-upload-image-blog"
        src={src}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

class AddBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Photo: "",
      BlogTitle: "",
      BlogCreatedBy: "",
      ShortDescription: "",
      LongDescription: "",
      BlogID: "",
      outputFileName: "",
      imagePreviewUrl: ImageUploadIcon,
      ImageApiUrl: 'https://api.bechofy.in/AddImage',
      originalImage: "",
      RandomNumber: Math.floor(100000 + Math.random() * 900000),
      BlogComments: []
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    }
    );
    // console.log(this.props.location.state)
    this.setState({
      BlogID: this.props.location.state === 'undefined' ? 0 : this.props.location.state.blogid,
    })
    if (this.props.sProductId != "") {
      this.getBlogdetails()
      this.getBlogComments()
    }


  }

  getBlogdetails = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_id=${this.props.location.state === 'undefined' ? 0 : this.props.location.state.blogid}`,
        recordCount: "*",
      },
      "GetBlogArticleById"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        Notiflix.Loading.Dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              BlogTitle: obj.data[0].fld_title,
              BlogCreatedBy: obj.data[0].fld_blog_by,
              ShortDescription: obj.data[0].fld_short_description,
              LongDescription: obj.data[0].fld_content,
              Photo: obj.data[0].fld_blogimage,
            });
            Notiflix.Loading.Remove()
          } else {
            this.setState({
              Photo: ImageUploadIcon
            })
          }
        }
        Notiflix.Loading.Remove()
      }
      )
    )
  }

  getBlogComments = () => {
    PostApiCall.postRequest(
      {
        blogid: this.props.location.state == 'undefined' ? 0 : this.props.location.state.blogid,
      },
      "GetBlogComments"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        Notiflix.Loading.Dots("Please wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              BlogComments: obj.data
            });
            Notiflix.Loading.Remove()
          }
        }
        Notiflix.Loading.Remove()
      }
      )
    )
  }

  onPostBlogData = () => {
    // Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        blogid: this.state.BlogID,
        blogtitle: this.state.BlogTitle,
        blogcreatedby: this.state.BlogCreatedBy,
        shortdescription: this.state.ShortDescription,
        longdescription: this.state.LongDescription,
        stockInHand: this.state.StockInHand,
        blogimage: this.state.originalImage == "" ? this.state.Photo : 'https://store.bechofy.in/images/' +
          JSON.parse(localStorage.getItem("ClientDetails"))[0]
            .fld_dbschema + "/blogimages/" +
          this.state.RandomNumber + "-" + this.state.outputFileName.split('.').slice(0, -1).join('.') + '.webp'
      },
      "AddBlog"
    ).then((blogresponse) =>
      blogresponse.json().then((obj1) => {
        if (blogresponse.status == 200 || blogresponse.status == 201) {
          Notiflix.Notify.Success("Blog Details Successfully Added");
          if (this.state.originalImage !== "") {
            this.onUploadImage();
          } else {
            this.props.history.push("/blog-list")
          }
          Notiflix.Loading.Remove();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj1.data);
        }
      })
    );
  };
  //upload image start
  onUploadImage = () => {
    Notiflix.Loading.Dots("Uploading Image");
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(this.state.originalImage, options)
        .then((compressedFile) => {
          // //
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "blogimages");
          form.append(
            "filename", this.state.RandomNumber +
            "-" + compressedFile.name
          );
          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
            headers: {
              "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
              "x-auth-token": JSON.parse(localStorage.getItem("access")),
              "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
            }
          }).then(response => response.json())
            .then(data => {
              Notiflix.Loading.Remove();
              this.props.history.push("/blog-list")
              this.getBlogdetails()
              Notiflix.Notify.Success("Blog image is successfully added");
            });
        })
    } else {
      Notiflix.Loading.Remove();
      Notiflix.Notify.Failure("Please Upload Blog Image");
    }
  }
  //upload image end
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            urlPath={"/customer-management"}
          />
          <Container fluid>
            <Row>
              <Col xl="12">
                <Card className={'overflow-hidden ' + this.state.DisplayVariant}>
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="4">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0">
                            Add New Blog
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="form my-3">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="sw-arrows-first-name">
                                  Blog Image
                                  <span className="mandatory">*</span>
                                </label>
                              </div>
                              (1372px*300px)
                              <div className="best-products position-relative">
                                <div className="img-container">
                                  <ImgUpload
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const imageFile = e.target.files[0];

                                      this.setState({
                                        Photo: URL.createObjectURL(imageFile),
                                        originalImage: imageFile,
                                        outputFileName: imageFile.name
                                      });
                                    }
                                    }
                                    src={this.state.Photo}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: "13px",
                                      top: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          ImageUrl: ImageUploadIcon,
                                        },
                                        () => {
                                          confirmAlert({
                                            title: "Confirm to Delete",
                                            message:
                                              "Are you sure you want to delete the image?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  // Notiflix.Loading.Dots("");
                                                  PostApiCall.postRequest(
                                                    {
                                                      blogid: this.state.BlogID,
                                                    },
                                                    "DeleteBlogImage"
                                                  ).then((results) =>
                                                    // const objs = JSON.parse(result._bodyText)
                                                    results.json().then((obj) => {
                                                      if (
                                                        results.status == 200 ||
                                                        results.status == 201
                                                      ) {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Success(
                                                          "Image successfully deleted."
                                                        );
                                                      } else {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Failure(
                                                          "Something went wrong, try again later."
                                                        );
                                                      }
                                                    })
                                                  );
                                                },
                                              },
                                              {
                                                label: "No",
                                                // onClick: () => alert('Click No')
                                              },
                                            ],
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    <span className="material-symbols-outlined">
                                      backspace
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className={'form-group '}>
                                    <label htmlFor="BlogTitle">
                                      Blog Title
                                    </label>
                                    <input
                                      type="text"
                                      id="BlogTitle"
                                      className="form-control"
                                      value={this.state.BlogTitle}
                                      onChange={(text) => {
                                        this.setState({
                                          BlogTitle: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="BlogCreatedBy">
                                      Blog Written By
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="BlogCreatedBy"
                                      className="form-control"
                                      value={this.state.BlogCreatedBy}
                                      onChange={(text) => {
                                        this.setState({
                                          BlogCreatedBy: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Short Description
                                      <span className="mandatory">*</span>
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.ShortDescription}
                                        onChange={(text) => {
                                          this.setState({
                                            ShortDescription:
                                              text.editor.getData(),
                                          });
                                        }}
                                      //
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Blog Content
                                      <span className="mandatory">*</span>
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.LongDescription}
                                        onChange={(text) => {
                                          this.setState({
                                            LongDescription:
                                              text.editor.getData(),
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <button
                                    style={{
                                      float: "right",
                                      marginTop: "5px",
                                    }}
                                    onClick={() => {
                                      if (this.state.BlogTitle != "") {
                                        if (this.state.BlogCreatedBy != "") {
                                          if (this.state.ShortDescription != "") {
                                            if (this.state.ShortDescription) {
                                              if (this.state.LongDescription != "") {
                                                this.onPostBlogData();
                                              }
                                            } else {
                                              Notiflix.Notify.Failure("Please enter long Description");
                                            }
                                          } else {
                                            Notiflix.Notify.Failure("Please Enter ShortDescription");
                                          }
                                        } else {
                                          Notiflix.Notify.Failure("Please Enter Created By");
                                        }
                                      }
                                      else {
                                        Notiflix.Notify.Failure("Please Enter Blog Title");
                                      }
                                    }}
                                    className="btn align-items-center Bechofy-btn"
                                  >
                                    Save Blog
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card
                  className={'overflow-hidden'}
                >
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text mb-0">Comments</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {this.state.BlogComments.length > 0 ? this.state.BlogComments.map((data) => (
                    <CommentsList comment={data} from="main" allComments={data.child_comments} />
                  )) : "No Comments Available"}
                  {/* {this.state.BlogComments.length > 0 ? this.state.BlogComments.map((data) => (
                    <>
                      {data.fld_parent_comment === null ?
                        < CardBody >
                          <div className="row">
                            <div className="col-12">
                              <div className="best-products">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p><strong>Customer Name</strong></p>
                                  <p><strong>Commented on - </strong><span>{data.fld_createdon}</span></p>
                                </div>
                                <p>{data.fld_comment}</p>
                                <p>
                                  <a href="" className="text-dark">
                                    <img src={ReplyIcon} alt="" className="btnicons"
                                      style={{ marginRight: "5px" }} />
                                    <span>Reply</span></a>
                                </p>
                                <p>
                                  <a href="" className="text-dark">
                                    <img src={ReplyIcon} alt="" className="btnicons"
                                      style={{ marginRight: "5px" }} />
                                    <span>Show Replies</span></a>
                                </p>
                                <p >{data.fld_comment}</p>
                                <div className="row">
                                  <div className="col-12">
                                    <div className={'form-group'}>
                                      <label htmlFor="BlogTitle">
                                        Reply to this Comment
                                      </label>
                                      <input
                                        type="text"
                                        id="BlogTitle"
                                        className="form-control"
                                        placeholder="Write your comment here..."
                                      />
                                      <button
                                        style={{
                                          float: "right",
                                          marginTop: "10px",
                                        }}
                                        className="btn align-items-center Bechofy-btn"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                        : ""}
                    </>
                  )) : ""} */}

                </Card>
                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* -row */}
          </Container>
        </div>
      </React.Fragment >
    );
  }
}
export default AddBlog;
