
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductCard from './ProductCard';
// import "./ShopByCategory.css";

function HomePageProducts(props) {
    const variantImages = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 800,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,

    };


    return (
        <>
            <section className="section-style-1 py-lg-5 py-5 category-card-style">
                <Container fluid>
                    <Slider
                        {...variantImages}
                        className="product-image-slider"
                    >
                        {props.productdata?.map((product) => (
                            <ProductCard product={product}
                                showItemName={props.showItemName}
                                showItemDescription={props.showItemDescription}
                                showItemPrice={props.showItemPrice}
                                showAddToCartBtn={props.showAddToCartBtn}
                                showCheckbox="false" />
                        ))}
                    </Slider>
                </Container>
            </section>
        </>
    );
}

export default HomePageProducts;