import { MDBCol, MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class RawList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			MaterialData: [],
			open: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
		};
	}
	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("Get_Rawmaterial").then((resultdes) =>
			resultdes.json().then((obj) => {
				//
				this.setState({
					MaterialData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
	}
	// fld_created_on: "Feb 14, 2021 12:31 AM"
	// fld_image: "http://demo.globaltrendz.online/Bechofy/images/RawMaterial/testdata-1.jpg"
	// fld_points: 1
	// fld_price: 100
	// fld_rawid: 1
	// fld_rawmaterialname: "testdata"
	// fld_status: "Active"
	// fld_unitofmeasurement: "ml"
	// fld_updated_by: 1
	// fld_updated_on: "Feb 14, 2021 12:31 AM"
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Raw Material Image",
					field: "image",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Raw Material Name",
					field: "name",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Unit of Measurement",
					field: "measurement",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Price",
					field: "price",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Points",
					field: "points",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.MaterialData.filter((data) => {
				// //
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					((data.fld_rawmaterialname !== null &&
						data.fld_rawmaterialname
							.toLowerCase()
							.includes(this.state.searchInput.toLowerCase())) ||
						(data.fld_unitofmeasurement !== null &&
							data.fld_unitofmeasurement
								.toLowerCase()
								.includes(this.state.searchInput.toLowerCase())) ||
						(data.fld_status !== null &&
							data.fld_status
								.toLowerCase()
								.includes(this.state.searchInput.toLowerCase())))
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: <td>{i + 1}</td>,
					image: (
						<td>
							<img src={data.fld_image} style={{ width: "50px" }} />
						</td>
					),
					name: <td>{data.fld_rawmaterialname}</td>,
					measurement: <td>{data.fld_unitofmeasurement}</td>,
					price: <td>{data.fld_price}</td>,
					points: <td>{data.fld_points}</td>,
					status: (
						<td
							style={{ color: data.fld_status == "Active" ? "green" : "red" }}
						>
							{data.fld_status}{" "}
						</td>
					),
					action: (
						<td className="text-center actionuser">
							<i
								className="fas fa-eye btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									localStorage.setItem(
										"RawMaterialDetails",
										JSON.stringify({ ...data, readOnly: true })
									);
									// localStorage.setItem('viewclick',JSON.stringify(this.state.ViewClick))
									window.location.href = "/edit-raw-material";
								}}
							></i>
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									localStorage.setItem(
										"RawMaterialDetails",
										JSON.stringify({ ...data, readOnly: false })
									);
									window.location.href = "/edit-raw-material";
								}}
							></i>
							<span className="btn" style={{ marginTop: "-9px" }}>
								<i
									className="fa fa-trash py-auto "
									aria-hidden="true"
									style={{ fontSize: "15px" }}
									onClick={() => {
										confirmAlert({
											title: "Confirm to Delete",
											message: "Are you sure you want to delete raw material.",
											buttons: [
												{
													label: "Yes",
													onClick: () => {
														Notiflix.Loading.Dots("");
														PostApiCall.postRequest(
															{
																id: data.fld_rawid,
																status: "Disabled",
															},
															"Delete_Rawmaterial"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (
																	results.status == 200 ||
																	results.status == 201
																) {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Success(
																		"Raw Material successfully deleted."
																	);
																	window.location.reload();
																} else {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Failure(
																		"Something went wrong, try again later."
																	);
																}
															})
														);
													},
												},
												{
													label: "No",
													// onClick: () => alert('Click No')
												},
											],
										});
									}}
								></i>
							</span>
						</td>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Raw Material Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Card className="overflow-hidden">
										<div className="Bechofy-bg-soft-pink">
											<Row>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<h5 className="Bechofy-text">
															Raw Material List
														</h5>
													</div>
												</Col>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<Link
															to="/add-raw-material"
															style={{ float: "right", marginTop: "-5px" }}
															className="btn align-items-center btn Bechofy-btn "
														>
															Add New Raw Material{" "}
															<i className="fa fa-plus" aria-hidden="true"></i>
														</Link>
													</div>
												</Col>
											</Row>
										</div>
										<CardBody className="pt-0">
											<Row></Row>
										</CardBody>
										<CardBody className="pt-0">
											<Row>
												<MDBCol md="3" style={{ marginBottom: "10px" }}>
													<input
														className="form-control"
														type="text"
														placeholder="Search"
														aria-label="Search"
														onChange={(e) => this.seachBarHandler(e)}
														value={this.state.searchInput}
													/>
												</MDBCol>
												<Col md="12">
													{this.state.MaterialData.length == 0 ? (
														<p style={{ textAlign: "center" }}>
															<b>No Raw Matarial List Available</b>
														</p>
													) : (
														<MDBDataTableV5
															striped
															bordered
															small
															data={data}
															searchBottom={false}
															seachTop={true}
														>
															<MDBTableHead columns={data.columns} />
															<MDBTableBody rows={data.rows} />
														</MDBDataTableV5>
													)}
												</Col>
											</Row>
										</CardBody>
									</Card>
									{/* <WelcomeComp /> */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RawList;
