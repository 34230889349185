import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxHamburgerMenu } from "react-icons/rx";
import PostApiCall from "../../../Api";
import Saveicon from "../../../assets/images/dashboardimages/filesave.png";
import Sequentialize from "../../../assets/images/dashboardimages/Sequentialize.png";
import "../../../pages/Authentication/login.css";
import "./DragDropList.css";
const DragDropList = (props) => {
  const [data, setData] = useState([]);
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [index, setIndex] = useState();
  const [id, setId] = useState();
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setData(tempData);
  };
  useEffect(() => {

    if (data.length <= 0) {
      setData(props.verticalData);
    }
  });
  return (
    <div className="App serialize-side-drawer">
      <h6 className="mb-4 d-flex dragdrop-side px-3 bg-white">
        <div>
          <img
            src={Sequentialize}
            alt="block user"
            className="sequence-drwa-icons ms-3 mt-1"
            style={{ marginRight: "5px" }}
          />
        </div>
        <div className="sequence-draw-title mx-3 ">
          Select the Sequence in which you want the {props.for} to be displayed.
          You can Drag and Drop the items to Sequentialize
        </div>
      </h6>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="table borderd mb-0 pb-0 mt-5 px-3">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <div
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data?.map((user, index) => (
                  <Draggable
                    key={"" + user.fld_verticleid}
                    draggableId={"" + user.fld_verticleid}
                    index={index}
                  >
                    {(provider) => (
                      <div
                        className="container-content sequentialize-parent"
                        {...provider.draggableProps}
                        value={index}
                        ref={provider.innerRef}
                      >
                        <div
                          className="image d-flex"
                          key={index}
                          style={{ width: "20%", height: "100%", flex: 1 }}
                        >
                          <img
                            className="sequentialize-img"
                            src={user.fld_verticalImage}
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                          />
                          <div
                            className="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          >
                            {user.fld_verticlename}
                          </div>
                        </div>
                        <div {...provider.dragHandleProps}>
                          {" "}
                          <RxHamburgerMenu className="p-0" />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <div className="sequence-save-btn bg-white">
        <a
          onClick={(e) => {
            {
              e.preventDefault();
              Notiflix.Loading.Dots("Sequence saved successfully...");
              PostApiCall.postRequest(
                {
                  sequence: data,
                  for: props.for,
                },
                "AddSequence"
              ).then((response) => {
                response.json().then((result) => {                  
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Success(
                    "Saved Successfully"
                  );
                });
              });
            }
          }}
          className="btn Bechofy-btn waves-effect waves-light btn-sm float-right my-3 py-2 px-5"
          style={{ fontSize: "15px" }}
        >
          <img src={Saveicon} alt="block user" className="btnicons mx-2"></img>{" "}
          Save this Sequence
        </a>
      </div>
    </div>
  );
};
export default DragDropList;
