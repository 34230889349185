import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-modern-drawer/dist/index.css';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Refreshicon from "../../assets/images/dashboardimages/refresh.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import variantlist from "../../assets/images/dashboardimages/variantlist.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
import Accordion from 'react-bootstrap/Accordion';
import { IoIosArrowUp } from "react-icons/io";
import "./customsection.css";
const Skeletonfeatured = ({ cards }) => {
    return (
        <Card>
            <div>
                <Row className="justify-content-between">
                    <Col xs="4" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                    <Col xs="6" className="px-4 my-auto">
                        <div className="Bechofy-text" style={{ textAlign: "end" }}>
                            <Skeleton className="py-2" style={{ width: "30%" }} />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col xs="12" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col xs="1" className="px-3">
                        <div className="Bechofy-text py-3 px-2">
                            <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                                <Skeleton className="py-2" />
                            </h4>
                        </div>
                    </Col>
                </Row>
            </div>
            <CardBody className="pt-0 px-0">
                <Row>
                    <Col
                        xs="12"
                        className="latestreview-mdbtable lastestcustomer-table-responsive"
                    >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <th scope="col">
                                        <Skeleton className="py-2" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <Skeleton className="py-2" />
                                    </th>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                    <td>
                                        <Skeleton className="py-2" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
class CustomSection extends Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
        this.state = {
            id: this.props.match.params.id,
            Sequence: null,
            SectionName: null,
            Status: 'Select',
            Headline1: null,
            Headline2: null,
            Headline3: null,
            MediaType: 'Select',
            ImageDimension: 'Select',
            ItemChoice: 'Select',
            TotalItem: 'Select',
            TotalItemDisplay: 'Select',
            ShowItemName: false,
            ShowShortDescription: false,
            ShowPrice: false,
            ShowAddToCart: false,
            SaveItemList: null,
            ItemsList: [],
            BrandData: [],
            VerticalData: [],
            SubCatgeoryData: [],
            CategoryData: [],
            searchInput: "",
            Brand: null,
            Vertical: null,
            Category: null,
            SubCategory: null,
            Product: null,
            RotateArrow:false
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        localStorage.removeItem("VariantImageID");
        localStorage.removeItem("VariantIdForImage");
        localStorage.removeItem("itemDetails");
        localStorage.removeItem("VariantDetails");
        GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    BrandData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
        GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    VerticalData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: ` where fld_id = ${this.state.id}`
            },
            "GetHomepageSetup"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {


                        this.setState({
                            id: obj.data[0].fld_id,
                            Sequence: obj.data[0].fld_sequence,
                            SectionName: obj.data[0].fld_section_name,
                            Status: obj.data[0].fld_status,
                            Headline1: obj.data[0].fld_headline1,
                            Headline2: obj.data[0].fld_headline2,
                            Headline3: obj.data[0].fld_headline3,
                            MediaType: obj.data[0].fld_media_type,
                            ImageDimension: obj.data[0].fld_image_dimension,
                            ItemChoice: obj.data[0].fld_item_choice,
                            TotalItem: obj.data[0].fld_total_item_to_be_displayed,
                            TotalItemDisplay: obj.data[0].fld_slide_item_count,
                            ShowItemName: obj.data[0].fld_show_item_name,
                            ShowShortDescription: obj.data[0].fld_show_short_description,
                            ShowPrice: obj.data[0].fld_show_price,
                            ShowAddToCart: obj.data[0].fld_show_add_to_cart
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                sectionid: this.state.id
            },
            "GetWebsiteHomePageItems"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        ItemsList: obj.data,
                        count: obj.data.length,
                        loader: false,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
    }
    repeatLastSearch = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_vertical_id=${localStorage.getItem("lastsearchitems").split(",")[1]}`,
            },
            "Get_categorydropdown"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (
                    results1.status == 200 ||
                    results1.status == 201
                ) {
                    this.setState({
                        CategoryData: obj1.data,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                categoryid: localStorage.getItem("lastsearchitems").split(",")[2],
            },
            "Get_subcategoryDropdown"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    this.setState({
                        SubCatgeoryData: obj1.data,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: ` where fld_subcategoryid =  ${localStorage.getItem("lastsearchitems").split(",")[3]}`,
                recordCount: " fld_productid,fld_itemname,fld_status ",
            },
            "GetProductMaster"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        ProductData: obj.data,
                        count: obj.data.length,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        );
        this.setState({
            Brand: localStorage.getItem("lastsearchitems").split(",")[0],
            Vertical: localStorage.getItem("lastsearchitems").split(",")[1],
            Category: localStorage.getItem("lastsearchitems").split(",")[2],
            SubCategory: localStorage.getItem("lastsearchitems").split(",")[3],
            Product: localStorage.getItem("lastsearchitems").split(",")[4]
        })
        this.getVariantData();
    }
    getVariantData = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_brandid = isnull(${localStorage.getItem("lastsearchitems").split(",")[0]},fld_brandid) and fld_productid= isnull(${localStorage.getItem("lastsearchitems").split(",")[4]},fld_productid)`,
                recordCount: "*",
                sectionid: this.state.id
            },
            "GetWebsiteHomePageAllItems"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        ItemsList: obj.data,
                        ViewVariantList: true,
                        ViewProductList: false,
                        ShowAddNewVaraintButton: true
                    });
                    Notiflix.Loading.Remove();
                }
            })
        )
    }
    onChangeCategory(text) {
        this.setState(
            {
                Category: text.target.value,
            },
            () => {
                PostApiCall.postRequest(
                    {
                        categoryid: this.state.Category,
                    },
                    "Get_subcategoryDropdown"
                ).then((results1) =>
                    results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {
                            this.setState({
                                SubCatgeoryData: obj1.data,
                            });
                            Notiflix.Loading.Remove();
                        }
                    })
                );
            }
        );
    }
    onChangeSubCategory(text) {
        this.setState(
            {
                SubCategory: text.target.value,
            },
            () => {
                // ======Get Item List==========
                PostApiCall.postRequest(
                    {
                        whereClause: ` where fld_subcategoryid =  ${this.state.SubCategory}`,
                        recordCount: " fld_productid,fld_itemname,fld_status ",
                    },
                    "GetProductMaster"
                ).then((results) =>
                    results.json().then((obj) => {
                        if (results.status == 200 || results.status == 201) {
                            this.setState({
                                ProductData: obj.data,
                                count: obj.data.length,
                            });
                            Notiflix.Loading.Remove();
                        }
                    })
                );
                Notiflix.Loading.Remove();
            }
        );
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };


    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    toggleDrawer = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };


    render() {
        const { isOpen } = this.state;
        const data = {
            columns: [
                {
                    label: "Image",
                    field: "image",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Variant Name",
                    field: "VariantName",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Category",
                    field: "Category",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Subcategory",
                    field: "Subcategory",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Add",
                    field: "Add",
                    sort: "disabled",
                    width: 300,
                }
            ],
            rows: this.state.ItemsList.filter((data) => {
                if (
                    this.state.searchInput == ""
                ) {
                    return data;
                }
                if (
                    data.fld_variantname?.toLowerCase()
                        .includes(this.state.searchInput?.toLowerCase()) ||
                    `${data.fld_variantname ? data.fld_variantname.toLowerCase() : ""
                        }`.includes(
                            this.state.searchInput?.toLowerCase()
                        )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    image: (
                        <img
                            className="sequentialize-img"
                            src={data.VariantImage.split("#")[0]}
                        />
                    ),
                    VariantName: data.fld_variantname,
                    Category: data.fld_categoryname,
                    Subcategory: data.fld_subcategoryname,
                    Add: (
                        <Form>
                            <Form.Check
                                type="switch"
                                checked={data.fld_status_items === "true" ? true : false}
                                id={"custom-switch-featured" + data.fld_variantid}
                                onClick={(e) => {
                                    PostApiCall.postRequest(
                                        {
                                            sectionid: this.state.id,
                                            variantid: data.fld_variantid,
                                            status: e.target.checked === true ? "true" : "false",
                                        },
                                        "UpdateWebsiteHomePageItems"
                                    ).then((results) =>
                                        results.json().then((obj) => {
                                            if (results.status == 200 || results.status == 201) {
                                                Notiflix.Notify.Success("Status Updated Successfully");
                                                this.repeatLastSearch();
                                                Notiflix.Loading.Remove();
                                            } else {
                                                Notiflix.Notify.Failure(obj.data);
                                                Notiflix.Loading.Remove();
                                            }
                                        })
                                    );
                                }}
                            />
                        </Form>)
                };
            }),
        }

        const variantImages = {
            dots: false,
            infinite: false,
            arrows: true,
            speed: 800,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        function CustomerSectionAccordian() {
            var element = document.getElementById("OpenAccordianbtn");
            element.classList.toggle("arrow");
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(
                                window.location.pathname.replace("/", "")
                            )
                                .replace("-", " ")
                                .replace("_", " ")}
                            urlPath={"/custom-section"}
                        />
                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    {this.state.loader == true ? (
                                        <>
                                            <Skeletonfeatured cards={5} />
                                        </>
                                    ) : (
                                        <Card className="overflow-hidden">
                                            <div
                                                className="Bechofy-bg-soft-pink"
                                                style={{ height: "60px" }}
                                            >
                                                <div className="row my-1">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <h4
                                                                className="px-4 text-white text-start"
                                                                style={{ marginTop: "15px" }}
                                                            >
                                                                Custom Section
                                                            </h4>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <CardBody className="">
                                                <Row className="text-center justify-content-center">
                                                    {/* <div className="col-lg-2 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Section Sequence
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    Sequence: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="col-lg-8 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Section Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="exampleFormControlInput1"
                                                            value={this.state.SectionName}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    SectionName: e.target.value
                                                                })
                                                            }}
                                                            placeholder=""
                                                        ></input>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Section Status
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.Status}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    Status: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>Active</option>
                                                            <option>Inactive</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Headline 1
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="exampleFormControlInput1"
                                                            value={this.state.Headline1}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    Headline1: e.target.value
                                                                })
                                                            }}
                                                        ></input>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Headline 2
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="exampleFormControlInput1"
                                                            value={this.state.Headline2}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    Headline2: e.target.value
                                                                })
                                                            }}
                                                        ></input>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Headline 3
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="exampleFormControlInput1"
                                                            value={this.state.Headline3}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    Headline3: e.target.value
                                                                })
                                                            }}
                                                        ></input>
                                                    </div>
                                                    <div className="col mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Media Type
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.MediaType}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    MediaType: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>Photo</option>
                                                            <option>Video</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className="col mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Image Dimension
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.ImageDimension}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    ImageDimension: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>Square</option>
                                                            <option>Circle</option>
                                                            <option>Rectangle</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="col mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Item Choice
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.ItemChoice}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    ItemChoice: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>Random</option>
                                                            <option>Choose Item</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Total No. of items to be displayed in section
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.TotalItem}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    TotalItem: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            No. of items to be displayed in a slide
                                                        </label>
                                                        <select
                                                            class="form-control"
                                                            id="exampleFormControlSelect1"
                                                            value={this.state.TotalItemDisplay}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    TotalItemDisplay: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <label
                                                            for="exampleFormControlInput1"
                                                            className="d-block text-left"
                                                        >
                                                            Item details to be shown
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-lg-3 text-left">
                                                                <div class="form-check mr-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value={this.state.ShowItemName}
                                                                        checked={this.state.ShowItemName == "true" ? true : false}
                                                                        id="defaultCheck1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                ShowItemName: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="defaultCheck1"
                                                                    >
                                                                        Item Name
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 text-left">
                                                                <div class="form-check">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value={this.state.ShowShortDescription}
                                                                        checked={this.state.ShowShortDescription == "true" ? true : false}
                                                                        id="defaultCheck1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                ShowShortDescription: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="defaultCheck1"
                                                                    >
                                                                        Short Description
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 text-left">
                                                                <div class="form-check">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value={this.state.ShowPrice}
                                                                        checked={this.state.ShowPrice == "true" ? true : false}
                                                                        id="defaultCheck1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                ShowPrice: !this.state.ShowPrice
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="defaultCheck1"
                                                                    >
                                                                        Price
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 text-left">
                                                                <div class="form-check">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value={this.state.ShowAddToCart}
                                                                        checked={this.state.ShowAddToCart == "true" ? true : false}
                                                                        id="defaultCheck1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                ShowAddToCart: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="defaultCheck1"
                                                                    >
                                                                        Add to Cart
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-5">
                                                                <Accordion className="text-left"
                                                                    style={{ display: this.state.ItemChoice != "Select" ? "block" : "none" }}
                                                                    onClick={CustomerSectionAccordian}
                                                                >
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header className="Bechofy-bg-soft-pink cms-acoordian py-2">
                                                                            <h4
                                                                                className="px-4 text-white d-flex w-100 justify-content-between"
                                                                            >
                                                                               <div> Select Variants </div>
                                                                               <div id="OpenAccordianbtn" className=""><IoIosArrowUp /></div>
                                                                            </h4>
                                                                        </Accordion.Header>

                                                                        <Accordion.Body>
                                                                            <div className="border">
                                                                                <Card style={{ padding: " 0 20px" }}>
                                                                                    <div className="row my-1 ">
                                                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                                                            <div className="form-group mt-lg-3">
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    placeholder="Select Brand"
                                                                                                    value={this.state.Brand}
                                                                                                    onChange={(text) => {
                                                                                                        this.setState({
                                                                                                            Brand: text.target.value,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <option>Select Brand</option>
                                                                                                    {this.state.BrandData?.map((brand) => (
                                                                                                        <option key={brand.value} value={brand.value}>
                                                                                                            {brand.label} - {brand.fld_status}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                                                            <div className="form-group mt-lg-3">
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    placeholder="Select Vertical"
                                                                                                    value={this.state.Vertical}
                                                                                                    onChange={(text) => {
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                Vertical: text.target.value,
                                                                                                            },
                                                                                                            () => {
                                                                                                                PostApiCall.postRequest(
                                                                                                                    {
                                                                                                                        whereClause: `where fld_vertical_id=${this.state.Vertical}`,
                                                                                                                    },
                                                                                                                    "Get_categorydropdown"
                                                                                                                ).then((results1) =>
                                                                                                                    results1.json().then((obj1) => {
                                                                                                                        if (
                                                                                                                            results1.status == 200 ||
                                                                                                                            results1.status == 201
                                                                                                                        ) {
                                                                                                                            this.setState({
                                                                                                                                CategoryData: obj1.data,
                                                                                                                            });
                                                                                                                            Notiflix.Loading.Remove();
                                                                                                                        }
                                                                                                                    })
                                                                                                                );
                                                                                                            }
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <option value="">Select Vertical</option>
                                                                                                    {this.state.VerticalData?.map((Verticle) => (
                                                                                                        <option
                                                                                                            key={Verticle.value}
                                                                                                            value={Verticle.value}
                                                                                                        >
                                                                                                            {Verticle.label} - {Verticle.fld_status}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                                                            <div className="form-group mt-lg-3">
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    placeholder="Select Vertical"
                                                                                                    value={this.state.Category}
                                                                                                    onChange={this.onChangeCategory.bind(this)}
                                                                                                >
                                                                                                    <option value="">Select Category</option>
                                                                                                    {this.state.CategoryData?.map((Category) => (
                                                                                                        <option
                                                                                                            key={Category.value}
                                                                                                            value={Category.value}
                                                                                                        >
                                                                                                            {Category.label} - {Category.fld_status}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                                                            <div className="form-group mt-lg-3">
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    placeholder="Select Vertical"
                                                                                                    value={this.state.SubCategory}
                                                                                                    onChange={this.onChangeSubCategory.bind(this)}
                                                                                                >
                                                                                                    <option>Select Sub Category</option>
                                                                                                    {this.state.SubCatgeoryData?.map((subcat) => (
                                                                                                        <option key={subcat.value} value={subcat.value}>
                                                                                                            {subcat.label} - {subcat.fld_status}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                                                                                            <div className="form-group mt-lg-3">
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    placeholder="Select Vertical"
                                                                                                    value={this.state.Product}
                                                                                                    onChange={(text) => {
                                                                                                        this.setState({
                                                                                                            Product: text.target.value
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <option>Select Product</option>
                                                                                                    {this.state.ProductData?.map((product) => (
                                                                                                        <option key={product.fld_itemname} value={product.fld_productid}>
                                                                                                            {product.fld_itemname} - {product.fld_status}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-6 mb-md-3 mt-lg-0">
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    PostApiCall.postRequest(
                                                                                                        {
                                                                                                            whereClause: `where fld_brandid = isnull(${this.state.Brand},fld_brandid) and fld_verticleid = isnull(${this.state.Vertical},fld_verticleid) and fld_categoryid = isnull(${this.state.Category},fld_categoryid) and fld_subcategoryid= isnull(${this.state.SubCategory},fld_subcategoryid)`,
                                                                                                            recordCount: "*",
                                                                                                            sectionid: this.state.id
                                                                                                        },
                                                                                                        "GetWebsiteHomePageAllItems"
                                                                                                    ).then((results) =>
                                                                                                        results.json().then((obj) => {
                                                                                                            if (results.status == 200 || results.status == 201) {

                                                                                                                localStorage.setItem("lastsearchitems", this.state.Brand + "," + this.state.Vertical + "," + this.state.Category + "," + this.state.SubCategory + "," + this.state.Product)
                                                                                                                if (obj.data.length > 0) {
                                                                                                                    localStorage.setItem("lastsearchitemsvalue", " Brand - " + obj.data[0].fld_brand_name + " Vertical - " + obj.data[0].fld_verticlename + " Category - " + obj.data[0].fld_categoryname + " SubCategory - " + obj.data[0].fld_subcategoryname + " Item - " + obj.data[0].fld_itemname)
                                                                                                                }
                                                                                                                this.setState({
                                                                                                                    ItemsList: obj.data,
                                                                                                                    ViewVariantList: true,
                                                                                                                    ViewProductList: false,
                                                                                                                    ShowAddNewVaraintButton: true
                                                                                                                });
                                                                                                                Notiflix.Loading.Remove();
                                                                                                            }
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                                className="btn align-items-center btn Bechofy-btn FilterCardBtn3 mt-lg-3"
                                                                                            >
                                                                                                <span className="">
                                                                                                    <img
                                                                                                        src={variantlist}
                                                                                                        alt="block user"
                                                                                                        className="btnicons"
                                                                                                        style={{ marginRight: "5px" }}
                                                                                                    ></img>{" "}
                                                                                                </span>
                                                                                                Get Variants
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn align-items-center btn refresh-item-responsive-btn Bechofy-btn FilterCardBtn4 mt-lg-3"
                                                                                                onClick={() => {
                                                                                                    window.location.reload();
                                                                                                }}
                                                                                            >
                                                                                                <span className="">
                                                                                                    <img
                                                                                                        src={Refreshicon}
                                                                                                        alt="block user"
                                                                                                        className="btnicons"
                                                                                                        style={{ marginRight: "5px" }}
                                                                                                    ></img>{" "}
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row my-1 pl-3">
                                                                                        <h6>Last Search:<a onClick={() => {
                                                                                            this.repeatLastSearch();
                                                                                        }}>{localStorage.getItem("lastsearchitemsvalue")}</a>
                                                                                        </h6>
                                                                                    </div>
                                                                                </Card>
                                                                                <CardBody className="pt-0">
                                                                                    <Row className="text-center justify-content-center">
                                                                                        <>
                                                                                            <MDBCol
                                                                                                md="12"
                                                                                                style={{
                                                                                                    marginBottom: "15px",
                                                                                                    marginTop: "15px",
                                                                                                }}
                                                                                            >
                                                                                                <div className="input-group mb-3">
                                                                                                    <div className="input-group-prepend">
                                                                                                        <span
                                                                                                            className="input-group-text"
                                                                                                            id="basic-addon1"
                                                                                                        >
                                                                                                            <span className="">
                                                                                                                <img
                                                                                                                    src={Searchicon}
                                                                                                                    alt="block user"
                                                                                                                    className="btnicons"
                                                                                                                ></img>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        placeholder="Type the search phrase you wish to search within the grid"
                                                                                                        aria-label="Search"
                                                                                                        aria-describedby="basic-addon1"
                                                                                                        onChange={(e) => this.seachBarHandler(e)}
                                                                                                        value={this.state.searchInput}
                                                                                                    />
                                                                                                </div>
                                                                                            </MDBCol>
                                                                                            <Col
                                                                                                md="12"
                                                                                                className="lastestcustomer-table-responsive"
                                                                                            >
                                                                                                <MDBDataTable
                                                                                                    hover
                                                                                                    // scrollY
                                                                                                    striped
                                                                                                    bordered
                                                                                                    data={data}
                                                                                                    seachTop={false}
                                                                                                    entriesOptions={[10, 25, 50, 100]}
                                                                                                    className="customer-table-responsive"
                                                                                                >
                                                                                                    <MDBTableHead columns={data.columns} />
                                                                                                    <MDBTableBody rows={data.rows} />
                                                                                                </MDBDataTable>
                                                                                            </Col>
                                                                                        </>

                                                                                    </Row>
                                                                                </CardBody>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>


                                                            </div>
                                                            <div className="col-lg-12">
                                                                <Card>
                                                                    <CardBody className="py-1 my-1">
                                                                        <Row>
                                                                            <Col xs="12">
                                                                                <div className="row justify-content-end">
                                                                                    <div className="col-6">
                                                                                        <button
                                                                                            style={{
                                                                                                float: "right",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                            onClick={() => {

                                                                                                if (this.state.SectionName !== null) {
                                                                                                    if (this.state.Status !== 'Select') {
                                                                                                        if (this.state.Headline1 !== null) {
                                                                                                            if (this.state.Headline2 !== null) {
                                                                                                                if (this.state.Headline3 !== null) {
                                                                                                                    if (this.state.MediaType !== 'Select') {
                                                                                                                        if (this.state.ItemChoice !== 'Select') {
                                                                                                                            if (this.state.TotalItem !== 'Select') {
                                                                                                                                if (this.state.TotalItemDisplay !== 'Select') {
                                                                                                                                    PostApiCall.postRequest(
                                                                                                                                        {
                                                                                                                                            sequence: this.state.Sequence,
                                                                                                                                            sectionname: this.state.SectionName,
                                                                                                                                            status: this.state.Status,
                                                                                                                                            headline1: this.state.Headline1,
                                                                                                                                            headline2: this.state.Headline2,
                                                                                                                                            headline3: this.state.Headline3,
                                                                                                                                            mediatype: this.state.MediaType,
                                                                                                                                            imagedimension: this.state.ImageDimension,
                                                                                                                                            itemchoice: this.state.ItemChoice,
                                                                                                                                            totalitemtobedisplayed: this.state.TotalItemDisplay,
                                                                                                                                            slideitemcount: this.state.TotalItem,
                                                                                                                                            showitemname: this.state.ShowItemName,
                                                                                                                                            showshortdescription: this.state.ShowShortDescription,
                                                                                                                                            showprice: this.state.ShowPrice,
                                                                                                                                            showaddtocart: this.state.ShowAddToCart,
                                                                                                                                            itemslist: this.state.SaveItemList
                                                                                                                                        },
                                                                                                                                        "AddWebsiteHomeSection"
                                                                                                                                    ).then((results1) =>
                                                                                                                                        results1.json().then((obj1) => {
                                                                                                                                            if (results1.status == 200 || results1.status == 201) {
                                                                                                                                                this.setState({
                                                                                                                                                    SubCatgeoryData: obj1.data,
                                                                                                                                                });
                                                                                                                                                Notiflix.Loading.Remove();
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                    );
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    Notiflix.Notify.Failure("Total Items Display is mandatory")
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                Notiflix.Notify.Failure("Total Items is mandatory")
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            Notiflix.Notify.Failure("Item Choice is mandatory")
                                                                                                                        }

                                                                                                                    }
                                                                                                                    else {
                                                                                                                        Notiflix.Notify.Failure("Media Type is mandatory")
                                                                                                                    }

                                                                                                                } else {
                                                                                                                    Notiflix.Notify.Failure("Headline3 is mandatory")
                                                                                                                }

                                                                                                            } else {
                                                                                                                Notiflix.Notify.Failure("Headline2 is mandatory")
                                                                                                            }

                                                                                                        } else {
                                                                                                            Notiflix.Notify.Failure("Headline1 is mandatory")
                                                                                                        }

                                                                                                    } else {
                                                                                                        Notiflix.Notify.Failure("Status is mandatory")
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    Notiflix.Notify.Failure("Section is mandatory")
                                                                                                }
                                                                                            }}
                                                                                            className="btn align-items-center Bechofy-btn "
                                                                                        >
                                                                                            Save this section
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default CustomSection;
