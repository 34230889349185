import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Col, Container, Row } from "reactstrap";
var width = '100%'
var height = 300
const ImgUploadCover = ({
	onChange,
	src
}) =>
	<label htmlFor="photo-upload-1" className="custom-file-upload fas" style={{ width: width, height: height + 'px', borderRadius: '10px' }}>
		<div className="img-wrap1 img-upload1" style={{ width: width - 10, height: height - 15 + 'px', borderRadius: '10px' }}>
			<img htmlFor="photo-upload-1" src={src} style={{ width: width, height: height - 15 + 'px', borderRadius: '10px' }} />
		</div>
		<input
			accept="image/*"
			id="photo-upload-1" type="file" onChange={onChange} />
	</label>
class BannerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			UserData: [],
			Id: "",
			searchInput: "",
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
	}
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink" style={{ height: '75px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '25px' }}>Heading</h4>
												</div>
											</div>
										</div>
									</div>
								</Card>
								<Card>
									<Row className="mt-2 ml-2">
										<div className="col-3">
											<div className="form-group">
												<label htmlFor="inputType" className="ml-10 mt-10">Vertical</label>
												<select
													className="form-control"
													value={this.state.Vertical}
													onChange={(text) => {
														this.setState({
															Vertical: text.target.value,
														});
													}}
												>
													<option value="">
														Select
													</option>
												</select>
											</div>
										</div>
										<div className="col-3">
											<div className="form-group">
												<label htmlFor="inputType" className="ml-10 mt-10">Category</label>
												<select
													className="form-control"
													value={this.state.Category}
													onChange={(text) => {
														this.setState({
															Category: text.target.value,
														});
													}}
												>
													<option value="">
														Select
													</option>
												</select>
											</div>
										</div>
										<div className="col-3">
											<div className="form-group">
												<label htmlFor="inputType" className="ml-10 mt-10">SubCategory</label>
												<select
													className="form-control"
													value={this.state.SubCategory}
													onChange={(text) => {
														this.setState({
															SubCategory: text.target.value,
														});
													}}
												>
													<option value="">
														Select
													</option>
												</select>
											</div>
										</div>
									</Row>
								</Card>
								<Card className="p-3">
									<Row>
										<Col xs="12">
											<div className="row">
												<div className="col-md-12">
													<div className="form-group mb-2">
														<div></div>
														<div>
															<label htmlFor="validationCustom05">Upload Image</label>
															<ImgUploadCover onChange={(e) => this.photoUploadCover(e)} src={this.state.imagePreviewUrlCover} />
														</div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default BannerList;
