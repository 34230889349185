import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "./Navbar.css";
import Home from "./pages/Home";
import HelpCenter from "./pages/HelpCenter";
import OrderHistory from "./pages/OrderHistory";
import SubmitRequest from "./pages/SubmitRequest";
import Navbar from "./Navbar";
function Routes() {
    return (
        <div className="container">
            <Router>
                <Navbar />
                <Route path="/home" component={Home} />
                <Route path="/helpcenter" component={HelpCenter} />
                <Route path="/submit-request" component={SubmitRequest} />
                <Route path="/order_history" component={OrderHistory} />
            </Router>
        </div>
    );
}
export default Routes;