export const MenuList = [
    {
        title: "Home",
        url: "/home"
    },
    {
        title: "Help Center",
        url: "/helpcenter"
    },
    {
        title: "Submit Request",
        url: "/submit-request"
    },
    {
        title: "Order History",
        url: "/order_history"
    }
]