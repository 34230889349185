import { MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class RawList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			PickupData: [],
			open: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
			ProductData: [],
			StateData: [],
			state: "",
			CityData: [],
			City: "",
			PincodeData: [],
			Pincode: "",
		};
	}
	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					ProductData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
		PostApiCall.postRequest(
			{
				countryid: 101,
			},
			"GetState"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((objstate) => {
				if (results.status == 200 || results.status == 201) {
					if (objstate.data.length != 0) {
						// this.props.setstate()
						this.setState({
							StateData: objstate.data,
						});
					}
					PostApiCall.postRequest(
						{
							stateid: objstate.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						// const objs = JSON.parse(result._bodyText)
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								if (objcity.data.length != 0) {
									// this.props.setcity(objcity.data[0].label)
									this.setState({
										CityId: objcity.data[0].value,
										CityData: objcity.data,
										City: objcity.data[0].label,
									});
								}
							}
							// PostApiCall.postRequest({
							//     id :objcity.data[0].value,
							//   },"GetPincodeById").then((resultspin) =>
							//     // const objs = JSON.parse(result._bodyText)
							//     resultspin.json().then(objpin => {
							//     if(resultspin.status == 200 || resultspin.status==201){
							//         if(objpin.data.length != 0 ){
							//             // this.props.setcity(objcity.data[0].label)
							//             this.setState({
							//                 PinId : objpin.data[0].value,
							//                 PincodeData : objpin.data,
							//                  Pincode:objpin.data[0].label
							//             })
							//           }
							//     }
							// }))
						})
					);
				}
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	// customer_name: "Shivani"
	// fld_landmark: "Delhi"
	// fld_pickup_date: "2021-03-03T00:00:00.000Z"
	// fld_pickup_id: 2
	// fld_pickup_status: "Pending"
	// fld_pickup_time: "01:00"
	// fld_presentaddress: "Delhi"
	// fld_presentcity: "Delhi"
	// fld_presentpincode: "110007"
	// fld_presentstate: "Delhi"
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		// //
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Product Name",
					field: "name",
					sort: "disabled",
					width: 200,
				},
				{
					label: "State",
					field: "state",
					sort: "disabled",
					width: 150,
				},
				{
					label: "City",
					field: "city",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Assign Pincode",
					field: "pincode",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.ProductData.map((data, index) => {
				return {
					serial: <td>{index + 1}</td>,
					name: <td>{data.fld_itemname}</td>,
					state: (
						<td>
							{" "}
							<select
								className="custom-select my-1 mr-sm-2"
								onChange={(e) => {
									this.setState(
										{
											state: e.target.value,
										},
										() => {
											Notiflix.Loading.Dots("Please wait...");
											PostApiCall.postRequest(
												{
													stateid: this.state.state,
												},
												"GetCity"
											).then((results3) => {
												// //
												results3.json().then((obj3) => {
													if (
														results3.status == 200 ||
														results3.status == 201
													) {
														// //
														this.setState({
															CityData: obj3.data,
														});
														Notiflix.Loading.Remove();
													}
												});
											});
											PostApiCall.postRequest(
												{
													id: this.state.state,
												},
												"GetPincodeByState"
											).then((results3) => {
												// //
												results3.json().then((obj3) => {
													if (
														results3.status == 200 ||
														results3.status == 201
													) {
														// //
														this.setState({
															PincodeData: obj3.data,
														});
														Notiflix.Loading.Remove();
													}
												});
											});
										}
									);
								}}
							>
								<option value="">Select State</option>
								{this.state.StateData.map((ra) => (
									<option key={ra.label} value={ra.value ? ra.value : null}>
										{ra.label}
									</option>
								))}
							</select>
						</td>
					),
					// state:<td>{data.fld_presentstate}</td>,
					city: (
						<td>
							{" "}
							<select
								className="custom-select my-1 mr-sm-2"
								onChange={(e) => {
									this.setState(
										{
											City: e.target.value,
										},
										() => {
											Notiflix.Loading.Dots("Please wait...");
											// //
											PostApiCall.postRequest(
												{
													id: this.state.City,
												},
												"GetPincodeById"
											).then((results3) => {
												// //
												results3.json().then((obj3) => {
													if (
														results3.status == 200 ||
														results3.status == 201
													) {
														// //
														this.setState({
															PincodeData: obj3.data,
														});
														Notiflix.Loading.Remove();
													}
												});
											});
										}
									);
								}}
							>
								<option value="">Select City</option>
								{this.state.CityData.map((ra) => (
									<option key={ra.label} value={ra.value ? ra.value : null}>
										{ra.label}
									</option>
								))}
							</select>
						</td>
					),
					pincode: (
						<td>
							{" "}
							<select
								className="custom-select my-1 mr-sm-2"
								onChange={(e) => {
									this.setState({
										Pincode: e.target.value,
									});
								}}
							>
								<option value="">Select Pincode</option>
								{this.state.PincodeData.map((pin) => (
									<option key={pin.label} value={pin.value ? pin.value : null}>
										{pin.label}
									</option>
								))}
							</select>
						</td>
					),
					action: (
						<td className="text-center actionuser">
							<button
								className="btn align-items-center Bechofy-btn "
								onClick={() => {
									if (this.state.Pincode !== "") {
										Notiflix.Loading.Dots("");
										var login = localStorage.getItem("LoginDetail");
										var details = JSON.parse(login);
										PostApiCall.postRequest(
											{
												productid: data.fld_productid,
												pincode: this.state.Pincode,
												status: "Assigned",
												createdon: moment().format("YYYY-MM-DD"),
												updatedon: moment().format("YYYY-MM-DD"),
												updatedby: details[0].fld_userid,
											},
											"AddProductPincode"
										).then((results) =>
											results.json().then((obj) => {
												if (results.status == 200 || results.status == 201) {
													Notiflix.Loading.Remove();
													Notiflix.Notify.Success(
														"Pincode successfully assigned."
													);
													window.location.reload();
												} else {
													Notiflix.Loading.Remove();
													Notiflix.Notify.Failure(
														"Something went wrong, try again later."
													);
												}
											})
										);
									} else {
										Notiflix.Notify.Failure("Please select pincode.");
									}
								}}
							>
								{" "}
								Assign Pincode
							</button>
						</td>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Product Assginment
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Product List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																{/* <a
                                    onClick={()=>{

                                    //   localStorage.removeItem('ProductDetails')
                                    //    window.location.href='/add-item'
                                    }}
                               style={{float:'right',marginTop:'-5px',color:'#fff'
                            }} className="btn align-items-center btn Bechofy-btn " >
                        Assign All
                      </a> */}
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														{/* <MDBCol md="3" style={{marginBottom:'10px'}}>
                                <input className="form-control" type="text" placeholder="Search" aria-label="Search" onChange={(e)=>this.seachBarHandler(e)} value={this.state.searchInput} />
                              </MDBCol>  */}
														<Col md="12" style={{ marginTop: "10px" }}>
															{this.state.ProductData.length == 0 ? (
																<p style={{ textAlign: "center" }}>
																	<b>No Product List Available</b>
																</p>
															) : (
																<MDBDataTableV5
																	striped
																	bordered
																	small
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTableV5>
															)}
														</Col>
													</Row>
												</CardBody>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RawList;
