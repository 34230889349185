import classname from "classnames";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, withRouter } from "react-router-dom";
import { Col, Collapse, Row } from "reactstrap";
import PostApiCall from "../../Api";
const Skeletonnavbar = ({ cards }) => {
  return (
    <Row>
      <Col md="12" lg="4">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <a className="navbar-brand">
              <Skeleton
                className="py-4"
                style={{ width: "100px", padding: '35px' }}
              />
            </a>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <Skeleton className="py-3 px-5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Col>
    </Row>
  );
};
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReport: false,
      Menu: [],
      SubMenu: [],
      Details: [],
      ClientLogo: "",
      loader: true,
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    // =====as admin and member======
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    var clientDetails = JSON.parse(localStorage.getItem("ClientDetails"));
    var lastlogin = localStorage.getItem("lastlogin");
    var LastDateTime = JSON.parse(lastlogin);
    this.setState({
      Details: details[0],
      Logintime: LastDateTime,
      ClientLogo: clientDetails[0].fld_image,
      loader: true,
    });
    // Notiflix.Loading.Dots("");
    PostApiCall.postRequest(
      {
        staffid: 1,
      },
      "MenuList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            Menu: obj.data,
          });
          PostApiCall.postRequest(
            {
              staffid: 1,
            },
            "SubmenuList"
          ).then((resultssub) =>
            resultssub.json().then((objsub) => {
              if (resultssub.status == 200 || resultssub.status == 201) {
                var data = [];
                var arr = new Array(Object.keys(obj.data).length).fill([]);
                if (obj.data.length == 0) {
                  Notiflix.Loading.Remove();
                }
                for (var i = 0; i < Object.keys(obj.data).length; i++) {
                  data = [];
                  for (var j = 0; j < Object.keys(objsub.data).length; j++) {
                    if (obj.data[i].fld_menuid == objsub.data[j].fld_parentid) {
                      data.push(objsub.data[j]);
                      arr[i] = data;
                      this.setState({
                        SubMenu: arr,
                        loader: false,
                      });
                    }
                  }
                  if (i == Object.keys(obj.data).length - 1) {
                    localStorage.setItem("SubMenuRights", JSON.stringify(arr));
                    Notiflix.Loading.Remove();
                  }
                }
              } else {
                Notiflix.Loading.Remove();
                Notiflix.Notify.Failure(objsub.data);
              }
            })
          );
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }
  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="mx-auto">
            <nav
              className="navbar navbar-light navbar-expand-sm topnav-menu"
              id="navigation"
            >
              {this.state.loader == true ? (
                <>
                  <Skeletonnavbar cards={6} />
                </>
              ) : (
                <>
                  <div className="navbar-brand-box ">
                    <Link to="/dashboard" className="logo logo-light">
                      <span className="logo-sm">
                        <img
                          src={this.state.ClientLogo}
                          style={{ margin: "0px" }}
                          className="logo-top-smallview"
                        />
                      </span>
                      <span className="logo-lg">
                        <img
                          src={this.state.ClientLogo}
                          alt=""
                          height="50"
                          style={{ mdargin: "0px" }}
                        />
                      </span>
                    </Link>
                  </div>
                  <Collapse
                    isOpen={this.props.menuOpen}
                    className="navbar-collapse"
                    id="topnav-menu-content"
                  >
                    <ul className="navbar-nav d-flex mx-3">
                      {this.state.Menu &&
                        this.state.Menu.map((data, index) => {
                          return (
                            <li className="nav-item dropdown navbar-menu-bg">
                              {data.fld_pagename != "/#" ? (
                                <a
                                  href={`${data.fld_pagename}`}
                                  className="nav-link dropdown-toggle header-menu-responsive arrow-none navbar-menu-bg"
                                >
                                  {this.props.t(`${data.fld_menuname}`)}
                                </a>
                              ) : (
                                <li className="nav-item dropdown navbar-menu-bg">
                                  <a className="nav-link dropdown-toggle arrow-none">
                                    {this.props.t(`${data.fld_menuname}`)}{" "}
                                    <div className="arrow-down"></div>
                                  </a>
                                  <div
                                    className={classname(
                                      "dropdown-menu mega-dropdown-menu dropdown-mega-menu-md  px-2",
                                      { show: this.state.uiState }
                                    )}
                                  >
                                    {this.state.SubMenu[index] == undefined ? (
                                      <span></span>
                                    ) : this.state.SubMenu[index].length > 0 ? (
                                      <span>
                                        {this.state.SubMenu[index] ==
                                          undefined ? (
                                          <span></span>
                                        ) : (
                                          this.state.SubMenu[index].map(
                                            (submenu, i) => (
                                              <a
                                                href={`${submenu.fld_pagename}`}
                                                className="dropdown-item"
                                              >
                                                {this.props.t(
                                                  `${submenu.fld_menuname}`
                                                )}
                                              </a>
                                            )
                                          )
                                        )}
                                      </span>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                </li>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </Collapse>
                </>
              )}
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withNamespaces()(Navbar));
