import Notiflix from "notiflix";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import "../Authentication/login.css";
const Skeletonordersummary = ({ cards }) => {
  return (
    <Row>
      <Col md="12" lg="12">
        <Card style={{ height: "400px" }}>
          <CardBody>
            <Media>
              <Media body>
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="mt-0 mb-3">
                      <Skeleton className="py-1" />
                    </h4>
                  </div>
                  {Array(cards)
                    .fill(0)
                    .map((item) => (
                      <>
                        <div className="col-md-10">
                          <h4 className="mt-0 mb-3">
                            <Skeleton className="py-2" />
                          </h4>
                        </div>
                      </>
                    ))}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
class SocialSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Orders: "All Orders",
      OrderData: [
        { label: "All Orders", value: "All Orders" },
        { label: "Todays Orders", value: "Todays Orders" },
        { label: "Orders This week", value: "Orders This week" },
        { label: "Orders This Month", value: "Orders This Month" },
        { label: "Orders This Year", value: "Orders This Year" },
      ],
      OrderSummaryData: [],
      loader: true,
    };
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        WhereClause: "All Orders",
      },
      "Get_DashboardOrderSummary"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          this.setState({
            OrderSummaryData: obj.data,
            loader: false
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {

    return (
      <React.Fragment>
        <Card className="mini-stats-wid latest-order-card-size-comon">
          {this.state.loader == true ? (
            <>
              <Skeletonordersummary cards={6} />
            </>
          ) : (
            <CardBody>
              <Media>
                <Media body>
                  <Row>
                    <Col xl="6">
                      <h4 className="mb-0">Order Summary</h4>
                    </Col>
                  </Row>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    New Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "total_order") {
                        return (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {data.order_count}
                          </span>
                        );
                      }
                    })}
                  </p>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    Accepted Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "accepted_order") {
                        return (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {i == 0 ? 0 : data.order_count}
                          </span>
                        );
                      } else {
                        if (i == 0) {
                          return (
                            <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                              0
                            </span>
                          );
                        }
                      }
                    })}
                  </p>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    Dispatched Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "dispatch_order") {
                        return (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {i == 0 ? 0 : data.order_count}
                          </span>
                        );
                      }
                    })}
                  </p>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    Delivered Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "delivered_order") {
                        return data.order_count != 0 ? (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {data.order_count}
                          </span>
                        ) : (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            0
                          </span>
                        );
                      }
                    })}
                  </p>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    Cancelled Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "cancelled_order") {
                        return data.order_count || data.order_count != 0 ? (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {data.order_count}
                          </span>
                        ) : (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            0
                          </span>
                        );
                      }
                    })}
                  </p>
                  <hr />
                  <p className="text-muted font-weight-medium">
                    Abandoned Orders
                    {this.state.OrderSummaryData.map((data, i) => {
                      if (data.count_for == "abandoned_order") {
                        return data.order_count || data.order_count != 0 ? (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            {data.order_count}
                          </span>
                        ) : (
                          <span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
                            0
                          </span>
                        );
                      }
                    })}
                  </p>
                </Media>
              </Media>
            </CardBody>
          )}
        </Card>
      </React.Fragment>
    );
  }
}
export default SocialSource;
