import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import GetApiCall from "../../GETAPI";
class CompanyMaster extends Component {
	state = {
		enabled: false,
		CountryData: [],
		CityData: [],
		StateData: [],
		CountryId: 0,
		StateId: 0,
		CityId: 0,
		name: "",
		image: "",
		status: "",
		companyId: null,
		isImageValid: null,
		imageValue: "",
		storeName: "",
		address: "",
		city: "",
		State: "",
		location: "",
		pincode: "",
		country: "",
		industry: "",
		companytype: "",
		businesstype: "",
		companysize: "",
		contactperson: "",
		designation: "",
		contactpersonpicture: "",
		mobilenumber: "",
		emailid: "",
		companyphonenumber: "",
		companymobilenumber: "",
		companyemailid: "",
		website: "",
		gst: "",
		gstcertificateupload: "",
		pancardnumber: "",
		pancardupload: "",
		bankaccountnumber: "",
		bankname: "",
		ifsccode: "",
		bankbranch: "",
		cancelledchequeupload: "",
	};
	onAddMoreHandler = () => {
		this.setState({
			inputList: [...this.state.inputList, { menuName: "", pageNAme: null }],
		});
	};
	onRemoveHandler = (index) => {
		let list = [...this.state.inputList];
		//   list.splice(index, 1);
		list = list.filter((data) => data !== index);
		this.setState({ ...this.state, inputList: list });
	};
	handleInputChange = (e, index) => {
		const { name, value } = e.target;
		this.setState({ ...this.state, [name]: value });
	};
	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};
	onChangeCountry(country) {
		// //
		this.setState({
			CountryId: country.target.value,
			Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
		});
		//  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country: this.state.CountryData[parseInt(country.target.value) + 1]
					.label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// this.props.setstate(obj.data[0].label)
					this.setState({
						StateId: obj.data[0].value,
						State: obj.data[0].label,
					});
					PostApiCall.postRequest(
						{
							stateid: obj.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								this.setState({
									CityData: objcity.data,
									StateData: obj.data,
								});
								if (objcity.data.length > 0) {
									// this.props.setcity(objcity.data[0].label)
									this.setState({
										CityId: objcity.data[0].value,
										City: objcity.data[0].label,
									});
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	}
	onChangeState(state) {
		//  this.props.setstate(state.target.value)
		this.setState({
			StateId: state.target.value,
		});
		Notiflix.Loading.Dots("Please wait...");
		for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
			if (this.state.StateData[i].value == state.target.value) {
				// this.props.setstate();
				this.setState({
					State: this.state.StateData[i].label,
				});
			}
		}
		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						// this.props.setcity(obj.data[0].label)
						this.setState({
							CityData: obj.data,
							CityId: obj.data[0].value,
							City: obj.data[0].label,
						});
					}
					Notiflix.Loading.Remove();
					// this.props.cityData(obj.data)
				}
			})
		);
	}
	onChangeCity(city) {
		// this.props.setcity(city.target.value)
		this.setState({
			CityId: city.target.value,
		});
		for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
			if (this.state.CityData[i].value == city.target.value) {
				this.setState({
					City: this.state.CityData[i].label,
				});
				// this.props.setcity(this.state.CityData[i].label);
			}
		}
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		var det = localStorage.getItem("GetCompanyDetails");
		var CompanyData = JSON.parse(det);
		//
		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryData: obj.data,
				});
				if (obj.data.length != 0) {
					// this.props.setcountry(obj.data[100].label)
					this.setState({
						CountryId: obj.data.filter(
							(value) => value.label == CompanyData.fld_country
						)[0].value,
						Country: obj.data[102].label,
					});
				}
				PostApiCall.postRequest(
					{
						countryid: obj.data.filter(
							(value) => value.label == CompanyData.fld_country
						)[0].value,
					},
					"GetState"
				).then((results) =>
					// const objs = JSON.parse(result._bodyText)
					results.json().then((objstate) => {
						if (results.status == 200 || results.status == 201) {
							if (objstate.data.length != 0) {
								//
								// this.props.setstate()
								//    //
								//    )
								this.setState({
									StateId: objstate.data.filter(
										(value) => value.label == CompanyData.fld_state
									)[0].value,
									StateData: objstate.data,
									State: objstate.data[0].label,
								});
							}
							PostApiCall.postRequest(
								{
									stateid: objstate.data.filter(
										(value) => value.label == CompanyData.fld_state
									)[0].value,
								},
								"GetCity"
							).then((resultscity) =>
								// const objs = JSON.parse(result._bodyText)
								resultscity.json().then((objcity) => {
									if (resultscity.status == 200 || resultscity.status == 201) {
										if (objcity.data.length != 0) {
											// this.props.setcity(objcity.data[0].label)
											this.setState({
												CityId: objcity.data.filter(
													(value) => value.label == CompanyData.fld_city
												)[0].value,
												CityData: objcity.data,
												City: objcity.data[0].label,
											});
										}
									}
								})
							);
						}
					})
				);
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													Menu
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Company Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="form my-4">
													<div className="row">
														<div className="col-4 ml-3 ">
															<div className="row">
																<div className="col col-12">
																	<div className="form-group ">
																		<label className="my-1 mr-2">
																			Company logo
																		</label>
																		<PhotoUploader
																			onInput={this.onImagePickerHanlder}
																			center
																			id="image"
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div className="col col-7">
															<div className="row">
																<div className="col-6">
																	<label className="my-1 mr-2">Store Name</label>
																	<input
																		className="form-control"
																		name="cmp_id"
																		type="text"
																		placeholder="Enter Store Name"
																		value={this.state.storeName}
																		onChange={(e) => {
																			this.handleInputChange(e);
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">Company Name</label>
																	<input
																		className="form-control"
																		name="name"
																		type="text"
																		placeholder="Enter Company Name"
																		value={this.state.name}
																		onChange={(e) => {
																			this.handleInputChange(e);
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">GST</label>
																	<input
																		className="form-control"
																		name="GST"
																		type="text"
																		placeholder="Enter GST Namuber"
																		value={this.state.gst}
																		onChange={(e) => {
																			this.handleInputChange(e);
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">PAN</label>
																	<input
																		className="form-control"
																		name="pan"
																		type="text"
																		placeholder="Enter GST Namuber"
																		value={this.state.pan}
																		onChange={(e) => {
																			this.handleInputChange(e);
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">TAN</label>
																	<input
																		className="form-control"
																		name="tan"
																		type="text"
																		placeholder="Enter GST Namuber"
																		value={this.state.tan}
																		onChange={(e) => {
																			this.handleInputChange(e);
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">Address</label>
																	<input
																		className="form-control"
																		name="address"
																		type="text"
																		placeholder="Enter Address"
																		value={this.state.address}
																		onChange={(address) => {
																			this.setState({
																				address: address.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-3">
																	<div className="form-group">
																		<label
																			className="my-1 mr-2"
																			htmlFor="inlineFormCustomSelectPref"
																		>
																			Country
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			className="custom-select my-1 mr-sm-2"
																			id="inlineFormCustomSelectPref"
																			name="UserType"
																			value={this.state.CountryId}
																			onChange={this.onChangeCountry.bind(this)}
																		>
																			{this.state.CountryData.map(
																				(schedule) => (
																					<option
																						key={schedule.label}
																						value={schedule.value}
																					>
																						{schedule.label}
																					</option>
																				)
																			)}
																		</select>
																	</div>
																</div>
																<div className="col-3">
																	<div className="form-group">
																		<label
																			className="my-1 mr-2"
																			htmlFor="inlineFormCustomSelectPref"
																		>
																			State
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			className="custom-select my-1 mr-sm-2"
																			id="inlineFormCustomSelectPref"
																			name="UserType"
																			value={this.state.StateId}
																			onChange={this.onChangeState.bind(this)}
																		>
																			{this.state.StateData.map((schedule) => (
																				<option
																					key={schedule.label}
																					value={schedule.value}
																				>
																					{schedule.label}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
																<div className="col-3">
																	<div className="form-group">
																		<label
																			className="my-1 mr-2"
																			htmlFor="inlineFormCustomSelectPref"
																		>
																			City
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			className="custom-select my-1 mr-sm-2"
																			id="inlineFormCustomSelectPref"
																			name="UserType"
																			value={this.state.CityId}
																			onChange={this.onChangeCity.bind(this)}
																		>
																			{this.state.CityData.map((schedule) => (
																				<option
																					key={schedule.label}
																					value={schedule.value}
																				>
																					{schedule.label}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">State</label>
																	<input
																		className="form-control"
																		name="location"
																		type="text"
																		placeholder="Enter location"
																		value={this.state.location}
																		onChange={(location) => {
																			this.setState({
																				location: location.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">pincode</label>
																	<input
																		className="form-control"
																		name="pincode"
																		type="text"
																		placeholder="Enter pincode"
																		value={this.state.pincode}
																		onChange={(pincode) => {
																			this.setState({
																				pincode: pincode.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">industry</label>
																	<input
																		className="form-control"
																		name="industry"
																		type="text"
																		placeholder="Enter industry"
																		value={this.state.industry}
																		onChange={(industry) => {
																			this.setState({
																				industry: industry.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">companytype</label>
																	<input
																		className="form-control"
																		name="companytype"
																		type="text"
																		placeholder="Enter companytype"
																		value={this.state.companytype}
																		onChange={(companytype) => {
																			this.setState({
																				companytype: companytype.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">businesstype</label>
																	<input
																		className="form-control"
																		name="businesstype"
																		type="text"
																		placeholder="Enter businesstype"
																		value={this.state.businesstype}
																		onChange={(businesstype) => {
																			this.setState({
																				businesstype: businesstype.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">companysize</label>
																	<input
																		className="form-control"
																		name="companysize"
																		type="text"
																		placeholder="Enter companysize"
																		value={this.state.companysize}
																		onChange={(companysize) => {
																			this.setState({
																				companysize: companysize.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">contactperson</label>
																	<input
																		className="form-control"
																		name="contactperson"
																		type="text"
																		placeholder="Enter contactperson"
																		value={this.state.contactperson}
																		onChange={(contactperson) => {
																			this.setState({
																				contactperson:
																					contactperson.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">designation</label>
																	<input
																		className="form-control"
																		name="designation"
																		type="text"
																		placeholder="Enter designation"
																		value={this.state.designation}
																		onChange={(designation) => {
																			this.setState({
																				designation: designation.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		contactpersonpicture
																	</label>
																	<input
																		className="form-control"
																		name="contactpersonpicture"
																		type="text"
																		placeholder="Enter contactpersonpicture"
																		value={this.state.contactpersonpicture}
																		onChange={(contactpersonpicture) => {
																			this.setState({
																				contactpersonpicture:
																					contactpersonpicture.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">mobilenumber</label>
																	<input
																		className="form-control"
																		name="mobilenumber"
																		type="text"
																		placeholder="Enter mobilenumber"
																		value={this.state.mobilenumber}
																		onChange={(mobilenumber) => {
																			this.setState({
																				mobilenumber: mobilenumber.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">emailid</label>
																	<input
																		className="form-control"
																		name="emailid"
																		type="text"
																		placeholder="Enter emailid"
																		value={this.state.emailid}
																		onChange={(emailid) => {
																			this.setState({
																				emailid: emailid.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		companyphonenumber
																	</label>
																	<input
																		className="form-control"
																		name="companyphonenumber"
																		type="text"
																		placeholder="Enter companyphonenumber"
																		value={this.state.companyphonenumber}
																		onChange={(companyphonenumber) => {
																			this.setState({
																				companyphonenumber:
																					companyphonenumber.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		companymobilenumber
																	</label>
																	<input
																		className="form-control"
																		name="companymobilenumber"
																		type="text"
																		placeholder="Enter companymobilenumber"
																		value={this.state.companymobilenumber}
																		onChange={(companymobilenumber) => {
																			this.setState({
																				companymobilenumber:
																					companymobilenumber.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		companyemailid
																	</label>
																	<input
																		className="form-control"
																		name="companyemailid"
																		type="text"
																		placeholder="Enter companyemailid"
																		value={this.state.companyemailid}
																		onChange={(companyemailid) => {
																			this.setState({
																				companyemailid:
																					companyemailid.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">website</label>
																	<input
																		className="form-control"
																		name="website"
																		type="text"
																		placeholder="Enter website"
																		value={this.state.website}
																		onChange={(website) => {
																			this.setState({
																				website: website.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">gst </label>
																	<input
																		className="form-control"
																		name="gst "
																		type="text"
																		placeholder="Enter gst "
																		value={this.state.gst}
																		onChange={(gst) => {
																			this.setState({
																				gst: gst.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		gstcertificateupload
																	</label>
																	<input
																		className="form-control"
																		name="gstcertificateupload"
																		type="text"
																		placeholder="Enter gstcertificateupload"
																		value={this.state.gstcertificateupload}
																		onChange={(gstcertificateupload) => {
																			this.setState({
																				gstcertificateupload:
																					gstcertificateupload.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		pancardnumber{" "}
																	</label>
																	<input
																		className="form-control"
																		name="pancardnumber "
																		type="text"
																		placeholder="Enter pancardnumber "
																		value={this.state.pancardnumber}
																		onChange={(pancardnumber) => {
																			this.setState({
																				pancardnumber:
																					pancardnumber.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">pancardupload</label>
																	<input
																		className="form-control"
																		name="pancardupload"
																		type="text"
																		placeholder="Enter pancardupload"
																		value={this.state.pancardupload}
																		onChange={(pancardupload) => {
																			this.setState({
																				pancardupload:
																					pancardupload.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		bankaccountnumber
																	</label>
																	<input
																		className="form-control"
																		name="bankaccountnumber"
																		type="text"
																		placeholder="Enter bankaccountnumber"
																		value={this.state.bankaccountnumber}
																		onChange={(bankaccountnumber) => {
																			this.setState({
																				bankaccountnumber:
																					bankaccountnumber.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">bankname</label>
																	<input
																		className="form-control"
																		name="bankname"
																		type="text"
																		placeholder="Enter bankname"
																		value={this.state.bankname}
																		onChange={(bankname) => {
																			this.setState({
																				bankname: bankname.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">ifsccode</label>
																	<input
																		className="form-control"
																		name="ifsccode"
																		type="text"
																		placeholder="Enter ifsccode"
																		value={this.state.ifsccode}
																		onChange={(ifsccode) => {
																			this.setState({
																				ifsccode: ifsccode.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">bankbranch</label>
																	<input
																		className="form-control"
																		name="bankbranch"
																		type="text"
																		placeholder="Enter bankbranch"
																		value={this.state.bankbranch}
																		onChange={(bankbranch) => {
																			this.setState({
																				bankbranch: bankbranch.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6">
																	<label className="my-1 mr-2">
																		cancelledchequeupload
																	</label>
																	<input
																		className="form-control"
																		name="cancelledchequeupload"
																		type="text"
																		placeholder="Enter cancelledchequeupload"
																		value={this.state.cancelledchequeupload}
																		onChange={(cancelledchequeupload) => {
																			this.setState({
																				cancelledchequeupload:
																					cancelledchequeupload.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-6 mt-2">
																	<div className="form-group">
																		<label htmlFor="EnableCategories">
																			Enable Company
																		</label>
																		<div className="custom-control custom-switch">
																			<input
																				className="form-control"
																				checked={this.state.enabled}
																				onChange={(e) => {
																					this.setState({
																						...this.state,
																						enabled: !this.state.enabled,
																					});
																				}}
																				type="checkbox"
																				id="customSwitch1"
																			/>
																			<label
																				className="custom-control-label"
																				htmlFor="customSwitch1"
																			>
																				Toggle this switch element
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1    my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={() => { }}
														>
															Create
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default CompanyMaster;
