import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import PostApiCall from "../../Api";
import customersicon from "../../assets/images/cuatomers.png";
import "../Authentication/login.css";
const Skeletonlatestcustomers = ({ cards }) => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="6" className="px-4 my-auto">
            <div className="Bechofy-text" style={{ textAlign: 'end' }}>
              <Skeleton className="py-2" style={{ width: '30%' }} />
            </div>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0 px-0">
        <Row>
          <Col
            xs="12"
            className="latestreview-mdbtable lastestcustomer-table-responsive"
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                  <th scope="col">
                    <Skeleton className="py-2" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <Skeleton className="py-2" />
                  </th>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                  <td>
                    <Skeleton className="py-2" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
class LatestCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerData: [],
      loader: true,
    };
  }
  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#777f80",
    // });
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: "Order By fld_customer_id DESC",
        RecordCount: "Top 10 *",
      },
      "GetCustomerList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            CustomerData: obj.data,
            loader: false,
          });
          // Notiflix.Loading.Remove();
        }
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Email Address",
          field: "email",
          sort: "disabled",
          width: 600,
        },
        {
          label: "Mobile No.",
          field: "contact",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Registered On",
          field: "registeredOn",
          sort: "disabled",
          width: 200,
        },
        {
          label: "No. of Orders",
          field: "ordercount",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Source",
          field: "source",
          sort: "disabled",
          width: 300,
        },
        {
          label: "",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.CustomerData.filter((data) => {
        {
          return data;
        }
      }).map((data, i) => {
        return {
          name: (
            <div className="text-start newcustomer-mdtablecol">{data.fld_name}</div>
          ),
          email: (
            <div className="text-start newcustomer-mdtablecol">
              {data.fld_email_address}
            </div>
          ),
          contact: data.fld_phone_number ? data.fld_phone_number : "N/A",
          registeredOn: data.fld_created_on != null ? moment(data.fld_created_on).format("DD/MM/YYYY") : "N/A",
          ordercount: data.total_Orders ? data.total_Orders : 0,
          // orderno: data.total_Orders,
          source: data.fld_status,
          action: (
            <span className="">
              <Link
                to={`/customer-profile`}
                className="btn align-items-center btn Bechofy-btn "
                onClick={() => {
                  localStorage.setItem(
                    "customerDetails",
                    JSON.stringify(data.fld_customerid)
                  );
                  // window.location.href = "/customer-profile";
                }}
              >
                View Profile
              </Link>
            </span>
          ),
        };
      }),
    };
    return (
      <React.Fragment>
        {this.state.loader == true ? (
          <>
            <Skeletonlatestcustomers />
          </>
        ) : (
          <Card>
            <div>
              <Row>
                <Col xs="6" className="px-4">
                  <div className="Bechofy-text py-3 px-2">
                    <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                      New Customers
                    </h4>
                  </div>
                </Col>
                <Col xs="6" className="px-4">
                  <div className="Bechofy-text">
                    <Link
                      // to="/viewallreviews"
                      style={{
                        float: "right",
                        background: "black",
                        color: "white",
                        marginTop: "13px",
                        marginRight: "13px",
                      }}
                      className="btn align-items-center btn Bechofy-btn "
                    >
                      View All Customers{" "}
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody className="pt-0 px-4">
              <Row>
                <Col
                  xs="12"
                  className="latestreview-mdbtable lastestcustomer-table-responsive"
                >
                  {this.state.CustomerData.length != 0 ? (
                    <MDBDataTable
                      hover
                      // scrollY
                      striped
                      bordered
                      data={data}
                      seachTop={false}
                      entriesOptions={[10, 25, 50, 100]}
                      className="customer-table-responsive"
                    >
                      <MDBTableHead
                        className="centered"
                        columns={data.columns}
                      />
                      <MDBTableBody rows={data.rows} />
                    </MDBDataTable>
                  ) : (
                    <Row className="mt-4 pt-2">
                      <Col sm="12" className="text-center">
                        <img
                          src={customersicon}
                          alt="block user"
                          className="customerhistory-icons ms-3 mt-1"
                          style={{ marginRight: "5px" }}
                        />
                      </Col>
                      <Col sm="12" className="text-center my-3">
                        <h6>No new customers</h6>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {/* ==========Latest Pickups ==========*/}
      </React.Fragment>
    );
  }
}
export default LatestCustomers;
