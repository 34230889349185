import React from 'react';
import { useEffect, useState } from 'react';
import Carousel from "react-bootstrap/Carousel";
import GetApiCall from '../../GETAPI';
import PostApiCall from '../../Api';
import Notiflix from "notiflix";
import { IoMdAddCircleOutline } from 'react-icons/io';
// import "./MainSlider.css";


const ImgUploadNew = ({ onChange, src }) => (
    <div className="">
        <label htmlFor="photo-upload-new" className="custom-file-upload fas w-100">
            <div className="upload-img img-upload mb-3">
                <div className="upload-image rounded" style={{ width: "80px", height: "90px", borderRadius: "5%" }}>
                    <div className="text-center">
                        <div className="text-dark cursor-pointer">
                            <IoMdAddCircleOutline className="upload-imge-btn" />
                        </div>
                    </div>
                </div>
            </div>
            <input accept="image/*" id="photo-upload-new" type="file" onChange={onChange} />
        </label>
    </div>
);
function MainSlider() {
    const [bannerData, setBannerData] = useState([]);
    const [imageApiUrl, setImageApiUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage");
    const [imageUrl, setImageUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path)
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const [index, setIndex] = useState(0);
    useEffect(() => {

        GetBannerImages();

    }, []);
    const GetBannerImages = () => {
        GetApiCall.getRequest(
            "GetBannerImages"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setBannerData(obj.data);
                }
            });
        });
    }

    const UpdateBannerMaster = (bannerid, bannerurl) => {
        PostApiCall.postRequest(
            {
                bannerid: bannerid,
                bannerurl: bannerurl,
                bannerlocation: "Main",
                bannerstatus: "Active",
            },
            "UpdateBannerMaster"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    GetBannerImages();
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("successfully added.");
                }
            })
        );
    }

    return (
        <div className="page-content">
            <div className='container-fluid'>
                <Carousel fade interval={null}>
                    {bannerData.map((data) => {
                        return (
                            <Carousel.Item>
                                {data.FLD_Banner_URL.split(/[#?]/)[0].split(".").pop().trim() !==
                                    "mp4" ? (
                                    <>
                                        <>

                                            <div className="img-upload">
                                                <img
                                                    htmlFor="photo-upload"
                                                    className="d-block w-100"
                                                    src={data.FLD_Banner_URL != "" ? data.FLD_Banner_URL : previewUrl}
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                                <input type='file' accept="image/*"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        const imageFile = e.target.files[0];
                                                        const form = new FormData();
                                                        let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`
                                                        let foldername = "banners/main"
                                                        form.append("file", imageFile);
                                                        form.append("foldername", foldername);
                                                        form.append("filename", filename.trim().replace(/\s/g, "-"));
                                                        let response;
                                                        Notiflix.Loading.Dots("")
                                                        response = fetch(imageApiUrl, {
                                                            method: "POST",
                                                            body: form,
                                                        }).then(response => response.json())
                                                            .then(data1 => {
                                                                UpdateBannerMaster(data.FLD_Banner_Id, imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp')
                                                            })
                                                    }
                                                    }

                                                    id={data.fld_id}
                                                />
                                            </div>

                                        </>
                                    </>
                                ) : (
                                    ""
                                )}
                                {data.FLD_Banner_URL.split(/[#?]/)[0].split(".").pop().trim() ===
                                    "mp4" ? (
                                    <>
                                        <video
                                            src={data.FLD_Banner_URL}
                                            className="img-fluid w-100"
                                            autoPlay
                                            muted
                                            loop
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                            </Carousel.Item>
                        );
                    })}
                    <ImgUploadNew
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`
                            let foldername = "banners/main"
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));
                            let response;
                            Notiflix.Loading.Dots("")
                            response = fetch(imageApiUrl, {
                                method: "POST",
                                body: form,
                            }).then(response => response.json()
                                .then(data1 => {
                                    if (response.status == 200 || response.status == 201) {
                                        console.log(filename.trim().replace(/\s/g, "-"))
                                        UpdateBannerMaster(null, imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-"))
                                    }
                                })
                            )
                        }
                        }

                    // id={data.fld_id}

                    // src={noImage}
                    />
                </Carousel>
            </div>
        </div>
    );
}

export default MainSlider;