import { MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class RawList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			PickupData: [],
			open: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
			Ridername: "",
			Pincode: "",
			selectedNames: [],
			RiderData: [],
		};
	}
	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("GetPickupList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//   //
				this.setState({
					PickupData: obj.data,
				});
				if (obj.data) {
					obj.data.map((item) => {
						Notiflix.Loading.Dots("Please wait...");
						PostApiCall.postRequest(
							{
								pincode: item.fld_presentpincode,
							},
							"GetRiderByid"
						).then((results2) =>
							// const objs = JSON.parse(result._bodyText)
							results2.json().then((obj2) => {
								if (results2.status == 200 || results2.status == 201) {
									//
									this.setState({
										RiderData: obj2.data,
									});
									Notiflix.Loading.Remove();
								}
							})
						);
					});
				}
				Notiflix.Loading.Remove();
			})
		);
	}
	onsaveAll() {
		// //
		if (this.state.Ridername !== "") {
			Notiflix.Loading.Dots("");
			for (var i = 0; i < this.state.PickupData.length; i++) {
				for (var j = 0; j < this.state.Ridername.length; j++) {
					//
				}
				//   PostApiCall.postRequest({
				//      pickupid :this.state.PickupData[i].fld_pickup_id,
				//      status :'Assigned',
				//      riderid :this.state.Ridername
				//      },"UpdateRiderpickup").then((results) =>
				//       // const objs = JSON.parse(result._bodyText)
				//       results.json().then(obj => {
				//       if(results.status == 200 || results.status==201){
				//           Notiflix.Loading.Remove()
				//           Notiflix.Notify.Success('Pickup area successfully added.')
				//           window.location.reload()
				//       }else
				//       {
				//           Notiflix.Loading.Remove()
				//           Notiflix.Notify.Failure('Something went wrong, try again later.')
				//       }
				//   }))
			}
		} else {
			Notiflix.Notify.Failure("Please select rider name.");
		}
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		//
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Customer Name",
					field: "name",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Present Address",
					field: "address",
					sort: "disabled",
					width: 150,
				},
				{
					label: "State",
					field: "state",
					sort: "disabled",
					width: 150,
				},
				{
					label: "City",
					field: "city",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Pincode",
					field: "pincode",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Pickup Time",
					field: "time",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Pickup Date",
					field: "date",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Assign Rider",
					field: "assign",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.PickupData.map((data, index) => {
				return {
					serial: <td>{index + 1}</td>,
					name: <td>{data.customer_name}</td>,
					address: <td>{data.fld_presentaddress}</td>,
					state: <td>{data.fld_presentstate}</td>,
					city: <td>{data.fld_presentcity}</td>,
					pincode: <td>{data.fld_presentpincode}</td>,
					time: <td>{data.fld_pickup_time}</td>,
					date: <td>{data.fld_pickup_date}</td>,
					status: (
						<td
							style={{
								color: data.fld_pickup_status == "Pending" ? "yellow" : "green",
							}}
						>
							{data.fld_pickup_status}{" "}
						</td>
					),
					assign: (
						<td>
							<select
								className="custom-select my-1 mr-sm-2"
								style={{
									display: data.fld_pickup_status == "Pending" ? "" : "none",
								}}
								onChange={(e) => {
									this.setState({
										Ridername: e.target.value,
									});
								}}
							>
								<option value="">Select Rider</option>
								{this.state.RiderData.map((ra) => (
									<option key={ra.label} value={ra.value ? ra.value : null}>
										{ra.label}
									</option>
								))}
							</select>
						</td>
					),
					action: (
						<td className="text-center actionuser">
							<button
								className="btn align-items-center Bechofy-btn "
								onClick={() => {
									if (this.state.Ridername !== "") {
										Notiflix.Loading.Dots("");
										PostApiCall.postRequest(
											{
												pickupid: data.fld_pickup_id,
												status: "Assigned",
												riderid: this.state.Ridername,
											},
											"AddProductPincode"
										).then((results) =>
											// const objs = JSON.parse(result._bodyText)
											results.json().then((obj) => {
												if (results.status == 200 || results.status == 201) {
													Notiflix.Loading.Remove();
													Notiflix.Notify.Success(
														"Pickup area successfully added."
													);
													window.location.reload();
												} else {
													Notiflix.Loading.Remove();
													Notiflix.Notify.Failure(
														"Something went wrong, try again later."
													);
												}
											})
										);
									} else {
										Notiflix.Notify.Failure("Please select rider name.");
									}
								}}
							>
								{" "}
								Assign Rider
							</button>
						</td>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Pickup Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Pickup List
																</h5>
															</div>
														</Col>
														{/* <Col xs="6">
                                <div className="Bechofy-text p-3">
                                <a
                                    onClick={this.onsaveAll.bind(this)}
                               style={{float:'right',marginTop:'-5px',color:'#fff'
                            }} className="btn align-items-center btn Bechofy-btn " >
                        Assign All
                      </a>
                                </div>
                            </Col>
                             */}
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														{/* <MDBCol md="3" style={{marginBottom:'10px'}}>
                                <input className="form-control" type="text" placeholder="Search" aria-label="Search" onChange={(e)=>this.seachBarHandler(e)} value={this.state.searchInput} />
                              </MDBCol>  */}
														<Col md="12" style={{ marginTop: "10px" }}>
															{this.state.PickupData.length == 0 ? (
																<p style={{ textAlign: "center" }}>
																	<b>No Pickup List Available</b>
																</p>
															) : (
																<MDBDataTableV5
																	striped
																	bordered
																	small
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTableV5>
															)}
														</Col>
													</Row>
												</CardBody>
											</Card>
											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RawList;
