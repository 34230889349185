import React, { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import ColorPicker from 'react-pick-color';
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { Modal } from "react-responsive-modal";
import { Container } from "react-bootstrap";
const ImgUpload = ({ onChange, src }) => (
    <div className="our-product-collection-image-upload p-3 mb-4">
        <img src={src} className="img-fluid w-100 h-100" />
        <input accept="image/*" id="photo-upload-new-our-collection" type="file" onChange={onChange} />
    </div>
);
export default function OurCollectionSection() {
    const [imageApiUrl, setImageApiUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage");
    const [imageUrl, setImageUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path);
    const [color, setColor] = useState('');
    const [homeCollections, setHomeCollections] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);
    const [modalOpen4, setModalOpen4] = useState(false);

    const [heading1, setHeading1] = useState('');
    const [heading2, setHeading2] = useState('');
    const [heading3, setHeading3] = useState('');
    const [heading4, setHeading4] = useState('');

    const [color2, setColor2] = useState('');
    const [color3, setColor3] = useState('');
    const [color4, setColor4] = useState('');

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');
    const [text4, setText4] = useState('');

    const [textColor1, setTextColor1] = useState('');
    const [textColor2, setTextColor2] = useState('');
    const [textColor3, setTextColor3] = useState('');
    const [textColor4, setTextColor4] = useState('');

    const UpdateHomePageCollection = () => {
        PostApiCall.postRequest(
            {
                id: 1,
                heading1: heading1,
                heading2: heading2,
                heading3: heading3,
                heading4: heading4,
                text1: text1,
                text2: text2,
                text3: text3,
                text4: text4,
                textcolor1: textColor1,
                textcolor2: textColor2,
                textcolor3: textColor3,
                textcolor4: textColor4,
                backgroundcolor1: color,
                backgroundcolor2: color2,
                backgroundcolor3: color3,
                backgroundcolor4: color4,
            },
            "UpdateHomePageCollection"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    // //
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("");
                }
            })
        );
    }
    useEffect(() => {

        PostApiCall.postRequest(
            {
                catCount: "*",
            },
            "GetHomeCollection"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setHomeCollections(obj.data);
                    setHeading1(obj.data[0]?.fld_txt1_heading)
                    setHeading2(obj.data[0]?.fld_txt2_heading)
                    setHeading3(obj.data[0]?.fld_txt3_heading)
                    setHeading4(obj.data[0]?.fld_txt4_heading)
                    setText1(obj.data[0]?.fld_txt1)
                    setText2(obj.data[0]?.fld_txt2)
                    setText3(obj.data[0]?.fld_txt3)
                    setText4(obj.data[0]?.fld_txt4)
                    setTextColor1(obj.data[0]?.fld_txt1_color)
                    setTextColor2(obj.data[0]?.fld_txt2_color)
                    setTextColor3(obj.data[0]?.fld_txt3_color)
                    setTextColor4(obj.data[0]?.fld_txt4_color)
                    setColor(obj.data[0]?.fld_background_color1)
                    setColor2(obj.data[0]?.fld_background_color2)
                    setColor3(obj.data[0]?.fld_background_color3)
                    setColor4(obj.data[0]?.fld_background_color4)
                }
            });
        });

    }, []);
    return (
        <Container fluid>
            <h2 className='text-center'>Our Product Collection</h2>
            <div className="row mb-5 pb-5">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="BasePrice">
                            Section Heading
                            <span className="mandatory">*</span>
                        </label>
                        <input
                            type="text"
                            id="BasePrice"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image1: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image1}
                    />
                </div>
                <div className="col-4">
                    <div className='our-product-collection-image-upload p-3'>
                        <div onClick={() => setModalOpen(true)}>
                            <label htmlFor="CategoryName">
                                Select Background Color
                            </label> <span className='card-color-picker' style={{ backgroundColor: `${color}` }}></span></div>
                        <div className="form-group my-1 mr-sm-2">
                            <label htmlFor="CategoryName">
                                Text Color
                            </label>
                            <br />
                            <label className="radio-inline">
                                <input type="radio" name="optradio" checked={textColor1 == "text-light" ? true : false}
                                    onChange={() => {
                                        setTextColor1("text-light")
                                    }}
                                />{" "}
                                Light
                            </label>
                            <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                            >
                                <input
                                    type="radio"
                                    name="optradio" checked={textColor1 == "text-dark" ? true : false}
                                    onChange={() => {
                                        setTextColor1("text-dark")
                                    }}
                                />{" "}
                                Dark
                            </label>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Heading"
                                    className="form-control"
                                    placeholder="Heading"
                                    value={heading1}
                                    onChange={(e) => {
                                        setHeading1(e.target.value);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Description"
                                    className="form-control"
                                    placeholder="Description"
                                    value={text1}
                                    onChange={(e) => setText1(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image2: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image2}
                    />
                </div>
                <div className="col-8">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image3: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image3}
                    />
                </div>
                <div className="col-4">
                    <div className='our-product-collection-image-upload p-3'>
                        <div onClick={() => setModalOpen2(true)}>
                            <label htmlFor="CategoryName">
                                Select Background Color
                            </label> <span className='card-color-picker' style={{ backgroundColor: `${color2}` }}></span></div>
                        <div className="form-group my-1 mr-sm-2">
                            <label htmlFor="CategoryName">
                                Text Color
                            </label>
                            <br />
                            <label className="radio-inline">
                                <input type="radio" name="optradio" checked={textColor2 == "text-light" ? true : false}
                                    onChange={() => {
                                        setTextColor2("text-light")
                                    }}
                                />{" "}
                                Light
                            </label>
                            <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                            >
                                <input
                                    type="radio"
                                    name="optradio" checked={textColor2 == "text-dark" ? true : false}
                                    onChange={() => {
                                        setTextColor2("text-dark")
                                    }}
                                />{" "}
                                Dark
                            </label>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Heading"
                                    className="form-control"
                                    placeholder="Heading"
                                    value={heading2}
                                    onChange={(e) => {
                                        setHeading2(e.target.value);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Description"
                                    className="form-control"
                                    placeholder="Description"
                                    value={text2}
                                    onChange={(e) => setText2(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className='our-product-collection-image-upload p-3'>
                        <div onClick={() => setModalOpen3(true)}>
                            <label htmlFor="CategoryName">
                                Select Background Color
                            </label> <span className='card-color-picker' style={{ backgroundColor: `${color3}` }}></span></div>
                        <div className="form-group my-1 mr-sm-2">
                            <label htmlFor="CategoryName">
                                Text Color
                            </label>
                            <br />
                            <label className="radio-inline">
                                <input type="radio" name="optradio" checked={textColor3 == "text-light" ? true : false}
                                    onChange={() => {
                                        setTextColor3("text-light")
                                    }}
                                />{" "}
                                Light
                            </label>
                            <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                            >
                                <input
                                    type="radio"
                                    name="optradio" checked={textColor3 == "text-dark" ? true : false}
                                    onChange={() => {
                                        setTextColor3("text-dark")
                                    }}
                                />{" "}
                                Dark
                            </label>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Heading"
                                    className="form-control"
                                    placeholder="Heading"
                                    value={heading3}
                                    onChange={(e) => {
                                        setHeading3(e.target.value);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Description"
                                    className="form-control"
                                    placeholder="Description"
                                    value={text3}
                                    onChange={(e) => setText3(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image4: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image4}
                    />
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image5: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image5}
                    />
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image6: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image6}
                    />
                </div>
                <div className="col-4">
                    <div className='our-product-collection-image-upload p-3'>
                        <div onClick={() => setModalOpen4(true)}>
                            <label htmlFor="CategoryName">
                                Select Background Color
                            </label> <span className='card-color-picker' style={{ backgroundColor: `${color4}` }}></span></div>
                        <div className="form-group my-1 mr-sm-2">
                            <label htmlFor="CategoryName">
                                Text Color
                            </label>
                            <br />
                            <label className="radio-inline">
                                <input type="radio" name="optradio" checked={textColor4 == "text-light" ? true : false}
                                    onChange={() => {
                                        setTextColor4("text-light")
                                    }}
                                />{" "}
                                Light
                            </label>
                            <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}
                            >
                                <input
                                    type="radio"
                                    name="optradio" checked={textColor4 == "text-dark" ? true : false}
                                    onChange={() => {
                                        setTextColor4("text-dark")
                                    }}
                                />{" "}
                                Dark
                            </label>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Heading"
                                    className="form-control"
                                    placeholder="Heading"
                                    value={heading4}
                                    onChange={(e) => {
                                        setHeading4(e.target.value);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="Description"
                                    className="form-control"
                                    placeholder="Description"
                                    value={text4}
                                    onChange={(e) => setText4(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <ImgUpload
                        onChange={async (e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}${imageFile.name}`;
                            let foldername = "banners";
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename.trim().replace(/\s/g, "-"));

                            let response;
                            Notiflix.Loading.Dots("");

                            try {
                                // Upload the image to the server using AddImage API
                                response = await fetch(imageApiUrl, {
                                    method: "POST",
                                    body: form,
                                });
                                if (response.status == 200 || response.status == 201) {
                                    const imgUrl = imageUrl + "/" + foldername + "/" + filename.trim().replace(/\s/g, "-").split('.').slice(0, -1).join('.') + '.webp';
                                    // setUpdatedImageUrl(imageUrl);

                                    const updateData = {
                                        id: 1,
                                        image7: imgUrl, // Update the specific field
                                    };
                                    await PostApiCall.postRequest(updateData, "UpdateHomePageCollection");
                                }
                            } catch (error) {
                                console.error("Error uploading image:", error);
                            } finally {
                                Notiflix.Loading.Remove();
                            }
                        }}
                        src={homeCollections[0]?.fld_image7}
                    />
                </div>
                <div className="text-right col-12">
                    <button className='btn align-items-center Bechofy-btn' onClick={UpdateHomePageCollection}>Save</button>
                </div>
            </div>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}
                center
            >
                <ColorPicker color={color} onChange={color => setColor(color.hex)} />
            </Modal>
            <Modal
                open={modalOpen2}
                onClose={() => {
                    setModalOpen2(false)
                }}
                center
            >
                <ColorPicker color={color2} onChange={color2 => setColor2(color2.hex)} />
            </Modal>
            <Modal
                open={modalOpen3}
                onClose={() => {
                    setModalOpen3(false)
                }}
                center
            >
                <ColorPicker color={color3} onChange={color3 => setColor3(color3.hex)} />
            </Modal>
            <Modal
                open={modalOpen4}
                onClose={() => {
                    setModalOpen4(false)
                }}
                center
            >
                <ColorPicker color={color4} onChange={color4 => setColor4(color4.hex)} />
            </Modal>
        </Container>
    )
}