import { MDBCol, MDBDataTableV5, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
class RawList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			TestimonialData: [],
			open: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
		};
	}
	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("");
		GetApiCall.getRequest("GetClientTestimonialWebsite").then((resultdes) =>
			resultdes.json().then((obj) => {
				//
				this.setState({
					TestimonialData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
	}
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Rating",
					field: "rating",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.TestimonialData.filter((data) => {
				// //
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					((data.fld_fld_clientname !== null &&
						data.fld_fld_clientname
							.toLowerCase()
							.includes(this.state.searchInput.toLowerCase())) ||
						(data.ffld_rating !== null &&
							data.ffld_rating
								.toLowerCase()
								.includes(this.state.searchInput.toLowerCase())) ||
						(data.fld_status !== null &&
							data.fld_status
								.toLowerCase()
								.includes(this.state.searchInput.toLowerCase())))
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: <td>{i + 1}</td>,
					name: <td>{data.fld_clientname}</td>,
					rating: <td>{data.fld_rating}</td>,
					status: (
						<td
							style={{ color: data.fld_status == "Active" ? "green" : "red" }}
						>
							{data.fld_status}{" "}
						</td>
					),
					action: (
						<td className="text-center actionuser">
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									localStorage.setItem(
										"ReviewDetails",
										JSON.stringify({ ...data, readOnly: false })
									);
									window.location.href = "/edit-review";
								}}
							></i>
						</td>
					),
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Review Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Card className="overflow-hidden">
										<Row>
											<Col xl="12">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Review List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<Link
																	to="/add-review"
																	style={{ float: "right", marginTop: "-5px" }}
																	className="btn align-items-center btn Bechofy-btn "
																>
																	Add New Review{" "}
																	<i
																		className="fa fa-plus"
																		aria-hidden="true"
																	></i>
																</Link>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<MDBCol
															md="3"
															style={{
																marginTop: "10px",
																marginbottom: "10px",
															}}
														>
															<input
																className="form-control"
																type="text"
																placeholder="Search"
																aria-label="Search"
																onChange={(e) => this.seachBarHandler(e)}
																value={this.state.searchInput}
															/>
														</MDBCol>
														<Col md="12">
															{this.state.TestimonialData.length == 0 ? (
																<p style={{ textAlign: "center" }}>
																	<b>No Review List Available</b>
																</p>
															) : (
																<MDBDataTableV5
																	striped
																	bordered
																	small
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTableV5>
															)}
														</Col>
													</Row>
												</CardBody>
												{/* <WelcomeComp /> */}
											</Col>
										</Row>
									</Card>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RawList;
