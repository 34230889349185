import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Modal, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import moment from "moment";
import Saveicon from "../../assets/images/dashboardimages/filesave.png";
import Editicon from "../../assets/images/dashboardimages/pencil.png";
import GetApiCall from "../../GETAPI";
// import CustomerAddressModal from "./CustomerAddressModal/CustomerAddressModal";
import AddAddressImage from "../../assets/images/add_address.png";
import logoplaceholder from "../../assets/images/logo-placeholder.jpg";
import RealizeIconBlue from "../../assets/images/Realize-Icon-Blue.png";
// import "./scrollbar.css";

// comopnent to hold and change image
const ImgUpload = ({ onChange, src }) => (
	<>
		<label className="custom-file-upload fas border-0">
			<div className="img-wrap img-upload custom-file-upload fas">
				<img for="photo-upload" src={src} style={{ width: "100%", height: "100%", borderRadius: "5%" }} />
			</div>
			<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
		</label>
	</>
);

// hold logo and change
const LogoUpload = ({ onChange, src }) => (
	<>
		<label className="custom-file-upload fas border-0">
			<div className="img-wrap img-upload custom-file-upload fas">
				<img for="logo-upload" src={src} style={{ width: "100%", height: "100%", borderRadius: "5%" }} />
			</div>
			<input accept="image/*" id="logo-upload" type="file" onChange={onChange} />
		</label>
	</>
);

class addCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl: RealizeIconBlue,
			logoPreviewUrl: logoplaceholder,
			ImageApiUrl: 'https://api.bechofy.in/AddImage',
			CompanyId: 19,
			Status: "Active",
			ImageData: [],
			LogoData: [],
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			CountryData: [], // holds the list of all the countries
			CityData: [],
			StateData: [],
			CountryId: 0, // country identifier
			StateId: 0,
			CityId: 0,
			Name: "",
			Mobile: "",
			Email: "",
			Website: "",
			Facebook: "",
			Instagram: "",
			Linkedin: "",
			Youtube: "",
			Address: "",
			Country: "", // specifc country among the list
			State: "",
			City: "",
			Pincode: null,
			NumRegex: /^[0-9]*$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex:
				/^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			ContactEmail: "",
			Landmark: "",

			// newly added states
			WarehouseType: "",
			GstType: "",
			PricingType: "",
			CurrencyType: "",
			TransactionNumber: "",
			DispatchSequenceNumber: "",
			OrderSequenceNumber: "",
			InvoiceSequenceNumber: "",
			OrderFormLink: "",

			Gstnumber: "",
			Pannumber: "",
			Tannumber: "",
			StoreName: "",
			StoreURL: "",
			Designation: "",
			ContactPerson: "",
			ApprovalStatus: "",
			CustomerStatus: "",
			GoLiveDate: "",
			AccountNo: "",
			BranchName: "",
			ifsc: "",
			swift: "",
			AboutCompany: "",
			BrandData: [],
			BrandId: null,

			CustomerId: localStorage.getItem("LoginDetail").fld_company_id,
			Industry: null,
			TypeOfCompany: null,
			BankName: "",
			// Manufacturer: "No",
			// Marketer: "No",
			// Vendor: "No",

			AddressModal: false,
			ShowAddAddressComponent: false,
			AddContact: {
				ContactName: "",
				PhoneNumber: "",
				EmailAddress: "",
				Extension: "",
			},
			CustomerAddressData: [],
			IndustryList: [],
			BankData: [],
			ClientData: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({ svgColor: "#777f80" });
		Notiflix.Loading.Dots("Please wait...");
		// if the customer id is present (from localstorage)
		if (this.state.CustomerId) {
			PostApiCall.postRequest(
				{
					whereClause: `where flc_company_id=${this.state.CustomerId}`,
				},
				"Get_Customer_Address"
			).then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						console.log(obj.data);
						this.setState({
							CustomerAddressData: obj.data,
						});
						Notiflix.Loading.Remove();
					}
				})
			);
		}
		PostApiCall.postRequest(
			{
				whereClause: "where fld_company_id = 6"
			},
			"GetClientData"
		).then((results) =>
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						Name: obj1.data[0]?.fld_companyname,
						Industry: obj1.data[0]?.fld_industry,
						TypeOfCompany: obj1.data[0]?.fld_companytype,
						Address: obj1.data[0]?.fld_address,
						CountryId: obj1.data[0]?.fld_country_id,
						StateId: obj1.data[0]?.fld_state_id,
						CityId: obj1.data[0]?.fld_city_id,
						Pincode: obj1.data[0]?.fld_pincode,
						Landmark: obj1.data[0]?.fld_landmark,
						WarehouseType: obj1.data[0]?.fld_warehouse_type,
						GstType: obj1.data[0]?.fld_gst_type,
						PricingType: obj1.data[0]?.fld_pricing_type,
						CurrencyType: obj1.data[0]?.fld_currency_type,
						Mobile: obj1.data[0]?.fld_phonenumber,
						Email: obj1.data[0]?.fld_email,
						Website: obj1.data[0]?.fld_website,
						Facebook: obj1.data[0]?.fld_facebook,
						Instagram: obj1.data[0]?.fld_instagram,
						Linkedin: obj1.data[0]?.fld_linkedin,
						Youtube: obj1.data[0]?.fld_youtube,
						Gstnumber: obj1.data[0]?.fld_gst,
						Pannumber: obj1.data[0]?.fld_pan,
						Tannumber: obj1.data[0]?.fld_tan,
						BankName: obj1.data[0]?.fld_bankname,
						AccountNo: obj1.data[0]?.fld_accountnumber,
						BranchName: obj1.data[0]?.fld_branch,
						ifsc: obj1.data[0]?.fld_ifsc,
						swift: obj1.data[0]?.fld_swiftcode,
						OrderFormLink: obj1.data[0]?.fld_order_form_link,
						StoreName: obj1.data[0]?.fld_storename,
						storeurl: obj1.data[0]?.fld_storeurl,
					})
				}
			})
		)
		GetApiCall.getRequest("Get_Industry").then((results) =>
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						IndustryList: obj1.data
					});
				}
			})
		)
		GetApiCall.getRequest("GetBankMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					BankData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
		// get country data
		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				console.log(obj.data);
				if (obj.data.length != 0) {
					this.setState({
						CountryData: obj.data,
						CountryId: 101,
						Country: obj.data[152].label,
					});
				}
				// get state data
				PostApiCall.postRequest(
					{
						countryid: 101,
					},
					"GetState"
				).then((results) =>
					results.json().then((objstate) => {
						if (results.status == 200 || results.status == 201) {
							if (objstate.data.length != 0) {
								this.setState({
									StateId: objstate.data[0].value,
									StateData: objstate.data,
									State: objstate.data[0].label,
								});
							}
							// get city data
							PostApiCall.postRequest(
								{
									stateid: objstate.data[0].value,
								},
								"GetCity"
							).then((resultscity) =>
								resultscity.json().then((objcity) => {
									if (resultscity.status == 200 || resultscity.status == 201) {
										if (objcity.data.length != 0) {
											this.setState({
												CityId: objcity.data[0].value,
												CityData: objcity.data,
												City: objcity.data[0].label,
											});
										}
									}
								})
							);
						}
					})
				);
			})
		);
	}

	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("Image too large, upload file less than 300 kb.");
		}
	};

	logoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					logoPreviewUrl: reader.result,
					LogoData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("Logo too large, upload file less than 300 kb.");
		}
	};

	onChangeCountry(country) {
		this.setState({
			CountryId: country.target.value,
			Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
		});

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country:
					this.state.CountryData[parseInt(country.target.value) + 1].label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						StateId: obj.data[0]?.value,
						State: obj.data[0]?.label,
					});

					PostApiCall.postRequest(
						{
							stateid: obj.data[0]?.value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								this.setState({
									CityData: objcity.data,
									StateData: obj.data,
								});
								if (objcity.data.length > 0) {
									this.setState({
										CityId: objcity.data[0].value,
										City: objcity.data[0].label,
									});
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	}

	onChangeState(state) {
		this.setState({
			StateId: state.target.value,
		});
		Notiflix.Loading.Dots("Please wait...");
		for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
			if (this.state.StateData[i].value == state.target.value) {
				this.setState({
					State: this.state.StateData[i].label,
				});
			}
		}
		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						this.setState({
							CityData: obj.data,
							CityId: obj.data[0].value,
							City: obj.data[0].label,
						});
					}
					Notiflix.Loading.Remove();
				}
			})
		);
	}

	onChangeCity(city) {
		this.setState({
			CityId: city.target.value,
		});
		for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
			if (this.state.CityData[i].value == city.target.value) {
				this.setState({
					City: this.state.CityData[i].label,
				});
			}
		}
	}

	// function to clear customer form after submission
	clearCustomerForm() {
		this.setState({
			Name: "",
			Industry: null,
			TypeOfCompany: null,
			Address: "",
			Country: "",
			State: "",
			City: "",
			Pincode: null,
			Landmark: "",
			WarehouseType: "",
			GstType: "",
			PricingType: "",
			CurrencyType: "",
			Mobile: "",
			Email: "",
			Website: "",
			Facebook: "",
			Instagram: "",
			Linkedin: "",
			Youtube: "",
			Gstnumber: "",
			Pannumber: "",
			Tannumber: "",
			BankName: "",
			AccountNo: "",
			BranchName: "",
			ifsc: "",
			swift: "",
		});
	}

	// validate and post data
	onSaveData() {
		this.onPost();
		this.state.imagePreviewUrl != RealizeIconBlue
			? Notiflix.Notify.Success("Company image uploaded.")
			: Notiflix.Notify.Failure("Company image cannot be empty!");
		this.state.logoPreviewUrl != logoplaceholder
			? Notiflix.Notify.Success("Store logo uploaded.")
			: Notiflix.Notify.Failure("Store logo cannot be empty!");
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		//////////////////// Company information ///////////////////////
		PostApiCall.postRequest(
			{
				// companyy information
				companyid: this.state.CompanyId,
				name: this.state.Name,
				image: this.state.imagePreviewUrl,
				industry: this.state.Industry,
				type: this.state.TypeOfCompany,
				address: this.state.Address,
				country: this.state.CountryId,
				state: this.state.StateId,
				city: this.state.CityId,
				pincode: this.state.Pincode,
				landmark: this.state.Landmark,
				warehouse: this.state.WarehouseType,
				gsttype: this.state.GstType,
				pricing: this.state.PricingType,
				currency: this.state.CurrencyType,
				// communication information
				mobile: this.state.Mobile,
				email: this.state.Email,
				website: this.state.Website,
				facebook: this.state.Facebook,
				instagram: this.state.Instagram,
				linkedin: this.state.Linkedin,
				youtube: this.state.Youtube,

				// tax and banking information
				gst: this.state.Gstnumber,
				pan: this.state.Pannumber,
				tan: this.state.Tannumber,
				bankname: this.state.BankName,
				accountnumber: this.state.AccountNo,
				branch: this.state.BranchName,
				ifsc: this.state.ifsc,
				swift: this.state.swift,

				// office use
				customerstatus: this.state.CustomerStatus,
				golivedate: this.state.GoLiveDate,

				// store information
				logo: this.state.logoPreviewUrl,
				storename: this.state.StoreName,
				storeurl: this.state.StoreURL,
				approvalstatus: this.state.ApprovalStatus,
				enquiryno: this.state.EnquiryNumber,
				transactionno: this.state.TransactionNumber,
				dispatchno: this.state.DispatchSequenceNumber,
				orderno: this.state.OrderSequenceNumber,
				invoiceno: this.state.InvoiceSequenceNumber,
				orderlink: this.state.OrderFormLink,
				storestatus: this.state.Status,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				createdBy: details[0].fld_userid,
				updatedBy: details[0].fld_userid,
			},
			"UpdateClientDetails" //  same api will be called to add company/customer
		)
			.then((res) => {
				if (res.status === 200) {
					this.clearCustomerForm();
					Notiflix.Notify.Success("Customer registered sucessfully.");
					this.onUploadImage();
					Notiflix.Loading.Remove();
				} else {
					Notiflix.Notify.Failure("Customer registration failed!");
					Notiflix.Loading.Remove();
				}
			})
			.catch((err) => {
				Notiflix.Notify.Failure("Something went wrong!");
				Notiflix.Loading.Remove();
			});
		// .then((resultcategory) =>
		//   resultcategory.json().then((obj) => {
		//     if (resultcategory.status == 200 || resultcategory.status == 201) {
		//       Notiflix.Loading.Remove();
		//       console.log(obj, "add customer response");
		//       this.onUploadImage(obj);
		//       localStorage.setItem("user", obj.data[0]);
		//       this.setState({
		//         ShowAddAddressComponent: !this.state.ShowAddAddressComponent,
		//       });
		//     }
		//   })
		// );
	};

	onUploadImage(obj) {
		// Notiflix.Loading.Dots("Please wait...");
		if (this.state.ImageData != "") {
			let name = `${Math.floor(Math.random() * 10000)}-${this.state.ImageData.name}`
			const form = new FormData();

			form.append("file", this.state.ImageData);
			form.append("foldername", "banners");
			form.append('filename', name)
			fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
				headers: {
					"imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
					"x-auth-token": JSON.parse(localStorage.getItem("access")),
					"schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
				}
			}).then((image) => {
				image
					.json()
					.then((data) => ({ data: data, status: image.status, }))
					.then((res) => {
					});
			});
		} else {
			Notiflix.Loading.Remove();
			console.log("error occured");
		}
	}

	clearAddressForm() {
		this.setState({
			AddContact: {
				ContactName: "",
				PhoneNumber: "",
				EmailAddress: "",
			},
		});
	}

	saveData = () => {
		PostApiCall.postRequest(
			{
				customerid: localStorage.getItem("user").fld_company_id,
				addressid: null,
				name: this.state.AddContact.ContactName,
				mobileNo: this.state.AddContact.PhoneNumber,
				extension: this.state.AddContact.Extension,
				email: this.state.AddContact.EmailAddress,
				// flatno: this.state.AddContact.FlatNo,
				// landmark: this.state.AddContact.Landmark,
				// pincode: this.state.AddContact.Pincode,
			},
			"Add_Customer_Address"
		)
			.then((res) => {
				if (res.status === 200) {
					this.clearAddressForm();
					Notiflix.Notify.Success("Form submitted successfully.");
				} else {
					Notiflix.Notify.Failure("Form submission failed!");
				}
			})
			.catch((err) => {
				Notiflix.Notify.Failure("Something went wrong!");
			});
	};

	onSaveAddressData = () => {
		if (this.state.AddContact.ContactName != "") {
			if (this.state.AddContact.EmailAddress !== "") {
				if (this.state.EmailRegex.test(this.state.AddContact.EmailAddress)) {
					this.saveData();
				} else {
					Notiflix.Notify.Failure("Please enter valid Contact's email");
				}
			} else {
				Notiflix.Notify.Failure("Please enter Contact's email");
			}
		} else {
			Notiflix.Notify.Failure("Please enter Contact's name");
		}
	};

	onChangeAddressState = (state) => {
		this.setState({
			AddContact: {
				...this.state.AddContact,
				StateId: state.target.value,
			},
		});

		Notiflix.Loading.Dots("Please wait...");

		for (var i = 0; i < Object.keys(this.state.AddContact.StateData).length; i++) {
			if (this.state.AddContact.StateData[i].value == state.target.value) {
				this.setState({
					AddContact: {
						...this.state.AddContact,
						State: state.target.value,
					},
				});
			}
		}

		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						this.setState({
							...this.state.AddContact,
							AddContact: {
								StateId: state.target.value,
								CityData: obj.data,
							},
						});
					}
					Notiflix.Loading.Remove();
				}
			})
		);
	};
	onChangeAddressCountry = (country) => {
		this.setState({
			AddContact: {
				...this.state.AddContact,
				CountryId: country.target.value,
				Country:
					this.state.AddContact.CountryData[parseInt(country.target.value) + 1]
						.label,
			},
		});

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country:
					this.state.AddContact.CountryData[parseInt(country.target.value) + 1]
						.label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						AddContact: {
							...this.state.AddContact,
							StateId: obj.data[0]?.value,
							State: obj.data[0]?.label,
						},
					});

					PostApiCall.postRequest(
						{
							stateid: obj.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								this.setState({
									...this.state.AddContact,
									CityData: objcity.data,
									StateData: obj.data,
								});
								if (objcity.data.length > 0) {
									this.setState({
										...this.state.AddContact,
										CityId: objcity.data[0].value,
										City: objcity.data[0].label,
									});
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	};
	onChangeAddressCity = (city) => {
		this.setState({
			AddContact: {
				...this.state.AddContact,
				CityId: city.target.value,
			},
		});

		for (
			var i = 0;
			i < Object.keys(this.state.AddContact.CityData).length;
			i++
		) {
			if (this.state.AddContact.CityData[i].value == city.target.value) {
				this.setState({
					AddContact: {
						...this.state.AddContact,
						City: city.target.value,
					},
				});
			}
		}
	};
	CheckMandetoryFields() {
		this.onPost();
		// if (this.state.Name !== "" && this.state.Address !== "" && this.state.CountryId !== 0 && this.state.StateId !== 0 &&
		// 	this.state.CityId !== 0 && this.state.Pincode !== null && this.state.WarehouseType !== "" && this.state.GstType !== ""
		// 	&& this.state.PricingType !== "" && this.state.CurrencyType !== "" && this.state.Mobile !== "" && this.state.StoreName !== "" &&
		// 	this.state.TransactionNumber !== "" && this.state.DispatchSequenceNumber !== "" && this.state.OrderSequenceNumber !== "" &&
		// 	this.state.InvoiceSequenceNumber !== "" && this.state.Status !== "") {
		// 	this.onPost();
		// } else {
		// 	Notiflix.Notify.Failure("Please fill all mandatory fields")
		// }
	}
	render() {
		return (
			<>
				<div>
					<Modal
						toggle={() =>
							this.setState({ AddressModal: !this.state.AddressModal })
						}
						isOpen={this.state.AddressModal}
					>
						<CardBody className="">
							<Row className="card-row ">
								<Col sm="12">
									<div className="row">
										<div className="col-12 Bechofy-bg-soft-pink py-3 mb-3">
											{this.state.BrandId ? (
												<h5 className="text-white mb-0 pb-0">Add Address</h5>
											) : (
												<h5 className="text-white mb-0 pb-0">Edit Contact</h5>
											)}
										</div>
										<div className="col-12 col-md-6">
											<label class="my-1 mr-2">Contact Name *</label>

											<input
												className="form-control"
												name="name"
												type="text"
												placeholder="Enter Contact Name"
												value={this.state.AddContact.ContactName}
												onChange={(text) =>
													this.setState({
														AddContact: {
															...this.state.AddContact,
															ContactName: text.target.value,
														},
													})
												}
											/>
										</div>

										{/* <div className="col-6">
                        <label class="my-1 mr-2">Area </label>

                        <input
                          className="form-control"
                          name="Area"
                          type="text"
                          placeholder="Enter Area"
                          value={this.state.AddContact.Area}
                          onChange={(text) => {
                            this.setState({
                              AddContact: {
                                ...this.state.AddContact,
                                Area: text.target.value,
                              },
                            });
                          }}
                        />
                      </div> */}
										{/* <div className="col-6 mb-3">
                        <label class="my-1 mr-2">Flat No *</label>
                        <input
                          className="form-control"
                          name="flatno"
                          type="text"
                          placeholder="Enter Flat No."
                          value={this.state.AddContact.FlatNo}
                          onChange={(text) => {
                            this.setState({
                              AddContact: {
                                ...this.state.AddContact,
                                FlatNo: text.target.value,
                              },
                            });
                          }}
                        />
                      </div> */}
										{/* <div className="col-6 mb-3">
                        <label class="my-1 mr-2">Landmark *</label>
                        <input
                          className="form-control"
                          name="flatno"
                          type="text"
                          placeholder="Enter Flat No."
                          value={this.state.AddContact.Landmark}
                          onChange={(text) => {
                            this.setState({
                              AddContact: {
                                ...this.state.AddContact,
                                Landmark: text.target.value,
                              },
                            });
                          }}
                        />
                      </div> */}
										{/* <div className="col-6 mb-3">
                        <label class="my-1 mr-2">Pincode</label>

                        <input
                          className="form-control"
                          name="pincode"
                          type="text"
                          placeholder="Enter Phone"
                          value={this.state.AddContact.Pincode}
                          onChange={(e) => {
                            this.setState({
                              AddContact: {
                                ...this.state.AddContact,
                                Pincode: e.target.value,
                              },
                            });
                          }}
                        />
                      </div> */}
										<div className="col-12 col-md-6">
											<label class="my-1 mr-2">Phone Number *</label>

											<input
												className="form-control"
												name="name"
												type="text"
												placeholder="Enter Phone"
												value={this.state.AddContact.PhoneNumber}
												onChange={(text) => {
													this.setState({
														AddContact: {
															...this.state.AddContact,
															PhoneNumber: text.target.value,
														},
													});
												}}
											/>
										</div>
										<div className="col-12 col-md-6">
											<label class="my-1 mr-2">Extension</label>

											<input
												className="form-control"
												name="name"
												type="text"
												placeholder="Enter Extension"
												value={this.state.AddContact.Extension}
												onChange={(text) => {
													this.setState({
														AddContact: {
															...this.state.AddContact,
															Extension: text.target.value,
														},
													});
												}}
											/>
										</div>
										<div className="col-12 col-md-6">
											<label class="my-1 mr-2">Email Address </label>

											<input
												className="form-control"
												name="name"
												type="text"
												placeholder="Enter Email"
												value={this.state.AddContact.EmailAddress}
												onChange={(text) => {
													this.setState({
														AddContact: {
															...this.state.AddContact,
															EmailAddress: text.target.value,
														},
													});
												}}
											/>
										</div>
									</div>
								</Col>
								<hr />
							</Row>
							<Row>
								<Col sm="12">
									{!this.state.BrandId ? (
										<a
											onClick={this.onSaveAddressData.bind(this)}
											// onClick={() => console.log(this.state)}
											className="btn Bechofy-btn Bechofy-btn waves-effect waves-light btn-sm float-right"
											style={{
												marginTop: "15px",
											}}
										>
											<span className="">
												<img
													src={Saveicon}
													alt="block user"
													className="btnicons"
													style={{ marginRight: "5px" }}
												></img>{" "}
											</span>
											Save Contact
										</a>
									) : (
										<a
											onClick={this.onSaveAddressData.bind(this)}
											className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
											style={{
												marginTop: "15px",
												color: "white",
											}}
										>
											<span className="">
												<img
													src={Editicon}
													alt="block user"
													className="btnicons"
													style={{ marginRight: "5px" }}
												></img>{" "}
											</span>
											Update Contact
										</a>
									)}
								</Col>
							</Row>
						</CardBody>
					</Modal>
				</div>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							title={"GTCommerce "}
							breadcrumbItem={"Add New Customer"}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text  p-3">
													<h5 className="Bechofy-text">
														Add New Customer
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0 usercarddata">
										<CardTitle className="usertitle">
											<div className="Bechofy-text  p-3 Bechofy-bg-soft-pink">
												<h5 className="Bechofy-text">
													Company Information
												</h5>
											</div>
										</CardTitle>
										<Row>
											<Col xs="12">
												<div className="form my-4 col col-12">
													<div className="row">
														<div className="col col-12">
															<div className="row">
																<div className="col-md-4">
																	<label for="sw-arrows-first-name">
																		Upload Company Logo (Size &lt; 100kb,
																		500*500)
																		<span className="mandatory">*</span>
																	</label>
																	<div class="div1">
																		<ImgUpload
																			onChange={this.photoUpload}
																			src={this.state.imagePreviewUrl}
																			id={1}
																			htmlfor="photo-upload"
																		/>
																	</div>
																</div>

																<div className="col-8">
																	<div className="row">
																		<div className="col-12">
																			<div className="form-group">
																				<label for="CompanyName">
																					Company Name
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					type="text"
																					id="CompanyName"
																					className="form-control"
																					name="Name"
																					placeholder="Company name"
																					value={this.state.Name}
																					onChange={(text) => {
																						this.setState({
																							Name: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-6">
																			<div className="form-group">
																				<label class="my-1 mr-2" for="industry">
																					Industry
																				</label>
																				<select
																					id="Industry-Type"
																					className="form-control my-1 mr-sm-2"
																					placeholder="Industry Type"
																					value={this.state.Industry}
																					onChange={(e) => {
																						this.setState({
																							Industry: e.target.value,
																						});
																					}}
																				>
																					<option selected>Select</option>
																					{this.state.IndustryList?.map((data) => {
																						return (
																							<option value={data.value}>{data.label}</option>
																						)
																					})}
																				</select>

																			</div>
																		</div>
																		<div className="col-6">
																			<div className="form-group">
																				<label
																					class="my-1 mr-2"
																					for="companytype"
																				>
																					Type of Company
																				</label>
																				<select
																					id="Pincode"
																					placeholder="Type of company"
																					className="form-control my-1 mr-sm-2"
																					value={this.state.TypeOfCompany}
																					onChange={(e) => {
																						this.setState({
																							TypeOfCompany: e.target.value,
																						});
																					}}>
																					<option selected>Select</option>
																				</select>
																			</div>
																		</div>
																		<div className="col-12">
																			<div className="form-group">
																				<label for="address">
																					Address
																					<span className="mandatory">*</span>
																				</label>
																				<textarea
																					className="form-control"
																					row="50"
																					col="30"
																					id="address"
																					placeholder="Address"
																					value={this.state.Address}
																					onChange={(text) => {
																						this.setState({
																							Address: text.target.value,
																						});
																					}}
																				></textarea>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															{/* start new row */}
															<div className="row">
																<div className="col-4">
																	<div className="form-group">
																		<label class="my-1 mr-2" for="country">
																			Country
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			class="custom-select my-1 mr-sm-2"
																			id="country"
																			name="UserType"
																			value={this.state.CountryId}
																			onChange={this.onChangeCountry.bind(this)}
																		>
																			{this.state.CountryData.map(
																				(schedule) => (
																					<option
																						key={schedule.label}
																						value={schedule.value}
																					>
																						{schedule.label}
																					</option>
																				)
																			)}
																		</select>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label class="my-1 mr-2" for="state">
																			State
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			class="custom-select my-1 mr-sm-2"
																			id="state"
																			name="UserType"
																			value={this.state.StateId}
																			onChange={this.onChangeState.bind(this)}
																		>
																			{this.state.StateData.map((schedule) => (
																				<option
																					key={schedule.label}
																					value={schedule.value}
																				>
																					{schedule.label}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label class="my-1 mr-2" for="city">
																			City
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			class="custom-select my-1 mr-sm-2"
																			id="city"
																			name="UserType"
																			value={this.state.CityId}
																			onChange={this.onChangeCity.bind(this)}
																		>
																			{this.state.CityData.map((schedule) => (
																				<option
																					key={schedule.label}
																					value={schedule.value}
																				>
																					{schedule.label}
																				</option>
																			))}
																		</select>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="Pincode">
																			Pincode
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="text"
																			id="Pincode"
																			className="form-control mr-sm-2"
																			value={this.state.Pincode}
																			onChange={(pincode) => {
																				if (
																					this.state.NumRegex.test(
																						pincode.target.value
																					) &&
																					pincode.target.value.length <= 6
																				) {
																					this.setState({
																						Pincode: pincode.target.value,
																					});
																				}
																			}}
																		/>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="landmark">Landmark</label>
																		<input
																			type="text"
																			id="landmark"
																			placeholder="Landmark"
																			className="form-control"
																			name="landmark"
																			value={this.state.Landmark}
																			onChange={(text) => {
																				this.setState({
																					Landmark: text.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="warehouse">
																			Warehouse Type
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			id="warehouse"
																			placeholder="Warehouse Type"
																			className="form-control"
																			name="warehouse"
																			value={this.state.WarehouseType}
																			onChange={(text) => {
																				this.setState({
																					WarehouseType: text.target.value,
																				});
																			}}>
																			<option>Select</option>
																			<option value="Single">Single</option>
																			<option value="Multiple">Multiple</option>
																		</select>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="gsttype">GST Type
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			id="gsttype"
																			placeholder="GST Type"
																			className="form-control"
																			name="gsttype"
																			value={this.state.GstType}
																			onChange={(text) => {
																				this.setState({
																					GstType: text.target.value,
																				});
																			}}>
																			<option>Select</option>
																			<option value="Inclusive">Inclusive</option>
																			<option value="Exclusive">Exclusive</option>
																		</select>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="pricingtype">
																			Pricing Type
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			id="pricingtype"
																			placeholder="Pricing Type"
																			className="form-control"
																			name="pricingtype"
																			value={this.state.PricingType}
																			onChange={(text) => {
																				this.setState({
																					PricingType: text.target.value,
																				});
																			}}>
																			<option>Select</option>
																			<option value="Single">Single</option>
																			<option value="Multiple">Multiple</option>
																		</select>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="currencytype">
																			Currency Type
																			<span className="mandatory">*</span>
																		</label>
																		<select
																			id="currencytype"
																			placeholder="Currency Type"
																			className="form-control"
																			name="currencytype"
																			value={this.state.CurrencyType}
																			onChange={(text) => {
																				this.setState({
																					CurrencyType: text.target.value,
																				});
																			}}>
																			<option>Select</option>
																			<option value="INR">INR</option>
																			<option value="USD">USD</option>
																		</select>
																	</div>
																</div>

																{/* <div className="col-6">
                                  <div className="form-group">
                                    <label for="aboutcompany">
                                      About Company
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="aboutcompany"
                                      placeholder="About Company"
                                      className="form-control"
                                      name="aboutcompany"
                                      value={this.state.AboutCompany}
                                      onChange={(text) => {
                                        this.setState({
                                          AboutCompany: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div> */}

																{/* Disabled code for checkboxes */}
																{/* <div className="col-4">
                                  <div className="form-group my-1 mr-sm-2">
                                    <label for="manufacturer">
                                      Manufacturer?
                                    </label>
                                    <br />
                                    <label class="radio-inline">
                                      <input
                                        type="radio"
                                        name="manufacturer"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Manufacturer == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Manufacturer: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      class="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="manufacturer"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Manufacturer == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Manufacturer: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group my-1 mr-sm-2">
                                    <label for="marketer">Marketer?</label>
                                    <br />
                                    <label class="radio-inline">
                                      <input
                                        type="radio"
                                        name="marketer"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Marketer == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Marketer: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      class="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="marketer"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Marketer == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Marketer: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group my-1 mr-sm-2">
                                    <label for="vendor">Vendor?</label>
                                    <br />
                                    <label class="radio-inline">
                                      <input
                                        type="radio"
                                        name="vendor"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Vendor == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Vendor: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      class="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="vendor"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Vendor == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Vendor: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div> */}
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									<CardBody className="pt-0 usercarddata">
										<Row>
											<Col xs="12">
												{/* Communication */}
												<CardTitle className="usertitle">
													<div className="Bechofy-text  p-3 Bechofy-bg-soft-pink">
														<h5 className="Bechofy-text">
															Communication Information
														</h5>
													</div>
												</CardTitle>
												<div className="row mt-4">
													<div className="col-4">
														<div className="form-group">
															<label for="mobile">
																Mobile Number
																<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																id="mobile"
																className="form-control"
																placeholder="Mobile number"
																value={this.state.Mobile}
																onChange={(mobile) => {
																	if (
																		this.state.NumRegex.test(
																			mobile.target.value
																		) &&
																		mobile.target.value.length <= 10
																	) {
																		this.setState({
																			Mobile: mobile.target.value,
																		});
																	}
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="companyemail">
																Company Email
																<span className="mandatory">*</span>
															</label>
															<input
																type="email"
																id="companyemail"
																placeholder="Company email"
																className="form-control"
																name="Email"
																value={this.state.Email}
																onChange={(text) => {
																	this.setState({
																		Email: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="companywebsite">
																Company Website
															</label>
															<input
																type="text"
																id="companywebsite"
																placeholder="Company website"
																className="form-control"
																name="website"
																value={this.state.Website}
																onChange={(text) => {
																	this.setState({
																		Website: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-3">
														<div className="form-group">
															<label for="facebook">Facebook</label>
															<input
																type="text"
																id="facebook"
																className="form-control"
																placeholder="Facebook"
																name="website"
																value={this.state.Facebook}
																onChange={(text) => {
																	this.setState({
																		Facebook: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-3">
														<div className="form-group">
															<label for="Intagram">Instagram</label>
															<input
																type="text"
																id="Intagram"
																className="form-control"
																placeholder="Instagram"
																name="website"
																value={this.state.Instagram}
																onChange={(text) => {
																	this.setState({
																		Instagram: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-3">
														<div className="form-group">
															<label for="linkedin">Linkedin</label>
															<input
																type="text"
																id="linkedin"
																className="form-control"
																name="website"
																placeholder="Linkedin"
																value={this.state.Linkedin}
																onChange={(text) => {
																	this.setState({
																		Linkedin: text.target.value,
																	});
																}}
															/>
														</div>
													</div>

													<div className="col-3">
														<div className="form-group">
															<label for="youtube">Youtube</label>
															<input
																type="text"
																id="youtube"
																className="form-control"
																name="website"
																value={this.state.Youtube}
																placeholder="Youtube"
																onChange={(text) => {
																	this.setState({
																		Youtube: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									<CardBody className="pt-0 usercarddata">
										<Row>
											<Col xs="12">
												{/* Communication */}
												<CardTitle className="usertitle">
													<div className="Bechofy-text  p-3 Bechofy-bg-soft-pink">
														<h5 className="Bechofy-text">
															Tax and Banking Information
														</h5>
													</div>
												</CardTitle>

												{/* Tax and Banking */}
												<div className="row mt-4">
													<div className="col-4">
														<div className="form-group">
															<label for="gst">GSTIN</label>
															<input
																type="text"
																id="gst"
																className="form-control"
																name="GSTNUM"
																placeholder="GSTIN"
																value={this.state.Gstnumber}
																onChange={(text) => {
																	this.setState({
																		Gstnumber: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="pan">PAN</label>
															<input
																type="text"
																id="pan"
																className="form-control"
																name="Pannum"
																placeholder="PAN"
																value={this.state.Pannumber}
																onChange={(text) => {
																	this.setState({
																		Pannumber: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="tan">TAN</label>
															<input
																type="text"
																id="tan"
																className="form-control"
																placeholder="TAN"
																name="TanNum"
																value={this.state.Tannumber}
																onChange={(text) => {
																	this.setState({
																		Tannumber: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-6">
														<div className="form-group">
															<label for="bankname">
																Bank Name
															</label>
															<select
																className="form-control"
																id="bankname"
																value={this.state.BankName}
																onChange={(text) => {
																	this.setState({
																		BankName: text.target.value,
																	});
																}}>
																<option selected>
																	Select Bank
																</option>
																{this.state.BankData.map(
																	(bank) => (
																		<option
																			key={bank.value}
																			value={bank.value}
																		>
																			{bank.label}
																		</option>
																	)
																)}
															</select>

														</div>
													</div>

													<div className="col-6">
														<div className="form-group">
															<label for="accountnumber">Account Number</label>
															<input
																type="text"
																id="accountnumber"
																className="form-control"
																name=" Contact Person"
																placeholder="Account number"
																value={this.state.AccountNo}
																onChange={(text) => {
																	this.setState({
																		AccountNo: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="branch">Branch Name</label>
															<input
																type="text"
																id="branch"
																className="form-control"
																name=" Contact Person"
																placeholder="Branch name"
																value={this.state.BranchName}
																onChange={(text) => {
																	this.setState({
																		BranchName: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="ifsc">IFSC Code</label>
															<input
																type="text"
																id="ifsc"
																placeholder="IFSC code"
																className="form-control"
																name=" Contact Person"
																value={this.state.ifsc}
																onChange={(text) => {
																	this.setState({
																		ifsc: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
													<div className="col-4">
														<div className="form-group">
															<label for="swift">Swift Code</label>
															<input
																type="text"
																id="swift"
																className="form-control"
																placeholder="Swift code"
																name=" Contact Person"
																value={this.state.swift}
																onChange={(text) => {
																	this.setState({
																		swift: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									<CardBody className="pt-0 usercarddata">
										<Row>
											<Col xs="12">
												{/* Communication */}
												<CardTitle className="usertitle">
													<div className="Bechofy-text  p-3 Bechofy-bg-soft-pink">
														<h5 className="Bechofy-text">
															Office Use
														</h5>
													</div>
												</CardTitle>

												{/* Office Use */}
												<div className="row mt-4">
													<div className="col-6">
														<div className="form-group">
															<label for="customerstatus">
																Customer Status
															</label>
															<span className="mandatory">*</span>
															<input
																type="text"
																placeholder="Customer status"
																id="customerstatus"
																className="form-control"
																name=" Contact Person"
																value={this.state.CustomerStatus}
																onChange={(text) => {
																	this.setState({
																		CustomerStatus: text.target.value,
																	});
																}}
															/>
														</div>
													</div>

													<div className="col-6">
														<div className="form-group">
															<label for="golive">Go Live Date</label>
															<span className="mandatory">*</span>
															<input
																type="date"
																id="golive"
																className="form-control"
																name="Contact Person"
																value={this.state.GoLiveDate}
																onChange={(text) => {
																	this.setState({
																		GoLiveDate: text.target.value,
																	});
																}}
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									<CardBody className="pt-0 usercarddata pb-0">
										<CardTitle className="usertitle">
											<div className="Bechofy-text  p-3 Bechofy-bg-soft-pink">
												<h5 className="Bechofy-text">
													Store Information
												</h5>
											</div>
										</CardTitle>
										<Row>
											<Col xs="12">
												<div className="form mt-4 mb-1">
													<div className="row">
														<div className="col col-12">
															<div className="row">
																<div className="col-md-4 col-12">
																	<label for="sw-arrows-first-name">
																		Upload Store Logo (Size &lt; 100kb, 500*500)
																		<span className="mandatory">*</span>
																	</label>
																	<div class="div1">
																		<LogoUpload
																			onChange={this.logoUpload}
																			src={this.state.logoPreviewUrl}
																		/>
																	</div>
																</div>
																<div className="col-md-8 col-12">
																	<div className="row">
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="StoreName">
																					Store Name
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					type="text"
																					id="CategoryName"
																					className="form-control"
																					placeholder="Store name"
																					name="StoreName"
																					value={this.state.StoreName}
																					onChange={(text) => {
																						this.setState({
																							StoreName: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="StoreURL">Store URL</label>
																				<input
																					type="text"
																					id="CategoryName"
																					className="form-control"
																					placeholder="Store url"
																					name="StoreURL"
																					value={this.state.StoreURL}
																					onChange={(text) => {
																						this.setState({
																							StoreURL: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="approvalstatus">
																					Approval Status
																				</label>
																				<input
																					className="form-control"
																					name="approvalstatus"
																					type="text"
																					placeholder="Approval Status"
																					value={this.state.ApprovalStatus}
																					onChange={(text) => {
																						this.setState({
																							ApprovalStatus: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>

																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="enquirynumber">
																					Enquiry Number
																				</label>
																				<input
																					className="form-control"
																					name="enquirynumber"
																					type="text"
																					placeholder="Enquiry Number"
																					value={this.state.EnquiryNumber}
																					onChange={(text) => {
																						this.setState({
																							EnquiryNumber: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		{/*  newly added fields on 25-10-2023 */}
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="transactionnumber">
																					Transaction Sequence Number (TSN)
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					className="form-control"
																					name="transactionnumber"
																					id="transactionnumber"
																					type="text"
																					placeholder="Transaction Number"
																					value={this.state.TransactionNumber}
																					onChange={(text) => {
																						this.setState({
																							TransactionNumber:
																								text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="dispatchsequencenumber">
																					Dispatch Sequence Number (DSN)
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					className="form-control"
																					name="dispatchsequencenumber"
																					id="dispatchsequencenumber"
																					type="text"
																					placeholder="Dispatch Sequence Number"
																					value={
																						this.state.DispatchSequenceNumber
																					}
																					onChange={(text) => {
																						this.setState({
																							DispatchSequenceNumber:
																								text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>

																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="ordersequencenumber">
																					Order Sequence Number
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					className="form-control"
																					name="ordersequencenumber"
																					id="ordersequencenumber"
																					type="text"
																					placeholder="Order Sequence Number"
																					value={this.state.OrderSequenceNumber}
																					onChange={(text) => {
																						this.setState({
																							OrderSequenceNumber:
																								text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="invoicesequencenumber">
																					Invoice Sequence Number
																					<span className="mandatory">*</span>
																				</label>
																				<input
																					className="form-control"
																					name="invoicesequencenumber"
																					id="invoicesequencenumber"
																					type="text"
																					placeholder="Invoice Sequence Number"
																					value={
																						this.state.InvoiceSequenceNumber
																					}
																					onChange={(text) => {
																						this.setState({
																							InvoiceSequenceNumber:
																								text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-4">
																			<div className="form-group">
																				<label for="orderformlink">
																					Order Form Link
																					{/* <span className="mandatory">*</span> */}
																				</label>
																				<input
																					className="form-control"
																					name="orderformlink"
																					id="orderformlink"
																					type="text"
																					placeholder="Order Form Link"
																					value={this.state.OrderFormLink}
																					onChange={(text) => {
																						this.setState({
																							OrderFormLink: text.target.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>

											<div className="col-6">
												<div className="form-group my-1 mr-sm-2">
													<label for="CategoryName">Status</label>
													<br />
													<label class="radio-inline">
														<input
															type="radio"
															name="optradio"
															//  disabled={!this.state.IsVisible}
															checked={
																this.state.Status == "Active" ? true : false
															}
															onChange={() => {
																this.setState({
																	Status: "Active",
																});
															}}
														/>{" "}
														Active
													</label>
													<label
														class="radio-inline"
														style={{ marginLeft: "10px" }}
													>
														<input
															type="radio"
															name="optradio"
															//  disabled={!this.state.IsVisible}
															checked={
																this.state.Status == "Inactive" ? true : false
															}
															onChange={() => {
																this.setState({
																	Status: "Inactive",
																});
															}}
														/>{" "}
														Inactive
													</label>
												</div>
											</div>
											<div className="col-6">
												<button
													style={{
														float: "right",
														marginTop: "5px",
													}}
													className="btn align-items-center Bechofy-btn Bechofy-btn"
													onClick={() => this.CheckMandetoryFields()}
												>
													Save
												</button>
											</div>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1 my-1">
										{!this.state.ShowAddAddressComponent && (
											<Row>
												<Col xl="12">
													<Card className="overflow-hidden pagebackground">
														<div className="Bechofy-bg-soft-pink">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text py-0 my-0">
																	Address Info.
																</h5>
															</div>
														</div>

														<Card style={{ padding: "10px 20px" }}>
															<CardBody>
																<Row>
																	<Col sm={2}>
																		<div>
																			<img
																				role="button"
																				src={AddAddressImage}
																				onClick={() =>
																					this.setState({
																						AddressModal:
																							!this.state.AddressModal,
																					})
																				}
																				alt=""
																			/>
																		</div>
																	</Col>
																	<Col sm={10}>
																		<div
																			className="vinay-scrollbar w-100 d-flex h-100"
																			style={{
																				overflowX: "auto",
																			}}
																		>
																			{this.state.CustomerAddressData.map(
																				(data) => (
																					<div
																						className="mb-4 mr-4 h-100"
																						style={{
																							minWidth: "350px",
																						}}
																					>
																						<CardBody className="border rounded">
																							<div className="d-flex">
																								<p style={{ flex: 0.8 }}>
																									<strong>
																										Contact Name :
																									</strong>
																								</p>
																								<p style={{ flex: 1 }}>
																									{data.ContactName}
																								</p>
																							</div>
																							<div className="d-flex">
																								<p style={{ flex: 0.8 }}>
																									<strong>
																										Phone Number :
																									</strong>
																								</p>
																								<p style={{ flex: 1 }}>
																									{data.PhoneNumber}
																								</p>
																							</div>
																							<div className="d-flex">
																								<p style={{ flex: 0.8 }}>
																									<strong>
																										Email Address :
																									</strong>
																								</p>
																								<p style={{ flex: 1 }}>
																									{data.EmailAddress}
																								</p>
																							</div>
																							<div className="d-flex">
																								<p style={{ flex: 0.8 }}>
																									<strong>Extention :</strong>
																								</p>
																								<p style={{ flex: 1 }}>
																									{data.Extension}
																								</p>
																							</div>
																						</CardBody>
																					</div>
																				)
																			)}
																		</div>
																	</Col>
																</Row>
															</CardBody>
														</Card>
													</Card>
												</Col>
											</Row>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}
export default addCustomer;