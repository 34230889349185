import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PostApiCall from '../../Api';
import Notiflix from "notiflix";
import GetApiCall from '../../GETAPI';
const ImgUpload = ({ onChange, src, id }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas w-100">
        <div className="img-wrap img-upload">

            <button>Click to edit background image</button>
        </div>
        <input
            accept="image/*"
            id="photo-upload"
            type="file"
            onChange={onChange}
        />
    </label>
);

function ParallaxSection(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [imageApiUrl, setImageApiUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage");
    const [imageUrl, setImageUrl] = useState(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path)
    const [heading1, setHeading1] = useState("")
    const [description1, setDescription1] = useState("")
    const [description2, setDescription2] = useState("")
    const [status, setStatus] = useState("")
    const [sequence, setSequence] = useState("")
    const [selectedImage, setSelectedImage] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("")
    const [sectionId, setSectionId] = useState(null)

    const AddWebsiteHomeSection = () => {
        Notiflix.Loading.Dots("")
        PostApiCall.postRequest({
            id: sectionId,
            sequence: sequence,
            sectionname: "Parallax Section",
            status: status,
            headline1: heading1,
            headline2: description1,
            image: backgroundImage
        }, "AddWebsiteHomeSection"
        ).then((results) => {
            results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    GetWebsiteHomeSection();
                    Notiflix.Loading.Remove();
                }
            });
        });
    }
    useEffect(() => {
        GetWebsiteHomeSection();
    }, [])
    const GetWebsiteHomeSection = () => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetHomepageSetup"
        ).then((homepagesetupresult) => {
            homepagesetupresult.json().then((obj) => {
                obj.data.filter(
                    (value) => value.fld_section_name == "Parallax Section"
                ).map(filtereddata => {
                    setSectionId(filtereddata.fld_id)
                    setHeading1(filtereddata.fld_headline1)
                    setDescription1(filtereddata.fld_headline2);
                    setDescription2(filtereddata.fld_headline3);
                    setSequence(filtereddata.fld_sequence);
                    setStatus(filtereddata.fld_status);
                    setSelectedImage(filtereddata.fld_image)
                }
                );



            });
        });
    }
    return (
        <>
            <Container fluid>
                <h2 className='text-center'>Parallax Section</h2>
                <div className='row'>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Section Heading
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={heading1}
                                onChange={(e) => { setHeading1(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Section Description 1
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={description1}
                                onChange={(e) => { setDescription1(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="BasePrice">
                                Sequence
                                <span className="mandatory">*</span>
                            </label>
                            <input
                                type="text"
                                id="BasePrice"
                                className="form-control"
                                value={sequence}
                                onChange={(e) => { setSequence(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <label>
                            Show on website
                            <span className="mandatory">*</span>
                        </label>
                        <select
                            value={status}
                            onChange={(e) => { setStatus(e.target.value) }}
                            className="custom-select"
                        >
                            <option value="">
                                Select
                            </option>
                            <option value="Active">Yes</option>
                            <option value="InActive">No</option>
                        </select>
                    </div>
                </div>

                <section className="parallax height-35" style={{ background: `url(${selectedImage}) no-repeat` }}>
                    <p className='text-white'>*The image will updated on all the places in website where u are using this image</p>
                    <ImgUpload
                        onChange={(e) => {
                            e.preventDefault();
                            const imageFile = e.target.files[0];
                            setSelectedImage(URL.createObjectURL(imageFile));
                            const form = new FormData();
                            let filename = `${Math.floor(Math.random() * 10000)}-${imageFile.name.replace(/ /g, "")}`
                            let foldername = "banners"
                            form.append("file", imageFile);
                            form.append("foldername", foldername);
                            form.append("filename", filename);
                            let response;
                            response = fetch(imageApiUrl, { method: "POST", body: form, })
                                .then((response) => response.json())
                                .then((data) => {
                                    setBackgroundImage(imageUrl + "/" + foldername + "/" + filename.split(".").slice(0, -1).join(".") + ".webp");
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Success("Image successfully uploaded.");
                                });
                        }}
                    // src={selectedImage}
                    />
                    <h1 className="text-white text-center fw-bold">{heading1}</h1>
                    <h4 className="text-white text-center">
                        {description1}
                    </h4>
                    <div className='w-100 text-center'>
                        <div className="btn btn-theme btn-lg mt-lg-5 know-more-btn">Know More</div>
                    </div>
                </section>
                <div className='text-right my-4'>
                    <button className="btn align-items-center Bechofy-btn" onClick={AddWebsiteHomeSection}>Save</button>
                </div>
            </Container>
        </>
    );
}

export default ParallaxSection;




