// Pages Calendar
import Calendar from "../pages/Calendar/index";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";
// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
// Inner Authentication
import LockScreen from "../pages/Authentication/auth-lock-screen";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
//Contacts
import ChangePasswordPage from "../pages/Authentication/ChangePassword";
import LoyaltyPointReport from "../pages/CustomerMangement/LoyaltyPointReport";
// Product Management
import categoryManagement from "../pages/ProductMangement/AddCategory";
import PricemMangement from "../pages/ProductMangement/PriceMangement";
// Order Management
import AddCustomer from "../pages/CustomerMangement/AddCustomer";
import customerList from "../pages/CustomerMangement/CustomerList";
import EditCustomer from "../pages/CustomerMangement/EditCustomer";
import Addattributemaster from "../pages/Masters/AddAttribute";
import AddAttributeDetail from "../pages/Masters/AddAttributeDetail";
import AddAttributeMaster from "../pages/Masters/AddAttributeMaster";
import BrandMaster from "../pages/Masters/AddBrandMaster";
import AddVerticalMapping from "../pages/Masters/AddVerticalMapping";
import AttributeMasterList from "../pages/Masters/AttributeList";
import AddAttributeList from "../pages/Masters/AttributemasterList";
import AddAttributeValueList from "../pages/Masters/AttributeValueList";
import CompanyMaster from "../pages/Masters/CompanyMaster";
import ProductMaster from "../pages/Masters/ProductMaster";
import ProductPinCode from "../pages/Masters/ProductPinCode";
import UpdateAttributeMapping from "../pages/Masters/UpdateAttributeMapping";
import VerticalMapping from "../pages/Masters/VerticalMapping";
import Verticalmaster from "../pages/Masters/Verticalmaster";
import ChildMenuMaster from "../pages/MenuManagement/ChildMenuMaster";
import MainMenuMaster from "../pages/MenuManagement/MainMenuMaster";
import SubMenuMaster from "../pages/MenuManagement/SubMenuMaster";
import AddOffer from "../pages/OfferManagement/AddOffer";
import EditOffer from "../pages/OfferManagement/EditOffer";
import OfferList from "../pages/OfferManagement/OfferList";
import DispatchMangement from "../pages/OrderMangement/DispatchMangement";
import Orderlist from "../pages/OrderMangement/OrderList";
import RefundManagement from "../pages/OrderMangement/RefundManagement";
import RiderResigtrationForm from "../pages/pages/RiderResigtration/RiderResigtrationForm";
import PickupList from "../pages/PickupManagement/PickupList";
import AddItemManagement from "../pages/ProductMangement/AddNewItem";
import AddRawMaterial from "../pages/ProductMangement/AddRawMaterial";
import Addsubcategory from "../pages/ProductMangement/AddSubCategory";
import Categorymanagementlist from "../pages/ProductMangement/CategoryManagementlist";
import CategoryMappingMaster from "../pages/ProductMangement/CategoryMappingMaster";
import ItemManagementList from "../pages/ProductMangement/ItemManagement";
import RawmaterialList from "../pages/ProductMangement/RawmaterialList";
import SubCategoryManagement from "../pages/ProductMangement/SubCategoryManagement";
import Edititem from "../pages/ProductMangement/UpdateNewItem";
import UpdateRawMaterial from "../pages/ProductMangement/UpdateRawMaterial";
import VariantManagement from "../pages/ProductMangement/VariantManagement";
import AddReview from "../pages/ReviewManagemet/AddReview";
import EditReview from "../pages/ReviewManagemet/EditReview";
import ReviewList from "../pages/ReviewManagemet/ReviewList";
import AddRider from "../pages/RiderMangement/AddRider";
import AddRiderArea from "../pages/RiderMangement/AddRiderArea";
import RiderAreaAssignment from "../pages/RiderMangement/RiderAreaAssignment";
import RiderList from "../pages/RiderMangement/RiderList";
import UpdateRider from "../pages/RiderMangement/UpdateRider";
import Add_Career from "../pages/UserManagement/AddCareer";
import AddUser from "../pages/UserManagement/AddUser";
import CareerList from "../pages/UserManagement/Careerlist";
import EditCareer from "../pages/UserManagement/Editcareer";
import Edituser from "../pages/UserManagement/Edituser";
import UserList from "../pages/UserManagement/UserList";
// ===========08/06/2021=========
import ViewCustomer from "../pages/CustomerMangement/ViewCustomer";
import ViewOrder from "../pages/OrderMangement/OrderForm";
import BrandManagementList from "../pages/ProductMangement/BrandManagementList";
import AddStock from "../pages/ProductMangement/StockManagement/AddStock";
import AddStockitem from "../pages/ProductMangement/StockManagement/AddStockitem";
import StockManagement from "../pages/ProductMangement/StockManagement/StockManagement";
//=================Banner Management================////
import AddNewBanner from "../pages/BannerManagement/AddNewBanner";
import BannerList from "../pages/BannerManagement/BannerList";
import Home from "../pages/BannerManagement/Home";
import NewPage from "../pages/BannerManagement/NewPage";
import BannerMaster from "../pages/Masters/BannerMaster";
import LogisticsMaster from "../pages/Masters/LogisticsMaster";
import SelfDeliveryMaster from "../pages/Masters/SelfDeliveryMaster";
import NotificationList from "../pages/Notification/NotificationList";
import DispatchForm from "../pages/OrderMangement/DispatchForm";
import InvoiceForm from "../pages/OrderMangement/InvoiceForm";
//==========================SideBar=====================
import AddAddress from "../pages/CustomerProfile/AddAddress";
import AddressBookForm from "../pages/CustomerProfile/AddressBookForm";
import ChangePassword from "../pages/CustomerProfile/ChangePassword";
import OrderHistory from "../pages/CustomerProfile/OrderHistory";
import Profile from "../pages/CustomerProfile/Profile";
//===========Company Master=======================
import HelpCenter from "../pages/CompanyMaster/HelpCenter";
import MenuBar from "../pages/CompanyMaster/MenuBar";
import Routes from "../pages/CompanyMaster/NavBar/Routes";
import SubmitRequest from "../pages/CompanyMaster/SubmitRequest";
import invoiceList from "../pages/OrderMangement/invoiceList";
import addnewvariant from "../pages/ProductMangement/AddVariant";
// =====Master management========
import addvendor from "../pages/Masters/AddVendor";
import GSTMaster from "../pages/Masters/GSTMaster";
import Unitofmeasurement from "../pages/Masters/Unitofmeasurement";
import PurchaseList from "../pages/ProductMangement/StockManagement/PurchaseList";
//===========Price Management start=============
import PriceList from "../pages/PriceManagement/PriceList";
import AddPrice from '../pages/PriceManagement/SinglePriceManagement/AddPrice';
import SinglePriceList from '../pages/PriceManagement/SinglePriceManagement/SinglePriceManagementList';
import ReduceStock from "../pages/ProductMangement/StockManagement/ReduceStock";
import StockList from "../pages/StockManagement/StockList";
// =======Company And Master========
import AddCompany from '../pages/CompanyMaster/AddCompany';
import CompanyList from '../pages/CompanyMaster/CompanyList';
import AddImages from '../pages/ProductMangement/AddImages';
import AddSEOData from "../pages/SEOManagement/AddSEOData";
import SeoList from "../pages/SEOManagement/SeoList";
import WarehouseMaster from '../pages/WarehouseMaster/AddWarehouse';
import WarehouseList from '../pages/WarehouseMaster/WarehouseList';
import WarehousePincodeMapping from '../pages/WarehouseMaster/WarehousePincodeMapping';
//==Delivery Management==
import DeliveryManagement from "../pages/DeliveryManagement/DeliveryManagement";
import ViewDeliveryChalan from "../pages/DeliveryManagement/ViewDeliveryChalan";
import ViewInvoice from "../pages/OrderMangement/ViewInvoice";
import AllReviews from "../pages/ReviewManagement/VIewAllReviews";
import addStaticPages from "../pages/StaticPages/staticPage";
import StaticPages from "../pages/StaticPages/StaticPageList";
//==BlogMaster==
import AddBlog from "../pages/Blog/AddBlog";
import BlogList from "../pages/Blog/BlogList";
//==reports
import Reports from "../pages/Reports/Reports";

// Feature Products
import FeatureProducts from "../pages/FeatureProduct/FeatureProducts";

// Custom Layout
import CustomSection from "../pages/CustomSection/CustomSection";
import CustomSectionList from "../pages/CustomSection/CustomSectionList";
import WebsiteCms from "../pages/WebsiteCMS/WebsiteCms";
import ClientInfoInternalPages from "../pages/WebsiteCMS/ClientInfoInternalPages";
import BulkProductsUpload from "../pages/BulkUpload/BulkProductsUpload";
import ImpersonateDashboard from "../pages/Dashboard/ImpersonateDashboard";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path: "/dashboar/:email/:pass", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/calendar", component: Calendar },
	{ path: "/loyalty-PointReport", component: LoyaltyPointReport },
	// Order management
	{ path: "/dispatch_management", component: DispatchMangement },
	{ path: "/order_list", component: Orderlist },
	{ path: "/refund-management", component: RefundManagement },
	// RIder management
	{ path: "/add-rider", component: AddRider },
	{ path: "/rider-area-assignment", component: RiderAreaAssignment },
	{ path: "/rider-list", component: RiderList },
	// RIder Authentication
	{ path: "/rider-resigtration-form", component: RiderResigtrationForm },
	// MenuMaster
	{ path: "/main-menu-master", component: MainMenuMaster },
	{ path: "/sub-menu-master", component: SubMenuMaster },
	{ path: "/child-menu-master", component: ChildMenuMaster },
	// Campany Master
	{ path: "/company-master", component: CompanyMaster },
	{ path: "/brand-master/:action", component: BrandMaster },
	{ path: "/product-master", component: ProductMaster },
	// Product management
	{ path: "/category-management", component: Categorymanagementlist },
	{ path: "/bulk-upload", component: BulkProductsUpload },
	{ path: "/add-category/:action", component: categoryManagement },
	{ path: "/price-mangement", component: PricemMangement },
	{ path: "/sub-category-management", component: SubCategoryManagement },
	{ path: "/category-type-master", component: categoryManagement },
	{ path: "/category-mapping-master", component: CategoryMappingMaster },
	{ path: "/add-sub-category/:action", component: Addsubcategory },
	{ path: "/item-management", component: ItemManagementList },
	{ path: "/add-item", component: AddItemManagement },
	{ path: "/update-item", component: AddItemManagement },
	{ path: "/edit-raw-material", component: UpdateRawMaterial },
	{ path: "/variant-management", component: VariantManagement },
	//  =====Rider Management=========
	{ path: "/rider-management", component: RiderList },
	{ path: "/add-rider", component: AddRider },
	{ path: "/rider-area", component: RiderAreaAssignment },
	{ path: "/add-riderarea", component: AddRiderArea },
	{ path: "/edit-rider", component: UpdateRider },
	// add-riderarea
	// =====User Management========
	{ path: "/user-management", component: UserList },
	{ path: "/add-user", component: AddUser },
	// ====Raw Material=========
	{ path: "/raw-material", component: RawmaterialList },
	{ path: "/add-raw-material", component: AddRawMaterial },
	{ path: "/career-management", component: CareerList },
	{ path: "/edit-career", component: EditCareer },
	{ path: "/add-career", component: Add_Career },
	{ path: "/edit-user", component: Edituser },
	{ path: "/add-attribute-value", component: AddAttributeDetail },
	{ path: "/attribute", component: AddAttributeList },
	{ path: "/attribute-value", component: AddAttributeValueList },
	{ path: "/add-attribute", component: AddAttributeMaster },
	{ path: "/attribute-master", component: AttributeMasterList },
	{ path: "/add-attribute-master", component: Addattributemaster },
	{ path: "/edit-attribute-mapping", component: UpdateAttributeMapping },
	{ path: "/vertical-master", component: Verticalmaster },
	{ path: "/vertical-mapping", component: VerticalMapping },
	{ path: "/add-vertical-mapping", component: AddVerticalMapping },
	// this route should be at the end of all other routes
	// =====	Customer Managment ====
	{ path: "/add-customer", component: AddCustomer },
	{ path: "/edit-customer", component: EditCustomer },
	{ path: "/customer-management", component: customerList },
	// ========Review==========
	// =====User Management========
	{ path: "/review-list", component: ReviewList },
	{ path: "/add-review", component: AddReview },
	{ path: "/edit-review", component: EditReview },
	{ path: "/pickup-management", component: PickupList },
	{ path: "/offer-list", component: OfferList },
	{ path: "/add-offer", component: AddOffer },
	{ path: "/edit-offer", component: EditOffer },
	{ path: "/edit-item", component: Edititem },
	{ path: "/product-pincode-mapping", component: ProductPinCode },
	// ============08/06/2021========
	{ path: "/brand-list", component: BrandManagementList },
	{ path: "/view-order", component: ViewOrder },
	{ path: "/customer-profile", component: ViewCustomer },
	{ path: "/stock-management", component: StockManagement },
	{ path: "/stocks", component: AddStock },
	{ path: "/addStockitem", component: AddStockitem },
	//=================Banner Management================////
	{ path: "/banner-list", component: BannerList },
	{ path: "/add-banner", component: AddNewBanner },
	{ path: "/home", component: Home },
	{ path: "/newpage", component: NewPage },
	// ========
	{ path: "/view-notification", component: NotificationList },
	{ path: "/view-invoice", component: InvoiceForm },
	{ path: "/banner-master", component: BannerMaster },
	{ path: "/logistics-master", component: LogisticsMaster },
	{ path: "/self-delivery-master", component: SelfDeliveryMaster },
	{ path: "/view-dispatch", component: DispatchForm },
	//==========================SideBar=====================
	{ path: "/account-profile", component: Profile },
	{ path: "/add-address", component: AddAddress },
	{ path: "/addressbook-form", component: AddressBookForm },
	{ path: "/order_history", component: OrderHistory },
	{ path: "/change_password", component: ChangePassword },
	//===========Company Master=======================
	{ path: "/help-center", component: HelpCenter },
	{ path: "/submit-request", component: SubmitRequest },
	{ path: "/menubar", component: MenuBar },
	// { path: "/navbar", component: Navbar},
	{ path: "/routes", component: Routes },
	{ path: "/add-new-variant", component: addnewvariant },
	{ path: "/invoices-list", component: invoiceList },
	// =============Master============
	{ path: "/gst-master", component: GSTMaster },
	{ path: "/unit_measurement_master", component: Unitofmeasurement },
	{ path: "/add-purchase-order", component: PurchaseList },
	{ path: "/add-vendor", component: addvendor },
	//=====Price Management=============
	{ path: "/upload-price", component: PriceList },
	{ path: "/upload-stock", component: StockList },
	{ path: "/reduce-stock", component: ReduceStock },
	{ path: "/price-management", component: SinglePriceList },
	{ path: "/add-Price", component: AddPrice },
	// =====Master=======
	{ path: "/add-company", component: AddCompany },
	{ path: "/vendor-master", component: CompanyList },
	//==========Warehouse Master==========
	{ path: "/add-warehouse", component: WarehouseMaster },
	{ path: "/warehouse-master", component: WarehouseList },
	{ path: "/warehouse-pincode", component: WarehousePincodeMapping },
	{ path: "/add-images", component: AddImages }, 
	{ path: "/seo-information/:action/:id", component: AddSEOData },
	{ path: "/seo-management", component: SeoList },

	{ path: "/delivery-management", component: DeliveryManagement },
	{ path: "/view-delivery-chalan/:dispatchid", component: ViewDeliveryChalan },
	{ path: "/view-invoice-new/:orderid", component: ViewInvoice },
	{ path: "/viewallreviews", component: AllReviews },
	{ path: "/static-pages-list", component: StaticPages },
	{ path: "/static-pages/:action", component: addStaticPages },
	//==Blog==
	{ path: "/add-blog", component: AddBlog },
	{ path: "/blog-list", component: BlogList },
	//==reports
	{ path: "/report/:type", component: Reports },

	// FeatureProducts
	{ path: "/feature-products", component: FeatureProducts },

	// CustomLayout
	{ path: "/custom-section/:id", component: CustomSection },
	{ path: "/custom-section-list", component: CustomSectionList },
	{ path: "/website-cms", component: WebsiteCms },
	{ path: "/clientinfo/:pagetype", component: ClientInfoInternalPages }
];
const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/change-password-screen", component: ChangePasswordPage },
	{ path: "/register", component: Register },
	{ path: "/pages-maintenance", component: PagesMaintenance },
	{ path: "/pages-comingsoon", component: PagesComingsoon },
	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },
	// Authentication Inner
	{ path: "/pages-login", component: Login }, //Login1
	{ path: "/pages-register", component: Register }, //Register1
	{ path: "/pages-forgot-pwd", component: ForgetPwd }, //Forgetpwd1
	{ path: "/auth-lock-screen", component: LockScreen }, //LockScreen
];
export { authProtectedRoutes, publicRoutes };
