import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-responsive-modal";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Calendericon from '../../assets/images/dashboardimages/calendar.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
class OrderList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			OrderData: [],
			FromDate: null,
			ToDate: null,
			searchInput: "",
			open: false,
			SkipDispatch: null,
			OrderId: null,
			OrderNumber: null,
			Email: null,
			DispatchTypeData: [
				{ label: 'Self Pick / Standard Shipping', value: 'Self Pick / Standard Shipping' },
				{ label: 'Courier Partner', value: 'Courier Partner' },
			],
			DispatchData: null,
			DispatchDate: null,
			ShippingDate: null,
			TrackingNumber: null,
			Amount: null,
			Payment_Mode: null,
			CustomerName: null,
			DeliveryType: null
		}
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		if (localStorage.getItem("ClientDetails") == null) {
			Notiflix.Notify.Failure("Session timed out. Please access the page from main business domain again.")
		} else {
			var clientDetails = localStorage.getItem("ClientDetails");
			var clientInformation = JSON.parse(clientDetails)
			this.setState({
				SkipDispatch: clientInformation[0].fld_customize_dispatch,
			})
		}
		Notiflix.Loading.Dots("");
		PostApiCall.postRequest(
			{
				WhereClause: 'Order By fld_order_id DESC'
			},
			"GetCustomerOrderDetails"
		).then((results4) =>
			results4.json().then((obj4) => {
				if (results4.status == 200 || results4.status == 201) {

					this.setState({
						OrderData: obj4.data,
						Amount: obj4.data[0].Total_Inclusive_Tax,
						Payment_Mode: obj4.data[0].fld_payment_mode,
					});
					Notiflix.Loading.Remove();
				}
			})
		);
	}
	onPost = () => {
		Notiflix.Loading.Dots("");
		PostApiCall.postRequest(
			{
				WhereClause: `where convert(datetime,fld_order_date,103) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)`
			},
			"GetCustomerOrderDetails"
		).then((results4) =>
			results4.json().then((obj4) => {
				if (results4.status == 200 || results4.status == 201) {
					this.setState({
						OrderData: obj4.data,
					});
					Notiflix.Loading.Remove();
				}
			})
		);
	}
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	sendMailer = () => {
		PostApiCall.postRequest({
			orderid: this.state.OrderNumber,
			disptachdate: moment(this.state.DispatchDate).format("DD/MM/YYYY"),
			trackingno: this.state.TrackingNumber,
			shippingdate: moment().format("DD/MM/YYYY"),
			total_amount: this.state.Amount,
			payment_mode: this.state.Payment_Mode == "COD" ? "Cash On Delivery" : this.state.Payment_Mode,
			email: this.state.Email,
			customerName: this.state.CustomerName
		}, "Mailer_Dispatch").then((result) => {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Success('Mail has been successfully sent to the customer with tracking details.')
			window.location.reload()
		}
		)
	}
	sendMailerStatndardShipping = () => {
		PostApiCall.postRequest({
			orderid: this.state.OrderNumber,
			disptachdate: moment(this.state.DispatchDate).format("DD/MM/YYYY"),
			trackingno: this.state.TrackingNumber,
			shippingdate: moment().format("DD/MM/YYYY"),
			total_amount: this.state.Amount,
			payment_mode: this.state.Payment_Mode == "COD" ? "Cash On Delivery" : this.state.Payment_Mode,
			email: this.state.Email,
			customerName: this.state.CustomerName
		}, "DispatchMailer_StandardShipping").then((result) => {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Success('Mail has been successfully sent to the customer with tracking details.')
			window.location.reload()
		}
		)
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "sn",
					sort: "asc",
					width: 150,
				},
				{
					label: "Order Number",
					field: "orderno",
					sort: 'enable',
					width: 270,
				},
				{
					label: "Order Date",
					field: "order",
					sort: 'enable',
					width: 200,
				},
				{
					label: "Customer Name",
					field: "name",
					sort: 'enable',
					width: 100,
				},
				{
					label: "Number of Items",
					field: "items",
					sort: 'enable',
					width: 150,
				},
				{
					label: "Order Value",
					field: "value",
					sort: 'enable',
					width: 100,
				},
				{
					label: "Payment Status",
					field: "paymentstatus",
					sort: 'enable',
					width: 100,
				},
				{
					label: "Order Status",
					field: "orderstatus",
					sort: 'enable',
					width: 100,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.OrderData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.customer_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_order_number
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_payment_status
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_order_status
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					sn: (i + 1),
					orderno: (data.fld_order_number),
					order: (data.fld_order_date),
					name: (data.customer_name),
					items: (data.total_items),
					value: (parseFloat(data.Total_Inclusive_Tax).toFixed(2)),
					paymentstatus: (data.fld_payment_mode == "COD" ? "Pending" : data.fld_payment_status),
					orderstatus: (data.fld_order_status),
					action: (
						<>
							{
								this.state.SkipDispatch == "Yes" ?
									<>{
										(data.fld_payment_status == "Successful" || data.fld_payment_status == "success" || data.fld_payment_mode == "COD") && (data.fld_order_status != "Dispatched") ?
											<button className="button-deliver-custom"
												onClick={() => {
													this.setState({
														open: true,
														OrderId: data.fld_order_id,
														OrderNumber: data.fld_order_number,
														DeliveryType: data.fld_delivery_type
													}, () => {
														PostApiCall.postRequest(
															{
																whereclause: `where fld_order_id=${this.state.OrderId}`,
															},
															"GetCustomerOrderDetails"
														).then((results) =>
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {

																	this.setState({
																		ItemDetails: obj.data,
																		Email: obj.data[0].fld_email,
																		CustomerName: obj.data[0].customer_name
																	});
																	Notiflix.Loading.Remove();
																}
															}))
													});
												}}>
												Deliver
											</button>
											:
											<button className="button-deliver-custom-inactive">
												Deliver
											</button>
									}
									</>
									:
									data.fld_payment_status == "Successful" || data.fld_payment_status == "success" || data.fld_payment_mode == "COD" ?
										<span><i
											className="fas fa-eye btn"
											style={{ fontSize: "15px", paddingTop: '2px' }}
											onClick={() => {
												localStorage.setItem("Dispatchdetails", JSON.stringify(data.fld_order_id));
												window.location.href = "/view-dispatch";
											}}
										></i></span>
										: <span><i
											className="fas fa-eye-slash btn"
											style={{ fontSize: "15px", paddingTop: '2px', cursor: "auto" }}
										></i></span>
							}
						</>
					)
				};
			}),
		};
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Modal
							open={this.state.open}
							onClose={() => {
								this.setState({ open: false });
							}}
							center
						>
							<div className="modal-content modelcontent2">
								<div
									className="modal-header Bechofy-bg-soft-pink"
									style={{ background: "#777f80" }}
								>
									<h4 className="modal-title text-white">
										Add Tracking Details
									</h4>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-12 mb-3">
											<div className="form-group my-1 mr-sm-2">
												<select className="form-control"
													value={this.state.DispatchData}
													onChange={(text) => {
														this.setState({
															DispatchData: text.target.value
														})
													}}
												>
													<option value=''>Select Dispatch Type</option>
													{this.state.DispatchTypeData.map(type => (
														<option
															key={type.value}
															value={type.value}
														>
															{type.label}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label htmlFor="CategoryName">Dispatch Date<span className="mandatory">
													*
												</span></label>
												<DatePicker
													dateFormat="dd/MM/yyyy"
													selected={this.state.DispatchDate}
													onChange={(date) => {
														this.setState({
															DispatchDate: date
														})
													}}
													isClearable
													className="form-control"
													minDate={new Date()}
													onKeyDown={(e) => e.preventDefault()}
												/>
											</div>
										</div>
										<div className="col-12" style={{ display: this.state.DispatchData == "Self Pick / Standard Shipping" ? "none" : "block" }}>
											<div className="form-group">
												<label htmlFor="CategoryName">Tracking URL<span className="mandatory">
													*
												</span></label>
												<input type="text" id="CategoryName" className="form-control"
													value={this.state.TrackingNumber}
													onChange={(number) => {
														this.setState({
															TrackingNumber: number.target.value
														})
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										className="btn align-items-center Bechofy-btn"
										type="submit"
										style={{ float: "right" }}
										onClick={() => {
											this.setState({
												open: false,
											});
										}}
									>
										Close
									</button>
									<button
										style={{ float: "right" }}
										className="btn align-items-center Bechofy-btn "
										onClick={() => {
											if (this.state.DispatchData != null) {
												if (this.state.DeliveryType == "SelfPickup") {
													if (this.state.DispatchDate != null) {
														let details = JSON.parse(localStorage.getItem("LoginDetail"));
														Notiflix.Loading.Dots()
														PostApiCall.postRequest(
															{
																orderid: this.state.OrderId,
																disptachdate: this.state.DispatchDate,
																dispatchmode: this.state.DispatchData,
																status: 'Dispatched',
																trackingno: this.state.TrackingNumber,
																shippingdate: this.state.ShippingDate,
																deliveryagent: null,
																createdBy: details[0].fld_userid,
																updatedby: details[0].fld_userid,
															},
															"UpdateDispatchTrackingDetails"
														).then((resultcategory) =>
															resultcategory.json().then((obj) => {
																if (resultcategory.status == 200 || resultcategory.status == 201) {
																	this.sendMailerStatndardShipping()
																} else {
																	Notiflix.Loading.Remove()
																	Notiflix.Notify.Failure(obj.data)
																}
															})
														);
													} else {
														Notiflix.Notify.Failure("Please select Dispatch Date")
													}
												} else {
													if (this.state.DispatchDate != null) {
														if (this.state.TrackingNumber != null) {
															let details = JSON.parse(localStorage.getItem("LoginDetail"));
															Notiflix.Loading.Dots()
															PostApiCall.postRequest(
																{
																	orderid: this.state.OrderId,
																	disptachdate: this.state.DispatchDate,
																	dispatchmode: this.state.DispatchData,
																	status: 'Dispatched',
																	trackingno: this.state.TrackingNumber,
																	shippingdate: this.state.ShippingDate,
																	deliveryagent: null,
																	createdBy: details[0].fld_userid,
																	updatedby: details[0].fld_userid,
																},
																"UpdateDispatchTrackingDetails"
															).then((resultcategory) =>
																resultcategory.json().then((obj) => {
																	if (resultcategory.status == 200 || resultcategory.status == 201) {
																		this.sendMailer()
																	} else {
																		Notiflix.Loading.Remove()
																		Notiflix.Notify.Failure(obj.data)
																	}
																})
															);
														} else {
															Notiflix.Notify.Failure("Please enter tracking URL.")
														}
													} else {
														Notiflix.Notify.Failure("Please select Dispatch Date")
													}
												}
											} else {
												Notiflix.Notify.Failure("Please select Dispatch Type")
											}
										}}
									>
										Save Tracking Details
									</button>
									<span></span>
								</div>
							</div>
						</Modal>
						{/* Render Breadcrumb */}
						<Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')} urlPath={"/dispatch_management"} />
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<div className="Bechofy-bg-soft-pink" style={{ borderRadius: "3px" }}>
												<div className="row my-1">
													<div className="col-2">
														<div className="form-group">
															<h4 className="FilterCard" style={{ marginTop: '25px' }}>Filter Data By</h4>
														</div>
													</div>
													<div className="col-3" style={{ marginTop: "16px" }}>
														<div className="form-group">
															<DatePicker
																placeholderText="From Date"
																dateFormat="dd/MM/yyyy"
																selected={this.state.FromDate}
																onChange={(date) => {
																	this.setState({
																		FromDate: date
																	})
																}}
																className="form-control"
																maxDate={new Date()}
															/>
															<span className="login-icon-change-pass" style={{ bottom: '30px' }}>
																<img src={Calendericon} alt="datepicker" className="btnicons"></img>
															</span>
														</div>
													</div>
													<div className="col-3">
														<div className="form-group" style={{ marginTop: "16px" }}>
															<DatePicker
																dateFormat="dd/MM/yyyy"
																placeholderText="To Date"
																selected={this.state.ToDate}
																onChange={(date) => {
																	this.setState({
																		ToDate: date
																	})
																}}
																className="form-control"
																maxDate={new Date()}
															/>
															<span className="login-icon-change-pass" style={{ bottom: '30px' }}>
																<img src={Calendericon} alt="datepicker" className="btnicons"></img>
															</span>
														</div>
													</div>
													<div className="col-1">
														<div className="position-relative">
															<a
																onClick={() => {
																	if (this.state.FromDate != null || this.state.ToDate != null) {
																		this.onPost()
																	}
																	else {
																		Notiflix.Notify.Failure("Please select From Date and To Date")
																	}
																}}
																style={{
																	float: "right",
																	marginTop: "16px",
																	background: 'white',
																	color: 'grey'
																}}
																className="btn align-items-center btn MaxXchange-btn "
															>
																Search
															</a>
														</div>
													</div>
													<div className="col-3">
														<div className="position-relative">
															<a
																style={{
																	float: "right",
																	marginTop: "16px",
																	background: 'white',
																	color: 'grey',
																	marginRight: '10px'
																}}
																className="btn align-items-center btn MaxXchange-btn "
															>
																<CSVLink filename={"Dispatch_List.csv"} data={this.state.OrderData}>Download Report (.csv)</CSVLink>
															</a>
														</div>
													</div>
												</div>
											</div>
											<Card className="overflow-hidden">
												<Row>
													<Col xs="6">
														<div className="MaxXchange-text  p-3">
															<h5 className="MaxXchange-text" style={{ color: '#495057' }}>
																Dispatch List
															</h5>
														</div>
													</Col>
												</Row>
												<CardBody className="pt-0">
													<Row>
														<MDBCol md="12" style={{ marginBottom: "10px" }}>
															<input type="text" className="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
																onChange={(e) => this.seachBarHandler(e)}
																value={this.state.searchInput}
															/>
														</MDBCol>
														<Col md="12">
															<MDBDataTable
																hover
																striped
																bordered
																data={data}
																seachTop={false}
																entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
															>
																<MDBTableHead columns={data.columns} />
																<MDBTableBody rows={data.rows} />
															</MDBDataTable>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default OrderList;
