import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./Invoice.css";
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderData: []
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    //   this.props.setclearbrand()
    var customer = localStorage.getItem('InvoiveDetails');
    var CustomerDetails = JSON.parse(customer)
    //
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        orderid: CustomerDetails
      },
      "Get_Dispatch_item_details"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          this.setState({
            OrderData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      }))
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="6" md="12">
                        <div className="Bechofy-text  p-3">
                          <h5 className="Bechofy-text">View Invoice</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <section>
                    <div className="container">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="card invoice" id="invoice">
                            <div className="card-header p-4 d-flex justify-content-between align-items-center">
                              <h1>Bechofy</h1>
                              {/* <p style={{ textAlign: 'center' }}>D 200, 3rd Floor, Mansarover Garden,New Delhi - 110 015.
                                INDIA<br />Phone: +91 11 4703 7722</p> */}
                              <div className="float-right">
                                <h3 className="mb-0">TAX INVOICE</h3>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="row mb-4">
                                <div className="col-sm-6 ">
                                  <table className="table table-sm info-en">
                                    <thead>
                                      <tr>
                                        <th scope="col" colSpan="2">YOUR DETAILS</th>
                                      </tr>
                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody>
                                            <tr>
                                              <th scope="row">NAME</th>
                                              <td>{data.fld_contactperson}</td>
                                            </tr>
                                            <tr>
                                              <th>EMAIL</th>
                                              <td>{data.fld_email}</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERY ADDRESS</th>
                                              <td>{data.fld_address}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>CARD NUMBER & CARD TYPE</th>
                                              <td>MASTER**********1234</td>
                                            </tr>
                                            <tr>
                                              <th>PAYMENT METHOD</th>
                                              <td>{data.Payment_Mode}</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERY METHOD</th>
                                              <td>Standard Delivery</td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    })}
                                  </table>
                                </div>
                                <div className="col-sm-6 ">
                                  <table className="table table-sm info-en">
                                    <thead>
                                      <tr>
                                        <th scope="col" colSpan="2">ORDER DETAILS</th>
                                      </tr>
                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody className="inv-info">
                                            <tr>
                                              <th scope="row">INVOICE DATE</th>
                                              <td>{data.fld_invoice_date}</td>
                                            </tr>
                                            <tr>
                                              <th>INVOICE NUMBER</th>
                                              <td>{data.fld_invoice_number}</td>
                                            </tr>
                                            <tr>
                                              <th>ORDER NUMBER</th>
                                              <td>{data.fld_orderNumber}</td>
                                            </tr>
                                            <tr>
                                              <th>ORDER DATE</th>
                                              <td>{data.fld_order_date}</td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    })}
                                    <thead id="ship">
                                      <th colSpan="2">SHIPED BY</th>
                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody>
                                            <tr>
                                              <td>SIGN</td>
                                              <td>Global Trendz<br />
                                                # 621, 3rd Floor, Anna Salai,<br />
                                                Chennai - 600 006. INDIA.<br />
                                                Phone: +91 44 4309 9119<br />
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    })}
                                  </table>
                                </div>
                              </div>
                              <div className="table-responsive-sm">
                                <table className="table table-sm info-en items">
                                  <thead>
                                    <tr>
                                      <th scope="col" colSpan="11">ORDER SUMMARY</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th>ORDER NO.</th>
                                      <th>HSN CODE</th>
                                      <th>ITEM NAME</th>
                                      <th>QTY</th>
                                      <th>UNIT PRICE</th>
                                      <th>DISCOUNT</th>
                                      <th>IGST</th>
                                      <th>IGST AMOUNT</th>
                                      <th>TOTAL PRICE</th>
                                    </tr>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tr>
                                            <td>{data.fld_orderNumber}</td>
                                            <td>{data.fld_hsncode} </td>
                                            <td>{data.variant_name}</td>
                                            <td>{data.fld_quantity}</td>
                                            <td>Rs. {data.fld_total}</td>
                                            <td>{data.fld_discount}</td>
                                            <td>5.00%</td>
                                            <td className="center">45.24</td>
                                            <td>Rs. {data.fld_total}</td>
                                          </tr>
                                        )
                                      }
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-sm-5">
                                </div>
                                <div className="col-lg-4 col-sm-5 ml-auto">
                                  {this.state.OrderData.map((data, i) => {
                                    if (i === 0) {
                                      return (
                                        <table className="table table-sm info-in sub-total" id="info">
                                          <tbody className="inv-info">
                                            <tr>
                                              <th scope="row">PRODUCTS TOTAL:</th>
                                              <td>Rs. 6997.00</td>
                                            </tr>
                                            <tr>
                                              <th>DISCOUNTS:</th>
                                              <td>-Rs. 1299.00</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERRY</th>
                                              <td>FREE</td>
                                            </tr>
                                          </tbody>
                                          <tbody className="total">
                                            <tr>
                                              <th>TOTAL</th>
                                              <td className="right"><strong>{data.fld_total}</strong></td>
                                            </tr>
                                            <tr>
                                              <td className="right" colSpan="2">
                                                Two Thousand Eight Hundred Seventy Eight Rupees
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Excluding GST</td>
                                              <td className="right">Rs. 2740.95</td>
                                            </tr>
                                            <tr>
                                              <td>IGST:5.00%</td>
                                              <td className="right">Rs. 137.05</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                              {/* <div className="row">
                                            <div className="col-lg-5 col-sm-5 qr-code">
                                              <h3>Scan to Return</h3>
                                              <img src='https://chart.googleapis.com/chart?cht=qr&chl=http%3A%2F%2Fglobaltrendz.com%2F&chs=90x90&choe=UTF-8&chld=L|2' alt='qr code'/>
                                                <p>Or visit customer service return page on Global Trebdz</p>
                                                <img src="https://barcode.tec-it.com/barcode.ashx?data=ABC-abc-1234&code=Code128&translate-esc=on" className="img-fluid" width="150px"/>
                                            </div>
                                                <div className="col-lg-4 col-sm-5 ml-auto">
                                                  <div className="d-flex justify-content-center align-items-center p-4">
                                                    <img src='https://chart.googleapis.com/chart?cht=qr&chl=http%3A%2F%2Fglobaltrendz.com%2F&chs=90x90&choe=UTF-8&chld=L|2' alt='qr code' className=""/>
                                                </div>
                                                  </div>
                                                </div> */}
                            </div>
                            <div className="card-footer bg-white">
                              <p className="mb-0">www.globaltrendz.com, D 200, 3rd Floor, Mansarover Garden, New Delhi - 110 015. INDIA.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <WelcomeComp /> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Invoice;
