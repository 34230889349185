import CKEditor from "ckeditor4-react";
import classnames from "classnames";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
	Card,
	CardBody, Col, Container, NavItem, NavLink, Row, TabContent,
	TabPane
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
class ProductMaster extends Component {
	state = {
		activeTab: 1,
		name: "",
		image: "",
		status: false,
		cmp_id: "",
		brnd_id: "",
		isImageValid: null,
		imageValue: "",
	};
	onAddMoreHandler = () => {
		this.setState({
			inputList: [...this.state.inputList, { menuName: "", pageNAme: null }],
		});
	};
	onRemoveHandler = (index) => {
		let list = [...this.state.inputList];
		//   list.splice(index, 1);
		list = list.filter((data) => data !== index);
		this.setState({ ...this.state, inputList: list });
	};
	handleInputChange = (e, index) => {
		const { name, value } = e.target;
		this.setState({ ...this.state, [name]: value });
	};
	//   onChangeDescription(description){
	//     this.setState({Description:description.editor.getData()})
	// }
	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};
	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			if (tab >= 1 && tab <= 4) {
				this.setState({
					activeTab: tab,
				});
			}
		}
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													Menu
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Product Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-1">
										<div id="basic-pills-wizard" className="twitter-bs-wizard">
											<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.activeTab === 1,
														})}
														onClick={() => {
															this.toggleTab(1);
														}}
													>
														<span className="step-number mr-2">01</span>
														Product Details
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.activeTab === 2,
														})}
														onClick={() => {
															this.toggleTab(2);
														}}
													>
														<span className="step-number mr-2">02</span>
														Description
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.activeTab === 3,
														})}
														onClick={() => {
															this.toggleTab(3);
														}}
													>
														<span className="step-number mr-2">03</span>
														Product Images
													</NavLink>
												</NavItem>
											</ul>
											<TabContent
												activeTab={this.state.activeTab}
												className="twitter-bs-wizard-tab-content"
											>
												<TabPane tabId={1}>
													<div className=" justify-content-center">
														<div className="form">
															<div className="row">
																<div className="col col-12">
																	<div className="form-group">
																		<label htmlFor="sku">
																			Product Name{" "}
																			<span style={{ color: "red" }}>*</span>
																		</label>
																		<input
																			placeholder="Name"
																			className="form-control"
																			type="text"
																			name="sku"
																			id="sku"
																		/>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">SKU</label>
																		<input
																			placeholder="sku"
																			className="form-control"
																			type="text"
																			name="sku"
																			id="sku"
																		/>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="price">Base Price</label>
																		<input
																			placeholder="Base Price"
																			className="form-control"
																			type="number"
																			name="base_price"
																			id="price"
																		/>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">Sub Category</label>
																		<select className="form-control">
																			<option value="sub_cate_1">
																				sub Category1
																			</option>
																			<option value="sub_cate_2">
																				sub Category2
																			</option>
																			<option value="sub_cate_3">
																				sub Category3
																			</option>
																			<option value="sub_cate_4">
																				sub Category4
																			</option>
																		</select>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">
																			Country of Origin{" "}
																		</label>
																		<select className="form-control">
																			<option value="sub_cate_1">IND 1</option>
																			<option value="sub_cate_2">Aus 2</option>
																			<option value="sub_cate_3">
																				China 3
																			</option>
																			<option value="sub_cate_4">USA 4</option>
																		</select>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">Manufactured by</label>
																		<select className="form-control">
																			<option value="sub_cate_1">
																				Manufactured 1
																			</option>
																			<option value="sub_cate_2">
																				Manufactured 2
																			</option>
																			<option value="sub_cate_3">
																				Manufactured 3
																			</option>
																			<option value="sub_cate_4">
																				Manufactured 4
																			</option>
																		</select>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">
																			Manufacturing Date
																		</label>
																		<input
																			placeholder="Name"
																			className="form-control"
																			type="date"
																			name="sku"
																			id="sku"
																		/>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">Marketed by</label>
																		<select className="form-control">
																			<option value="sub_cate_1">
																				Marketed 1
																			</option>
																			<option value="sub_cate_2">
																				Marketed 2
																			</option>
																			<option value="sub_cate_3">
																				Marketed 3
																			</option>
																			<option value="sub_cate_4">
																				Marketed 4
																			</option>
																		</select>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">Imported by </label>
																		<select className="form-control">
																			<option value="sub_cate_1">
																				Imported 1
																			</option>
																			<option value="sub_cate_2">
																				Imported 2
																			</option>
																			<option value="sub_cate_3">
																				Imported 3
																			</option>
																			<option value="sub_cate_4">
																				Imported 4
																			</option>
																		</select>
																	</div>
																</div>
																<div className="col col-6">
																	<div className="form-group">
																		<label htmlFor="sku">Batch Number</label>
																		<input
																			placeholder="Batch Number"
																			className="form-control"
																			type="number"
																			name="batch"
																			id="sku"
																		/>
																	</div>
																</div>
																<div className="col-6 ">
																	<div className="form-group">
																		<label htmlFor="EnableCategories">
																			Enable Product
																		</label>
																		<div className="custom-control custom-switch">
																			<input
																				className="form-control"
																				checked={this.state.status}
																				onChange={(e) => {
																					this.setState({
																						...this.state,
																						status: !this.state.status,
																					});
																				}}
																				type="checkbox"
																				id="customSwitch1"
																			/>
																			<label
																				className="custom-control-label"
																				htmlFor="customSwitch1"
																			>
																				Toggle this switch element
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId={2}>
													<div className=" justify-content-center">
														<div className="form">
															<div className="row">
																<div className="col col-12">
																	<div className="form-group">
																		<label htmlFor="sku">
																			Short Description
																		</label>
																		<textarea
																			placeholder="short description"
																			className="form-control"
																			type="text"
																			name="shortDescription"
																			id="sku"
																		/>
																		<small>
																			Max 1000 character are only allowed
																		</small>
																	</div>
																</div>
																<div className="col col-12">
																	<div className="form-group">
																		<label htmlFor="sku">
																			Long Description
																		</label>
																		<CKEditor
																			config={{
																				extraPlugins:
																					"justify,font,colorbutton",
																			}}
																		/>
																		<small>
																			Max 1000 character are only allowed
																		</small>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId={3}>
													<div className=" justify-content-center">
														<div className="form">
															<div className="row">
																<div className="col-5 ml-3 ">
																	<div className="row">
																		<div className="col col-12">
																			<div className="form-group ">
																				<label className="my-1 mr-2">
																					Product Image 1
																				</label>
																				<PhotoUploader
																					onInput={this.onImagePickerHanlder}
																					center
																					id="image"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-5 ml-3 ">
																	<div className="row">
																		<div className="col col-12">
																			<div className="form-group ">
																				<label className="my-1 mr-2">
																					Product Image 2
																				</label>
																				<PhotoUploader
																					onInput={this.onImagePickerHanlder}
																					center
																					id="image"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-5 ml-3 ">
																	<div className="row">
																		<div className="col col-12">
																			<div className="form-group ">
																				<label className="my-1 mr-2">
																					Product Image 3
																				</label>
																				<PhotoUploader
																					onInput={this.onImagePickerHanlder}
																					center
																					id="image"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-5 ml-3 ">
																	<div className="row">
																		<div className="col col-12">
																			<div className="form-group ">
																				<label className="my-1 mr-2">
																					Product Image 4
																				</label>
																				<PhotoUploader
																					onInput={this.onImagePickerHanlder}
																					center
																					id="image"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
											<ul className="pager wizard twitter-bs-wizard-pager-link">
												<li
													className={
														this.state.activeTab === 1
															? "previous disabled"
															: "previous"
													}
												>
													<button
														className="btn Bechofy-btn"
														onClick={() => {
															this.toggleTab(this.state.activeTab - 1);
														}}
													>
														Previous
													</button>
												</li>
												<li
													className={
														this.state.activeTab === 4
															? "next disabled"
															: "next"
													}
												>
													<button
														className="btn Bechofy-btn"
														onClick={() => {
															this.toggleTab(this.state.activeTab + 1);
														}}
													>
														Next
													</button>
												</li>
											</ul>
										</div>
										<Row></Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1    my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center btn Bechofy-btn "
															onClick={() => { }}
														>
															Create
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default ProductMaster;
