import React, { Component } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { Container } from "reactstrap";
import Navbar from "../CompanyMaster/NavBar/Navbar";
class SubmitRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TopicData: [
                { value: "Managing Account", label: "Managing Account" },
                { value: "Working with Dashboard", label: "Working with Dashboard" },
                { value: "Payment Methods", label: "Payment Methods" },
                { value: "Delivery Information", label: "Delivery Information" },
                { value: "Refund Policy", label: "Refund Policy" },
                { value: "Affiliate Program", label: "Affiliate Program" },
            ],
        }
    }
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid style={{ backgroundColor: 'white' }}>
                        <Navbar />
                        <div className="bg-secondary py-4">
                            <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                                <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                                    <h1 className="h3 mb-0">Submit a request</h1>
                                </div>
                            </div>
                        </div>
                        <div className="container py-5 mt-md-2 mb-md-4">
                            <div className="row">
                                <div className="col-lg-3">
                                    {/* Related articles sidebar */}
                                    <div className="offcanvas offcanvas-collapse border-end" id="help-sidebar">
                                        <div className="offcanvas-header align-items-center shadow-sm">
                                            <h2 className="h5 mb-0">Related articles</h2>
                                            <button className="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div className="offcanvas-body py-grid-gutter py-lg-1" data-simplebar="init" data-simplebar-auto-hide="true">
                                            <div className="simplebar-wrapper" style={{ margin: "-4px 0px" }}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                                        <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden" }}>
                                                            <div className="simplebar-content" style={{ padding: "4px 0px" }}>
                                                                {/* Links */}
                                                                <div className="widget widget-links">
                                                                    <h3 className="widget-title d-none d-lg-block">Related articles</h3>
                                                                    <ul className="widget-list">
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Managing account
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Working with dashboard
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Available payment methods
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Delivery information
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Order tracking instructions
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Refund policy
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Offers and discounts
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Reward points
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Affiliate program
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                After purchase support
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Service terms &amp; conditions
                                                                            </a>
                                                                        </li>
                                                                        <li className="widget-list-item">
                                                                            <a className="widget-list-link" href="#">
                                                                                <i className="ci-book opacity-60 align-middle mt-n1 me-1"></i>
                                                                                Most popular questions
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder" style={{ width: "auto", height: "386px" }}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                                                <div className="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-vertical" style={{ visibility: "hidden" }}>
                                                <div className="simplebar-scrollbar" style={{ height: "0px", display: "none" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="alert alert-info d-flex fs-sm mb-4" role="alert">
                                        <div className="alert-icon">
                                            <FaExclamationCircle />
                                        </div>
                                        <div>Our friendly Support team is always here to help you. Begin by selecting a topic we can help you with.</div>
                                    </div>
                                    <form className="needs-validation" novalidate="">
                                        <div className="row gx-4 gy-3">
                                            <div className="col-sm-6">
                                                <label className="form-label" htmlFor="help-topic">Select a topic <strong className="text-danger">*</strong></label>
                                                <select
                                                    className="custom-select my-1 mr-sm-2"
                                                    id="inlineFormCustomSelectPref"
                                                    value={this.state.Topic}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            Topic: text.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option> - </option>
                                                    {this.state.TopicData.map(
                                                        (topic) => (
                                                            <option
                                                                key={topic.value}
                                                                value={topic.value}
                                                            >
                                                                {topic.label}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">Please choose a topic!</div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label" htmlFor="help-subject">Request Subject </label>
                                                <input className="form-control" type="text" id="help-subject" />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label" htmlFor="help-message">Request Message<strong className="text-danger">*</strong></label>
                                                <textarea className="form-control" rows="6" required="" id="help-message"></textarea>
                                                <div className="invalid-feedback">Please provide a detailed description of your problem!</div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label" htmlFor="help-name">Your Name<strong className="text-danger">*</strong></label>
                                                <input className="form-control" type="text" required="" id="help-name" />
                                                <div className="invalid-feedback">Please enter your name!</div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label" htmlFor="help-email">Your Email<strong className="text-danger">*</strong></label>
                                                <input className="form-control" type="email" required="" id="help-email" />
                                                <div className="invalid-feedback">Please enter valid email address!</div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <button className="btn Bechofy-btn me-4 mr-3" type="submit">Submit a request</button>
                                                <a className="nav-link-style fw-medium d-inline-block align-middle fs-sm py-2 mr-3" href="#">
                                                    <u>Privacy policy</u>
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
export default SubmitRequest;